import { CalendarIcon, PlusIcon, TrashIcon, TruckIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import BoardPopup from "../../components/board/board-popup";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputSelect from "../../components/common/form/input-select";
import BoardReletedEntities, { LAND } from "../../constants/config";
import ButtonSubmit from "../../components/common/form/button-submit";
import InputSearch from "../../components/common/form/input-search";
import LoaderWrapper from "../../components/common/loader/loader-wrapper";
import { useSelector } from "react-redux";
import { getProductsFilter } from "../../api/catalog";
import ProductCell from "../../components/common/table/product-cell";
import { getShippingDynamic } from "../../api/shipping";
import CustomerCell from "../../components/common/table/customer-cell";
import { getFormattedDate } from "../../utils/timeUtils";
import { getQuotationsDynamic } from "../../api/quotation";
import { StatusBarIcon } from "../../components/common/alert/status-bar-icon";
import { getLeads } from "../../api/lead";
import { getCustomersDynamic } from "../../api/customer";
import { getElement, getElementBody, postElement } from "../../api/config";
import { getPathParam } from "../../utils/converter";
import { COMPANY, COMPANY_SUPPLIERS, CONTAINERS } from "../../api/endpoints";
import { BOARD, BOOKINGS, RELETED_ENTITY, TASK } from "../endpoints";
import toast from "react-hot-toast";
import ShippingIcon from "../../resources/icons/ShippingIcon";
import Initials from "../../components/common/avatar/initials";


const RelationEntities = ({ boardData, taskData, onRefresh }) => {
  
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState(false);
  const [isLoading, setLoading] = useState(false);

  
  const {
    control,
    handleSubmit,
    setValue,
    watch
  } = useForm();
  const { t } = useTranslation();


  const onSubmit = async (data) => {

    let sendData = { entity_name: data.entity.value, entity_id: data.entity_id }

    setLoading(true);
    postElement(getPathParam([BOARD, boardData.id, TASK, taskData.id, RELETED_ENTITY]), sendData)
      .then(response => {
        setLoading(false);
        onRefresh(true);
        setIsOpen(false);
        toast.success(t("app.tasks.update_success"))
      }).catch(e => {
        setLoading(false);
        toast.error(e.message);
      });

  };

  return (
   
    <>

      <div className={'flex w-full flex-1 items-center cursor-pointer group ml-3'} onClick={() => {
        setIsOpen(true);
      }}>
        <PlusIcon className="h-4 w-4 flex-shrink-0 text-gray-400 group-hover:text-gray-600" aria-hidden="true" />
        <div className="ml-1 flex min-w-0 flex-1 gap-2">
          <span className="text-sm font-medium leading-6 text-gray-400 group-hover:text-gray-600">{t("app.board.add_relation")}</span>
        </div>
      </div>
    
      {
        isOpen && (
          <BoardPopup
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            canClose={true}
            setClean={() => {
            }}
          >
            <div className="px-4 sm:px-6 -mt-6">
              {/* TITLE */}
              <div className="flex items-center justify-between mt-3">
                <div className="flex items-start space-x-5">
                  <div className="pt-1.5">
                    <h1 className="text-2xl font-bold text-blue-1000">{t("app.board.add_relation")}</h1>
                    <p className="text-sm font-medium text-gray-500 mt-1">
                      {t("app.board.add_relation_title")}
                    </p>
                  </div>
                </div>
              </div>


              <form onSubmit={handleSubmit(onSubmit)} className="-border-none pb-5 mt-5 ">

                  <Controller
                    name="entity"
                    rules={{ required: true }}
                    control={control}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <InputSelect
                        label={t("app.board.property_type")}
                        name={name}
                        options={BoardReletedEntities().filter(r => r.conSelect === true)}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        isLoading={false}
                        isDisabled={false}
                        errors={error}
                        isRequired={true}
                      />
                    )}
                  />


                  {
                    watch('entity') && (
                      <div className="mt-3">
                      <InputSearch onSearch={setSearch} isDynamicSearch={false} hasMargin={false} extraClass={' border border-gray-200 shadow-sm'} />
                      <ul className="mt-2 border border-gray-200  rounded-md h-96 overflow-x-scroll h-96 divide-y divide-gray-200">
                            {
                              watch('entity').value === 'shipping' && (
                              <ShippingList query={search} selectedId={watch('entity_id')} onSelect={(id) => {
                                    setValue('entity_id', id);
                                }} />
                              )
                            }

                            {
                              watch('entity').value === 'shipping_container' && (
                                <ContainerList query={search} selectedId={watch('entity_id')} onSelect={(id) => {
                                  setValue('entity_id', id);
                                }} />
                              )
                            }


                            {
                              watch('entity').value === 'product' && (
                                <ProductList query={search} selectedId={watch('entity_id')} onSelect={(id) => {
                                  setValue('entity_id', id);
                                }} />
                              )
                            }

                            {
                              watch('entity').value === 'quotation' && (
                                <QuotationList query={search} selectedId={watch('entity_id')} onSelect={(id) => {
                                  setValue('entity_id', id);
                                }} />
                              )
                            }

                            {
                                watch('entity').value === 'lead' && (
                                <LeadList query={search} selectedId={watch('entity_id')} onSelect={(id) => {
                                  setValue('entity_id', id);
                                }} />
                              )
                            }

                            {
                              watch('entity').value === 'customer' && (
                                <CustomerList query={search} selectedId={watch('entity_id')} onSelect={(id) => {
                                  setValue('entity_id', id);
                                }} />
                              )
                            }


                            {
                              watch('entity').value === 'supplier' && (
                                <SupplierList query={search} selectedId={watch('entity_id')} onSelect={(id) => {
                                  setValue('entity_id', id);
                                }} />
                              )
                            }

                            {
                              watch('entity').value === 'booking' && (
                              <BookingList query={search} selectedId={watch('entity_id')} onSelect={(id) => {
                                  setValue('entity_id', id);
                                }} />
                              )
                            }

                        
                        
                        
                          </ul>
                      </div>
                    )
                  }


                  <ButtonSubmit
                    btnColor={'bg-blue-1000 hover:bg-blue-900 mt-6'}
                    isLoading={isLoading}
                    isFullWith={true}
                    disabled={!(watch('entity_id') && watch('entity'))}
                    label={t("app.board.property_add")}
                  />

              </form>
            </div>
          </BoardPopup>
        )
      }
      
    </>
  );
};





const ProductList = ({ query, selectedId, onSelect }) => {

  const { loggedCompany } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);


  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    function featchData() {
      if (loggedCompany) {
        setIsLoading(true);
        getProductsFilter({
          company_id: loggedCompany.id,
          params: { limit: 10,  offset: 0, query: query },
          data: {},
          signal,
        })
          .then((response) => {
            setList(response.products);
            setIsLoading(false);
          })
          .catch((e) => {
            if (signal.aborted) {
              setIsLoading(true);
            } else {
              setList([]);
              setIsLoading(false);
            }
          });
      }
    }

    featchData();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line
  }, [query, loggedCompany]);
  return(
    <LoaderWrapper isLoading={isLoading}>
      {
        list.map((item) => (
          <li className={item.id === selectedId ? "p-2 bg-gray-100/[0.5] cursor-pointer" : "p-2 hover:bg-gray-100/[0.5] cursor-pointer"} onClick={() => {
              onSelect(item.id)
          }}>
            <ProductCell product={item} isActiveManufacture={true} />
          </li>
      ))}
    </LoaderWrapper>
  )
}

const ShippingList = ({ query, selectedId, onSelect }) => {

  const { loggedCompany } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);
  const { t } = useTranslation();


  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    function featchData() {
      if (loggedCompany) {
        setIsLoading(true);
        getShippingDynamic({
          company_id: loggedCompany.id,
          params: {
            limit: 10,
            offset: 0,
            query: query,
          },
          data: {},
          signal,
        })
          .then((response) => {
            setList(response.shippings);
            setIsLoading(false);
          })
          .catch((e) => {
            if (signal.aborted) {
              setIsLoading(true);
            } else {
              setList([]);
              setIsLoading(false);
            }
          });
      }
    }

    featchData();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line
  }, [query, loggedCompany]);
  return (
    <LoaderWrapper isLoading={isLoading}>
      {
        list.map((item) => (
          <li className={item.id === selectedId ? "p-2 bg-gray-100/[0.5] cursor-pointer" : "p-2 hover:bg-gray-100/[0.5] cursor-pointer"} onClick={() => {
            onSelect(item.id)
          }}>
            <div className={"flex items-center justify-between"}>
              <CustomerCell customer={item.customer} secondExtra={item?.destination?.name ?? false} extra={item.tracking ? item.tracking : t("app.container.container_not_defined")} />
            
              <div className="flex items-center mr-3">
                <div className="text-left">
                  <div className="text-xs text-blue-1000">
                    {t("dashboard.active_shipping.departure")}
                  </div>
                  <div className="text-xs whitespace-pre-line text-gray-900">
                    {getFormattedDate(item.departure_date)}
                  </div>
                </div>
              </div>
            
            </div>
          </li>
        ))}
    </LoaderWrapper>
  )
}

const QuotationList = ({ query, selectedId, onSelect }) => {

  const { loggedCompany } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);


  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    function featchData() {
      if (loggedCompany) {
        setIsLoading(true);
        getQuotationsDynamic({
          company_id: loggedCompany.id,
          params: {
            limit: 10,
            offset: 0,
            query: query
          },
          data: {},
          signal,
        })
          .then((response) => {
            setList(response.quotation);
            setIsLoading(false);
          })
          .catch((e) => {
            if (signal.aborted) {
              setIsLoading(true);
            } else {
              setList([]);
              setIsLoading(false);
            }
          });
      }
    }

    featchData();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line
  }, [query, loggedCompany]);
  return (
    <LoaderWrapper isLoading={isLoading}>
      {
        list.map((item) => (
          <li className={item.id === selectedId ? "p-2 bg-gray-100/[0.5] cursor-pointer" : "p-2 hover:bg-gray-100/[0.5] cursor-pointer"} onClick={() => {
            onSelect(item.id)
          }}>
            <div className={"flex items-center justify-between"}>
              
              <CustomerCell customer={item.customer} extra={item.quotation_reference} />

              <StatusBarIcon status={item.status} />

            </div>
          </li>
        ))}
    </LoaderWrapper>
  )
}

const LeadList = ({ query, selectedId, onSelect }) => {

  const { loggedCompany } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);


  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    function featchData() {
      if (loggedCompany) {
        setIsLoading(true);
        getLeads({
          company_id: loggedCompany.id,
          params: {
            limit: 10,
            offset: 0,
            query: query
          },
          data: {},
          signal,
        })
          .then((response) => {
            setList(response.leads);
            setIsLoading(false);
          })
          .catch((e) => {
            if (signal.aborted) {
              setIsLoading(true);
            } else {
              setList([]);
              setIsLoading(false);
            }
          });
      }
    }

    featchData();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line
  }, [query, loggedCompany]);
  return (
    <LoaderWrapper isLoading={isLoading}>
      {
        list.map((item) => (
          <li className={item.id === selectedId ? "p-2 bg-gray-100/[0.5] cursor-pointer" : "p-2 hover:bg-gray-100/[0.5] cursor-pointer"} onClick={() => {
            onSelect(item.id)
          }}>
            <div className={"flex items-center justify-between"}>

              <CustomerCell
                customer={{ business_name: item.business_name, color: item.color }}
                extra={item.contact_first_name + ' ' + item.contact_last_name}
                secondExtra={item.lead_reference}
              />

            </div>
          </li>
        ))}
    </LoaderWrapper>
  )
}

const CustomerList = ({ query, selectedId, onSelect }) => {

  const { loggedCompany } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);


  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    function featchData() {
      if (loggedCompany) {
        setIsLoading(true);
        getCustomersDynamic({
          company_id: loggedCompany.id,
          params: {
            limit: 10,
            offset: 0,
            query: query
          },
          data: {},
          signal,
        })
          .then((response) => {
            setList(response.customers);
            setIsLoading(false);
          })
          .catch((e) => {
            if (signal.aborted) {
              setIsLoading(true);
            } else {
              setList([]);
              setIsLoading(false);
            }
          });
      }
    }

    featchData();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line
  }, [query, loggedCompany]);
  return (
    <LoaderWrapper isLoading={isLoading}>
      {
        list.map((item) => (
          <li className={item.id === selectedId ? "p-2 bg-gray-100/[0.5] cursor-pointer" : "p-2 hover:bg-gray-100/[0.5] cursor-pointer"} onClick={() => {
            onSelect(item.id)
          }}>
            <div className={"flex items-center justify-between"}>
              <CustomerCell customer={item} extra={item.importer_reference} />
            </div>
          </li>
        ))}
    </LoaderWrapper>
  )
}

const SupplierList = ({ query, selectedId, onSelect }) => {

  const { loggedCompany } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);


  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    function featchData() {
      if (loggedCompany) {
        setIsLoading(true);
        getElement(getPathParam([COMPANY, loggedCompany.id, COMPANY_SUPPLIERS]), signal, { query: query, offset: 0, limit: 10 }).then((response) => {
          setList(response.company_suppliers);
            setIsLoading(false);
          })
          .catch((e) => {
            if (signal.aborted) {
              setIsLoading(true);
            } else {
              setList([]);
              setIsLoading(false);
            }
          });
      }
    }

    featchData();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line
  }, [query, loggedCompany]);
  return (
    <LoaderWrapper isLoading={isLoading}>
      {
        list.map((item) => (
          <li className={item.id === selectedId ? "p-2 bg-gray-100/[0.5] cursor-pointer" : "p-2 hover:bg-gray-100/[0.5] cursor-pointer"} onClick={() => {
            onSelect(item.id)
          }}>
            <div className={"flex items-center justify-between"}>
              <CustomerCell
                customer={{ business_name: item.name, color: item.color}}
                extra={item.exporter_reference}
              />
            </div>
          </li>
        ))}
    </LoaderWrapper>
  )
}


const ContainerList = ({ query, selectedId, onSelect }) => {

  const { loggedCompany } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);
  const { t } = useTranslation();


  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    function featchData() {
      if (loggedCompany) {
        setIsLoading(true);
        getElement(getPathParam([COMPANY, loggedCompany.id, CONTAINERS]), signal, { query: query, offset: 0, limit: 10 }).then((response) => {
          setList(response.containers);
          setIsLoading(false);
        })
          .catch((e) => {
            if (signal.aborted) {
              setIsLoading(true);
            } else {
              setList([]);
              setIsLoading(false);
            }
          });
      }
    }

    featchData();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line
  }, [query, loggedCompany]);
  return (
    <LoaderWrapper isLoading={isLoading}>
      {
        list.map((item) => (
          <li className={item.id === selectedId ? "p-2 bg-gray-100/[0.5] cursor-pointer" : "p-2 hover:bg-gray-100/[0.5] cursor-pointer"} onClick={() => {
            onSelect(item.id)
          }}>
            <div className={"flex items-center justify-between"}>
              <CustomerCell customer={item.customer} secondExtra={item?.destination?.name ?? false} extra={item.container_number ? item.container_number : item?.container_type?.name ?? ''} />

              <div className="flex items-center mr-3">
                <div className="text-left">
                  <div className="text-xs text-blue-1000">
                    {t("app.container.load_date")}
                  </div>
                  <div className="text-xs whitespace-pre-line text-gray-900">
                    {getFormattedDate(item.load_date)}
                  </div>
                </div>
              </div>

            </div>
          </li>
        ))}
    </LoaderWrapper>
  )
}


const BookingList = ({ query, selectedId, onSelect }) => {

  const { loggedCompany } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);


  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    function featchData() {
      if (loggedCompany) {
        setIsLoading(true);
        getElementBody(getPathParam([COMPANY, loggedCompany.id, BOOKINGS]), signal, { query: query, offset: 0, limit: 10 }).then((response) => {
          setList(response.bookings);
          setIsLoading(false);
        })
          .catch((e) => {
            if (signal.aborted) {
              setIsLoading(true);
            } else {
              setList([]);
              setIsLoading(false);
            }
          });
      }
    }

    featchData();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line
  }, [query, loggedCompany]);
  return (
    <LoaderWrapper isLoading={isLoading}>
      {
        list.map((item) => (
          <li className={item.id === selectedId ? "p-2 bg-gray-100/[0.5] cursor-pointer" : "p-2 hover:bg-gray-100/[0.5] cursor-pointer"} onClick={() => {
            onSelect(item.id)
          }}>
            <div className={"flex items-center justify-between"}>
              <CustomerCell customer={item.connection} secondExtra={item.reference} extra={item.internal_note} />

              <div className="flex items-center mr-3">
                <StatusBarIcon status={item.status} />
              </div>

            </div>
          </li>
        ))}
    </LoaderWrapper>
  )
}








export const BookingCard = ({ data, onSelect, onDelete, canEdit }) => {

  return (

    <fieldset className="hover:bg-gray-200 mt-1   p-1 pl-2 hover:rounded text-sm leading-6  text-gray-700 flex group items-center justify-between cursor-pointer" onClick={onSelect}>
      <div className="flex items-center">

        {
          canEdit && (
            <div className="cursor-pointer " onClick={(e) => {
              e.stopPropagation();
              onDelete()
            }}>
              <TrashIcon className="h-4 w-4 text-red-600 mr-2" />
            </div>
          )
        }

        <CustomerCell customer={data.connection} secondExtra={data.reference} extra={data.internal_note} />
      </div>

  

      <div className="flex items-center mr-3">
        <StatusBarIcon status={data.status} />
      </div>


    </fieldset>
  )
}


export const ShippingCard = ({ data, onSelect, onDelete, canEdit }) => {

  return(

    <fieldset className="hover:bg-gray-200 mt-1   p-1 pl-2 hover:rounded text-sm leading-6  text-gray-700 flex group items-center justify-between cursor-pointer" onClick={onSelect}>
      <div className="flex items-center">

        {
          canEdit && (
            <div className="cursor-pointer " onClick={(e) => {
              e.stopPropagation();
              onDelete()
            }}>
              <TrashIcon className="h-4 w-4 text-red-600 mr-2" />
            </div>
          )
        }
       


        <span className={"flex-shrink-0 inline-flex relative items-center justify-center mr-2 rounded-full bg-gray-900 p-2"}>
          {
            (data.shipping_type && (data.shipping_type.code === LAND)) ? (
              <TruckIcon className={'w-5 h-5 text-white'} />
            ) : (
              <ShippingIcon className={'w-5 h-5 text-white'} />
            )
          }
        </span>

        <div className="flex items-center">
          <div className="text-left">
            <div className="text-sm whitespace-pre-line text-gray-900">
              {data.tracking}
            </div>
            <div className="text-xs whitespace-pre-line text-gray-700">
              {data.customer.business_name}
            </div>
          </div>
        </div>
      </div>


    
        <div className="flex items-center mr-3">
          <div className="text-sm text-gray-900">
            {getFormattedDate(data.departure_date)}
          </div>
          <CalendarIcon className={'w-5 h-5 text-gray-900 ml-2'} />
        </div>


    </fieldset>
  )
}


export const CustomerCard = ({ data, onSelect, onDelete, canEdit }) => {

  return (

    <fieldset className="hover:bg-gray-200 mt-1   p-1 pl-2 hover:rounded text-sm leading-6  text-gray-700 flex group items-center justify-between cursor-pointer" onClick={onSelect}>
      <div className="flex items-center">
        {
          canEdit && (
            <div className="cursor-pointer " onClick={(e) => {
              e.stopPropagation();
              onDelete()
            }}>
              <TrashIcon className="h-4 w-4 text-red-600 mr-2" />
            </div>
          )
        }
       


        <Initials
          color={data.customer_data.color}
          name={data.customer_data.business_name}
          isVerified={data?.supplier_data?.status === 'confirmed' ?? false}
        />
        <div className="text-left">
          <div className="text-sm whitespace-pre-line text-gray-900">
            {data.customer_data.business_name}
          </div>
          <div className="text-xs whitespace-pre-line text-gray-700">
            {data.customer_data.reference}
          </div>
        </div>

      </div>

    </fieldset>
  )
}

export const CompanySupplierCard = ({ data, onDelete, canEdit, onSelect }) => {

  return (

    <fieldset className="hover:bg-gray-200 mt-1   p-1 pl-2 hover:rounded text-sm leading-6  text-gray-700 flex group items-center justify-between cursor-pointer" onClick={onSelect}>
      <div className="flex items-center">

        {
          canEdit && (
            <div className="cursor-pointer " onClick={(e) => {
              e.stopPropagation();
              onDelete()
            }}>
              <TrashIcon className="h-4 w-4 text-red-600 mr-2" />
            </div>
          )
        }
       
       


        <Initials
          color={data.supplier_data.color}
          name={data.supplier_data.business_name}
          isVerified={data?.supplier_data?.status === 'confirmed' ?? false}
        />
        <div className="text-left">
          <div className="text-sm whitespace-pre-line text-gray-900">
            {data.supplier_data.business_name}
          </div>
          <div className="text-xs whitespace-pre-line text-gray-700">
            {data.supplier_data.reference}
          </div>
        </div>

      </div>

    </fieldset>
  )
}


export const ProductCard = ({ data, onSelect, onDelete, canEdit }) => {

  return (

    <fieldset className="hover:bg-gray-200 mt-1   p-1 pl-2 hover:rounded text-sm leading-6  text-gray-700 flex group items-center justify-between cursor-pointer" onClick={onSelect}>
      <div className="flex items-center">
       
        {
          canEdit && (
            <div className="cursor-pointer " onClick={(e) => {
              e.stopPropagation();
              onDelete()
            }}>
              <TrashIcon className="h-4 w-4 text-red-600 mr-2" />
            </div>
          )
        }
     

        <ProductCell product={data} isActiveManufacture={true} />
      </div>

    </fieldset>
  )
}

export const LeadCard = ({ data, onSelect, onDelete, canEdit }) => {

  return (

    <fieldset className="hover:bg-gray-200 mt-1   p-1 pl-2 hover:rounded text-sm leading-6  text-gray-700 flex group items-center justify-between cursor-pointer" onClick={onSelect}>
      <div className="flex items-center">
        
        {
          canEdit && (
            <div className="cursor-pointer " onClick={(e) => {
              e.stopPropagation();
              onDelete()
            }}>
              <TrashIcon className="h-4 w-4 text-red-600 mr-2" />
            </div>
          )
        }

       

        <Initials
          color={data.color}
          name={data.business_name}
        />

        <div className="text-left">
          <div className="text-sm whitespace-pre-line text-gray-900">
            {data.business_name}
          </div>
          <div className="text-xs whitespace-pre-line text-gray-700">
            {data.lead_reference}
          </div>
        </div>

      </div>

    </fieldset>
  )
}

export const ContainerCard = ({ data, onSelect, onDelete, canEdit }) => {

  return (

    <fieldset className="hover:bg-gray-200 mt-1   p-1 pl-2 hover:rounded text-sm leading-6  text-gray-700 flex group items-center justify-between cursor-pointer" onClick={onSelect}>
      <div className="flex items-center">

        {
          canEdit && (
            <div className="cursor-pointer " onClick={(e) => {
              e.stopPropagation();
              onDelete()
            }}>
              <TrashIcon className="h-4 w-4 text-red-600 mr-2" />
            </div>
          )
        }
       


        <span className={"flex-shrink-0 inline-flex relative items-center justify-center mr-2 rounded-full bg-gray-900 p-2"}>
          {
            (data.shipping_type && (data.shipping_type === LAND)) ? (
              <TruckIcon className={'w-5 h-5 text-white'} />
            ) : (
              <ShippingIcon className={'w-5 h-5 text-white'} />
            )
          }
        </span>

        <div className="flex items-center">
          <div className="text-left">
            <div className="text-sm whitespace-pre-line text-gray-900">
              {data.container_number ? data.container_number : data?.container_type?.name ?? '' }
            </div>
            <div className="text-xs whitespace-pre-line text-gray-700">
              {data.customer.business_name}
            </div>
          </div>
        </div>
      </div>



      <div className="flex items-center mr-3">
        <div className="text-sm text-gray-900">
          {getFormattedDate(data.load_date)}
        </div>
        <CalendarIcon className={'w-5 h-5 text-gray-900 ml-2'} />
      </div>


    </fieldset>
  )
}


export const QuotationCard = ({ data, onSelect, onDelete, canEdit }) => {

  return (

    <fieldset className="hover:bg-gray-200 mt-1   p-1 pl-2 hover:rounded text-sm leading-6  text-gray-700 flex group items-center justify-between cursor-pointer" onClick={onSelect}>
      <div className="flex items-center">

        {
          canEdit && (
            <div className="cursor-pointer " onClick={(e) => {
              e.stopPropagation();
              onDelete()
            }}>
              <TrashIcon className="h-4 w-4 text-red-600 mr-2" />
            </div>
          )
        }
      


        <Initials
          color={data.customer.color}
          name={data.customer.business_name}
          isVerified={data.customer?.status === 'confirmed' ?? false}
        />
        <div className="flex items-center">
          <div className="text-left">
            <div className="text-sm whitespace-pre-line text-gray-900">
              {data.quotation_reference}
            </div>
            <div className="text-xs whitespace-pre-line text-gray-700">
              {data.customer.business_name}
            </div>
          </div>
        </div>
      </div>



      <div className="flex items-center mr-3">
        <StatusBarIcon status={data.status} />
      </div>


    </fieldset>
  )
}


export default RelationEntities;

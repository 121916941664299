



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function UnderlineTab({ tabs, activeGroup, setActiveGroup, marginTop = 'mt-10', extraClass = '', baseClass = 'mb-4', hasBorder = true}) {
    return (
        <div className={baseClass}>
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                    Select a tab
                </label>
                {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                <select
                    id="tabs"
                    name="tabs"
                    onChange={(event) => {setActiveGroup(event.target.value)}}
                    className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    defaultValue={activeGroup}
                >
                    {tabs.map((tab, index) => (
                        <option key={tab.group + index} value={tab.group}>{tab.name}</option>
                    ))}
                </select>
            </div>
            <div className={"hidden sm:block " + marginTop}>
                <div className={hasBorder ? "border-b border-gray-200" : ""}>
                    <nav className={"-mb-px flex space-x-8 " + extraClass} aria-label="Tabs">
                        {tabs.map((tab, index) => (
                            <button
                                onClick={() => {setActiveGroup(tab.group)}}
                                key={tab.name + index}
                                className={classNames(
                                    activeGroup === tab.group
                                        ? 'border-sky-400 text-blue-1000'
                                        : 'border-transparent text-blue-1000/[0.5] hover:text-blue-1000 hover:border-sky-400',
                                    'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-xs uppercase'
                                )}
                                aria-current={tab.current ? 'page' : undefined}
                            >
                                {tab.name}
                            </button>
                        ))}
                    </nav>
                </div>
            </div>
        </div>
    )
}

import React, { useEffect, useState } from "react";
import {Link, useLocation} from "react-router-dom";
import PageHeader, { ActionButton } from "../../components/layout/page-header";
import { useDispatch, useSelector } from "react-redux";
import AddShipping from "./add-shipping";
import ActionsMenu from "../../components/common/table/actions-menu";
import {CONTAINERS, DOCUMENTS, EVENTS, LOGISTICS, SHIPPING, SHIPPING_DETAIL, SHIPPING_OVERVIEW, SHIPPINGS} from "../endpoints";
import ShippingIcon from "../../resources/icons/ShippingIcon";
import { getFormattedDate } from "../../utils/timeUtils";
import {getShippingDynamic} from "../../api/shipping";
import {getPathParam, getWebPathParam} from "../../utils/converter";
import CustomerCell from "../../components/common/table/customer-cell";
import {getPermission, permissionGroup, permissionType, sp} from "../../constants/permissions";
import { useTranslation } from "react-i18next";
import ListSection from "../../components/layout/list-section";
import OptionalLink from "../../components/common/table/optional-link";
import CardDetail from "../../components/common/list/card-detail";
import TableDetail from "../../components/common/list/table-detail";
import {COMPANY, SHIPPING_IMPORTER} from "../../api/endpoints";
import RemovePopup from "../../components/common/popup/remove-popup";
import {COMPANY_EXPORTER, COMPANY_IMPORTER, LAND} from "../../constants/config";
import {hasSubscriptionPermission} from "../../components/partials/restricted/subscription-wrapper";
import {subscriptionUsageLimits} from "../../reducers/subscriptionReducer";
import {PlusIcon, TruckIcon} from "@heroicons/react/outline";
import AnimatedPing from "../../components/common/progress/animated-ping";
import DynamicFilterMenu from "../../components/common/table/dynamic-filter-menu";
import {generateParamsFormLocation} from "../../constants/dynamic-filter";
import BasePermissionWrapper, {hasPermissionFor} from "../../components/partials/restricted/base-permission-wrapper";
import {StatusBarIcon} from "../../components/common/alert/status-bar-icon";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const Shippings = () => {
  const { loggedCompany, companyType, isExporter } = useSelector((state) => state.user);
  const { permissions, subscriptions } = useSelector((s) => s.userCompany);
  const { t } = useTranslation();
  const location = useLocation();

  const columns = [
    {
      header:  isExporter || isExporter === null ? t("app.common.customer") : t("app.common.supplier"),
      id: 'business_name',
      accessor: r => r.customer.business_name,
    },
    {
      header: t("app.shippings.destination"),
      id: 'destination',
      accessor: r => r?.destination?.name ?? '-',
    },
    {
      header: t("app.shippings.tracking"),
      id: 'tracking',
      accessor: "tracking"
    },
    {
      header: t("app.shippings.dep_date"),
      id: 'departure_date',
      accessor: r => new Date(r.departure_date).getTime()
    },
    {
      header: t("app.shippings.arr_date"),
      id: 'arrival_date',
      accessor: r => new Date(r.arrival_date).getTime()
    },
    {
      header: t("app.shippings.status"),
      id: 'status',
      accessor: "status"
    },
    {
      header: "",
      accessor: null
    }
  ];



  const [isFetchShippingLoading, setFetchShippingLoading] = useState(true);
  const [shippingList, setShippingList] = useState([]);
  const [shippingListRows, setShippingListRows] = useState(0);

  const [limit] = useState(20);


  const checkOffset = () => {
    const pageNumber = new URLSearchParams(window.location.search).get(
        "page"
    );

    if(pageNumber){
      return parseInt(((pageNumber * limit) - limit));
    }
    return 0;
  }

  const [offset, setOffset] = useState(checkOffset());

  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenRemove, setOpenRemove] = useState(false);
  const [removeDetail, setRemoveDetail] = useState({});
  const [needRefresh, setNeedRefresh] = useState(false);
  const [sortBy, setSortBy] = useState({accessor: null, type: null, id: null, position: 0});

  const [dynamicFilters, setDynamicFilters] = useState(false);
  const [activeDynamicFilter, setDynamicDateFilter] = useState(generateParamsFormLocation(location.search));

  const [defaultParams, setDefaultParams] = useState(false);
  const [defaultParamsUsed, setDefaultParamsUsed] = useState(false);
  const [lastCompanyType, setLastCompanyType] = useState(companyType);
  const [resetPagination, setResetPagination] = useState(false);

  const dispatch = useDispatch();


  useEffect(() => {
    if(companyType !== lastCompanyType){
      setDynamicDateFilter([]);
      setDefaultParams(false);
      setDefaultParamsUsed(false);
    }
    // eslint-disable-next-line
  }, [companyType]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;


    async function fetchShippings() {
      if (loggedCompany) {
        setFetchShippingLoading(true);
        try {
          const r = await getShippingDynamic({
            company_id: loggedCompany.id,
            params: {
              limit: limit,
              offset: offset,
              query: query,
              remove_default: !defaultParamsUsed,
              company_type: companyType

            },
            data: activeDynamicFilter,
            signal,
          });

          setResetPagination(false);

          if(query.length > 0 || offset === 0){
            setShippingList(r.shippings);
            setShippingListRows(r.total_rows);
          }else{
            if(r.total_rows > 0 ){
              setShippingList(r.shippings);
              setShippingListRows(r.total_rows);
            }else{
              setShippingList([])
            }
          }
          if(r.filters){
            setDynamicFilters(r.filters);
          }

          if(r.default_filter && !defaultParamsUsed && (generateParamsFormLocation(location.search).length === 0)){
            setDefaultParams('?'+r.default_filter);
          }
          setLastCompanyType(companyType);
          setNeedRefresh(false);
        } catch (e) {
          setShippingList([]);
          setShippingListRows(0);
          if(signal.aborted){
            setFetchShippingLoading(true);
          }
        } finally {
          if(signal.aborted){
            setFetchShippingLoading(true);
          }else{
            setFetchShippingLoading(false);
          }
        }
      }
    }

    fetchShippings();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line
  }, [dispatch, offset, query, loggedCompany, companyType, needRefresh, limit, activeDynamicFilter]);



  return (
    <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.SHIPPING, permissionType.LIST)]} renderBaseOnFail={true}>
      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          {/* Page header */}
          <PageHeader canSwitch={true} title={t("app.shippings.shippings")}>

            <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.SHIPPING, permissionType.CREATE)]}>
              <ActionButton
                icon={
                  <PlusIcon
                    className="w-4 h-4 "
                  />
                }
                onClick={() => {
                  setIsOpen(true);
                }}
                text={t("app.shippings.new_shipping")}
              />
            </BasePermissionWrapper>

          </PageHeader>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">


          <ListSection
            pageLimit={limit}
            title={isExporter || isExporter === null ? t("app.common.customer") : t("app.common.supplier")}
            columns={columns}
            totalRows={shippingListRows}
            list={shippingList}
            onSearch={(r) => {
              setResetPagination(true);
              setOffset(0);
              setQuery(r);
            }}
            isLoading={isFetchShippingLoading}
            paginationOnChange={setOffset}
            isDynamicPagination={true}
            mobileRow={ShippingMobRow}
            desktopRow={ShippingDeskRow}
            resetPagination={resetPagination}
            isTableSort={true}
            sortBy={sortBy}
            onSortBy={(n) => {
              setSortBy(n);
            }}
            activeDynamicFilter={true}
            extras={{
              onRemove: (r, detail) => {
                setOpenRemove(r);
                setRemoveDetail(detail);
              },
              isSubscriptionActive: hasSubscriptionPermission(subscriptions, sp.SHIPPING_INTERNAL_PACKAGE),
              isLinked: hasPermissionFor(permissions, permissionGroup.SHIPPING, permissionType.EDIT),
              canView:  hasPermissionFor(permissions, permissionGroup.SHIPPING, permissionType.VIEW),
              canDelete:  hasPermissionFor(permissions, permissionGroup.SHIPPING, permissionType.DELETE),
              isLinkedContainer: hasPermissionFor(permissions, permissionGroup.CONTAINER, permissionType.LIST),
              isLinkedDocument: hasPermissionFor(permissions, permissionGroup.DOCUMENT, permissionType.LIST),
              isLinkedEvent: hasPermissionFor(permissions, permissionGroup.EVENT, permissionType.LIST)
            }}
          >
            <DynamicFilterMenu
                filters={dynamicFilters}
                defaultParams={defaultParams}
                lastCompanyType={lastCompanyType}
                isLoading={isFetchShippingLoading && offset === 0}
                filterType={'shippings'}
                onChange={(r) => {
                  setDynamicDateFilter(r);
                  setOffset(0);
                  const querySearch = new URLSearchParams(window.location.search).get("querySearch");
                  if (querySearch) {
                    setQuery(querySearch);
                  } else {
                    setQuery('');
                  }
                  setDefaultParamsUsed(true);
                  setDefaultParams(false);

                }}
            />
          </ListSection>
        </div>

        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.SHIPPING, permissionType.DELETE)]}>
          {isOpenRemove && (
              <RemovePopup
                  isOpen={isOpenRemove}
                  setIsOpen={setOpenRemove}
                  detail={removeDetail}
                  onRemoveConfirmed={() => {
                    setNeedRefresh(true);
                    dispatch(subscriptionUsageLimits(loggedCompany.id));
                  }}
              />
          )}
        </BasePermissionWrapper>


        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.SHIPPING, permissionType.CREATE)]}>
          {isOpen && <AddShipping isOpen={isOpen} setIsOpen={setIsOpen} />}
        </BasePermissionWrapper>

      </div>
    </BasePermissionWrapper>
  );
};

const ShippingMobRow = ({ element, extras }) => {
  const { t } = useTranslation();
  const { companyType, loggedCompany, isExporter } = useSelector((s) => s.user);
  const canEdit = (companyType === element.creator_type ) || (isExporter && element.creator_type === COMPANY_IMPORTER && element.can_edit) || (!isExporter && element.creator_type === COMPANY_EXPORTER && element.can_edit);

  const removeDetail = {
    title: t("app.shippings.remove_title"),
    message: t("app.shippings.remove_description"),
    endpoint: getPathParam([COMPANY, loggedCompany.id, isExporter ? SHIPPING : SHIPPING_IMPORTER, element.id]),
    redirect: getWebPathParam([LOGISTICS, SHIPPINGS]),
  };

  return (
    <li key={element.id} className="flex items-center gap-4 p-4">
      <OptionalLink
        isLinked={false}
        to={getWebPathParam([LOGISTICS, SHIPPING, element.id, SHIPPING_DETAIL])}
        className="flex-grow"
      >
        <div className="pb-4 items-center flex justify-between">

          <div className={"flex items-center"}>

            <div className="mr-4">
              {
                (element.shipping_type && (element.shipping_type.code === LAND)) ? (
                    <TruckIcon className={'w-5 h-5 text-blue-1000'} />
                ) : (
                    <ShippingIcon className={'w-5 h-5 text-blue-1000'} />
                )
              }
            </div>

            <CustomerCell customer={element.customer} extra={!isExporter ? (element?.customer_note ?? false) : false} />
          </div>
          <ActionsMenu>
            { extras.isSubscriptionActive && extras.isLinked && canEdit ? (
              <Link to={getWebPathParam([LOGISTICS + SHIPPING, element.id, SHIPPING_DETAIL])}>
                  {t("app.common.edit")}
                </Link>
            ) : (
                (extras.isSubscriptionActive && extras.isLinkedContainer && canEdit) ? (
                  <Link to={getWebPathParam([LOGISTICS + SHIPPING, element.id, CONTAINERS])}>
                      {t("app.common.edit")}
                    </Link>
                ) : (
                    (extras.isSubscriptionActive && extras.isLinkedDocument && canEdit) ? (
                      <Link to={getWebPathParam([LOGISTICS + SHIPPING, element.id, DOCUMENTS])}>
                          {t("app.common.edit")}
                        </Link>
                    ) : (
                        (extras.isSubscriptionActive && extras.isLinkedEvent && canEdit) && (
                          <Link to={getWebPathParam([LOGISTICS + SHIPPING, element.id, EVENTS])}>
                              {t("app.common.edit")}
                            </Link>
                        )
                    )
                )
            )}
            {extras.canView && (
              <Link
                to={getWebPathParam([LOGISTICS + SHIPPING, element.id, SHIPPING_OVERVIEW])}
              >
                {t("app.common.overview")}
              </Link>
            )}

            {extras.isSubscriptionActive && (extras.canDelete && companyType === element.creator_type && ((isExporter && (element.shipping_closed === false)) || (!isExporter && (element.shipping_closed_importer === false)))) && (
                <button className={'text-left'} onClick={() => {
                  extras.onRemove(true, removeDetail);
                }}
                >
                  {t("app.common.remove")}
                </button>
            )}
          </ActionsMenu>
        </div>

        <div className="flex flex-col">

          <CardDetail title={t("app.shippings.tracking")}>
            {element.tracking ? element.tracking : t("app.container.container_not_defined")}
          </CardDetail>

          <CardDetail title={t("app.shippings.status")}>

            {
              isExporter ? (
                  <StatusBarIcon status={element.status} />
              ) : (
                  <StatusBarIcon status={element.shipping_closed_importer ? 'completed' : 'in_progress'} />
              )
            }
          </CardDetail>

          <CardDetail title={t("app.shippings.destination")}>
            {element?.destination?.name ?? '-'}
          </CardDetail>

          <CardDetail title={t("app.shippings.dep_date")}>
            {getFormattedDate(element.departure_date)}
          </CardDetail>

          <CardDetail title={t("app.shippings.arr_date")}>
            {getFormattedDate(element.arrival_date)}
          </CardDetail>

        </div>
      </OptionalLink>
    </li>
  );
};

const ShippingDeskRow = ({ element, extras }) => {
  const { t } = useTranslation();
  const { companyType, loggedCompany, isExporter } = useSelector((s) => s.user);
  const canEdit = (companyType === element.creator_type ) || (isExporter && element.creator_type === COMPANY_IMPORTER && element.can_edit) || (!isExporter && element.creator_type === COMPANY_EXPORTER && element.can_edit);

  const removeDetail = {
    title: t("app.shippings.remove_title"),
    message: t("app.shippings.remove_description"),
    endpoint: getPathParam([COMPANY, loggedCompany.id, isExporter ? SHIPPING : SHIPPING_IMPORTER, element.id]),
    redirect: getWebPathParam([LOGISTICS, SHIPPINGS]),
  };


  return (
    <tr key={element.id}>
      <TableDetail extraClass={classNames(
          "max-w-sm",
          element.tracking_state ? "relative" : ""
      )}>


        {
          element.tracking_state && (
                <AnimatedPing color={element.tracking_state.error_color} extraClass={'sm:mt-4 sm:-ml-4 lg:mt-2 lg:-ml-3'} />
            )
        }
        <div className="flex items-center space-x-3 lg:pl-2">
          <OptionalLink
              isLinked={extras.isSubscriptionActive && extras.isLinked && canEdit}
            to={getWebPathParam([LOGISTICS, SHIPPING, element.id, SHIPPING_DETAIL])}
              className="truncate hover:text-gray-600"
          >
              <CustomerCell customer={element.customer} extra={!isExporter ? (element?.customer_note ?? false) : false} />
          </OptionalLink>
        </div>
      </TableDetail>

      <TableDetail>

        <div className={"flex items-center"}>

          <div className="mr-4">
            {
              (element.shipping_type && (element.shipping_type.code === LAND)) ? (
                  <TruckIcon className={'w-5 h-5 text-blue-1000'} />
              ) : (
                  <ShippingIcon className={'w-5 h-5 text-blue-1000'} />
              )
            }
          </div>
          <div>
            <div className="text-sm text-gray-900 capitalize">
              {element?.destination?.name ?? '-'}
            </div>
            <div className="text-xs text-gray-500 capitalize">
              {element?.destination?.country  ?? '-'}
            </div>
          </div>

        </div>
      </TableDetail>

      <TableDetail>
        {element.tracking ? element.tracking : t("app.container.container_not_defined")}
      </TableDetail>

      <TableDetail>
        {getFormattedDate(element.departure_date)}
      </TableDetail>

      <TableDetail>
        {getFormattedDate(element.arrival_date)}
      </TableDetail>

      <TableDetail>

        {
          isExporter ? (
              <StatusBarIcon status={element.status} />
          ) : (
              <StatusBarIcon status={element.shipping_closed_importer ? 'completed' : 'in_progress'} />
          )
        }

      </TableDetail>





      <TableDetail>
        <ActionsMenu>

          { extras.isSubscriptionActive && extras.isLinked && canEdit ? (
            <Link to={getWebPathParam([LOGISTICS + SHIPPING, element.id, SHIPPING_DETAIL])}>
              {t("app.common.edit")}
            </Link>
          ) : (
              extras.isSubscriptionActive && extras.isLinkedContainer && canEdit ? (
                <Link to={getWebPathParam([LOGISTICS + SHIPPING, element.id, CONTAINERS])}>
                    {t("app.common.edit")}
                  </Link>
              ) : (
                  extras.isSubscriptionActive && extras.isLinkedDocument && canEdit ? (
                    <Link to={getWebPathParam([LOGISTICS + SHIPPING, element.id, DOCUMENTS])}>
                        {t("app.common.edit")}
                      </Link>
                  ) : (
                      (extras.isSubscriptionActive && extras.isLinkedEvent && canEdit) && (
                        <Link to={getWebPathParam([LOGISTICS + SHIPPING, element.id, EVENTS])}>
                            {t("app.common.edit")}
                          </Link>
                      )
                  )
              )
          )}

          {extras.canView && (
            <Link
              to={getWebPathParam([LOGISTICS + SHIPPING, element.id, SHIPPING_OVERVIEW])}
            >
              {t("app.common.overview")}
            </Link>
          )}
          {extras.isSubscriptionActive && (extras.canDelete && companyType === element.creator_type && ((isExporter && (element.shipping_closed === false)) || (!isExporter && (element.shipping_closed_importer === false)))) && (
              <button className={'text-left'} onClick={() => {
                extras.onRemove(true, removeDetail);
              }}
              >
                {t("app.common.remove")}
              </button>
          )}
        </ActionsMenu>
      </TableDetail>
    </tr>
  );
};

export default Shippings;

import React from 'react'
import NumberFormat from "react-number-format";
import SlidePanel from "../../../components/layout/slide-panel";
import {useTranslation} from "react-i18next";
import Loader from "../../../components/common/loader/loader";
import {useSelector} from "react-redux";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const LoadContainerDetail = ({isOpen, setIsOpen, containerLoadDetails, containerDetail = false, isSeaType, isLoading = false}) => {
    const { t } = useTranslation();
    const { company } = useSelector((state) => state.userCompany);

    return (

        <SlidePanel title={isSeaType ? t("app.container.container_status") : t("app.container.truck_status")}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    bg={'bg-white'}
                    setClean={() => {

                    }}
        >
            <div className="space-y-6 pt-6 pb-5">

                {
                    isLoading ? (
                        <Loader />
                    ) : (
                        <>
                            
                            <div>
                                <div className='flex items-center justify-between'>
                                        <h3 className="font-medium text-gray-900">{t("app.container.container_total_title")}</h3>
                                        <p className='font-bold text-gray-900'>{containerLoadDetails.percentage.toFixed(2)}%</p>
                                </div>
                                    
                                <div className="w-full bg-gray-200 rounded mt-1">
                                    <div className={classNames(
                                        containerLoadDetails.percentage >= 50 ? 'shim-green' : '',
                                        containerLoadDetails.percentage < 50 ? 'shim-red' : '',
                                        "text-xs font-medium rounded text-black text-center p-1 leading-none "
                                        )} style={{ width: (containerLoadDetails.percentage > 100 ? 100 : containerLoadDetails.percentage) + '%' }}>
                                    </div>
                                </div>

                                <dl className="mt-2  border-b border-gray-200 divide-y divide-gray-200">
                                    <div className="py-3 flex justify-between text-sm font-medium">
                                        <dt className="text-gray-500">{t("app.container.volume")}</dt>
                                        <dd className="text-gray-900">
                                            <NumberFormat
                                                value={containerLoadDetails.total_volume}
                                                displayType={"text"}
                                                decimalScale={2}
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                suffix={' m³'}
                                            />
                                        </dd>
                                    </div>
                                    <div className="py-3 flex justify-between text-sm font-medium">
                                        <dt className="text-gray-500">{t("app.container.gross_weight")}</dt>
                                        <dd className="text-gray-900">
                                            <NumberFormat
                                                value={containerLoadDetails.total_gross_weight}
                                                displayType={"text"}
                                                decimalScale={2}
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                suffix={' Kg'}
                                            />
                                        </dd>
                                    </div>

                                    <div className="py-3 flex justify-between text-sm font-medium">
                                        <dt className="text-gray-500">{t("app.container.net_weight")}</dt>
                                        <dd className="text-gray-900">
                                            <NumberFormat
                                                value={containerLoadDetails.total_net_weight}
                                                displayType={"text"}
                                                decimalScale={2}
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                suffix={' Kg'}
                                            />
                                        </dd>
                                    </div>


                                    {
                                        containerLoadDetails.total_price && (
                                            <div className="py-3 flex justify-between text-sm font-medium">
                                                <dt className="text-gray-500">{t("app.container.total_price")}</dt>
                                                <dd className="text-gray-900">
                                                    <NumberFormat
                                                        value={containerLoadDetails.total_price}
                                                        displayType={"text"}
                                                        decimalScale={2}
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        suffix={" " + (company.currency?.currency_symbol ?? '')}
                                                    />
                                                </dd>
                                            </div>
                                        )
                                    }


                                </dl>
                            </div>

                            <div>

                                <div className='flex items-center justify-between'>
                                    <h3 className="font-medium text-gray-900">{t("app.container.container_confirmed_title")}</h3>
                                    <p className='font-bold text-gray-900'>{containerLoadDetails.percentage_confirmed.toFixed(2)}%</p>
                                </div>

                                <div className="w-full bg-gray-200 rounded mt-1">
                                    <div className={classNames(
                                        containerLoadDetails.percentage_confirmed >= 50 ? 'shim-green' : '',
                                        containerLoadDetails.percentage_confirmed < 50 ? 'shim-red' : '',
                                        "text-xs font-medium rounded text-black text-center p-1 leading-none "
                                        )} style={{ width: (containerLoadDetails.percentage_confirmed > 100 ? 100 : containerLoadDetails.percentage_confirmed) + '%' }}>

                                        
                                    </div>
                                </div>


                                <dl className="mt-2  border-b border-gray-200 divide-y divide-gray-200">
                                    <div className="py-3 flex justify-between text-sm font-medium">
                                        <dt className="text-gray-500">{t("app.container.volume")}</dt>
                                        <dd className="text-gray-900">
                                            <NumberFormat
                                                value={containerLoadDetails.total_volume_confirmed}
                                                displayType={"text"}
                                                decimalScale={2}
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                suffix={' m³'}
                                            />
                                        </dd>
                                    </div>
                                    <div className="py-3 flex justify-between text-sm font-medium">
                                        <dt className="text-gray-500">{t("app.container.gross_weight")}</dt>
                                        <dd className="text-gray-900">
                                            <NumberFormat
                                                value={containerLoadDetails.total_gross_weight_confirmed}
                                                displayType={"text"}
                                                decimalScale={2}
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                suffix={' Kg'}
                                            />
                                        </dd>
                                    </div>

                                    <div className="py-3 flex justify-between text-sm font-medium">
                                        <dt className="text-gray-500">{t("app.container.net_weight")}</dt>
                                        <dd className="text-gray-900">
                                            <NumberFormat
                                                value={containerLoadDetails.total_net_weight_confirmed}
                                                displayType={"text"}
                                                decimalScale={2}
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                suffix={' Kg'}
                                            />
                                        </dd>
                                    </div>


                                    {
                                        containerLoadDetails.total_price && (
                                            <div className="py-3 flex justify-between text-sm font-medium">
                                                <dt className="text-gray-500">{t("app.container.total_price")}</dt>
                                                <dd className="text-gray-900">
                                                    <NumberFormat
                                                        value={containerLoadDetails.total_price}
                                                        displayType={"text"}
                                                        decimalScale={2}
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        suffix={" " + (company.currency?.currency_symbol ?? '')}
                                                    />
                                                </dd>
                                            </div>
                                        )
                                    }


                                </dl>
                            </div>



                            <div>

                                <div className='flex items-center justify-between'>
                                        <h3 className="font-medium text-gray-900">{t("app.container.container_residual_title")}</h3>
                                        <p className='font-bold text-gray-900'>{(containerLoadDetails.percentage - containerLoadDetails.percentage_confirmed).toFixed(2)}%</p>
                                </div>

                                <div className="w-full bg-gray-200 rounded mt-1">
                                    <div className={classNames(
                                        (containerLoadDetails.percentage - containerLoadDetails.percentage_confirmed) >= 50 ? 'shim-green' : '',
                                        (containerLoadDetails.percentage - containerLoadDetails.percentage_confirmed) < 50 ? 'shim-red' : '',
                                        "text-xs font-medium rounded text-black text-center p-1 leading-none "
                                        )} style={{ width: ((containerLoadDetails.percentage - containerLoadDetails.percentage_confirmed) > 100 ? 100 : (containerLoadDetails.percentage - containerLoadDetails.percentage_confirmed)) + '%' }}>
                                    </div>
                                </div>


                                <dl className="mt-2  border-b border-gray-200 divide-y divide-gray-200">
                                    <div className="py-3 flex justify-between text-sm font-medium">
                                        <dt className="text-gray-500">{t("app.container.volume")}</dt>
                                        <dd className="text-gray-900">
                                            <NumberFormat
                                                value={containerLoadDetails.total_volume - containerLoadDetails.total_volume_confirmed}
                                                displayType={"text"}
                                                decimalScale={2}
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                suffix={' m³'}
                                            />
                                        </dd>
                                    </div>
                                    <div className="py-3 flex justify-between text-sm font-medium">
                                        <dt className="text-gray-500">{t("app.container.gross_weight")}</dt>
                                        <dd className="text-gray-900">
                                            <NumberFormat
                                                value={containerLoadDetails.total_gross_weight - containerLoadDetails.total_gross_weight_confirmed}
                                                displayType={"text"}
                                                decimalScale={2}
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                suffix={' Kg'}
                                            />
                                        </dd>
                                    </div>

                                    <div className="py-3 flex justify-between text-sm font-medium">
                                        <dt className="text-gray-500">{t("app.container.net_weight")}</dt>
                                        <dd className="text-gray-900">
                                            <NumberFormat
                                                value={containerLoadDetails.total_net_weight - containerLoadDetails.total_net_weight_confirmed}
                                                displayType={"text"}
                                                decimalScale={2}
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                suffix={' Kg'}
                                            />
                                        </dd>
                                    </div>


                                    {
                                        containerLoadDetails.total_price && (
                                            <div className="py-3 flex justify-between text-sm font-medium">
                                                <dt className="text-gray-500">{t("app.container.total_price")}</dt>
                                                <dd className="text-gray-900">
                                                    <NumberFormat
                                                        value={containerLoadDetails.total_price}
                                                        displayType={"text"}
                                                        decimalScale={2}
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        suffix={" " + (company.currency?.currency_symbol ?? '')}
                                                    />
                                                </dd>
                                            </div>
                                        )
                                    }


                                </dl>
                            </div>
                        </>
                    )
                }




            </div>


        </SlidePanel>
    );
};

export default LoadContainerDetail;

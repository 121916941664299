import React, { useEffect, useState } from "react";
import {  useDispatch, useSelector } from "react-redux";
import { BOOKING, BOOKING_CONFIGURATION, BOOKINGS, COMPANY, LOGISTICS, STATUS } from "../../endpoints";
import {  getPathParam, getSelectParam, getSelectParams, getShippingTypeParam, getWebPathParam } from "../../../utils/converter";
import {useTranslation} from "react-i18next";
import { deleteElement, getElement, getWarehouses, postElement, putElement } from "../../../api/config";
import LoaderWrapper from "../../../components/common/loader/loader-wrapper";
import toast from "react-hot-toast";
import BoxUploadMultifile from "../../../components/partials/upload/box-upload-multifile";
import UnderlineTab from "../../../components/partials/Tabs/underline-tab";
import { NegotiationCard } from "./booking-component/negotiation";
import { ChevronLeftIcon, PlusIcon, TrashIcon, DuplicateIcon, PencilIcon } from "@heroicons/react/outline";
import Initials from "../../../components/common/avatar/initials";
import InputText from "../../../components/common/form/input-text";
import { Warning } from "postcss";
import InputSubmit from "../../../components/common/form/input-submit";
import InputSelect from "../../../components/common/form/input-select";
import { Controller, useForm } from "react-hook-form";
import InputPortSelect from "../../../components/common/form/input-port-select";
import InputDate from "../../../components/common/form/input-date";
import BoxIcon from "../../../resources/icons/BoxIcon";
import { ActionButton } from "../../../components/layout/page-header";
import RemovePopup from "../../../components/common/popup/remove-popup";
import { fetchContainerTypes } from "../../../reducers/configReducer";
import { Disclosure, RadioGroup } from "@headlessui/react";
import PalletIcon from "../../../resources/icons/PalletIcon";
import ContainerIcon from "../../../resources/icons/ContainerIcon";
import NumberFormat from "react-number-format";
import InputFormattedNumber from "../../../components/common/form/input-formatted-number";
import { getFormattedDate } from "../../../utils/timeUtils";
import WarehouseIcon from "../../../resources/icons/WarehouseIcon";
import FormPopup from "../../../components/common/popup/form-popup";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ATTACHMENT, NEGOTIATION } from "../../../api/endpoints";
import { BookingStatusList } from "../../../components/common/alert/booking-status-list";
import ConfirmPopup from "../../../components/common/popup/confirm-popup";
import InputEmailEditor from "../../../components/common/form/input-email-editor";
import ConfirmTextPopup from "../../../components/common/popup/confirm-text-popup";
import { permissionGroup, permissionType } from "../../../constants/permissions";
import { hasPermissionFor } from "../../../components/partials/restricted/base-permission-wrapper";
import SystemTasks from "../../../components/partials/header/system-task";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}



export default function ManageBooking({ onUpdateData, onRefresh }) {

    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);
    const { permissions } = useSelector((s) => s.userCompany);

    const [isLoading, setLoading] = useState(false);
    const [needRefresh, setNeedRefresh] = useState(null);

    
    const [responseData, setResponseData] = useState({});
    const [combinations, setCombinations] = useState([]);
    const [selectedAttachments, setSelectedAttachments] = useState([]);

    const [isOpen, setIsOpen] = useState(false);
    let { id } = useParams();
    let navigate = useNavigate();

    //REMOVE
    const [isOpenRemove, setOpenRemove] = useState(false);
    const [removeDetail, setRemoveDetail] = useState({});

    const [isOpenConfirm, setOpenConfirm] = useState(false);
    const [confirmDetail, setConfirmDetail] = useState({});


    const [isOpenConfirmCheck, setOpenConfirmCheck] = useState(false);
    const canEdit = hasPermissionFor(permissions, permissionGroup.BOOKING, permissionType.EDIT);
    const canView = hasPermissionFor(permissions, permissionGroup.BOOKING, permissionType.VIEW);
    const canDelete = hasPermissionFor(permissions, permissionGroup.BOOKING, permissionType.DELETE);


    const closePage = () => {
        setResponseData({});
        setCombinations([]);
        setSelectedAttachments([]);
        setOpenRemove(false);
        setRemoveDetail({});
    }


    useEffect(() => {

        if (id){
            setIsOpen(true);
        }else{
            closePage();
            setIsOpen(false);
            setNeedRefresh(null);
        }
       
    }, [id]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchData() {
            if (isOpen && loggedCompany) {

                if (needRefresh === null){
                    setLoading(true);
                }
                
                try {
                    const res = await getElement(getPathParam([COMPANY, loggedCompany.id, BOOKING, id]), signal);
                    setResponseData(res);
                    setCombinations(res.configurations);
                    setSelectedAttachments(res.booking_attachments);
                    //setActiveGroup(res.configurations.length > 0 ? NEGOTIATION_TAB : CONFIGURATION_TAB);
                } catch (e) {
                    setLoading(false);
                } finally {
                    setLoading(false);
                }
            }
        }

        fetchData();
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [isOpen, id, loggedCompany, needRefresh]);


    const INFORMATION_TAB = 'information';
    const CONFIGURATION_TAB = 'configuration';
    const NEGOTIATION_TAB = 'negotiation';
    const ATTACHMENT_TAB = 'attachment';


    const tabs = [
        { name: t("app.booking.general_information"), group: INFORMATION_TAB },
        { name: t("app.booking.configuration"), group: CONFIGURATION_TAB },
        { name: t("app.booking.forworder_negotiation"), group: NEGOTIATION_TAB },
        { name: t("app.booking.attachments"), group: ATTACHMENT_TAB },
    ];
    const [activeGroup, setActiveGroup] = useState(INFORMATION_TAB);



    return (

        (isOpen && canView) && (
            <LoaderWrapper isLoading={isLoading}>
                <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last  ">

                    {/* Breadcrumb */}
                    <nav aria-label="Breadcrumb" className="flex items-start px-4 py-3 sm:px-6 lg:px-8 xl:hidden bg-white border-b border-t">
                        <Link to={getWebPathParam([LOGISTICS, BOOKINGS])} className="inline-flex items-center space-x-3 text-sm font-medium text-gray-900">
                            <ChevronLeftIcon aria-hidden="true" className="-ml-2 h-5 w-5 text-gray-400" />
                            <span>{t("app.common.back")}</span>
                        </Link>
                    </nav>

                    <article className="">
                        <div className=" mt-2 p-4  mt-6">
                            <div className="bg-white  shadow rounded-xl">
                                <div className="mx-auto max-w-screen px-4 sm:px-6 lg:px-8 py-3 bg-blue-1000 rounded-xl">
                                    <div className="flex items-center justify-between">


                                        <div className="flex items-center">
                                            <div className='mr-2'>
                                                <SystemTasks containerClass={''} extraClass={'bg-white text-blue-1000'} taskContext={'booking'} taskContextId={responseData.id} />
                                            </div>
                                            <div className="text-white font-semibold text-sm">
                                                <p>#{responseData?.reference ?? ''}</p>
                                            </div>

                                        </div>


                                        {
                                            canDelete && (
                                                <button
                                                    type="button"
                                                    className=" rounded-md text-white hover:text-white/70 "
                                                    onClick={async () => {
                                                        const removeDetail = {
                                                            type: 'booking',
                                                            title: t("app.common.remove_generic_title"),
                                                            message: t("app.common.remove_generic_description"),
                                                            endpoint: getPathParam([COMPANY, loggedCompany.id, BOOKING, id,]),
                                                        };
                                                        setRemoveDetail(removeDetail);
                                                        setOpenRemove(true);
                                                    }}
                                                >
                                                    <span className="sr-only">Remove</span>
                                                    <TrashIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                                                </button>
                                            )
                                        }
                                      

                                    </div>
                                </div>

                                <div className="mx-auto max-w-screen px-4 sm:px-6 lg:px-8 py-3 mt-3">
                                    <div className="flex items-center justify-between">

                                        <div className="flex items-center">

                                           

                                            <Initials
                                                color={responseData?.connection?.color ?? ''}
                                                name={responseData?.connection?.business_name ?? ''}
                                                iconClass=' h-12 w-12 '
                                                verifiedIcon='h-3 w-3'
                                                isVerified={false}
                                                registryType={responseData?.connection?.registry_type ?? ''}
                                            />
                                            <div className="text-left">
                                                <div className="text-2xl -mb-1 font-bold whitespace-pre-line text-blue-1000">
                                                    {responseData?.connection?.business_name ?? ''}
                                                </div>
                                            </div>
                                        </div>

                                        
                                        <BookingStatusList
                                            isLoading={false}
                                            status={responseData?.status ?? 'draft'}
                                            isEditable={canEdit}
                                            onChangeStatus={(r) => {

                                                const detailData = {
                                                    status: r,
                                                    title: t("app.fulfillment.status_confirm_title"),
                                                    message: t("app.fulfillment.status_confirm_description", { status: t("app.booking." + r) }),
                                                    endpoint: getPathParam([COMPANY, loggedCompany.id, BOOKING, responseData.id, STATUS, r]),
                                                };


                                                if (r === 'confirmed'){
                                                    setConfirmDetail(detailData);
                                                    setOpenConfirmCheck(true);
                                                }else{
                                                    setConfirmDetail(detailData);
                                                    setOpenConfirm(true);
                                                }

                                              
                                            }}
                                        />


                                    </div>
                                </div>

                                <UnderlineTab
                                    baseClass={"mx-auto max-w-screen px-4 sm:px-6 lg:px-8 mt-6  border-b border-gray-200"}
                                    hasBorder={false}
                                    marginTop={'mt-0'}
                                    tabs={tabs}
                                    activeGroup={activeGroup}
                                    setActiveGroup={setActiveGroup}
                                />

                                {
                                     activeGroup === INFORMATION_TAB && (
                                        <div className="mx-auto mt-2 max-w-screen px-4 sm:px-6 lg:px-8 py-4">
                                            <BookingGeneralInformation canEdit={canEdit} bookingDetail={responseData} onUpdateData={(r) => { setResponseData(r); onRefresh(r)}} />
                                        </div>
                                    )
                                }

                                {
                                    activeGroup === CONFIGURATION_TAB && (
                                        <div className="mx-auto mt-2 max-w-screen px-4 sm:px-6 lg:px-8 py-4">
                                            <CombinationCard canEdit={canEdit} bookingDetail={responseData} combinations={combinations} setCombinations={setCombinations} />
                                        </div>
                                    )
                                }


                                {
                                    activeGroup === ATTACHMENT_TAB && (
                                        <div className="mx-auto mt-2 max-w-screen px-4 sm:px-6 lg:px-8 py-4">
                                            <AttachmentsCard canEdit={canEdit}  bookingDetail={responseData} setSelectedAttachments={setSelectedAttachments} selectedAttachments={selectedAttachments} />
                                        </div>
                                    )
                                }

                                {
                                    activeGroup === NEGOTIATION_TAB && (
                                        (responseData?.negotiations?? []).length > 0 ? (
                                            <div className="mx-auto mt-2 max-w-screen px-4 sm:px-6 lg:px-8 py-4">
                                                <ConfirmedNegotaionsCard canEdit={canEdit}  bookingDetail={responseData} onRemove={(removeDetail) => {
                                                    setRemoveDetail(removeDetail);
                                                    setOpenRemove(true);

                                                }} />
                                            </div>
                                        ) : (
                                                <NegotiationCard combinations={combinations} bookingDetail={responseData} onUpdateData={(r) => {
                                                    if (needRefresh === null){
                                                        setNeedRefresh(false);
                                                    }else{
                                                        setNeedRefresh(!needRefresh);
                                                    }
                                                }} />
                                        )


                                    )
                                }
                            </div>

                        </div>
                    </article>
                </main>


                {isOpenRemove && (
                    <RemovePopup
                        isOpen={isOpenRemove}
                        setIsOpen={setOpenRemove}
                        detail={removeDetail}
                        setDetail={(r) => {
                            if (removeDetail.type === 'nego'){
                                setNeedRefresh(!needRefresh);
                            }else{
                                navigate(getWebPathParam([LOGISTICS, BOOKINGS]));
                                onUpdateData();
                            }
                        
                        }}
                    />
                )}

                {isOpenConfirm && (
                    <ConfirmPopup
                        isOpen={isOpenConfirm}
                        setIsOpen={() => {
                            setOpenConfirm(false);
                        }}
                        detail={confirmDetail}
                        setDetail={(res) => {
                            setResponseData(res);
                            setCombinations(res.configurations);
                            setSelectedAttachments(res.booking_attachments);
                            onUpdateData(true);
                        }}
                    />
                )}


                {(isOpenConfirmCheck) && (
                    <ConfirmTextPopup
                        isOpen={isOpenConfirmCheck}
                        setIsOpen={setOpenConfirmCheck}
                        cancelTitle={t("app.common.no")}
                        detail={{ title: t("app.booking.booking_create_title"), message: t("app.booking.booking_create_description") }}
                        onConfirmed={(r) => {
                            const detailData = {
                                title: t("app.fulfillment.status_confirm_title"),
                                endpointData: { create_booking: true },
                                message: t("app.fulfillment.status_confirm_description", { status: t("app.booking." + confirmDetail.status) }),
                                endpoint: getPathParam([COMPANY, loggedCompany.id, BOOKING, responseData.id, STATUS, confirmDetail.status]),
                            };
                            setConfirmDetail(detailData);
                            setOpenConfirm(true);
                        }}
                        onCancel={() => {
                            setOpenConfirm(true);
                        }}
                    />
                )}

            </LoaderWrapper>
            
        )
        
    );
}





// GENERAL INFORMATION
const BookingGeneralInformation = ({ bookingDetail, onUpdateData, canEdit }) => {

    const { termTypesList, isTermTypesListLoading } = useSelector((state) => state.config);
    const {register,handleSubmit,control,setValue,watch,formState: { errors }} = useForm({ mode: "onBlur" });
    const { loggedCompany } = useSelector((state) => state.user);


    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);
    const { t } = useTranslation();


    // create form
    useEffect(() => {
        if (bookingDetail !== null) {
            setValue('id', bookingDetail.id)
            setValue('internal_reference', bookingDetail.internal_reference)
            setValue('expected_date', bookingDetail.expected_date)
            setValue('departure_port', getSelectParam(bookingDetail.departure_port, 'name'))
            setValue('arrival_port', getSelectParam(bookingDetail.arrival_port, 'name'))
            setValue('terms_type', getSelectParam(bookingDetail.terms_type, 'name'))
            setValue('internal_note', bookingDetail.internal_note)
            setValue('shipper', bookingDetail.shipper)
            setValue('consignee', bookingDetail.consignee)
            setValue('forwarder_note', bookingDetail.forwarder_note)


        }

    }, [bookingDetail, setValue]);


    const onSubmit = async (data) => {

        setLoading(true);
        setException(false);
        putElement(getPathParam([COMPANY, loggedCompany.id, BOOKING, bookingDetail.id]), data)
            .then(response => {
                onUpdateData(response);
                toast.success(t("app.tasks.update_success"));
                setLoading(false);
            }).catch(e => {
                setException(e.message);
                setLoading(false);
            });

    };


    


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset>
                <div className="space-y-4">

                    <div className=" grid md:grid-cols-2 lg:grid-cols-2 gap-y-6 gap-x-6 sm:grid-cols-1">


                        <InputText
                            id={'internal_reference'}
                            type={'text'}
                            isDisabled={!canEdit}
                            errors={errors.internal_reference}
                            input={{ ...register("internal_reference", { required: false }) }}
                            label={t("app.fulfillment.reference")}
                        />

                        <Controller
                            name="expected_date"
                            rules={{ required: false }}
                            control={control}
                            render={({
                                field: { onChange, value, name },
                                fieldState: { error },
                            }) => (
                                <InputDate
                                    label={t("app.fulfillment.expected_date")}
                                    format={"YYYY-MM-D HH:mm:ss"}
                                    placeholder={"Es. 01/12/2021"}
                                    activeMinDate={false}
                                    startDate={value}
                                    disabled={!canEdit}
                                    isShowTime={true}
                                    errors={error}
                                    name={name}
                                    onChange={(e) => {
                                        setValue('expected_date', e);
                                        onChange(e);
                                    }}
                                />
                            )}
                        />

                        <InputPortSelect
                            isShowLabel={true}
                            label={t("app.fulfillment.pickup_port")}
                            isDisabled={!canEdit}
                            placeholder={t("app.fulfillment.pickup_port")}
                            selectedItem={watch('departure_port')}
                            onSelect={(e) => {
                                setValue('departure_port', e)
                                //onUpdate('departure_port', { id: e.id });
                            }}
                        />

                        <InputPortSelect
                            isShowLabel={true}
                            label={t("app.fulfillment.delivery_port")}
                            isDisabled={!canEdit}
                            placeholder={t("app.fulfillment.delivery_port")}
                            selectedItem={watch('arrival_port')}
                            onSelect={(e) => {
                                setValue('arrival_port', e)
                                //onUpdate('departure_port', { id: e.id });
                            }}
                        />


                        <Controller
                            name="terms_type"
                            rules={{ required: false }}
                            control={control}
                            render={({
                                field: { onChange, value, name },
                                fieldState: { error },
                            }) => (
                                <InputSelect
                                    label={t("app.shippings.terms_type")}
                                    name={name}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    options={termTypesList}
                                    value={value}
                                    isLoading={isTermTypesListLoading}
                                    isDisabled={isTermTypesListLoading || !canEdit}
                                    errors={error}
                                />
                            )}
                        />

                        <InputText
                            id={'internal_note'}
                            type={'text'}
                            isDisabled={!canEdit}
                            errors={errors.internal_note}
                            input={{ ...register("internal_note", { required: false }) }}
                            label={t("app.booking.internal_note")}
                        />



                        <div>
                            <label
                                className="block text-sm font-medium mb-1 text-gray-700"
                            >
                                {t("app.shippings.shipper")}
                            </label>
                            <Controller
                                name="shipper"
                                rules={{ required: false }}
                                control={control}
                                render={({ field: { onChange, value, name },
                                    fieldState: { error },
                                }) => (
                                    <InputEmailEditor
                                        className={'activity_quill'}
                                        errors={error}
                                        imageEdit={false}
                                        defaultValue={value}
                                        isDisabled={!canEdit}
                                        name={name}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                    />
                                )}
                            />
                        </div>

                        <div>
                            <label
                                className="block text-sm font-medium mb-1 text-gray-700"
                            >
                                {t("app.shippings.consignee")}
                            </label>
                            <Controller
                                name="consignee"
                                rules={{ required: false }}
                                control={control}
                                render={({ field: { onChange, value, name },
                                    fieldState: { error },
                                }) => (
                                    <InputEmailEditor
                                        className={'activity_quill'}
                                        errors={error}
                                        imageEdit={false}
                                        defaultValue={value}
                                        isDisabled={!canEdit}
                                        name={name}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                    />
                                )}
                            />
                        </div>


                        <div className="col-span-2">
                            <label
                                className="block text-sm font-medium mb-1 text-gray-700"
                            >
                                {t("app.booking.forwarder_note")}
                            </label>
                            <Controller
                                name="forwarder_note"
                                rules={{ required: false }}
                                control={control}
                                render={({ field: { onChange, value, name },
                                    fieldState: { error },
                                }) => (
                                    <InputEmailEditor
                                        className={'activity_quill'}
                                        errors={error}
                                        imageEdit={false}
                                        defaultValue={value}
                                        isDisabled={!canEdit}
                                        name={name}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                    />
                                )}
                            />
                        </div>
                        




                    </div>

                    {exception && (
                        <Warning message={exception} />
                    )}
                </div>
            </fieldset>

            {
                canEdit && (
                    <div className={"text-center  flex items-center justify-end mt-6"}>
                        <InputSubmit isLoading={isLoading} label={t("app.shippings.save")} />
                    </div>
                )
            }
        

        </form>
    )
}


// COMBINATIONS
const CombinationCard = ({ bookingDetail, onRefresh, combinations, setCombinations, canEdit }) => {


    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { loggedCompany } = useSelector((state) => state.user);

    // containers type list
    const { containerTypesList, isContainerTypesListLoading } = useSelector((state) => state.config);

    const [isAddCombination, setAddCombination] = useState(false);

    //REMOVE
    const [isOpenRemove, setOpenRemove] = useState(false);
    const [removeDetail, setRemoveDetail] = useState({});


    // Warehouse
    const [isWarehouseLoading, setWarehouseLoading] = useState(true);
    const [warehouseList, setWarehouseList] = useState([]);


    useEffect(() => {

        const controller = new AbortController();
        const signal = controller.signal;

        dispatch(fetchContainerTypes());

        function fetchWarehouses() {
            if (loggedCompany) {
                setWarehouseLoading(true);
                getWarehouses({ company_id: loggedCompany.id, params: null, signal })
                    .then((response) => {
                        setWarehouseList(getSelectParams(response.warehouses, "name"));
                        setWarehouseLoading(false);
                    })
                    .catch((e) => {
                        setWarehouseLoading(false);
                    });
            }
        }

        fetchWarehouses();

    }, [dispatch, loggedCompany]);




    const onCloneData = async (data) => {
        postElement(getPathParam([BOOKING, bookingDetail.id, BOOKING_CONFIGURATION]), data)
            .then(response => {
                setCombinations(response);
                onRefresh(true);
                toast.success(t("app.tasks.update_success"));
            }).catch(e => {
            });


    };

    return (
        <>
            {
                combinations.length > 0 ? (
                    <>

                        {
                            combinations.map((combination) => (
                                <CombinationDetail
                                    key={combination.id}
                                    combination={combination}
                                    bookingDetail={bookingDetail}
                                    canEdit={canEdit}
                                    onRemove={(detail) => {
                                        setRemoveDetail(detail);
                                        setOpenRemove(true);
                                    }}
                                    onClone={(detail) => {
                                        delete detail['id'];
                                        onCloneData(detail);
                                    }}
                                    onUpdateData={(detail) => {
                                        setCombinations(detail);
                                    }}
                                    isWarehouseLoading={isWarehouseLoading}
                                    warehouseList={warehouseList}
                                    containerTypesList={containerTypesList}
                                    isContainerTypesListLoading={isContainerTypesListLoading}

                                />
                            ))

                        }
                        
                        {
                            canEdit && (
                                <div className={'mt-5 mx-auto w-1/2 '}>
                                    <div
                                        onClick={() => {
                                            setAddCombination(true);
                                        }}
                                        className="flex p-2 justify-center items-center  rounded-full border border-gray-200 px-4 text-center text-sm font-medium   text-blue-1000 hover:bg-solitude-400/[0.5] cursor-pointer">
                                        <div className="flex items-center ">
                                            <div className={' mr-2  text-blue-1000'}>
                                                <PlusIcon
                                                    className="h-4 w-4"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <span>{t("app.fulfillment.add_configuration")}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                     
                    </>
                ) : (
                        canEdit && (
                            <div className="bg-white border-2 border-gray-300 border-dashed rounded-md px-4">
                                <div className="max-w-7xl mx-auto py-10 px-4 sm:py-10 sm:px-6 lg:px-8">
                                    <div className="text-center">
                                        <BoxIcon
                                            className="h-20 w-20 text-blue-1000 m-auto"
                                        />
                                        <p className="mt-1 text-2xl font-extrabold text-gray-900 sm:text-2xl sm:tracking-tight lg:text-2xl">
                                            {t("app.container.packaging_title")}
                                        </p>
                                        <p className="max-w-xl -mt-1 mb-5 mx-auto text-md text-gray-500">
                                            {t("app.container.packaging_description")}
                                        </p>

                                        <ActionButton
                                            extraClass={' md:ml-0 ml-0'}
                                            onClick={() => {
                                                setAddCombination(true);
                                            }}
                                            text={t("app.fulfillment.add_configuration")}
                                        />

                                    </div>
                                </div>
                            </div>
                        )
                    
                )
            }



            {
                isAddCombination && (
                    <AddConfiguration
                        isOpen={isAddCombination}
                        setIsOpen={setAddCombination}
                        combinations={combinations}
                        canEdit={canEdit}
                        bookingDetail={bookingDetail}
                        containerTypesList={containerTypesList}
                        isContainerTypesListLoading={isContainerTypesListLoading}
                        isWarehouseLoading={isWarehouseLoading}
                        warehouseList={warehouseList}
                        onAdded={(r) => {
                            setCombinations(r);
                        }}
                    />
                )
            }

            {isOpenRemove && (
                <RemovePopup
                    isOpen={isOpenRemove}
                    setIsOpen={setOpenRemove}
                    detail={removeDetail}
                    setDetail={(r) => {
                        setCombinations(r);
                    }}
                />
            )}

        </>
    )
}

const CombinationDetail = ({ combination, bookingDetail, onClone, onUpdateData, onRemove, canEdit, isWarehouseLoading, warehouseList, containerTypesList, isContainerTypesListLoading }) => {
    


    const { watch, handleSubmit, control, setValue } = useForm({ mode: "onBlur" });
    const { t } = useTranslation();
    const configurationType = combination.configuration_type;


    // get Shipping
    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);

    // Warehouse manage
    const [selectedWarehouse, setSelectedWarehouse] = useState(combination?.booking_configuration_warehouses ?? []);

    // Volume Calcolation
    const onUpdateVolume = () => {
        if (watch('length') > 0 && watch('width') > 0 && watch('height') > 0) {
            setValue('volume', (watch('length') * watch('width') * watch('height')) / 1000000);
        }
    };

    // create form
    useEffect(() => {
        if (combination !== null) {
            for (const k in combination) {

                if (typeof combination[k] === "object" && k !== 'delivery_type') {
                    setValue(k, getSelectParam(combination[k], 'name'));
                } else if (typeof combination[k] === "object" && k === 'delivery_type') {
                    setValue('delivery_type', getShippingTypeParam(combination[k]));
                } else {
                    setValue(k, combination[k]);
                }
            }
        }

    }, [combination, setValue]);



    const onSubmit = async (data) => {
        if (combination && bookingDetail) {
            delete data['fulfillment'];

            let toRemove = combination?.booking_configuration_warehouses ?? [];
            data['booking_configuration_warehouses'] = { to_add: [], to_remove: [] };
            if (selectedWarehouse.length > 0) {
                data['booking_configuration_warehouses']['to_add'] = selectedWarehouse;
                toRemove = (combination.booking_configuration_warehouses ?? []).filter(obj1 => !selectedWarehouse.some(obj2 => obj1.company_warehouse.id === obj2.company_warehouse.id));
            }
            data['booking_configuration_warehouses']['to_remove'] = toRemove;


            setLoading(true);
            putElement(getPathParam([BOOKING, bookingDetail.id, BOOKING_CONFIGURATION, combination.id]), data)
                .then(response => {
                    toast.success(t("app.tasks.update_success"));
                    onUpdateData(response);
                    setLoading(false);
                }).catch(e => {
                    setLoading(false);
                    setException(e.message);
                });
        }
    };

  


    return (

        <Disclosure defaultOpen={false} as={'div'} className={'mt-3 mx-auto w-full border rounded-md bg-white'}>
            {({ open }) => (
                <>

                    <dl className={configurationType === 'container' ? 'group grid grid-cols-1 gap-0.5 overflow-hidden text-center sm:grid-cols-2 hover:bg-solitude-100 lg:grid-cols-2' : 'group grid grid-cols-1 gap-0.5 overflow-hidden text-center sm:grid-cols-2 hover:bg-solitude-100 lg:grid-cols-6'}>
                        <div className={configurationType === 'container' ? "flex flex-col sm:flex-row p-3 items-center items-center" : "flex flex-col xl:flex-row p-3 lg:col-span-2 items-center"}>
                            <div className={configurationType === 'container' ? "flex items-center text-left" : "flex items-center justify-center text-left lg:justify-start lg:text-center"}>
                                <div className="mr-3 text-center">
                                    {
                                        (configurationType === 'box') && (
                                            <BoxIcon className={'w-5 h-5 text-blue-1000'} />
                                        )
                                    }

                                    {
                                        (configurationType === 'pallet') && (
                                            <PalletIcon className={'w-5 h-5 text-blue-1000'} />
                                        )
                                    }

                                    {
                                        (configurationType === 'container') && (
                                            <ContainerIcon className={'w-5 h-5 text-blue-1000'} />
                                        )
                                    }
                                </div>
                                <div>
                                    <div className="text-sm text-gray-900">
                                        {
                                            (configurationType !== 'container') ? (
                                                <span>
                                                    <NumberFormat
                                                        value={combination?.length ?? 0}
                                                        displayType={"text"}
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        decimalScale={2}
                                                        suffix={''}
                                                    />
                                                    x
                                                    <NumberFormat
                                                        value={combination?.width ?? 0}
                                                        displayType={"text"}
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        decimalScale={2}
                                                        suffix={''}
                                                    />
                                                    x
                                                    <NumberFormat
                                                        value={combination?.height ?? 0}
                                                        displayType={"text"}
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        decimalScale={2}
                                                        suffix={''}
                                                    />
                                                    {' '}cm
                                                </span>
                                            ) : (
                                                <span>
                                                    {combination?.container_type?.name ?? '-'}
                                                </span>
                                            )
                                        }
                                    </div>

                                    {
                                        (configurationType !== "container") && (
                                            <div className="text-xs text-gray-500 -mt-1" style={{ fontSize: '0.70rem' }}>
                                                {'Dimensions'}
                                            </div>
                                        )
                                    }

                                </div>
                            </div>
                        </div>

                        {
                            configurationType !== 'container' && (
                                <>
                                    <div className="flex flex-col  p-2">
                                        <div className="text-sm text-blue-1000">
                                            <div className="text-sm text-gray-900">
                                                <NumberFormat
                                                    value={combination?.volume ?? 0}
                                                    displayType={"text"}
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    decimalScale={4}
                                                    suffix={' m³'}
                                                />
                                            </div>
                                            <div className="text-xs text-gray-500 capitalize" style={{ fontSize: '0.70rem' }}>
                                                {t("app.products.volume")}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex flex-col  p-2">
                                        <div className="text-sm text-blue-1000">
                                            <div className="text-sm text-gray-900">
                                                <NumberFormat
                                                    value={combination?.net_weight ?? 0}
                                                    displayType={"text"}
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    decimalScale={4}
                                                    suffix={' Kg'}
                                                />
                                            </div>
                                            <div className="text-xs text-gray-500 capitalize" style={{ fontSize: '0.70rem' }}>
                                                {t("app.products.gross_weight")}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex flex-col  p-2">
                                        <div className="text-sm text-blue-1000">
                                            <div className="text-sm text-gray-900">
                                                <NumberFormat
                                                    value={combination?.gross_weight ?? 0}
                                                    displayType={"text"}
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    decimalScale={4}
                                                    suffix={' Kg'}
                                                />
                                            </div>
                                            <div className="text-xs text-gray-500 capitalize" style={{ fontSize: '0.70rem' }}>
                                                {t("app.products.gross_weight")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-center p-2 group-hover:hidden">
                                        <div className="text-sm text-gray-900 text-center">
                                            {combination?.company_warehouse && (
                                                <div className="flex items-center justify-center  capitalize">
                                                    <WarehouseIcon className={'w-4 h-4 mr-2'} />
                                                    {combination?.company_warehouse?.name ?? ''}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )
                        }






                        <div className="flex flex-col inline-flex content-evenly  p-2 hidden group-hover:block">

                            <div className="text-sm text-gray-900 text-center">

                                <div className={configurationType === 'container' ? "flex items-center justify-end  " : "flex items-center justify-center  "}>
                                    {
                                        canEdit && (
                                            <button
                                                type="button"
                                                className="text-gray-900 hover:text-gray-700  mr-2"
                                                onClick={() => {
                                                    onClone(combination)
                                                }}
                                            >
                                                <DuplicateIcon className="h-5 w-5" aria-hidden="true" />
                                            </button>
                                        )
                                    }

                                   
                                            <Disclosure.Button>
                                                <PencilIcon className="h-5 w-5 text-gray-900 hover:text-gray-700" />
                                            </Disclosure.Button>
                                   



                                    {canEdit && (
                                        <button
                                            type="button"
                                            className="text-red-700 hover:text-red-900  ml-2"
                                            onClick={() => {
                                                const removeDetail = {
                                                    title: t("app.common.remove_generic_title"),
                                                    message: t("app.common.remove_generic_description"),
                                                    endpoint: getPathParam([BOOKING, bookingDetail.id, BOOKING_CONFIGURATION, combination.id]),
                                                };
                                                onRemove(removeDetail)
                                            }}
                                        >
                                            <TrashIcon className="h-5 w-5" aria-hidden="true" />
                                        </button>
                                    )}

                                </div>
                            </div>

                        </div>


                    </dl>

                    <Disclosure.Panel >

                        <form onSubmit={handleSubmit(onSubmit)} >
                            <div className=" px-4 pt-4 pb-2 text-sm text-gray-500 border-t space-y-2">
                                <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-3 gap-x-4 sm:grid-cols-1">

                                    {
                                        configurationType === "container" && (
                                            <div className={"col-span-2"}>
                                                <Controller
                                                    name="container_type"
                                                    rules={{ required: true }}
                                                    control={control}
                                                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                                        <InputSelect
                                                            label={t("app.container.cont_type")}
                                                            name={name}
                                                            options={containerTypesList}
                                                            value={value}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                            }}
                                                            isLoading={isContainerTypesListLoading}
                                                            isDisabled={isContainerTypesListLoading || !canEdit}
                                                            errors={error}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        )
                                    }




                                    {configurationType !== "container" && (
                                        <>
                                            <div className="col-span-2 grid md:grid-cols-3 lg:grid-cols-3 gap-x-5 sm:grid-cols-1">
                                                <Controller
                                                    name="length"
                                                    rules={{ required: false }}
                                                    control={control}
                                                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                                        <InputFormattedNumber
                                                            label={t("app.products.length")}
                                                            name={name}
                                                            value={value}
                                                            isDisabled={!canEdit}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                                onUpdateVolume();
                                                            }}
                                                            prefix={''}
                                                            suffix={' cm'}
                                                            errors={error}
                                                        />
                                                    )}
                                                />

                                                <Controller
                                                    name="width"
                                                    rules={{ required: false }}
                                                    control={control}
                                                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                                        <InputFormattedNumber
                                                            label={t("app.products.width")}
                                                            name={name}
                                                            value={value}
                                                            isDisabled={!canEdit}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                                onUpdateVolume();
                                                            }}
                                                            prefix={''}
                                                            suffix={' cm'}
                                                            errors={error}
                                                        />
                                                    )}
                                                />

                                                <Controller
                                                    name="height"
                                                    rules={{ required: false }}
                                                    control={control}
                                                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                                        <InputFormattedNumber
                                                            label={t("app.products.height")}
                                                            name={name}
                                                            value={value}
                                                            isDisabled={!canEdit}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                                onUpdateVolume();
                                                            }}
                                                            prefix={''}
                                                            suffix={' cm'}
                                                            errors={error}
                                                        />
                                                    )}
                                                />

                                            </div>

                                            <div className="col-span-2 grid md:grid-cols-3 lg:grid-cols-3 gap-x-5 sm:grid-cols-1">

                                                <Controller
                                                    name="volume"
                                                    rules={{ required: true }}
                                                    control={control}
                                                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                                        <InputFormattedNumber
                                                            label={t("app.products.volume")}
                                                            name={name}
                                                            value={value}
                                                            isDisabled={!canEdit}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                            }}
                                                            prefix={''}
                                                            suffix={' m³'}
                                                            errors={error}
                                                        />
                                                    )}
                                                />

                                                <Controller
                                                    name="gross_weight"
                                                    rules={{ required: true }}
                                                    control={control}
                                                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                                        <InputFormattedNumber
                                                            label={t("app.products.gross_weight")}
                                                            name={name}
                                                            value={value}
                                                            isDisabled={!canEdit}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                            }}
                                                            prefix={''}
                                                            suffix={' Kg'}
                                                            errors={error}
                                                        />
                                                    )}
                                                />

                                                <Controller
                                                    name="net_weight"
                                                    rules={{ required: true }}
                                                    control={control}
                                                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                                        <InputFormattedNumber
                                                            label={t("app.products.net_weight")}
                                                            name={name}
                                                            value={value}
                                                            isDisabled={!canEdit}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                            }}
                                                            prefix={''}
                                                            suffix={' Kg'}
                                                            errors={error}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </>
                                    )}



                                    <div className={"col-span-2"}>
                                        <ManageWarehouses
                                            selectedWarehouse={selectedWarehouse}
                                            setSelectedWarehouse={setSelectedWarehouse}
                                            fulfillmentType={bookingDetail.type}
                                            isWarehouseLoading={isWarehouseLoading}
                                            warehouseList={warehouseList}
                                            canEdit={canEdit}
                                        />
                                    </div>

                                </div>
                            </div>

                            {/* Warning */}
                            {exception && (
                                <Warning message={exception} />
                            )}

                            {
                                canEdit && (
                                    <div className="flex items-center  justify-end mt-6 bg-solitude-100/30 px-4 py-2 border-t">
                                        <div className="flex items-center">
                                            <InputSubmit
                                                isLoading={isLoading}
                                                label={t("app.fulfillment.update_configuration")}
                                            />
                                        </div>
                                    </div>
                                
                                )
                            }

                           
                        </form>



                    </Disclosure.Panel>

                </>
            )}
        </Disclosure>

    )
}


const AddConfiguration = ({ canEdit, isOpen, setIsOpen, combinations, bookingDetail, containerTypesList, isContainerTypesListLoading, isWarehouseLoading, warehouseList, onAdded }) => {

    const {
        handleSubmit,
        control,
        watch,
        setValue
    } = useForm({ mode: "onBlur" });

    const { t } = useTranslation();


    // post details
    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);


    // Warehouse
    const [selectedWarehouse, setSelectedWarehouse] = useState([]);

    // Combination tpyes
    const combinationTypes = [
        //responseData.shipping_type.code !== SEA && { type: 'box', name: '', icon: BoxIcon },
        ((combinations.length === 0) || (combinations.filter(r => r.configuration_type === 'container').length === 0)) && { type: 'pallet', name: '', icon: PalletIcon },
        ((combinations.length === 0) || (combinations.filter(r => r.configuration_type === 'pallet' || r.configuration_type === 'box').length === 0)) && { type: 'container', name: '', icon: ContainerIcon },
    ].filter(Boolean);
    const [combinationType, setCombinationType] = useState(combinationTypes[0])

    // Valume calcolation
    const onUpdateVolume = () => {
        if (watch('length') > 0 && watch('width') > 0 && watch('height') > 0) {
            setValue('volume', (watch('length') * watch('width') * watch('height')) / 1000000);
        }
    };



    const onSubmit = async (data) => {
        data['configuration_type'] = combinationType.type;
        if (combinationType.type === "container") {
            delete data['length'];
            delete data['width'];
            delete data['height'];
            delete data['volume'];
            delete data['gross_weight'];
            delete data['net_weight'];
        }

        if (selectedWarehouse.length > 0) {
            data['booking_configuration_warehouses'] = selectedWarehouse;
        }

        setLoading(true);
        postElement(getPathParam([BOOKING, bookingDetail.id, BOOKING_CONFIGURATION]), data)
            .then(response => {
                toast.success(t("app.tasks.update_success"));
                onAdded(response);
                setLoading(false);
                setIsOpen(false);
            }).catch(e => {
                setException(e.message);
                setLoading(false);
            });
    };


    return (
        <FormPopup
            title={t("app.fulfillment.configuration", t("app.fulfillment.pickup") )}
            isOpen={isOpen}
            setIsOpen={(r) => {
                setIsOpen(r);
            }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-2">
                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-3 gap-x-4 sm:grid-cols-1">


                        <fieldset className={"col-span-1"}>
                            <label className="block text-sm font-medium mb-1 text-gray-700">
                                {t("app.fulfillment.combination_type")}<span className="text-red-500">*</span>
                            </label>

                            <RadioGroup value={combinationType} onChange={setCombinationType} className="flex items-center   rounded-md ring-1 ring-gray-200">
                                {combinationTypes.map((option) => (
                                    <RadioGroup.Option
                                        key={option.name}
                                        value={option}
                                        className={({ active, checked }) =>
                                            `cursor-pointer flex items-center justify-center  w-full p-2 text-sm  rounded-md font-semibold     sm:flex-1
                                                    ${(combinationType.type === option.type) ? 'bg-blue-1000 text-white' : 'bg-white text-blue-1000 hover:bg-gray-50'}`
                                        }
                                    >
                                        <option.icon className="h-5 w-5" />
                                    </RadioGroup.Option>
                                ))}
                            </RadioGroup>
                        </fieldset>

                        <Controller
                            name="quantity_clone"
                            rules={{ required: false }}
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                <InputFormattedNumber
                                    label={t("app.container.quantity")}
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    prefix={''}
                                    suffix={''}
                                    errors={error}
                                />
                            )}
                        />

                        {
                            combinationType.type === "container" && (
                                <div className="col-span-2">
                                    <Controller
                                        name="container_type"
                                        rules={{ required: true }}
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                            <InputSelect
                                                label={t("app.container.cont_type")}
                                                name={name}
                                                options={containerTypesList}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                }}
                                                isLoading={isContainerTypesListLoading}
                                                isDisabled={isContainerTypesListLoading}
                                                errors={error}
                                            />
                                        )}
                                    />
                                </div>
                            )}



                        {combinationType.type !== "container" && (
                            <>
                                <div className="col-span-2 grid md:grid-cols-3 lg:grid-cols-3 gap-x-5 sm:grid-cols-1">
                                    <Controller
                                        name="length"
                                        rules={{ required: false }}
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                            <InputFormattedNumber
                                                label={t("app.products.length")}
                                                name={name}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                    onUpdateVolume();
                                                }}
                                                prefix={''}
                                                suffix={' cm'}
                                                errors={error}
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="width"
                                        rules={{ required: false }}
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                            <InputFormattedNumber
                                                label={t("app.products.width")}
                                                name={name}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                    onUpdateVolume();
                                                }}
                                                prefix={''}
                                                suffix={' cm'}
                                                errors={error}
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="height"
                                        rules={{ required: false }}
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                            <InputFormattedNumber
                                                label={t("app.products.height")}
                                                name={name}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                    onUpdateVolume();
                                                }}
                                                prefix={''}
                                                suffix={' cm'}
                                                errors={error}
                                            />
                                        )}
                                    />

                                </div>

                                <div className="col-span-2 grid md:grid-cols-3 lg:grid-cols-3 gap-x-5 sm:grid-cols-1">

                                    <Controller
                                        name="volume"
                                        rules={{ required: false }}
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                            <InputFormattedNumber
                                                label={t("app.products.volume")}
                                                name={name}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                }}
                                                prefix={''}
                                                suffix={' m³'}
                                                errors={error}
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="gross_weight"
                                        rules={{ required: false }}
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                            <InputFormattedNumber
                                                label={t("app.products.gross_weight")}
                                                name={name}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                }}
                                                prefix={''}
                                                suffix={' Kg'}
                                                errors={error}
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="net_weight"
                                        rules={{ required: false }}
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                            <InputFormattedNumber
                                                label={t("app.products.net_weight")}
                                                name={name}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                }}
                                                prefix={''}
                                                suffix={' Kg'}
                                                errors={error}
                                            />
                                        )}
                                    />
                                </div>
                            </>
                        )}

                      


                        <div className="col-span-2">
                            <ManageWarehouses
                                selectedWarehouse={selectedWarehouse}
                                setSelectedWarehouse={setSelectedWarehouse}
                                fulfillmentType={bookingDetail.type}
                                isWarehouseLoading={isWarehouseLoading}
                                warehouseList={warehouseList}
                                canEdit={canEdit}
                            />
                        </div>


                    </div>
                </div>

                {/* Warning */}
                {exception && (
                    <Warning message={exception} />
                )}

                <div className="flex before:flex-1 items-center justify-between mt-6">
                    <InputSubmit
                        isLoading={isLoading}
                        label={t("app.fulfillment.add_configuration")}
                    />
                </div>
            </form>
        </FormPopup>
    );
}


const ManageWarehouses = ({ selectedWarehouse, canEdit, setSelectedWarehouse, fulfillmentType, isWarehouseLoading, warehouseList }) => {

    const { t } = useTranslation();
    const {  watch, control, setValue } = useForm();

    return (
        <>
            <h3 className="block text-sm font-medium mb-1 text-gray-700">
                {t("app.fulfillment.delivery_load")}
            </h3>


            {
                (selectedWarehouse.length > 0 && warehouseList.length > 0) && (
                    <div className=" bg-white mb-3  rounded-sm border border-slate-200">
                        <nav className="overflow-y-auto max-h-80" aria-label="Directory">
                            <ul className="relative z-0 divide-y divide-gray-200">
                                {selectedWarehouse.map((n, index) => (
                                    <li key={index} className=" px-2 bg-white hover:bg-gray-50">
                                        <div className="py-2 flex items-center justify-between space-x-3">
                                            <div className="min-w-0 flex-1 flex items-center space-x-3">
                                                <div className="min-w-0 flex-1">
                                                    <p className="text-sm font-medium text-gray-900">
                                                        {warehouseList.find(r => r.id === n.company_warehouse.id).name}
                                                    </p>
                                                    <p className="text-xs font-normal text-gray-700">
                                                        {getFormattedDate(n.expected_date)}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="mt-4 space-x-3 flex md:mt-0 md:ml-4">

                                                {
                                                    canEdit && (
                                                        <div
                                                            onClick={() => {
                                                                let da = selectedWarehouse.filter(r => r.company_warehouse.id !== n.company_warehouse.id)
                                                                setSelectedWarehouse(da);
                                                            }}
                                                            className={
                                                                "w-full btn bg-red-500 hover:bg-red-700 cursor-pointer"
                                                            }
                                                        >
                                                            <TrashIcon
                                                                className="h-4 w-4 text-white"
                                                                aria-hidden="true"
                                                            />
                                                        </div>
                                                    )
                                                }
                                               
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                )
            }

            {
                canEdit && (selectedWarehouse.length === 0 || warehouseList.filter(
                    (c) =>
                        !selectedWarehouse
                            .map((c) => c.company_warehouse.id)
                            .includes(c.id)
                ).length > 0) && (
                    <div className="col-span-2 grid md:grid-cols-7 lg:grid-cols-7 gap-x-5 sm:grid-cols-1">
                        <div className="col-span-3">
                            <Controller
                                name="company_warehouse"
                                rules={{ required: false }}
                                control={control}
                                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                    <InputSelect
                                        label={fulfillmentType === 'delivery' ? t("app.fulfillment.pickup_place") : t("app.fulfillment.delivery_place")}
                                        isShowLabel={false}
                                        name={name}
                                        options={warehouseList.filter((c) => !selectedWarehouse.map((c) => c.company_warehouse.id).includes(c.id))}
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        isLoading={isWarehouseLoading}
                                        isDisabled={isWarehouseLoading}
                                        errors={error}
                                    />
                                )}
                            />
                        </div>

                        <div className="col-span-3 mt-3 xl:mt-0">
                            <Controller
                                name="expected_date"
                                rules={{ required: false }}
                                control={control}
                                render={({
                                    field: { onChange, value, name },
                                    fieldState: { error },
                                }) => (
                                    <InputDate
                                        label={t("app.fulfillment.expected_date")}
                                        isShowLabel={false}
                                        format={"YYYY-MM-D HH:mm:ss"}
                                        placeholder={"Es. 01/12/2021"}
                                        activeMinDate={false}
                                        startDate={value}
                                        disabled={false}
                                        cleanData={watch('expected_date') === null}
                                        isShowTime={true}
                                        errors={error}
                                        name={name}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                    />
                                )}
                            />
                        </div>


                        <div className="mt-3 md:mt-0 w-full mx-auto sm:flex-shrink-0 flex justify-between items-end">
                            <div
                                style={{ height: '2.4rem' }}
                                className={classNames(
                                    "btn cursor-pointer shadow-none bg-green-700 hover:bg-green-600 rounded-md text-white w-full   ",
                                    (!watch('company_warehouse') || !watch('expected_date')) && "border-gray-200 bg-gray-100 text-gray-400 cursor-not-allowed",
                                )}
                                onClick={() => {
                                    if (watch('company_warehouse') && watch('expected_date')) {
                                        let data = { company_warehouse: { id: watch('company_warehouse').id }, expected_date: watch('expected_date') }
                                        setValue('company_warehouse', null);
                                        setValue('expected_date', null);
                                        setSelectedWarehouse([...selectedWarehouse, data]);
                                    }
                                }}
                            >
                                <PlusIcon className="w-4 h-4 text-white" />
                            </div>
                        </div>


                    </div>
                )
            }

            




        </>
    )
}

// ATTACHMENTS
const AttachmentsCard = ({ bookingDetail, selectedAttachments, setSelectedAttachments, canEdit }) => {

    const [isLoading, setLoading] = useState(false);


    const { t } = useTranslation();


    const onSubmit = async (data) => {
        postElement(getPathParam([BOOKING, bookingDetail.id, ATTACHMENT]), data)
            .then(response => {
                setSelectedAttachments(response);
                toast.success(t("app.tasks.update_success"));
            }).catch(e => {
                //toast.error(e.message);
                setLoading(false);
            });

    };

    const onRemove = async (id) => {
        deleteElement(getPathParam([BOOKING, bookingDetail.id, ATTACHMENT, id]))
            .then(response => {
                setSelectedAttachments(response);
                toast.success(t("app.tasks.update_success"))
            }).catch(e => {
                toast.error(e.message);
            });

    };
    const onUpload = async (data) => {
        setLoading(true);
        data.map(async r => {
            await setTimeout(async function () {
                await onSubmit({
                    file_name: r.name,
                    file: {
                        media_url: r.reference
                    }
                })
            }, 500);
        })
        setLoading(false);
    };


    return (
        <>
            <BoxUploadMultifile
                onUpload={async (data) => {
                    await onUpload(data);
                }}
                onException={(e) => { }}
                isLoading={isLoading}
                canEdit={canEdit}
                isDepend={true}
                genericDocuments={selectedAttachments}
                onRemoveGeneric={(id) => {
                    setSelectedAttachments(selectedAttachments.filter(function (obj) {
                        return obj.id !== id;
                    }));
                    onRemove(id);
                }}
            />
        </>
    )
}


// ATTACHMENTS
const ConfirmedNegotaionsCard = ({ bookingDetail, onRemove, canEdit }) => {

    const { t } = useTranslation();
    const { company } = useSelector((state) => state.userCompany);


    const DetailCard = ({title, description = null, children}) => (
        <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt className="text-sm font-medium leading-6 text-gray-900">{title}</dt>

            {
                description ? (
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{description}</dd>
                ) : (
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{children}</dd>
                )
            }

        </div>
    )

    return (
        <>
            <ul className="grid grid-cols-1 gap-3 sm:grid-cols-1">

                {
                    (bookingDetail?.negotiations ?? []).map(negotiation => (

                        <li key={negotiation.id} className="col-span-1 border flex flex-col divide-y divide-gray-200 rounded-lg bg-white">
                            <div className='flex items-center justify-between py-3 px-3'>

                                <span className="flex items-center ">
                                    {
                                        canEdit && (
                                            <TrashIcon className="h-4 w-4 text-red-700 hover:text-red-600 cursor-pointer mr-3"
                                                onClick={() => {
                                                    const removeDetail = {
                                                        type: 'nego',
                                                        title: t("app.common.remove_generic_title"),
                                                        message: t("app.common.remove_generic_description"),
                                                        endpoint: getPathParam([BOOKING, bookingDetail.id, NEGOTIATION, negotiation.id]),
                                                    };
                                                    onRemove(removeDetail);
                                                }}
                                            />
                                        )
                                    }
                                   
                                    <span className="flex items-center ">
                                        <Initials
                                            color={negotiation?.forwarder_traffic_line?.forwarder?.color ?? ''}
                                            name={negotiation?.forwarder_traffic_line?.forwarder?.name ?? ''}
                                            isVerified={false}
                                        />
                                        <span className="flex min-w-0 text-left flex-1 flex-col">
                                            <p className="flex-wrap  whitespace-normal text-sm font-medium text-gray-900">{negotiation?.forwarder_traffic_line?.forwarder?.name ?? ''}</p>
                                        </span>
                                    </span>
                                </span>


                               

                                <div className="flex items-center mr-3 ">

                                    <div className={"flex items-center text-right mr-3 border-r border-gray-100 pr-2"}>
                                        <div>
                                            <div className="text-xs text-blue-1000">
                                                {t("dashboard.shipping_tracking_log.title")}
                                            </div>
                                            <div className="text-sm whitespace-pre-line text-gray-900">
                                                {negotiation.forwarder_traffic_line.transit_time} {t("app.plan.days")}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex items-center">
                                        <dd className="text-2xl font-bold text-blue-1000 tracking-tight">
                                            <NumberFormat
                                                value={negotiation.forwarder_traffic_line?.total_cost ?? 0}
                                                displayType={"text"}
                                                decimalScale={2}
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                suffix={" " + (company?.currency?.currency_symbol ?? '')}
                                            />
                                        </dd>
                                    </div>
                                </div>
                            </div>



                            <div className="border-t border-gray-100">
                                <dl className="divide-y divide-gray-100 grid grid-cols-1 gap-3 sm:grid-cols-2">
                                    <DetailCard title={t("app.shippings.shipping_line")} description={negotiation?.forwarder_traffic_line?.shipping_line?.name ?? '-'} />
                                    <DetailCard title={t("app.booking.transit_time")}>
                                        <NumberFormat
                                            value={negotiation.forwarder_traffic_line?.transit_time ?? 0}
                                            displayType={"text"}
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            decimalScale={2}
                                            suffix={" " + (negotiation.forwarder_traffic_line.transit_time ? (negotiation.forwarder_traffic_line.transit_time === 1 ? t("app.booking.day") : t("app.booking.days")) : "")}
                                        />
                                    </DetailCard>
                                    <DetailCard title={t("app.booking.free_time")}>
                                        <NumberFormat
                                            value={negotiation.forwarder_traffic_line?.free_time ?? 0}
                                            displayType={"text"}
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            decimalScale={2}
                                            suffix={" " + (negotiation.forwarder_traffic_line.free_time ? (negotiation.forwarder_traffic_line.free_time === 1 ? t("app.booking.day") : t("app.booking.days")) : "")}
                                        />
                                    </DetailCard>
                                


                                    
                                    <DetailCard title={t("app.shippings.departure_date")} description={getFormattedDate(negotiation.forwarder_traffic_line.departure_date)} />
                                    
                                    
                                    
                                    
                                    <DetailCard title={t("app.quotations.expire_date")}>
                                        {
                                            negotiation.forwarder_traffic_line.start_date && (
                                                <>
                                                    {getFormattedDate(negotiation.forwarder_traffic_line.start_date)}{" -> "}
                                                </>
                                            )
                                        }
                                        {getFormattedDate(negotiation.forwarder_traffic_line.end_date)}
                                    </DetailCard>
                                    
                                    <DetailCard title={t("app.booking.transport_cost")}>
                                        <NumberFormat
                                            value={negotiation.forwarder_traffic_line?.transport_cost ?? 0}
                                            displayType={"text"}
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            decimalScale={2}
                                            suffix={" " + (company.currency?.currency_symbol ?? '')}
                                        />
                                    </DetailCard>

                                    <DetailCard title={t("app.booking.extra_stops")}>
                                        <NumberFormat
                                            value={negotiation.forwarder_traffic_line?.extra_stops ?? 0}
                                            displayType={"text"}
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            decimalScale={2}
                                            suffix={""}
                                        />
                                    </DetailCard>

                                    <DetailCard title={t("app.booking.extra_stops_cost")}>
                                        <NumberFormat
                                            value={negotiation.forwarder_traffic_line?.extra_stops_cost ?? 0}
                                            displayType={"text"}
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            decimalScale={2}
                                            suffix={" " + (company.currency?.currency_symbol ?? '')}
                                        />
                                    </DetailCard>

                                    <DetailCard title={t("app.booking.service_accessory_cost")}>
                                        <NumberFormat
                                            value={negotiation.forwarder_traffic_line?.service_accessory_cost ?? 0}
                                            displayType={"text"}
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            decimalScale={2}
                                            suffix={" " + (company.currency?.currency_symbol ?? '')}
                                        />
                                    </DetailCard>

                                    <DetailCard title={t("app.booking.administrative_cost")}>
                                        <NumberFormat
                                            value={negotiation.forwarder_traffic_line?.administrative_cost ?? 0}
                                            displayType={"text"}
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            decimalScale={2}
                                            suffix={" " + (company.currency?.currency_symbol ?? '')}
                                        />
                                    </DetailCard>

                                    <DetailCard title={t("app.booking.delivery_cost")}>
                                        <NumberFormat
                                            value={negotiation.forwarder_traffic_line?.delivery_cost ?? 0}
                                            displayType={"text"}
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            decimalScale={2}
                                            suffix={" " + (company.currency?.currency_symbol ?? '')}
                                        />
                                    </DetailCard>

                                    <DetailCard title={t("app.booking.insurance_cost")}>
                                        <NumberFormat
                                            value={negotiation.forwarder_traffic_line?.insurance_cost ?? 0}
                                            displayType={"text"}
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            decimalScale={2}
                                            suffix={" " + (company.currency?.currency_symbol ?? '')}
                                        />
                                    </DetailCard>

                                </dl>
                            </div>



                        </li>
                      
                    ))
                }

            </ul>
           
            



            
        </>
    )
}




import React, {Fragment} from "react";
import List from "../common/table/list";
import Pagination from "../common/table/pagination";
import Table from "../common/table/table";
import TableHeader from "../common/table/table-header";
import InfiniteScrollPagination from "../common/table/infinite-scroll";
import TableSort from "../common/table/table-sort";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DynamicPagination from "../common/table/dynamicPagination";
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const ListSection = ({
  showHeader = true,
  onSearch,
  paginationOnChange,
  isLoading,
  totalRows,
  list,
  columns,
  mobileRow = null,
  desktopRow,
  extras = {},
  hasCheckbox = false,
  showMobile = true,
  children,
  handleSelectAll,
  selectAll,
  title= '',
  onInfiniteScroll,
  isPanelScroll = false,
  isTableSort = false,
  isDynamicPagination = false,
  isDynamicSearch = false,
  resetPagination = false,
  sortBy,
  onSortBy,
  panelHeight = 800,
  isSortableRow = false,
  activeDynamicFilter = false,
  onUpdateSortableRow,
  pageLimit = 10,
  showType = false,
  showSearch = true,
  activeFixedColumn = false,
  showShippingType= false,
  isActiveSelectAll = true,
  shippingTypes = [],
  shippingType,
  setShippingType,
  checkoxWidth= null,
  searchLength = 3
}) => {
  const MRow = mobileRow;

  const getFilterType = (t, accessor) => {
    if(typeof accessor === 'function'){
      return accessor(t);
    }
    return t[accessor];
  }



  return (
    <div>
      <div className="bg-white shadow-sm rounded-sm relative">
        {showHeader && (<TableHeader onSearch={onSearch} showSearch={showSearch} searchLength={searchLength} showShippingType={showShippingType} shippingTypes={shippingTypes} shippingType={shippingType} setShippingType={setShippingType} isDynamicSearch={isDynamicSearch} title={title} totalRows={totalRows} activeDynamicFilter={activeDynamicFilter}>{children}</TableHeader>)}
        {/* Projects list (only on smallest breakpoint) */}
        <InfiniteScrollPagination
            isActiveScrollPagination={!!onInfiniteScroll}
            dataLength={list.length}
            isPanelScroll={isPanelScroll}
            onInfiniteScroll={onInfiniteScroll}
            panelHeight={panelHeight}
        >
        {
          showMobile  && (
                <div className={classNames(
                  showType === false ? 'sm:hidden' :
                  (showType === 'sm' ? 'block' : 'hidden')
                )}>
                  <List isLoading={isLoading} dataLength={list.length}>
                    {list.map((element, index) => (
                        <MRow key={element.id} element={element} extras={extras} index={index} />
                    ))}
                  </List>
                </div>
            )
        }


        {/* Projects table (small breakpoint and up) */}
        <div  className={classNames(
            showType === false ? (showMobile ? "hidden sm:block" : "block max-h-full") :
                (showType === 'lg' ? 'block max-h-full' : 'hidden')
        )}>

          {
            isTableSort ? (
                <TableSort
                    columns={columns}
                    checkoxWidth={checkoxWidth}
                    isLoading={isLoading}
                    dataLength={list.length}
                    hasCheckbox={hasCheckbox}
                    handleSelectAll={handleSelectAll}
                    selectAll={selectAll}
                    isActiveSelectAll={isActiveSelectAll}
                    sortBy={sortBy}
                    onSortBy={onSortBy}
                    activeFixedColumn={activeFixedColumn}
                >
                  {
                    sortBy.position > 0  ? (
                        <>
                          {
                              sortBy.type === 'asc' && (
                                  <SortableRow
                                      isSortableRow={isSortableRow}
                                      items={list.sort( (a, b) => getFilterType(a, sortBy.accessor) > getFilterType(b, sortBy.accessor) ? 1 : -1)}
                                      draw={desktopRow}
                                      extras={extras}
                                      onUpdate={r => {
                                        onUpdateSortableRow(r);
                                      }}
                                  />
                              )
                          }

                          {
                              sortBy.type === 'desc' && (
                                  <SortableRow
                                      isSortableRow={isSortableRow}
                                      items={list.sort((a, b) => getFilterType(b, sortBy.accessor) > getFilterType(a, sortBy.accessor) ? 1 : -1)}
                                      draw={desktopRow}
                                      extras={extras}
                                      onUpdate={r => {
                                        onUpdateSortableRow(r);
                                      }}
                                  />
                              )
                          }

                        </>

                    ) : (
                        <SortableRow
                            isSortableRow={isSortableRow}
                            items={list}
                            draw={desktopRow}
                            extras={extras}
                            onUpdate={r => {
                              onUpdateSortableRow(r);
                            }}
                        />
                    )

                  }

                </TableSort>
            ) : (
                <Table
                    columns={columns}
                    isLoading={isLoading}
                    dataLength={list.length}
                    hasCheckbox={hasCheckbox}
                    isActiveSelectAll={isActiveSelectAll}
                    handleSelectAll={handleSelectAll}
                    selectAll={selectAll}
                >
                  <SortableRow
                      isSortableRow={isSortableRow}
                      items={list}
                      draw={desktopRow}
                      extras={extras}
                      onUpdate={r => {
                        onUpdateSortableRow(r);
                      }}
                  />
                </Table>
            )
          }


        </div>

        </InfiniteScrollPagination>


      </div>


      {paginationOnChange && (

          <>
            {
              isDynamicPagination ? (
                  <DynamicPagination
                      isLoading={isLoading}
                      total={totalRows}
                      resetPagination={resetPagination}
                      limit={pageLimit}
                      onChange={paginationOnChange}
                  />
              ) : (
                  <Pagination
                      isLoading={isLoading}
                      total={totalRows}
                      limit={pageLimit}
                      onChange={paginationOnChange}
                  />
              )
            }

          </>

      )}
    </div>
  );
};


const SortableRow = ({isSortableRow, items, draw, extras, onUpdate}) => {

  const Draw = draw;

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;
    if (result.type === "droppableItem") {
      const itemsData = reorder(items, sourceIndex, destIndex);
      onUpdate(itemsData);
    } else if (result.type === "droppableSubItem") {
      const itemSubItemMap = items.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});


      const sourceParentId = result.source.droppableId;
      const destParentId = result.destination.droppableId;

      const sourceSubItems = itemSubItemMap[sourceParentId];
      const destSubItems = itemSubItemMap[destParentId];

      let newItems = [...items];

      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const reorderedSubItems = reorder(
            sourceSubItems,
            sourceIndex,
            destIndex
        );
        newItems = newItems.map(item => {
          if (item.id === sourceParentId) {
            item = reorderedSubItems;
          }
          return item;
        });
        onUpdate(newItems);
      } else {



        let newSourceSubItems = [...sourceSubItems];
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1);

        let newDestSubItems = [...destSubItems];
        newDestSubItems.splice(destIndex, 0, draggedItem);
        newItems = newItems.map(item => {
          if (item.id === sourceParentId) {
            item = newSourceSubItems;
          } else if (item.id === destParentId) {
            item = newDestSubItems;
          }
          return item;
        });
        onUpdate(newItems);
      }
    }
  }

  return (
      isSortableRow ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" type="droppableItem">
              {(provided, snapshot) => (
                  <tbody className="bg-white divide-y divide-gray-100" ref={provided.innerRef}>
                    {items.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                          {(provided, snapshot) => (
                              <tr
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                              >
                                <Draw key={item.id} element={item} extras={extras} index={index} dragHandle={provided.dragHandleProps} />
                              </tr>
                          )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
              )}
            </Droppable>
          </DragDropContext>
      ) : (
          <tbody className="bg-white divide-y divide-gray-100">
            {items.map((item, index) => (
                <Draw key={item.id} element={item} extras={extras} index={index} />
            ))}
          </tbody>
      )
  )
}


export default ListSection;

import { Popover, Transition } from '@headlessui/react'
import React, {Fragment} from 'react'
import {ArrowNarrowDownIcon, ArrowNarrowUpIcon} from "@heroicons/react/outline";
import {useTranslation} from "react-i18next";
import NumberFormat from "react-number-format";

export default function PriceRange({price, purchasePrice, isLeft = false, showMargin = false, boxExtraClass = '', symbol = '€'}) {
    const { t } = useTranslation();

    return (

        <Popover>
            {({ open }) => (
                <>
                    <Popover.Button>
                        {
                            (purchasePrice && (price > 0)) ? (
                                <div className="flex items-center ">

                                    {
                                        showMargin && !isLeft && price > 0 && (
                                            <div className={price > purchasePrice ? "text-green-900" :"text-red-900"}>
                                                <b><NumberFormat
                                                    value={(( (price/purchasePrice) -1) * 100)}
                                                    displayType={"text"}
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    decimalScale={2}
                                                    suffix={'%'}
                                                /></b>
                                            </div>
                                        )
                                    }

                                    {
                                        price > purchasePrice  && price > 0 ? (
                                            <ArrowNarrowUpIcon className={isLeft ? "cursor-pointer text-green-400 h-5 w-5 ml-3": "cursor-pointer text-green-400 h-5 w-5 mr-3"} />
                                        ) : (
                                            <ArrowNarrowDownIcon className={isLeft ? "cursor-pointer text-red-400 h-5 w-5 ml-3": "cursor-pointer text-red-400 h-5 w-5 mr-3"} />
                                        )
                                    }

                                    {
                                        showMargin && isLeft  && price > 0 && (
                                            <div className={price > purchasePrice ? "text-green-900" :"text-red-900"}>
                                                <b><NumberFormat
                                                    value={(( (price/purchasePrice) -1) * 100)}
                                                    displayType={"text"}
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    decimalScale={2}
                                                    suffix={'%'}
                                                /></b>
                                            </div>
                                        )
                                    }

                                </div>
                            ) : (<></>)
                        }


                    </Popover.Button>




                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel className={"absolute z-10 mt-3  max-w-md transform px-4 sm:px-0 z-50 "+boxExtraClass}>
                            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                <div className="relative  bg-white">

                                    <div className=" bg-white  rounded-sm border border-slate-200">
                                        <nav className="overflow-y-auto max-h-80" aria-label="Directory">
                                        <div className="relative">
                                            <ul className="relative z-0 divide-y divide-gray-200 text-sm font-medium text-gray-900">

                                                <li className=" px-2 bg-white">
                                                    <div className="py-2 flex items-center justify-between space-x-3">
                                                        <div className="min-w-0 flex-1 flex items-center space-x-3">
                                                            <div className="min-w-0 flex-1">
                                                                <p className="text-sm font-medium text-gray-600 truncate">
                                                                    {t("app.quotations.purchase_price")}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="mt-4 space-x-3 flex md:mt-0 md:ml-4">
                                                            <NumberFormat
                                                                value={purchasePrice}
                                                                displayType={"text"}
                                                                thousandSeparator={"."}
                                                                decimalSeparator={","}
                                                                decimalScale={2}
                                                                suffix={' '+symbol}
                                                            />
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className=" px-2 bg-white">
                                                    <div className="py-2 flex items-center justify-between space-x-3">
                                                        <div className="min-w-0 flex-1 flex items-center space-x-3">
                                                            <div className="min-w-0 flex-1">
                                                                <p className="text-sm font-medium text-gray-600 truncate">
                                                                    {t("app.quotations.net_price")}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="mt-4 space-x-3 flex md:mt-0 md:ml-4">
                                                            <NumberFormat
                                                                value={price}
                                                                displayType={"text"}
                                                                thousandSeparator={"."}
                                                                decimalSeparator={","}
                                                                decimalScale={2}
                                                                suffix={' '+symbol}
                                                            />
                                                        </div>
                                                    </div>
                                                </li>

                                                <li className=" px-2 bg-solitude-100">
                                                    <div className="py-2 flex items-center justify-between space-x-3">
                                                        <div className="min-w-0 flex-1 flex items-center space-x-3">
                                                            <div className="min-w-0 flex-1">
                                                                <p className="text-sm font-medium text-gray-600 truncate">
                                                                    {t("app.quotations.margin")} ({symbol})
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="mt-4 space-x-3 flex md:mt-0 md:ml-4">
                                                            <NumberFormat
                                                                value={price - purchasePrice}
                                                                displayType={"text"}
                                                                thousandSeparator={"."}
                                                                decimalSeparator={","}
                                                                decimalScale={2}
                                                                suffix={' '+symbol}
                                                            />
                                                        </div>
                                                    </div>
                                                </li>

                                                <li className=" px-2 bg-solitude-100">
                                                    <div className="py-2 flex items-center justify-between space-x-3">
                                                        <div className="min-w-0 flex-1 flex items-center space-x-3">
                                                            <div className="min-w-0 flex-1">
                                                                <p className="text-sm font-medium text-gray-900 truncate">
                                                                    {t("app.quotations.margin")} (%)
                                                                </p>

                                                            </div>
                                                        </div>
                                                        <div className={price > purchasePrice ? "mt-4 space-x-3 flex md:mt-0 md:ml-4 text-green-900" :"mt-4 space-x-3 flex md:mt-0 md:ml-4 text-red-900"}>
                                                            <b><NumberFormat
                                                                value={(( (price/purchasePrice) -1) * 100)}
                                                                displayType={"text"}
                                                                thousandSeparator={"."}
                                                                decimalSeparator={","}
                                                                decimalScale={2}
                                                                suffix={'%'}
                                                            /></b>
                                                        </div>
                                                    </div>
                                                </li>

                                            </ul>
                                        </div>
                                        </nav>
                                    </div>

                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    )
}


import React from "react";
import { classNames } from "../../../utils/Utils";

const TableDetail = ({ extraClass = '', padding = 'px-6 py-3', children, colSpan = 1, isWrap = true, width = null }) => {
    return (
        <td colSpan={colSpan} style={width && { width: width }} className={
            classNames(
                isWrap ? 'whitespace-nowrap text-sm font-medium text-gray-900 ' : 'text-sm font-medium text-gray-900',
                extraClass,
                padding
            )
            }>
            {children}
        </td>
    );
};
export default TableDetail;

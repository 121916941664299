import { Transition } from "@headlessui/react";
import { useEffect, useState } from "react";
import { getElement } from "../../../api/config";
import { getPathParam } from "../../../utils/converter";
import { COMPANY } from "../../../api/endpoints";
import { useSelector } from "react-redux";
import { JOB } from "../../../routes/endpoints";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ProgressNotification({ notifications }) {


    return (
       
        <div className="pointer-events-none absolute bottom-0 right-0 flex flex-col items-end gap-4 px-6 pb-5 lg:px-8 z-[1000]">
            {notifications.map((notification, index) => (
                <TransitionCard key={index} notification={notification} />
            ))}
        </div>
   
    )
}


const TransitionCard = ({ notification }) => {

    const [show, setShow] = useState(true);
    const { t } = useTranslation();


    const [notificationDetail, setNotificationDetail] = useState(notification);
    const { loggedCompany } = useSelector((state) => state.user);




    useEffect(() => {
        const interval = setInterval(async () => {
            if (loggedCompany && show) {
                try {
                    const res = await getElement(getPathParam([COMPANY, loggedCompany.id, JOB, notification.id]));
                    setNotificationDetail(res);

                    if (res.status === 'completed'){
                        setShow(false);
                    }

                } catch (e) {
                   // setStatuses([]);
                }
            }
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    });

    return(
        <Transition
            key={notification.id}
            show={show}
            enter="transform transition duration-500 ease-in-out"
            enterFrom="opacity-0 translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="transform transition duration-300 ease-in-out"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-4"
        >

            <div className="pointer-events-auto relative  flex flex-col items-center justify-start gap-x-6 bg-gray-900 px-6 py-3 rounded-xl  z-[1000]">
                <p className="text-sm leading-6 text-white">
                    <strong className="font-semibold">{t("app.company_jobs." + notificationDetail.action)} {t("app.company_jobs." + notificationDetail.entity_name)}</strong>
                </p>
                <div className="flex w-1/2 items-left justify-start mr-auto mt-1">
                    <div className={classNames(
                        notificationDetail.progress >= 50 ? 'shim-green' : '',
                        notificationDetail.progress < 50 ? 'shim-red' : '',
                        "text-xs font-medium rounded text-white text-center p-0.5 leading-none"
                    )} style={{ width: notificationDetail.progress + '%' }}>
                    </div>
                </div>
            </div>
        </Transition>
        
    )
}

import React, {useEffect} from "react";
import BaseList from "../../../../components/partials/common/base-list";
import {useTranslation} from "react-i18next";
import {getPathParam, getSelectParam} from "../../../../utils/converter";
import {COMPANY, WAREHOUSES, WAREHOUSE} from "../../../../api/endpoints";
import {useDispatch, useSelector} from "react-redux";
import ActionsMenu from "../../../../components/common/table/actions-menu";
import CardDetail from "../../../../components/common/list/card-detail";
import TableDetail from "../../../../components/common/list/table-detail";
import {Controller, useForm} from "react-hook-form";
import InputText from "../../../../components/common/form/input-text";
import {Warning} from "../../../../components/common/alert/banner";
import InputSubmit from "../../../../components/common/form/input-submit";
import WarehouseIcon from "../../../../resources/icons/WarehouseIcon";
import {fetchCountries} from "../../../../reducers/configReducer";
import InputSelect from "../../../../components/common/form/input-select";
import {getPermission, permissionGroup, permissionSpecific} from "../../../../constants/permissions";
import BasePermissionWrapper from "../../../../components/partials/restricted/base-permission-wrapper";
import RegionsSearch from "../../../../components/common/form/regions-search";
import CitiesSearch from "../../../../components/common/form/cities-search";
import MunicipalitySearch from "../../../../components/common/form/municipality-search";

const Warehouse = () => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    return (
        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.COMPANY_CONFIGURATION, permissionSpecific.COMPANY_CONF_WAREHOUSE)]} renderBaseOnFail={true} >
        {
                loggedCompany && (
                    <BaseList
                        columns={[
                            t("app.warehouse.name"),
                            t("app.connection.address"),
                            "",
                        ]}
                        endpoint={getPathParam([COMPANY, loggedCompany.id, WAREHOUSES])}
                        storeEndpoint={getPathParam([COMPANY, loggedCompany.id, WAREHOUSE])}
                        updateEndpoint={getPathParam([COMPANY, loggedCompany.id, WAREHOUSE, ':id'])}
                        baseResult={{total_rows: 0, warehouses: []}}
                        resultLabel={'warehouses'}
                        title={t("app.warehouse.name")}
                        showHeader={true}
                        mobRow={MobRow}
                        deskRow={DeskRow}
                        addForm={AddWarehouse}
                        icon={WarehouseIcon}
                        addFormLabel={t("app.warehouse.add_name")}
                        btnAddLabel={t("app.warehouse.add_name")}
                        editForm={EditWarehouse}
                        editTitle={t("app.warehouse.warehouses")}
                        isActiveEdit={true}

                    />
                )
            }

        </BasePermissionWrapper>
    );
};


const MobRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    const removeDetail = {
        title: t("app.warehouse.warehouse_remove_title"),
        message: t("app.warehouse.warehouse_remove_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, WAREHOUSE, element.id])
    };

    return (
        <li key={element.id} className="flex items-center gap-4 p-4">
            <div className="flex-grow">
                <div className="pb-4 items-center flex justify-between">
                    {element.name}
                    <ActionsMenu>
                        <button className={'text-left'} onClick={() => {
                            extras.onEdit(true, element);
                        }}
                        >
                            {t("app.common.edit")}
                        </button>
                        <button className={'text-left'} onClick={() => {
                            extras.onRemove(true, removeDetail);
                        }}
                        >
                            {t("app.common.remove")}
                        </button>
                    </ActionsMenu>
                </div>
                <div className="flex flex-col">
                    <CardDetail title={t("app.warehouse.name")}>
                        {element.name}
                    </CardDetail>

                    <CardDetail title={t("app.connection.address")}>
                        {element.full_address}
                    </CardDetail>

                </div>
            </div>
        </li>
    );
};

const DeskRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);

    const removeDetail = {
        title: t("app.warehouse.warehouse_remove_title"),
        message: t("app.warehouse.warehouse_remove_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, WAREHOUSE, element.id])
    };

    return (
        <tr key={element.id}>
            <TableDetail extraClass="max-w-sm">
                <div className="flex items-center space-x-3 lg:pl-2">
                    <div className="truncate hover:text-gray-600">
                        {element.name}
                    </div>
                </div>
            </TableDetail>

            <TableDetail>
                {element.full_address}
            </TableDetail>

            <TableDetail>
                <ActionsMenu>
                    <button className={'text-left'} onClick={() => {
                        extras.onEdit(true, element);
                    }}
                    >
                        {t("app.common.edit")}
                    </button>
                    <button className={'text-left'} onClick={() => {
                        extras.onRemove(true, removeDetail);
                    }}
                    >
                        {t("app.common.remove")}
                    </button>
                </ActionsMenu>
            </TableDetail>
        </tr>
    );
};

const AddWarehouse = ({ exception, isLoading, onSubmitData }) =>  {

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        watch,
        setValue
    } = useForm();

    const { isCountriesLoading, countries } = useSelector((state) => state.config);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const onSubmit = async (data) => {

        if (data.country) {
            data['country'] = { id: data.country.id };
        }

        if (data.city) {
            data['city'] = { id: data.city.id };
        }

        if (data.region) {
            data['region'] = { id: data.region.id };
        }

        if (data.municipality) {
            data['municipality'] = { id: data.municipality.id };
        }

        onSubmitData(data);
    };

    useEffect(() => {
        dispatch(fetchCountries());
    }, [dispatch]);




    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-4">
                <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-3 gap-x-4 sm:grid-cols-1">
                    <InputText
                        id={"name"}
                        type={"text"}
                        errors={errors.name}
                        input={{ ...register("name", { required: true }) }}
                        label={t("app.warehouse.name")}
                        isRequired={true}
                    />

                    <InputText
                        id={"full_address"}
                        type={"text"}
                        errors={errors.full_address}
                        input={{ ...register("full_address", { required: true }) }}
                        label={t("app.connection.address")}
                        isRequired={true}
                    />


                    <MunicipalitySearch
                        label={t("app.common.municipality")}
                        placeholder={t("app.common.municipality")}
                        isRequired={true}
                        selectedItem={watch('municipality') ?? null}
                        countryCode={watch('country')?.code ?? null}
                        isDisabled={false}
                        onSelect={(e) => {
                            setValue('municipality', e)
                        }}
                    />

                    <CitiesSearch
                        label={t("app.common.city")}
                        placeholder={t("app.common.city")}
                        isRequired={true}
                        selectedItem={watch('city') ?? null}
                        countryCode={watch('country')?.code ?? null}
                        isDisabled={false}
                        onSelect={(e) => {
                            setValue('city', e)
                        }}
                    />

                    <InputText
                        id={'zip_code'}
                        type={'text'}
                        errors={errors.zip_code}
                        input={{ ...register("zip_code", { required: true }) }}
                        label={t("app.connection.zip_code")}
                        isRequired={true}
                    />

                    <RegionsSearch
                        label={t("app.common.region")}
                        placeholder={t("app.common.region")}
                        isRequired={false}
                        selectedItem={watch('region') ?? null}
                        countryCode={watch('country')?.code ?? null}
                        isDisabled={false}
                        onSelect={(e) => {
                            setValue('region', e)
                        }}
                    />


                    <div className="col-span-2">
                        <Controller
                            name="country"
                            rules={{ required: true }}
                            control={control}
                            render={({
                                         field: { onChange, value, name },
                                         fieldState: { error },
                                     }) => (
                                <InputSelect
                                    label={t("app.form.country")}
                                    name={name}
                                    options={countries}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    isRequired={true}
                                    isLoading={isCountriesLoading}
                                    isDisabled={isCountriesLoading}
                                    errors={error}
                                />
                            )}
                        />
                    </div>

                </div>
            </div>

            {/* Warning */}
            {exception && (
                <Warning message={exception} />
            )}

            <div className="flex before:flex-1 items-center justify-between mt-6">
                <InputSubmit
                    isLoading={isLoading}
                    label={t("app.warehouse.add_name")}
                />
            </div>
        </form>
    );
}

const EditWarehouse = ({ data, exception, onSubmitData, isEditLoading }) =>  {

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        watch
    } = useForm();

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isCountriesLoading, countries } = useSelector((state) => state.config);

  

    useEffect(() => {
        if (data !== null) {
            for (const k in data) {
                if (typeof data[k] !== "object") {
                    setValue(k, data[k]);
                } else {
                    setValue(k, getSelectParam(data[k], "name"));

                }
            }
        }
    }, [setValue, data]);

    const onSubmit = async (data) => {
        onSubmitData(data);
    };


    useEffect(() => {
        dispatch(fetchCountries());
    }, [dispatch]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>

                    <h3 className="mt-4 text-md font-medium text-gray-900">
                        {t("app.warehouse.information")}
                    </h3>

                    <div className="space-y-4 pt-4 pb-5">

                        <InputText
                            id={"name"}
                            type={"text"}
                            errors={errors.name}
                            input={{ ...register("name", { required: true }) }}
                            label={t("app.warehouse.name")}
                            isRequired={true}
                        />

                        <InputText
                            id={"full_address"}
                            type={"text"}
                            errors={errors.full_address}
                            input={{ ...register("full_address", { required: true }) }}
                            label={t("app.connection.address")}
                            isRequired={true}
                        />


                        <MunicipalitySearch
                            label={t("app.common.municipality")}
                            placeholder={t("app.common.municipality")}
                            isRequired={true}
                            selectedItem={watch('municipality') ?? null}
                            countryCode={watch('country')?.code ?? null}
                            isDisabled={false}
                            onSelect={(e) => {
                                setValue('municipality', e)
                            }}
                        />

                        <CitiesSearch
                            label={t("app.common.city")}
                            placeholder={t("app.common.city")}
                            isRequired={true}
                            selectedItem={watch('city') ?? null}
                            countryCode={watch('country')?.code ?? null}
                            isDisabled={false}
                            onSelect={(e) => {
                                setValue('city', e)
                            }}
                        />

                        <InputText
                            id={'zip_code'}
                            type={'text'}
                            errors={errors.zip_code}
                            input={{ ...register("zip_code", { required: true }) }}
                            label={t("app.connection.zip_code")}
                            isRequired={true}
                        />

                        <RegionsSearch
                            label={t("app.common.region")}
                            placeholder={t("app.common.region")}
                            isRequired={false}
                            selectedItem={watch('region') ?? null}
                            countryCode={watch('country')?.code ?? null}
                            isDisabled={false}
                            onSelect={(e) => {
                                setValue('region', e)
                            }}
                        />

                        <Controller
                            name="country"
                            rules={{ required: true }}
                            control={control}
                            render={({
                                         field: { onChange, value, name },
                                         fieldState: { error },
                                     }) => (
                                <InputSelect
                                    label={t("app.form.country")}
                                    name={name}
                                    options={countries}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    isLoading={isCountriesLoading}
                                    isDisabled={isCountriesLoading}
                                    errors={error}
                                />
                            )}
                        />

                    </div>
                </div>

                {/* Warning */}
                {exception && (
                    <Warning message={exception} />
                )}

                <div className="mt-3">
                    <InputSubmit
                        isLoading={isEditLoading}
                        isFullWith={true}
                        label={t("app.form.save")}
                    />
                </div>
            </form>

        </>
    );
}

export default Warehouse;

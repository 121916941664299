import React from "react";
import { Route, Routes } from "react-router";
import * as Endpoint from "./endpoints";
import { useSelector } from "react-redux";
import Loading from "../components/layout/loading";

// layout
import GuestLayout from "../components/layout/guest-layout";
import RequireAuth from "../components/layout/require-auth";
import RequireAuthOnboarding from "../components/layout/require-auth-onboarding";

// oauth
import Login from "./oauth/login";
import Register from "./oauth/register";
import VerifyRegistration from "./oauth/verify-registration";
import ConfirmRegistration from "./oauth/confirm-registration";
import ResetPasswordRequest from "./oauth/reset-password-request";
import ResetPassword from "./oauth/reset-password";
import Dashboard from "./dashboard/dashboard";

// onboarding
import Customers from "./customer/customers";
import Shippings from "./shipping/shippings";
import ShippingDetail from "./shipping/panel/shipping/shipping-detail";
import Containers from "./shipping/panel/container/containers";
import Documents from "./shipping/panel/document/documents";
import Events from "./shipping/panel/event/events";
import ContainerDetail from "./shipping/panel/container/edit/detail/container-detail";
import ContainerGallery from "./shipping/panel/container/edit/detail/container-gallery";
import LoadConfiguration from "./shipping/panel/container/edit/load-configuration/load-configuration";
import Products from "./catalog/product/products";
import Orders from "./order/orders";
import VerifyConnection from "./supplier/verify-connection";
import ShippingOverview from "./shipping/shipping-overview";
import StepCreateCompany from "./onboarding/step-create-company";
import StepComplete from "./onboarding/step-complete";
import StepCompanyType from "./onboarding/step-company-type";
import Users from "./user/users";
import UserDetail from "./user/user-detail";
import ProductDetail from "./catalog/product/edit/detail/product-details";
import ProductGallery from "./catalog/product/edit/media/product-gallery";
import Settings from "./account/user/settings";
import Subscription from "./account/user/subscription";
import Companies from "./account/company/companies";
import CompanyDetail from "./account/company/company-detail";
import Forwarders from "./forwarder/forwarders";
import UserVerifyConnection from "./connection/user-verify-connection";
import ConnectionDetail from "./account/user/connection-detail";
import Suppliers from "./supplier/suppliers";
import Page404 from "./404";
import OrderOverview from "./order/order-overview";
import ShippingEdit from "./shipping/panel";
import ContainerEdit from "./shipping/panel/container/edit";
import RegisterConnection from "./oauth/register-connection";
import Tasks from "./task/tasks";
import CompanyRegisterConnection from "./oauth/company-register-connection";
import CustomerVerifyConnection from "./customer/cusotmer-verify-connection";
import CompanyConfiguration from "./account/company/configuration";
import ImportModel from "./account/company/configuration/import-model";
import UserCategory from "./account/company/configuration/user-category";
import Warehouse from "./account/company/configuration/warehouse";
import ProductDimension from "./catalog/product/edit/dimension/product-dimension";
import ProductEdit from "./catalog/product/edit";
import SettingEdit from "./account/user";
import PaymentComplete from "./account/user/payment-complete";
import UserSubscription from "./account/user/user-subscription";
import BillingSettings from "./account/user/billing-settings";
import EmailIntegration from "./account/company/configuration/email-integration";
import ShippingTracking from "./shipping/panel/tracking/shipping-tracking";
import Quotations from "./quotation/quotations";
import QuotationOverview from "./quotation/quotation-overview";
import ProductTranslation from "./catalog/product/edit/translation/product-translation";
import CompanyPreference from "./account/company/configuration/company-preference";
import ProductDetailEdit from "./catalog/settings";
import Category from "./catalog/settings/category";
import Brand from "./catalog/settings/brand";
import Supplier from "./catalog/settings/supplier";
import Manufacture from "./catalog/settings/manufactures";
import PriceHistory from "./catalog/product/edit/pricing/price-history";
import ProductSupplier from "./catalog/product/edit/supplier/product-supplier";
import ProductMedia from "./catalog/product/edit/media/product-media";
import Webhooks from "./account/company/configuration/webhooks";
import SubCategory from "./catalog/settings/sub-category";
import CompanyPermissionGroup from "./account/company/configuration/permission/company-permission-group";
import ProductLog from "./catalog/product/edit/log/product-log";
import Leads from "./lead/leads";
import LeadOverview from "./lead/lead-overview";
import LeadDataOverview from "../components/partials/connection/lead-data-overview";
import ConnectionNotes from "../components/partials/connection/connection-notes";
import ConnectionAttachments from "../components/partials/connection/connetion-attachments";
import ConnectionLogs from "../components/partials/connection/connetion-logs";
import ConnectionTasks from "../components/partials/connection/connetion-tasks";
import CustomerOverview from "./customer/customer-overview";
import ConnectionOverview from "../components/partials/connection/connetion-overview";
import DocumentTemplate from "./account/company/configuration/document-template";
import ProductAlternative from "./catalog/product/edit/alternative/product-alternative";
import Boards from "./board/boards";
import SupplierOverview from "./supplier/supplier-overview";
import ConnectionAddresses from "../components/partials/connection/connetion-addresses";
import EmailTemplate from "./account/company/configuration/email-template";
import ForwarderOverview from "./forwarder/forwarder-overview";
import ForwarderContact from "./forwarder/detail/forwarder-contact";
import ForwarderLine from "./forwarder/detail/forwarder-line";
import Booking from "./logistic/booking/booking";
import Attributes from "./catalog/settings/attributes";
import Encodings from "./catalog/settings/encoding/encodings";
import DocumentTemplateGroup from "./account/company/configuration/document-template-group";
import AttributeGroup from "./catalog/settings/attributes-group";
import CompanyMediaGroup from "./account/company/configuration/company-media-group";
import CompanyMedia from "./account/company/configuration/company-media";

function Main() {
  const { isLoading } = useSelector((state) => state.user);
  if (isLoading) {
    return <Loading />;
  }

  return (
    <Routes>
      {/* GUEST LAYOUT ROUTING */}
      <Route path="/" element={<GuestLayout />}>
        <Route path={Endpoint.LOGIN} element={<Login />} />
        <Route path={Endpoint.REGISTER} element={<Register />} />
        <Route path={Endpoint.COMPANY_REGISTER_CONNECTION  + "/:connection_code"} element={<CompanyRegisterConnection />} />
        <Route path={Endpoint.REGISTER_CONNECTION  + "/:connection_code"} element={<RegisterConnection />} />
        <Route
          path={Endpoint.VERIFY_REGISTRATION}
          element={<VerifyRegistration />}
        />
        <Route
          path={Endpoint.VERIFY_REGISTRATION + "/:confirmation_code"}
          element={<ConfirmRegistration />}
        />
        <Route
          path={Endpoint.RESET_PASSWORD}
          element={<ResetPasswordRequest />}
        />
        <Route
          path={Endpoint.RESET_PASSWORD + "/:confirmation_code"}
          element={<ResetPassword />}
        />
      </Route>

      {/* ONBOARDING AUTH ROUTING */}
      <Route path={Endpoint.ONBOARDING} element={<RequireAuthOnboarding />}>
        <Route
          index
          path={Endpoint.ONBOARDING}
          element={<StepCompanyType />}
        />
        <Route
          index
          path={Endpoint.ONBOARDING + Endpoint.ONBOARDING_ADD_COMPANY}
          element={<StepCreateCompany />}
        />
        <Route
          index
          path={Endpoint.ONBOARDING + Endpoint.ONBOARDING_DONE}
          element={<StepComplete />}
        />
      </Route>

      {/* REQUIRE AUTH ROUTING */}
      <Route path="/" element={<RequireAuth />}>

        {/* DASHBOARD ROUTING */}
        <Route index path={Endpoint.DASHBOARD} element={<Dashboard />} />

        {/* ORDER ROUTING */}
        <Route path={Endpoint.SALES + Endpoint.ORDERS} element={<Orders />} />
        <Route path={Endpoint.SALES + Endpoint.ORDER + Endpoint.ID + Endpoint.ORDER_OVERVIEW} element={<OrderOverview />} />


        <Route path={Endpoint.SALES + Endpoint.QUOTATIONS} element={<Quotations />} />
        <Route path={Endpoint.SALES + Endpoint.QUOTATION + Endpoint.ID + Endpoint.QUOTATION_OVERVIEW} element={<QuotationOverview />} />

        <Route path={Endpoint.SALES + Endpoint.LEADS} element={<Leads />} />
        <Route path={Endpoint.SALES + Endpoint.LEAD + Endpoint.ID} element={<LeadOverview />}>
          <Route index path={Endpoint.C_CONNECTION_OVERVIEW} element={<LeadDataOverview />} />
          <Route path={Endpoint.C_CONNECTION_NOTES} element={<ConnectionNotes />} />
          <Route path={Endpoint.C_CONNECTION_ATTACHMENTS} element={<ConnectionAttachments />} />
          <Route path={Endpoint.C_CONNECTION_TASKS} element={<ConnectionTasks />} />
          <Route path={Endpoint.C_CONNECTION_LOGS} element={<ConnectionLogs />} />
        </Route>

        {/* CATALOG ROUTING */}
        <Route path={Endpoint.CATALOG + Endpoint.PRODUCTS} element={<Products />}/>

        <Route path={Endpoint.CATALOG + Endpoint.PRODUCT + Endpoint.ID} element={<ProductEdit />}>
          <Route path={Endpoint.C_PRODUCT_DETAIL} element={<ProductDetail />} />
          <Route path={Endpoint.C_PRICING} element={<PriceHistory />} />
          <Route path={Endpoint.C_CONTAINER_GALLERY} element={<ProductGallery />}/>
          <Route path={Endpoint.C_DIMENSIONS} element={<ProductDimension />}/>
          <Route path={Endpoint.C_TRANSLATION} element={<ProductTranslation />}/>
          <Route path={Endpoint.C_PRODUCT_SUPPLIERS} element={<ProductSupplier />}/>
          <Route path={Endpoint.C_PRODUCT_MEDIA} element={<ProductMedia />}/>
          <Route path={Endpoint.C_PRODUCT_LOG} element={<ProductLog />} />
          <Route path={Endpoint.C_PRODUCT_ALTERNATIVE} element={<ProductAlternative />} />
        </Route>


        <Route path={Endpoint.CATALOG + Endpoint.PRODUCT_SETTINGS} element={<ProductDetailEdit />}>
          <Route path={Endpoint.C_CATEGORIES} element={<Category />} />
          <Route path={Endpoint.C_CATEGORY + Endpoint.ID + Endpoint.C_SUB_CATEGORIES} element={<SubCategory />} />
          <Route path={Endpoint.C_BRANDS} element={<Brand />} />
          <Route path={Endpoint.C_COMPANY_SUPPLIERS} element={<Supplier />} />
          <Route path={Endpoint.C_MANUFACTURE} element={<Manufacture />} />

          <Route path={Endpoint.C_ATTRIBUTE} element={<AttributeGroup />} />
          <Route path={Endpoint.C_ATTRIBUTE + '/:category_id'} element={<Attributes />} />

          <Route path={Endpoint.C_ENCODING} element={<Encodings />} />

        </Route>

        {/* SHIPPING ROUTING */}
        <Route path={Endpoint.LOGISTICS + Endpoint.SHIPPINGS} element={<Shippings />} />

        <Route path={Endpoint.LOGISTICS + Endpoint.SHIPPING + Endpoint.ID} element={<ShippingEdit />}>
          <Route path={Endpoint.C_SHIPPING_DETAIL} element={<ShippingDetail />} />

          {/* CONTAINER ROUTING */}
          <Route path={Endpoint.C_CONTAINERS} element={<Containers />} />

          {/* DOCUMENT ROUTING */}
          <Route path={Endpoint.C_DOCUMENTS} element={<Documents />} />

          {/* EVENT ROUTING */}
          <Route path={Endpoint.C_EVENTS} element={<Events />} />
          <Route path={Endpoint.C_TRACKING} element={<ShippingTracking />} />
        </Route>
        <Route path={Endpoint.LOGISTICS + Endpoint.SHIPPING + Endpoint.ID +  Endpoint.SHIPPING_OVERVIEW} element={<ShippingOverview />} />


        {/* FREIGHT ROUTING*/}
        <Route path={Endpoint.LOGISTICS + Endpoint.BOOKINGS} element={<Booking />} />
        <Route path={Endpoint.LOGISTICS + Endpoint.BOOKINGS + Endpoint.ID} element={<Booking />} />


        {/* CONTAINER ROUTING */}
        <Route path={Endpoint.LOGISTICS + Endpoint.SHIPPING + Endpoint.ID +  Endpoint.CONTAINER + Endpoint.CONTAINER_ID} element={<ContainerEdit />}>
          <Route path={Endpoint.C_CONTAINER_GENERAL_INFORMATION} element={<ContainerDetail />} />
          <Route path={Endpoint.C_CONTAINER_GALLERY} element={<ContainerGallery />} />
          <Route path={Endpoint.C_CONTAINER_LOAD_CONFIGURATION} element={<LoadConfiguration />}/>
        </Route>




        {/* CUSTOMER ROUTING */}
        <Route path={Endpoint.REGISTRY + Endpoint.CUSTOMERS} element={<Customers />} />
        <Route path={Endpoint.REGISTRY + Endpoint.CUSTOMER + Endpoint.ID} element={<CustomerOverview />}>
          <Route index path={Endpoint.C_CONNECTION_OVERVIEW} element={<ConnectionOverview />} />
          <Route index path={Endpoint.C_CONNECTION_ADDRESSES} element={<ConnectionAddresses />} />
          <Route path={Endpoint.C_CONNECTION_NOTES} element={<ConnectionNotes />} />
          <Route path={Endpoint.C_CONNECTION_ATTACHMENTS} element={<ConnectionAttachments />} />
          <Route path={Endpoint.C_CONNECTION_TASKS} element={<ConnectionTasks />} />
          <Route path={Endpoint.C_CONNECTION_LOGS} element={<ConnectionLogs />} />
        </Route>



        {/* SUPPLIER ROUTING */}
        <Route path={Endpoint.REGISTRY + Endpoint.SUPPLIERS} element={<Suppliers />} />
          <Route path={Endpoint.REGISTRY + Endpoint.SUPPLIER + Endpoint.ID} element={<SupplierOverview />}>
          {/* <Route  path={Endpoint.C_CONNECTION_OVERVIEW} element={<ConnectionOverview />} />  */}
          <Route index path={Endpoint.C_CONNECTION_ADDRESSES} element={<ConnectionAddresses />} />
          <Route path={Endpoint.C_CONNECTION_NOTES} element={<ConnectionNotes />} />
          <Route path={Endpoint.C_CONNECTION_ATTACHMENTS} element={<ConnectionAttachments />} />
          <Route path={Endpoint.C_CONNECTION_TASKS} element={<ConnectionTasks />} />
          <Route path={Endpoint.C_CONNECTION_LOGS} element={<ConnectionLogs />} />
        </Route>



        {/* COMPANY ROUTING */}

        {/* USER ROUTING */}
        <Route path={Endpoint.REGISTRY + Endpoint.USERS} element={<Users />} />
        <Route path={Endpoint.REGISTRY + Endpoint.USER + Endpoint.ID} element={<UserDetail />} />


        {/* CUSTOMER ROUTING */}
        <Route path={Endpoint.REGISTRY + Endpoint.FORWARDERS} element={<Forwarders />} />
        <Route path={Endpoint.REGISTRY + Endpoint.FORWARDER + Endpoint.ID} element={<ForwarderOverview />}>
          <Route index path={Endpoint.C_FORWARDER_CONTACT} element={<ForwarderContact />} />
          <Route path={Endpoint.C_FORWARDER_LINES} element={<ForwarderLine />} />
        </Route>


        <Route path={Endpoint.COMPANY + Endpoint.COMPANY_DETAIL} element={<Companies />}/>
        <Route path={Endpoint.COMPANY + Endpoint.COMPANY_DETAIL + Endpoint.ID} element={<CompanyDetail />} />


        <Route path={Endpoint.COMPANY + Endpoint.CONFIGURATION} element={<CompanyConfiguration />}>
          <Route index path={Endpoint.WAREHOUSE} element={<Warehouse /> } />
          <Route path={Endpoint.IMPORT_MODELS} element={<ImportModel /> } />
          <Route path={Endpoint.EMAIL_INTEGRATION} element={<EmailIntegration /> } />
          <Route path={Endpoint.EMAIL_INTEGRATION + '/:provider_type'} element={<EmailIntegration /> } />
          <Route path={Endpoint.EMAIL_TEMPLATE} element={<EmailTemplate />} />
          <Route path={Endpoint.CONNECTION_CATEGORIES} element={<UserCategory /> } />
          <Route path={Endpoint.COMPANY_PREFERENCES} element={<CompanyPreference /> } />
          <Route path={Endpoint.COMPANY_PERMISSION} element={<CompanyPermissionGroup /> } />
          <Route path={Endpoint.WEBHOOKS} element={<Webhooks /> } />

          <Route path={Endpoint.DOCUMENT_TEMPLATE} element={<DocumentTemplateGroup />} />
          <Route path={Endpoint.DOCUMENT_TEMPLATE + '/:category_id'} element={<DocumentTemplate /> } />


          <Route path={Endpoint.COMPANY_MEDIA} element={<CompanyMediaGroup />} />
          <Route path={Endpoint.COMPANY_MEDIA + '/:category_id'} element={<CompanyMedia />} />
          
        </Route>


        {/* ACCOUNT ROUTING */}
        <Route path={Endpoint.ACCOUNT} element={<SettingEdit />}>
          <Route index path={Endpoint.C_PROFILE_CONNECTION} element={<ConnectionDetail />}/>
          <Route index path={Endpoint.C_PROFILE_SETTINGS} element={<Settings />}/>
          <Route index path={Endpoint.C_BILLING_INFORMATION} element={<BillingSettings />}/>
          <Route path={Endpoint.C_SUBSCRIPTION} element={<Subscription />}/>
          <Route path={Endpoint.C_USER_SUBSCRIPTION} element={<UserSubscription />}/>
        </Route>


        <Route path={Endpoint.ACCOUNT + Endpoint.SUBSCRIPTION_COMPLATE} element={<PaymentComplete />}/>
        <Route path={Endpoint.ACCOUNT + Endpoint.SUBSCRIPTION_COMPLATE + '/:checkout_session_id'} element={<PaymentComplete />}/>



        {/* BOARDS ROUTING */}
        <Route path={Endpoint.BOARDS} element={<Boards />} />

        {/* CONNECTION ROUTING */}
        <Route path={Endpoint.TASKS} element={<Tasks />}/>

        {/* SETTINGS ROUTING */}
        <Route path={Endpoint.VERIFY_CONNECTION + "/:connection_code"} element={<VerifyConnection />} />
        <Route path={Endpoint.COMPANY_VERIFY_CONNECTION + "/:connection_code"} element={<CustomerVerifyConnection />} />
        <Route path={Endpoint.USER_VERIFY_CONNECTION + "/:connection_code"} element={<UserVerifyConnection />}/>

        {/* 404 */}
        <Route path="*" element={<Page404 />} />
      </Route>
    </Routes>
  );
}

export default Main;

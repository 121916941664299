import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {getPermission, permissionGroup, permissionType} from "../../../constants/permissions";
import { useTranslation } from "react-i18next";
import BasePermissionWrapper, { hasPermissionFor } from "../../../components/partials/restricted/base-permission-wrapper";

import { PlusCircleIcon, PlusIcon } from '@heroicons/react/outline'
import { StatusBarIcon } from "../../../components/common/alert/status-bar-icon";
import InputSearch from "../../../components/common/form/input-search";
import { RadioGroup } from "@headlessui/react";
import ShippingIcon from "../../../resources/icons/ShippingIcon";
import DynamicFilterMenu from "../../../components/common/table/dynamic-filter-menu";
import { generateParamsFormLocation } from "../../../constants/dynamic-filter";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ManageBooking from "./manage-booking";
import { BOOKINGS, COMPANY, LOGISTICS } from "../../endpoints";
import { getPathParam, getWebPathParam, joinClassNames } from "../../../utils/converter";
import { getElementBody } from "../../../api/config";
import InfiniteScroll from "react-infinite-scroll-component";
import LoaderWrapper from "../../../components/common/loader/loader-wrapper";
import AddBooking from "./add-booking";
import { getFormattedDate } from "../../../utils/timeUtils";



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function updateArray(originalArray, updatedObject) {
  return originalArray.map(item => {
    if (item.id === updatedObject.id) {
      return { ...item, ...updatedObject };
    }
    return item;
  });
}




const Booking = () => {
  const { loggedCompany, companyType } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const location = useLocation();
  let navigate = useNavigate();


  const { permissions } = useSelector((s) => s.userCompany);


  const canCreate = hasPermissionFor(permissions, permissionGroup.BOOKING, permissionType.CREATE);
  const canView = hasPermissionFor(permissions, permissionGroup.BOOKING, permissionType.VIEW);


  let { id } = useParams();
  const checkOffset = () => {
    const pageNumber = new URLSearchParams(window.location.search).get(
      "page"
    );

    if (pageNumber) {
      return parseInt(((pageNumber * limit) - limit));
    }
    return 0;
  }

  // Details
  const [list, setList] = useState([]);
  const [rows, setRows] = useState(0);
  const [limit] = useState(30);
  const [query, setQuery] = useState("");
  const [offset, setOffset] = useState(checkOffset());
  const [isFetchLoading, setFetchLoading] = useState(true);
  const [lastCompanyType, setLastCompanyType] = useState(companyType);


  //Create Details
  const [isOpenCreate, setOpenCreate] = useState(false);


  const shippingTypes = [
    { type: 'sea', name: '', icon: ShippingIcon },
  ];
  const [shippingType, setShippingType] = useState(shippingTypes[0])


  
  const [dynamicFilters, setDynamicFilters] = useState([]);
  const [activeDynamicFilter, setDynamicDateFilter] = useState(generateParamsFormLocation(location.search));
  const [defaultParams, setDefaultParams] = useState(false);
  const [defaultParamsUsed, setDefaultParamsUsed] = useState(false);
  const [refreshData, setRefreshData] = useState(null);


  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;


    async function fetchShippings() {
      if (loggedCompany) {
        if (refreshData === null){
          setFetchLoading(true);
        }
        try {

          const r = await getElementBody(getPathParam([COMPANY, loggedCompany.id, BOOKINGS]), signal,
            {
              limit: limit,
              offset: query.length > 0 ? 0 : offset,
              query: query,
              shipping_type: shippingType.type,
              remove_default: !defaultParamsUsed,
              company_type: companyType
            },
            activeDynamicFilter
          );

          if (query.length > 0 || offset === 0) {
            setList(r.bookings);
            setRows(r.total_rows);
          } else {
            if (r.total_rows > 0) {
              setList(prevState => [...prevState, ...r.bookings]);
              setRows(r.total_rows);
            } else {
              setList([])
            }
          }


          if (r.filters) {
            setDynamicFilters(r.filters);
          }

          if (r.default_filter && !defaultParamsUsed && (generateParamsFormLocation(location.search).length === 0)) {
            setDefaultParams('?' + r.default_filter);
          }
          setLastCompanyType(companyType);
        } catch (e) {
          setList([]);
          setRows(0);
          if (signal.aborted) {
            setFetchLoading(true);
          }
        } finally {
          if (signal.aborted) {
            setFetchLoading(true);
          } else {
            setFetchLoading(false);
          }
        }
      }
    }

    fetchShippings();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line
  }, [offset, query, loggedCompany, companyType, limit, activeDynamicFilter, shippingType, refreshData]);


  return (
    <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.BOOKING, permissionType.LIST)]} renderBaseOnFail={true}>
      <div className="flex h-full ">

        <div className="relative z-0 flex flex-1 overflow-hidden ">
          
          

          {
            (!id && canCreate) && (
              <div className="relative z-0 mt-48 flex-1 overflow-y-auto focus:outline-none xl:order-last">
                <div className="px-6 py-14 text-center text-sm ">
                  <h2 className="text-center text-2xl leading-8 font-extrabold tracking-tight text-blue-1000 sm:text-4xl">
                    {t("app.booking.booking_title")}
                  </h2>
                  <p className="mt-2 mb-6 max-w-2xl mx-auto text-center text-md text-gray-500">
                    {t("app.booking.booking_description")}
                  </p>
                  <button
                    type="button"
                    onClick={() => {
                      setOpenCreate(true)
                    }}
                    className="inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    <PlusCircleIcon className="mr-3 h-4 w-4" />
                    {t("app.booking.add_booking")}
                  </button>
                </div>
              </div>
            )
          }

          {
            canView && (
              <ManageBooking
                onUpdateData={() => {
                  setOffset(0);
                  if (refreshData === null) {
                    setRefreshData(true);
                  } else {
                    setRefreshData(!refreshData);
                  }
                }}
                onRefresh={(r) => { setList(updateArray(list, r)) }}
              />
            )
          }
           

          <aside className={classNames(
            " flex-shrink-0 border-r border-gray-200 xl:order-first xl:flex xl:flex-col bg-white xl:w-96",
            id ? " hidden xl:block " : " w-full"
            )}>
            <div className="px-4 pb-4 pt-6">

              <div className=" flex items-center justify-between ">

                <h2 className="text-xl md:text-2xl text-blue-1000 uppercase font-bold">{t("app.booking.page_title")}</h2>
                <fieldset className="min-w-36">
                  <RadioGroup value={shippingType} onChange={setShippingType} className="flex items-center  rounded-md ring-1 ring-gray-200">
                    {shippingTypes.map((option) => (
                      <RadioGroup.Option
                        key={option.name}
                        value={option}
                        className={({ active, checked }) =>
                          `cursor-pointer flex items-center justify-center   p-2 text-sm  rounded-md font-semibold     sm:flex-1
                       ${(shippingType.type === option.type) ? 'bg-blue-1000 text-white' : 'bg-white text-blue-1000 hover:bg-gray-50'}`
                        }
                      >
                        <option.icon className="h-5 w-5" />
                      </RadioGroup.Option>
                    ))}
                  </RadioGroup>
                </fieldset>

              </div>
              


              <div className="mt-6 flex gap-x-2">
                <div className="min-w-0 flex-1">
                  <InputSearch onSearch={setQuery} extraClass={"  border-gray-200"} isDynamicSearch={true} hasMargin={false} length={1} />
                </div>
                {
                  canCreate && (
                    <button onClick={() => { setOpenCreate(true) }} className="inline-flex justify-center rounded-md bg-green-700 px-3 py-2 text-white  hover:bg-green-500">
                      <PlusIcon aria-hidden="true" className="h-5 w-5" />
                    </button>
                  )
                }
              </div>


              <div className="mt-2  ">
                <DynamicFilterMenu
                  isActiveName={true}
                  isActiveFilterCards={true}
                  filters={dynamicFilters}
                  defaultParams={defaultParams}
                  lastCompanyType={lastCompanyType}
                  isLoading={false}
                  filterType={'booking'}
                  onChange={(r) => {
                    setDynamicDateFilter(r);
                    setOffset(0);
                    const querySearch = new URLSearchParams(window.location.search).get("querySearch");
                    if (querySearch) {
                      setQuery(querySearch);
                    } else {
                      setQuery('');
                    }
                    setDefaultParamsUsed(true);
                    setDefaultParams(false);

                  }}
                />
              </div>

            </div>


          
            {/* Directory list */}
              <nav aria-label="Directory" className="min-h-0 flex-1 overflow-y-auto  border-t" id={"scrollableCards"}>
              <InfiniteScroll
                dataLength={rows} //This is important field to render the next data
                next={() => {
                  setOffset(limit + offset);
                }}
                hasMore={true}
                loader={<></>}
                scrollableTarget="scrollableCards"
              >

                <LoaderWrapper isLoading={isFetchLoading && offset === 0}>
                  <ul className="relative z-0 divide-y divide-gray-200" >
                    {list.map((booking) => (
                      <li key={booking.id}>
                        <Link to={canView ? getWebPathParam([LOGISTICS, BOOKINGS, booking.id]) : '#'} 
                          className={joinClassNames(
                            "relative flex flex-col  px-4 py-4 hover:bg-gray-50",
                            booking.id === id && "bg-gray-50"
                          )}
                        
                        >
                          <div className=" flex items-center justify-between mb-2">
                            <p className="text-xs flex-wrap text-gray-500">
                              #{booking.reference}
                            </p>
                            <p className="text-xs flex-wrap text-gray-500">
                              {getFormattedDate(booking.expected_date)}
                            </p>
                          </div>

                          <div className=" flex items-center justify-between">
                            <span className="flex items-center">
                              <div className="text-left">
                                <p className="flex-wrap w-36 whitespace-normal text-sm font-medium text-gray-900">{booking?.connection?.business_name ?? ''}</p>
                                <p className="truncate text-xs text-gray-500">{booking.internal_note}</p>
                              </div>
                            </span>


                            <StatusBarIcon status={booking.status} />
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </LoaderWrapper>
                
              </InfiniteScroll>
              
              

              </nav>


          </aside>
        </div>
      </div>

      <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.BOOKING, permissionType.CREATE)]}>
        {
          isOpenCreate &&
            <AddBooking
              isOpen={isOpenCreate}
              setIsOpen={setOpenCreate}
              onCreate={(r) => {
                setOffset(0);
                navigate(getWebPathParam([LOGISTICS, BOOKINGS, r.id]));

                if (refreshData === null){
                  setRefreshData(true);
                }else{
                  setRefreshData(!refreshData);
                }
              }}
            />
        }
      </BasePermissionWrapper>

      
      
    </BasePermissionWrapper>
  );
};


export default Booking;

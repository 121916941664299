import React, { useEffect, useState } from "react";
import InputText from "../../components/common/form/input-text";
import InputSubmit from "../../components/common/form/input-submit";
import { Controller, useForm } from "react-hook-form";
import FormPopup from "../../components/common/popup/form-popup";
import {useDispatch, useSelector} from "react-redux";
import { Warning } from "../../components/common/alert/banner";
import {FORWARDER, REGISTRY} from "../endpoints";
import { useNavigate } from "react-router-dom";
import { postForwarder } from "../../api/forwarder";
import { getWebPathParam } from "../../utils/converter";
import {sp} from "../../constants/permissions";
import {useTranslation} from "react-i18next";
import SubscriptionWrapper from "../../components/partials/restricted/subscription-wrapper";
import {subscriptionUsageLimits} from "../../reducers/subscriptionReducer";
import { fetchCountries } from "../../reducers/configReducer";
import InputSelect from "../../components/common/form/input-select";

export default function AddForwarder({isOpen, setIsOpen, isNavigateActive = true, onAdded}) {
  const { loggedCompany } = useSelector((state) => state.user);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isCreateForwarderLoading, setCreateForwarderLoading] = useState(false);
  const [createForwarderException, setCreateForwarderException] =
    useState(false);
  let navigate = useNavigate();


  useEffect(() => {
    dispatch(fetchCountries());
  }, [dispatch]);


  const { isCountriesLoading, countries } = useSelector((state) => state.config);

  const storeForwarder = async (data) => {
    if (loggedCompany) {
      setCreateForwarderLoading(true);
      postForwarder({ company_id: loggedCompany.id, data })
        .then((response) => {

          dispatch(subscriptionUsageLimits(loggedCompany.id));

          setCreateForwarderLoading(false);
          if (!isNavigateActive) {
            onAdded(response);
            setIsOpen(false);
          } else {
            navigate(getWebPathParam([REGISTRY + FORWARDER, response.id]));
          }
        })
        .catch((e) => {
          setCreateForwarderException(e.message);
          setCreateForwarderLoading(false);
        });
    }
  };

  const onSubmit = async (data) => {
    await storeForwarder(data);
  };

  return (
      <FormPopup
          title={t("app.forwarder.new_forwarder")}
          isOpen={isOpen}
          setIsOpen={(r) => {
            setIsOpen(r);
          }}
      >
        <SubscriptionWrapper requiredPermission={sp.FORWARDER_INTERNAL_PACKAGE}>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-4">
              <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-3 gap-x-4 sm:grid-cols-1">
                <InputText
                    id={"name"}
                    type={"text"}
                    errors={errors.name}
                    input={{ ...register("name", { required: true }) }}
                    label={t("app.forwarder.business_name")}
                />

                <InputText
                    id={"code"}
                    type={"text"}
                    errors={errors.code}
                    input={{ ...register("code", { required: false }) }}
                    label={t("app.forwarder.reference")}
                />

              <div className="col-span-4 md:col-span-2">
                <Controller
                  name={"country"}
                  rules={{ required: true }}
                  control={control}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <InputSelect
                      label={t("app.form.country")}
                      name={name}
                      options={countries}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      isLoading={isCountriesLoading}
                      isDisabled={isCountriesLoading}
                      errors={error}
                    />
                  )}
                />
              </div>
              </div>
            </div>

            {/* Warning */}
            {createForwarderException && (
                <Warning message={createForwarderException} />
            )}

            <div className="flex before:flex-1 items-center justify-between mt-6">
              <InputSubmit
                  isLoading={isCreateForwarderLoading}
                  label={t("app.forwarder.add_forwarder")}
              />
            </div>
          </form>
        </SubscriptionWrapper>
      </FormPopup>
  );
}

import {
    COMPANY,
    QUOTATIONS,
    QUOTATION,
    PRODUCTS,
    PRODUCT,
    CATALOG_PRODUCTS,
    CONTAINERS,
    CONTAINER, PRODUCT_POSITIONS, STATUS, CLONE
} from "./endpoints";
import API from "../utils/apiUtils";
import { getPathParam, serialize } from "../utils/converter";

// detail
export const getQuotations = ({ company_id, params, signal }) =>
    API.get(
        getPathParam([COMPANY, company_id, QUOTATIONS]) +
        (params ? serialize(params) : ""),
        { signal: signal }
    );

export const getQuotationsDynamic = ({ company_id, params, data, signal }) =>
    API.post(
        getPathParam([COMPANY, company_id, QUOTATIONS]) +
        (params ? serialize(params) : ""),
        data, { signal: signal }
    );

export const getQuotation = ({ company_id, quotation_id, signal }) =>
  API.get(getPathParam([COMPANY, company_id, QUOTATION, quotation_id]), {
    signal: signal,
  });

export const postQuotation = ({ company_id, data }) =>
  API.post(getPathParam([COMPANY, company_id, QUOTATION]), data);

export const putQuotation = ({ company_id, quotation_id, data }) =>
  API.put(getPathParam([COMPANY, company_id, QUOTATION, quotation_id]), data);

export const cloneQuotation = ({ company_id, quotation_id, data }) =>
    API.put(getPathParam([COMPANY, company_id, QUOTATION, quotation_id, CLONE]), data);


export const putQuotationStatus = ({ company_id, quotation_id, status, data = {} }) =>
    API.put(getPathParam([COMPANY, company_id, QUOTATION, quotation_id, STATUS, status]), data);


// containers
export const getQuotationContainers = ({ quotation_id, params, signal }) =>
    API.get(
        getPathParam([QUOTATION, quotation_id, CONTAINERS]) +
        (params ? serialize(params) : ""),
        { signal: signal }
    );

export const getQuotationContainer = ({ quotation_id, container_id, signal }) =>
    API.get(getPathParam([QUOTATION, quotation_id, CONTAINER, container_id]), {
        signal: signal,
    });

export const postQuotationContainer = ({ quotation_id, data }) =>
    API.post(getPathParam([QUOTATION, quotation_id, CONTAINER]), data);

export const putQuotationContainer = ({ quotation_id, container_id, data }) =>
    API.put(getPathParam([QUOTATION, quotation_id, CONTAINER, container_id]), data);


// products
export const getQuotationProducts = ({ quotation_id, container_id, params, signal }) =>
    API.get(
        getPathParam([QUOTATION, quotation_id, CONTAINER, container_id, PRODUCTS]) +
        (params ? serialize(params) : ""),
        { signal: signal }
    );

export const getQuotationProduct = ({ quotation_id, container_id, product_id, signal }) =>
    API.get(getPathParam([QUOTATION, quotation_id, CONTAINER, container_id, PRODUCT, product_id]), {
        signal: signal,
    });

export const postQuotationProduct = ({ quotation_id, container_id, data }) =>
    API.post(getPathParam([QUOTATION, quotation_id, CONTAINER, container_id, PRODUCT]), data);

export const postQuotationProducts = ({ quotation_id, container_id, data }) =>
    API.post(getPathParam([QUOTATION, quotation_id, CONTAINER, container_id, PRODUCTS]), data);


export const putQuotationProduct = ({ quotation_id, container_id, product_id, data }) =>
    API.put(getPathParam([QUOTATION, quotation_id, CONTAINER, container_id, PRODUCT, product_id]), data);

export const putQuotationProductPositions = ({ quotation_id, container_id, data }) =>
    API.put(getPathParam([QUOTATION, quotation_id, CONTAINER, container_id, PRODUCT_POSITIONS]), data);

// Catalog
export const getQuotationCatalogProducts = ({ quotation_id, params, signal }) =>
    API.get(
        getPathParam([QUOTATION, quotation_id, CATALOG_PRODUCTS]) +
        (params ? serialize(params) : ""),
        { signal: signal }
    );


export const getQuotationCatalogProductsDynamic = ({ quotation_id, params, data,  signal }) =>
    API.post(
        getPathParam([QUOTATION, quotation_id, CATALOG_PRODUCTS]) +
        (params ? serialize(params) : ""),
        data,
        { signal: signal }
    );

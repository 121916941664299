import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {FilterButton, WhiteButton} from "../../../components/layout/page-header";
import {getPathParam} from "../../../utils/converter";
import {CONTAINER, PRODUCT, QUOTATION} from "../../../api/endpoints";
import {HIDE_PRODUCTS, PRODUCTS, QUOTATION_CONFIRMS, SHOW_PRODUCTS, UPDATE_PRODUCTS} from "../../endpoints";
import {ExclamationCircleIcon, EyeIcon, EyeOffIcon, RefreshIcon, TrashIcon} from "@heroicons/react/outline";
import CatalogIcon from "../../../resources/icons/CatalogIcon";
import ListSection from "../../../components/layout/list-section";
import {getQuotationProducts, putQuotationProductPositions} from "../../../api/quotation";
import ProductCell from "../../../components/common/table/product-cell";
import CardDetail from "../../../components/common/list/card-detail";
import NumberFormat from "react-number-format";
import TableDetail from "../../../components/common/list/table-detail";
import ActionsMenu from "../../../components/common/table/actions-menu";
import AddCatalog from "../product";
import RemovePopup from "../../../components/common/popup/remove-popup";
import {permissionGroup, permissionSpecific, permissionType, sp} from "../../../constants/permissions";
import SubscriptionWrapper from "../../../components/partials/restricted/subscription-wrapper";
import AddProduct from "../product/add-product";
import ManageProduct from "../product/manage-product";
import ProductServiceCell from "../../../components/common/table/product-service-cell";
import toast from "react-hot-toast";
import PriceRange from "../../../components/common/card/price-range";
import ImportProduct from "../product/import-product";
import {getCustomFormat} from "../../../utils/timeUtils";
import ConfirmPopup from "../../../components/common/popup/confirm-popup";
import {useSelector} from "react-redux";
import {hasPermission, hasPermissionFor} from "../../../components/partials/restricted/base-permission-wrapper";
import BoxIcon from "../../../resources/icons/BoxIcon";
import ManageQuantity from "./manage-quantity";
import { DRAFT } from "../../../constants/config";
import LoadContainerDetail from "./load-container-detail";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const PRODUCT_SERVICE = 'service';

const QuotationProducts = ({quotationDetail, containerDetail, setNeedRefresh, setContainerId, importType, setLastImport, refresh, isHideSensitiveData, isOpenContainerDetail = false, onOpenContainerDetail, onConfirmedPackaging}) => {

    const { t } = useTranslation();
    let { id } = useParams();

    const [query, setQuery] = useState("");
    const [sortBy, setSortBy] = useState({accessor: null, type: null, id: null, position: 0});

    const [limit] = useState(10000);
    const [offset] = useState(0);
    const [isFetchProductsLoading, setFetchProductsLoading] = useState(true);

    const [selectAll, setSelectAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);

    const [productList, setProductList] = useState( { products: [], total_rows: 0 });

    //REMOVE
    const [isOpenRemove, setOpenRemove] = useState(false);
    const [removeDetail, setRemoveDetail] = useState({});

    //UPDATE FAST
    const [isOpenConfirm, setOpenConfirm] = useState(false);
    const [confirmDetail, setConfirmDetail] = useState({});


    //EDIT
    const [productToEdit, setProductToEdit] = useState(null);
    const [refreshList, setRefreshList] = useState(false);

    //ADD
    const [isOpenAdd, setIsOpenAdd] = useState(false);
    const [isOpenAddCatalog, setIsOpenAddCatalog] = useState(false);
    const [isOpenAddProduct, setIsOpenAddProduct] = useState(false);


    //UPDATE QUANTITY
    const [isOpenQuantity, setOpenQuantity] = useState(false);
    const [productDetails, setProductDetails] = useState({});


    const [isOpenUpload, setOpenUpload] = useState(false);

    const isExporter = quotationDetail?.can_edit ?? false;

    const {  permissions } = useSelector((s) => s.userCompany);

    const { companyType } = useSelector((state) => state.user);
    const canEdit =  hasPermission(permissions, permissionGroup.QUOTATION, permissionType.EDIT, companyType, true);
    const isActiveHideProduct = hasPermissionFor(permissions, permissionGroup.QUOTATION, permissionSpecific.QUOTATION_HIDE_PRODUCT, companyType, true);
    const isActiveProductQuantity = hasPermissionFor(permissions, permissionGroup.QUOTATION, permissionSpecific.QUOTATION_PRODUCT_CONFIRMATION, companyType, true);

    

    const updatePositions = (data) =>{
        let detail = [];
        if(data.length > 0){
            // eslint-disable-next-line
            data.map(item => {
                detail.push({id: item.id })
            })
        }

        putQuotationProductPositions({ quotation_id: id, container_id: containerDetail.id, data: detail })
            .then((response) => {
                setProductList(response);
                toast.success(t("app.quotations.product_update"))
            }).catch((e) => {});
    };


    const validateQuantities = (products = null) => {


        let flag = true;
        // eslint-disable-next-line
        products.map(product => {

            let closedBoxes = product?.product?.qty_per_packaging ?? 0;
            if(closedBoxes && closedBoxes > 0){

                let desiredQuantity = product.quantity;
                if(closedBoxes !== desiredQuantity){
                    if(!isNaN(desiredQuantity)){
                        if (desiredQuantity === 0) {
                            flag = false;
                        }else{
                            if(closedBoxes > desiredQuantity){
                                flag = false;
                            }else{
                                let suggestedQuantityPerBox = Math.ceil(desiredQuantity / closedBoxes);
                                if((closedBoxes * suggestedQuantityPerBox) !== desiredQuantity){
                                    flag = false;
                                }
                            }
                        }
                    }
                }
            }
        });
        onConfirmedPackaging(flag);
    }


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        setFetchProductsLoading(true);
        fetchQuotationProducts(signal);
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [offset, query, limit, containerDetail, id, refresh, refreshList]);


    const fetchQuotationProducts = async (signal = null) => {
        try {
            const res = await getQuotationProducts({
                quotation_id: id,
                container_id: containerDetail.id,
                params: { limit: limit, offset: query.length > 0 ? 0 : offset, query: query },
                signal,
            });
            setProductList(res);
            setRefreshList(false);
            validateQuantities(res.products);
        } catch (e) {
            setProductList({ products: [], total_rows: 0 });
            if (signal && signal.aborted) {
                setFetchProductsLoading(true);
            }
        } finally {
            if (signal && signal.aborted) {
                setFetchProductsLoading(true);
            } else {
                setFetchProductsLoading(false);
            }
        }
    }



    const handleSelectAll = () => {
        if(productList.products.length > 0){
            setSelectAll(!selectAll);
            setIsCheck(productList.products.map(li => li.id));
            if (selectAll) {
                setIsCheck([]);
            }
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        setSelectAll(false);
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
    };


    const columns = [
        {
            header:  'ID',
            id: 'position',
            width: '5%',
            accessor: r => r.position,
        },
        {
            header:  t("app.quotations.product"),
            width: '40%',
            id: 'product_code',
            accessor: r => r.product.name,
        },
        {
            header: t("app.quotations.delivery_date"),
            width: '10%',
            id: 'delivery_date',
            accessor: r => r.delivery_date ? new Date(r.delivery_date).getTime() : 0
        },
        {
            header: t("app.quotations.quantity"),
            width: '10%',
            position: 'center',
            id: 'quantity',
            accessor: r => r.quantity
        },
        {
            header: t("app.quotations.base_price"),
            width: '10%',
            position: 'center',
            id: 'base_price',
            accessor: r => r.base_price
        },
        {
            header: t("app.quotations.price"),
            width: '15%',
            position: 'end',
            id: 'price',
            accessor:  r =>  (r.quantity * r.base_price)
        },
        isExporter && {
            width: '5%',
            header: "",
            accessor: null
        }
    ].filter(Boolean);

    return (
        <>
            <ListSection
                title={t("app.quotations.products")}
                columns={columns}
                checkoxWidth={'5%'}
                totalRows={productList.total_rows}
                list={productList.products}
                onSearch={setQuery}
                isLoading={isFetchProductsLoading}
                mobileRow={MobRow}
                desktopRow={DeskRow}
                sortBy={sortBy}
                isTableSort={true}
                hasCheckbox={canEdit && !quotationDetail.is_closed && isExporter }
                selectAll={selectAll}
                handleSelectAll={handleSelectAll}
                isSortableRow={canEdit && !quotationDetail.is_closed && isExporter}
                onUpdateSortableRow={ (r) => {
                    setProductList({products: r, total_rows: productList.total_rows});
                    validateQuantities(r);
                    updatePositions(r);
                }}
                onSortBy={(n) => {
                    setSortBy(n);
                }}
                extras={{
                    isExporter,
                    isHideSensitiveData,
                    isActiveProductQuantity,
                    isCheck,
                    handleClick,
                    quotationDetail,
                    containerDetail,
                    canEdit,
                    onRemove: (r, detail) => {
                        setOpenRemove(r);
                        setRemoveDetail(detail);
                    },
                    onShowHide: (r, detail) => {
                        setOpenConfirm(r);
                        setConfirmDetail(detail);
                    },
                    onOpenQuantity: (r, detail) => {
                        setOpenQuantity(r);
                        setProductDetails(detail);
                    },
                    setProductToEdit,
                    isActiveHideProduct
                }}
            >
                <SubscriptionWrapper requiredPermission={sp.QUOTATION_QUOTATION_PACKAGE} renderBaseOnFail={false}>
                    {
                        (!quotationDetail.is_closed && isExporter && canEdit) && (
                            <>
                                {
                                    isCheck.length > 0 && canEdit && (
                                        <div className="inline-flex items-center justify-center">
                                            <p className={'text-sm font-medium text-gray-900 mr-2'}>{isCheck.length} {t("app.common.item_selected")}</p>
                                            
                                            
                                            
                                            
                                            <WhiteButton
                                                extraClassName={'  rounded-r-none'}
                                                onClick={() => {
                                                    const removeDetail = {
                                                        type: 'product',
                                                        title: t("app.quotations.remove_products_title"),
                                                        message: t("app.quotations.remove_products_description"),
                                                        endpoint: getPathParam([QUOTATION, id, CONTAINER, containerDetail.id, PRODUCTS]) + '?quotation_products='+isCheck,

                                                    };
                                                    setOpenRemove(true);
                                                    setRemoveDetail(removeDetail);
                                                }}
                                                icon={
                                                    <TrashIcon
                                                        className="w-4 h-4 text-red-700"
                                                        aria-hidden="true"
                                                    />
                                                }
                                            />


                                            {
                                                isActiveHideProduct && (
                                                    <>
                                                        <div className="">
                                                            <WhiteButton
                                                                extraClassName={' rounded-none'}
                                                                onClick={() => {
                                                                    const details = {
                                                                        type: 'product',
                                                                        title: t("app.quotations.hide_products_title"),
                                                                        message: t("app.quotations.hide_products_description"),
                                                                        
                                                                        endpoint: getPathParam([QUOTATION, id, CONTAINER, containerDetail.id, HIDE_PRODUCTS]) + '?quotation_products='+isCheck,
                                                                    };
                                                                    setOpenConfirm(true);
                                                                    setConfirmDetail(details);

                                                                }}
                                                                icon={
                                                                    <EyeOffIcon
                                                                        className=" w-4 h-4"
                                                                        aria-hidden="true"
                                                                    />
                                                                }
                                                            />
                                                        </div>

                                                        <div className="">
                                                            <WhiteButton
                                                                extraClassName={'  rounded-none '}
                                                                onClick={() => {
                                                                    const details = {
                                                                        type: 'product',
                                                                        title: t("app.quotations.show_products_title"),
                                                                        message: t("app.quotations.show_products_description"),
                                                                        endpoint: getPathParam([QUOTATION, id, CONTAINER, containerDetail.id, SHOW_PRODUCTS]) + '?quotation_products='+isCheck,
                                                                    };
                                                                    setOpenConfirm(true);
                                                                    setConfirmDetail(details);

                                                                }}
                                                                icon={
                                                                    <EyeIcon
                                                                        className=" w-4 h-4"
                                                                        aria-hidden="true"
                                                                    />
                                                                }
                                                            />
                                                        </div>
                                                    </>
                                                )
                                            }

                                            {
                                                isActiveHideProduct && (
                                                    <>
                                                        <div className="">
                                                            <WhiteButton
                                                                extraClassName={'  rounded-none '}
                                                                onClick={() => {
                                                                    const details = {
                                                                        type: 'product',
                                                                        title: t("app.quotations.confirm_load_products_title"),
                                                                        message: t("app.quotations.confirm_load_description"),
                                                                        endpointData: { quotation_products: isCheck.join(",") },
                                                                        endpoint: getPathParam([QUOTATION, id, CONTAINER, containerDetail.id, QUOTATION_CONFIRMS]),
                                                                    };
                                                                    setOpenConfirm(true);
                                                                    setConfirmDetail(details);

                                                                }}
                                                                icon={
                                                                    <BoxIcon
                                                                        className=" w-4 h-4"
                                                                        aria-hidden="true"
                                                                    />
                                                                }
                                                            />
                                                        </div>
                                                    </>
                                                )
                                            }

                                            <div className="">
                                                <WhiteButton
                                                    extraClassName={'  rounded-l-none '}
                                                    onClick={() => {
                                                        const details = {
                                                            type: 'product',
                                                            title: t("app.quotations.update_products_title"),
                                                            message: t("app.quotations.update_load_description"),
                                                            endpointData: { quotation_products: isCheck.join(",") },
                                                            endpoint: getPathParam([QUOTATION, id, CONTAINER, containerDetail.id, UPDATE_PRODUCTS]),
                                                        };
                                                        setOpenConfirm(true);
                                                        setConfirmDetail(details);

                                                    }}
                                                    icon={
                                                        <RefreshIcon
                                                            className=" w-4 h-4"
                                                            aria-hidden="true"
                                                        />
                                                    }
                                                />
                                            </div>



                                        </div>
                                    )
                                }

                                {
                                    canEdit && (
                                        <FilterButton
                                            onClick={() => {
                                                setIsOpenAdd(true);
                                            }}
                                            icon={
                                                <CatalogIcon
                                                    className="w-4 h-4"
                                                    aria-hidden="true"
                                                />
                                            }
                                            text={t("app.quotations.add_inside")}
                                        />
                                    )
                                }

                            </>


                        )
                    }


                </SubscriptionWrapper>


            </ListSection>


            {
                (isOpenAdd && canEdit) && (
                    <AddProduct
                        isOpen={isOpenAdd}
                        onOpenCatalog={(r) =>{
                            setIsOpenAddCatalog(r);
                        }}
                        onOpenAddProduct={(r) =>{
                            setIsOpenAddProduct(r);
                        }}
                        onOpenUpload={(r) => {
                            setOpenUpload(r);
                        }}
                        setIsOpen={(r) => {
                            setIsOpenAdd(r);
                        }}
                    />
                )
            }

            <ManageProduct
                isOpen={(isOpenAddProduct || productToEdit !== null)}
                setIsOpen={(r) => {
                    setIsOpenAddProduct(false);
                    setProductToEdit(null);
                }}
                isClosed={quotationDetail.is_closed}
                quotationDetail={quotationDetail}
                containerDetail={containerDetail}
                isEditing={productToEdit !== null}
                productInfo={productToEdit !== null ? productToEdit : null}
                onUpdateDetail={(r) => {
                    setProductList(r);
                    validateQuantities(r.products);
                    setNeedRefresh(true);
                }}
                onRefreshProduct={() => {
                    setRefreshList(true);
                }}
                isHideSensitiveData={isHideSensitiveData}
            />

            <AddCatalog
                isOpen={isOpenAddCatalog}
                customer={quotationDetail?.customer ?? false}
                quotationDetail={quotationDetail}
                containerDetail={containerDetail}
                setIsOpen={(r) => {
                    setIsOpenAddCatalog(r);
                }}
                setDetail={(r) => {
                    setProductList(r);
                    validateQuantities(r.products);
                    setNeedRefresh(true);
                }}
                isHideSensitiveData={isHideSensitiveData}
            />


            {isOpenRemove && (
                <RemovePopup
                    isOpen={isOpenRemove}
                    setIsOpen={setOpenRemove}
                    detail={removeDetail}
                    setDetail={(r) => {
                        setProductList(r);
                        validateQuantities(r.products);
                        setIsCheck([]);
                        setSelectAll(false);
                        setNeedRefresh(true);
                    }}
                />
            )}

            {isOpenConfirm && (
                <ConfirmPopup
                    isOpen={isOpenConfirm}
                    setIsOpen={() =>{
                        setOpenConfirm(false);
                    }}
                    detail={confirmDetail}
                    setDetail={(r) => {
                        setProductList(r);
                        validateQuantities(r.products);
                        setNeedRefresh(true);
                    }}
                />
            )}

            <ImportProduct
                isOpen={isOpenUpload}
                setIsOpen={setOpenUpload}
                containerId={containerDetail.id}
                importGroup={importType}
                onImportComplete={(r) => {
                    setLastImport(r);
                    setContainerId(containerDetail.id);
                }}
            />


            <LoadContainerDetail
                isOpen={isOpenContainerDetail}
                isSeaType={false}
                isLoading={isFetchProductsLoading}
                containerDetail={containerDetail}
                containerLoadDetails={{
                    percentage: productList.volume > 0 && parseInt(containerDetail.container_type.volume) > 0 ? (productList.volume / parseInt(containerDetail.container_type.volume)) * 100 : 0,
                    total_volume: parseFloat(productList?.volume ?? 0),
                    total_gross_weight: parseFloat(productList?.gross_weight ?? 0),
                    total_net_weight: parseFloat(productList?.net_weight ?? 0),
                    percentage_confirmed: productList.confirmed_volume > 0 && parseInt(containerDetail.container_type.volume) > 0 ? (productList.confirmed_volume / parseInt(containerDetail.container_type.volume)) * 100 : 0,
                    total_volume_confirmed: parseFloat(productList?.confirmed_volume ?? 0),
                    total_gross_weight_confirmed: parseFloat(productList?.confirmed_gross_weight ?? 0),
                    total_net_weight_confirmed: parseFloat(productList?.confirmed_net_weight ?? 0)
                }}
                setIsOpen={(r) => {
                    onOpenContainerDetail(r);
                }}
            />

            

            {isOpenQuantity && (
                <ManageQuantity
                    isOpen={isOpenQuantity}
                    setIsOpen={() => {
                        setOpenQuantity(false);
                    }}
                    productData={productDetails}
                    quotationDetail={quotationDetail}
                    quotationId={id}
                    containerId={containerDetail.id}
                    onUpdateData={(r) => {
                        fetchQuotationProducts()
                        setNeedRefresh(true);

                     }}
                />
            )}


        </>
    )
};


const MobRow = ({ element, extras, index }) => {
    const { t } = useTranslation();
    let {id}  = useParams();
    const isCheck = extras.isCheck.includes(element.id);
    const { company } = useSelector((state) => state.userCompany);
    
    let percentage = ((element.confirmed_quantity > element.quantity) ? 100 : (100 / element.quantity * (element?.confirmed_quantity ?? 0)));


    const validateQuantity = (quantity = null) => {

        let closedBoxes = element?.product?.qty_per_packaging ?? 0;
        if (closedBoxes && closedBoxes > 0) {

            let desiredQuantity = quantity;
            if (closedBoxes === desiredQuantity) {
                return false
            }

            if (isNaN(desiredQuantity)) {
                return false
            } else {
                if (desiredQuantity === 0) {
                    return true
                } else {
                    if (closedBoxes > desiredQuantity) {
                        return true
                    } else {
                        let suggestedQuantityPerBox = Math.ceil(desiredQuantity / closedBoxes);
                        if ((closedBoxes * suggestedQuantityPerBox) === desiredQuantity) {
                            return false
                        } else {
                            return true
                        }
                    }
                }
            }
        }

        return false

    }



    const validateQuantity2 = (quantity = null) => {

        let closedBoxes = element?.product?.qty_per_packaging ?? 0;
        if (closedBoxes > 0) {

            let desiredQuantity = quantity;

            if (closedBoxes === desiredQuantity) {
                return ''
            }

            if (isNaN(desiredQuantity)) {
                return ''
            } else {
                if (desiredQuantity === 0) {
                    return t("app.container.product_predict") + (closedBoxes);
                } else {
                    if (closedBoxes > desiredQuantity) {
                        return t("app.container.product_predict") + (closedBoxes);

                    } else {
                        let suggestedQuantityPerBox = Math.ceil(desiredQuantity / closedBoxes);
                        if ((closedBoxes * suggestedQuantityPerBox) === desiredQuantity) {
                            return ''
                        } else {
                            return t("app.container.product_predict") + (closedBoxes * suggestedQuantityPerBox);
                        }
                    }
                }
            }



        } else {
            return ''
        }
    }


    let discount = false;
    if(element.discount && element.discount.length > 0){
        discount = element.discount.join('% + ') + '%';
    }


     const removeDetail = {
        type: 'product',
        title: t("app.quotations.remove_product_title"),
        message: t("app.quotations.remove_product_description"),
        endpoint: getPathParam([QUOTATION, id, CONTAINER, extras.containerDetail.id, PRODUCT, element.id]),
    };

    const showHideDetail = {
        type: 'show/hide',
        title: !element.editable ?  t("app.quotations.show_product_title")  : t("app.quotations.hide_product_title"),
        message: !element.editable ?  t("app.quotations.show_product_description") : t("app.quotations.hide_product_description"),
        endpoint: getPathParam([QUOTATION, id, CONTAINER, extras.containerDetail.id, PRODUCT, element.id]),
        endpointData: {
            id: element.id,
            editable: !element.editable,
        }
    };

    const hasError = (element.product.volume === '' || element.product.volume === undefined) ||
        (element.product.gross_weight === '' || element.product.gross_weight === undefined) ||
        (element.product.net_weight === '' || element.product.net_weight === undefined) ||
        (element.product.qty_per_packaging === '' || element.product.qty_per_packaging === undefined);



    return (
        <li key={element.id} className={
            !element.editable ? 'flex items-center gap-4 p-4 bg-red-100' :  (isCheck ? 'flex items-center gap-4 p-4 bg-solitude-100' : 'flex items-center gap-4 p-4')
        }>
            <div className="flex-grow">

                <div className="pb-4 items-center flex justify-between">

                    <div className={"flex justify-between items-center"}>

                        {
                            !extras.quotationDetail.is_closed && extras.canEdit && extras.isExporter && (
                                <SubscriptionWrapper requiredPermission={sp.QUOTATION_QUOTATION_PACKAGE} renderBaseOnFail={false}>

                                    <input type="checkbox"
                                           id={element.id}
                                           onChange={extras.handleClick} checked={isCheck}
                                           className="h-4 w-4 mr-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />

                                </SubscriptionWrapper>
                            )
                        }



                        {
                            element.type === PRODUCT_SERVICE ? (
                                <ProductServiceCell product={element.product}/>
                            ) : (
                                    <ProductCell product={element.product} isShowStatus={extras.isExporter && company.product_manage_status}  isActiveManufacture={false} hasError={hasError} errorMessage={t("app.quotations.quotation_missing_data")} />
                            )
                        }
                    </div>


                    {
                        !extras.quotationDetail.is_closed && extras.canEdit && extras.isExporter && (
                            <SubscriptionWrapper requiredPermission={sp.QUOTATION_QUOTATION_PACKAGE} renderBaseOnFail={false}>
                                <ActionsMenu>
                                    <button className={'text-left'}
                                            onClick={(e) => {
                                                extras.setProductToEdit(element);
                                            }}
                                    >
                                        {t("app.common.edit")}
                                    </button>


                                    {
                                        extras.isActiveHideProduct && (
                                            <button
                                                className={'text-left'}
                                                onClick={() => {
                                                    extras.onShowHide(true, showHideDetail);
                                                }}
                                            >
                                                {element.editable ?  t("app.common.hide") : t("app.common.restore")}
                                            </button>
                                        )
                                    }




                                    <button className={'text-left'}
                                            onClick={() => {
                                                extras.onRemove(true, removeDetail);
                                            }}
                                    >
                                        {t("app.common.remove")}
                                    </button>
                                </ActionsMenu>
                            </SubscriptionWrapper>
                        )
                    }



                </div>

                <div className="flex flex-col">
                    <CardDetail title={"ID"}>
                        {(index + 1)}
                    </CardDetail>

                    <CardDetail title={t("app.quotations.delivery_date")}>
                        {getCustomFormat(element.delivery_date, 'DD/MM/YYYY')}
                    </CardDetail>



                    <CardDetail title={t("app.quotations.quantity")}>
                        {
                            element.type === PRODUCT_SERVICE ? (
                                <NumberFormat
                                    value={element.quantity}
                                    displayType={"text"}
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    suffix={''}
                                />
                            ) : (
                                    element.quantity ? (
                                        <div className="cursor-pointer" onClick={() => {
                                            if (extras.quotationDetail.status !== DRAFT && extras.isExporter && extras.isActiveProductQuantity) {
                                                extras.onOpenQuantity(true, element)
                                            }
                                        }}>
                                            <div className="flex  justify-end items-center relative">

                                                <div className={element.confirmed_quantity > element.quantity && "font-bold text-red-800"}>
                                                    <div className="flex items-end ">

                                                        {element.confirmed_quantity > element.quantity && (
                                                            <ExclamationCircleIcon className="h-5 w-5 text-red-800 mr-1" />
                                                        )}
                                                        <div>
                                                            {
                                                                extras.quotationDetail.status !== DRAFT && (
                                                                    <>
                                                                        <NumberFormat
                                                                            value={element.confirmed_quantity ? element.confirmed_quantity : 0}
                                                                            displayType={"text"}
                                                                            thousandSeparator={"."}
                                                                            decimalSeparator={","}
                                                                            suffix={''}
                                                                        />/
                                                                    </>
                                                                )
                                                            }

                                                            <NumberFormat
                                                                value={element.quantity}
                                                                displayType={"text"}
                                                                thousandSeparator={"."}
                                                                decimalSeparator={","}
                                                                suffix={' ' + (element.unit_value ? element.unit_value.symbol : ((element.product && element.product.unit_value) ? element.product.unit_value.symbol : ''))}
                                                            />
                                                        </div>



                                                    </div>

                                                </div>


                                            </div>
                                            {
                                                extras.quotationDetail.status !== DRAFT && element.type !== PRODUCT_SERVICE && (
                                                    <div className="w-full bg-gray-200 rounded mt-1">
                                                        <div className={classNames(
                                                            percentage >= 50 ? 'shim-green' : '',
                                                            percentage < 50 ? 'shim-red' : '',
                                                            "text-xs font-medium rounded text-white text-center p-0.5 leading-none"
                                                        )} style={{ width: percentage + '%' }}>
                                                        </div>
                                                    </div>
                                                )
                                            }


                                        </div>
                                    ) : <div className="flex  justify-center items-center relative">-</div>
                            )
                        }

                        {
                            (validateQuantity(element.quantity) && extras.isExporter) && (
                                <div className="text-red-500 capitalize flex  justify-center items-center" style={{ fontSize: '0.60rem' }}>
                                    {t("app.container.packaging_error")}
                                </div>
                            )
                        }


                        {
                            (!(validateQuantity(element.quantity) && extras.isExporter) && (validateQuantity(element.confirmed_quantity) && extras.isExporter)) && (
                                <div className="text-red-500 capitalize flex  justify-center items-center" style={{ fontSize: '0.60rem' }}>
                                    {validateQuantity2(element.confirmed_quantity)}
                                </div>
                            )
                        }

                    </CardDetail>

                    { element.type !== PRODUCT_SERVICE && (
                        <CardDetail title={t("app.products.unit_value")}>
                            <>{(element.unit_value ? element.unit_value.name : ((element.product && element.product.unit_value) ?  element.product.unit_value.name: '')) }</>
                        </CardDetail>
                    )}


                    <CardDetail title={t("app.quotations.base_price")}>
                        <div className="text-sm text-gray-900 text-right">
                            {
                                (element.discount_price !== element.base_price) ? (
                                    <>
                                        <s className="line-through">
                                            <NumberFormat
                                                value={element.base_price}
                                                displayType={"text"}
                                                thousandSeparator={"."}
                                                decimalScale={4}
                                                decimalSeparator={","}
                                                suffix={" "+ (extras?.quotationDetail?.currency?.currency_symbol ?? '')}
                                            />
                                        </s>{' '}
                                        <NumberFormat
                                            value={element.discount_price}
                                            displayType={"text"}
                                            thousandSeparator={"."}
                                            decimalScale={4}
                                            decimalSeparator={","}
                                            suffix={" "+ (extras?.quotationDetail?.currency?.currency_symbol ?? '')}
                                        />
                                    </>
                                ) : (
                                    <NumberFormat
                                        value={element.base_price}
                                        displayType={"text"}
                                        thousandSeparator={"."}
                                        decimalScale={4}
                                        decimalSeparator={","}
                                        suffix={" "+ (extras?.quotationDetail?.currency?.currency_symbol ?? '')}
                                    />
                                )
                            }
                        </div>
                        <div className="text-xs text-gray-500">

                            {
                                discount !== false && (
                                    <>{t("app.quotations.discount")} {discount}</>
                                )
                            }

                        </div>
                    </CardDetail>

                    <CardDetail title={t("app.quotations.price")}>
                        <div className="flex flex-col justify-end  ">

                            <div className="flex justify-end">
                                <div className={"flex justify-end text-right"}>
                                    <div>
                                        <div className="text-sm text-gray-900 capitalize">
                                            <NumberFormat
                                                value={element.quantity * element.discount_price}
                                                displayType={"text"}
                                                thousandSeparator={"."}
                                                decimalScale={2}
                                                decimalSeparator={","}
                                                suffix={" " + (extras?.quotationDetail?.currency?.currency_symbol ?? '')}
                                            />
                                        </div>
                                        <div className="text-xs text-gray-500 capitalize">

                                            {
                                                extras.quotationDetail.status !== DRAFT && (
                                                    <div style={{ fontSize: '0.65rem' }}>
                                                        <NumberFormat
                                                            value={element.confirmed_quantity * element.discount_price}
                                                            displayType={"text"}
                                                            thousandSeparator={"."}
                                                            decimalScale={2}
                                                            decimalSeparator={","}
                                                            suffix={" " + (extras?.quotationDetail?.currency?.currency_symbol ?? '')}
                                                        />
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>

                                </div>

                                {
                                    extras.isHideSensitiveData && (
                                        <PriceRange
                                            symbol={(extras?.quotationDetail?.currency?.currency_symbol ?? '')}
                                            purchasePrice={element.product.purchase_price ? element.product.purchase_price : false} price={element.discount_price} isLeft={true} showMargin={true} />

                                    )
                                }
                            </div>

                            {
                                extras.quotationDetail.status !== DRAFT && element.type !== PRODUCT_SERVICE && (
                                    <div className="w-full bg-gray-200  rounded mt-1">
                                        <div className={classNames(
                                            percentage >= 50 ? 'shim-green' : '',
                                            percentage < 50 ? 'shim-red' : '',
                                            "text-xs font-medium rounded text-white text-center p-0.5 leading-none"
                                        )} style={{ width: percentage + '%' }}>
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                    </CardDetail>

                </div>

            </div>
        </li>
    );
};

const DeskRow = ({ element, extras, dragHandle, index }) => {

    const { t } = useTranslation();
    let {id}  = useParams();
    const isCheck = extras.isCheck.includes(element.id);
    const { company } = useSelector((state) => state.userCompany);

    let percentage = ((element.confirmed_quantity > element.quantity) ? 100 : (100 / element.quantity * (element?.confirmed_quantity ?? 0)));

    const validateQuantity = (quantity = null) => {

        let closedBoxes = element?.product?.qty_per_packaging ?? 0;
        if(closedBoxes && closedBoxes > 0){

            let desiredQuantity = quantity;
            if(closedBoxes === desiredQuantity){
               return false
            }

            if(isNaN(desiredQuantity)){
                return false
            }else{
                if (desiredQuantity === 0) {
                    return true
                }else{
                    if(closedBoxes > desiredQuantity){
                        return true
                    }else{
                        let suggestedQuantityPerBox = Math.ceil(desiredQuantity / closedBoxes);
                        if((closedBoxes * suggestedQuantityPerBox) === desiredQuantity){
                            return false
                        }else{
                            return true
                        }
                    }
                }
            }
        }

        return false

    }


    const validateQuantity2 = (quantity = null) => {

        let closedBoxes = element?.product?.qty_per_packaging ?? 0;
        if (closedBoxes > 0) {

            let desiredQuantity = quantity;

            if (closedBoxes === desiredQuantity) {
                return ''
            }

            if (isNaN(desiredQuantity)) {
                return ''
            } else {
                if (desiredQuantity === 0) {
                    return t("app.container.product_predict") + (closedBoxes);
                } else {
                    if (closedBoxes > desiredQuantity) {
                        return t("app.container.product_predict") + (closedBoxes);

                    } else {
                        let suggestedQuantityPerBox = Math.ceil(desiredQuantity / closedBoxes);
                        if ((closedBoxes * suggestedQuantityPerBox) === desiredQuantity) {
                            return ''
                        } else {
                            return t("app.container.product_predict") + (closedBoxes * suggestedQuantityPerBox);
                        }
                    }
                }
            }



        } else {
            return ''
        }
    }


    let discount = false;
    if(element.discount && element.discount.length > 0){
        discount = element.discount.join('% + ') + '%';
    }

    const hasError = (element.product.volume === '' || element.product.volume === undefined) ||
        (element.product.gross_weight === '' || element.product.gross_weight === undefined) ||
        (element.product.net_weight === '' || element.product.net_weight === undefined) ||
        (element.product.qty_per_packaging === '' || element.product.qty_per_packaging === undefined);

    const removeDetail = {
        type: 'product',
        title: t("app.quotations.remove_product_title"),
        message: t("app.quotations.remove_product_description"),
        endpoint: getPathParam([QUOTATION, id, CONTAINER, extras.containerDetail.id, PRODUCT, element.id]),
    };

    const showHideDetail = {
        type: 'show/hide',
        title: !element.editable ?  t("app.quotations.show_product_title")  : t("app.quotations.hide_product_title"),
        message: !element.editable ?  t("app.quotations.show_product_description") : t("app.quotations.hide_product_description"),
        endpoint: getPathParam([QUOTATION, id, CONTAINER, extras.containerDetail.id, PRODUCT, element.id]),
        endpointData: {
            id: element.id,
            editable: !element.editable,
        }
    };

    const RowContainer = ({children}) => {


        if(!extras.isExporter){
            return (<tr>{children}</tr>);
        }

        if(extras.quotationDetail.is_closed){
            return (<tr>{children}</tr>);
        }
        if(!extras.canEdit){
            return (<tr>{children}</tr>);
        }
        return (<>{children}</>);
    }


    return (
        <RowContainer>

            {
                !extras.quotationDetail.is_closed && extras.canEdit && extras.isExporter && (
                    <TableDetail extraClass={"relative w-12 px-6 sm:w-16 sm:px-8 " + (!element.editable ? 'bg-red-100 ' : '')+ (isCheck ? ' bg-solitude-100' : '')}>
                        {
                            isCheck && (
                                <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                            )
                        }

                        {
                            !element.editable && (
                                <div className="absolute inset-y-0 left-0 w-0.5 bg-red-600" />
                            )
                        }
                        <input type="checkbox"
                               id={element.id}
                               onChange={extras.handleClick} checked={isCheck}
                               className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6" />
                    </TableDetail>
                )
            }

            <TableDetail extraClass={(!element.editable ? ' bg-red-100 ' : '') + (isCheck ? ' bg-solitude-100 ' : '')}>
                <div className={"flex items-center"}>
                    {
                        !extras.quotationDetail.is_closed && extras.canEdit && extras.isExporter && (
                            <div className="cursor-move -ml-4 mr-2" {...dragHandle}>
                                <svg className="w-3 h-3 fill-slate-500" viexbox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 1h12v2H0V1Zm0 4h12v2H0V5Zm0 4h12v2H0V9Z" fill="#CBD5E1" fillRule="evenodd" />
                                </svg>
                            </div>
                        )
                    }
                    {(index + 1)}
                </div>
            </TableDetail>

            <TableDetail extraClass={"whitespace-pre-line relative " + (!element.editable ? 'bg-red-100 ' : '')  + (isCheck ? 'bg-solitude-100' : '')} >
                {
                    element.type === PRODUCT_SERVICE ? (
                            <ProductServiceCell product={element.product}/>
                    ) : (
                        <>
                                <ProductCell product={element.product} isShowStatus={extras.isExporter && company.product_manage_status} hasError={extras.isExporter ? hasError : false} errorMessage={t("app.quotations.quotation_missing_data")}/>
                        </>
                    )
                }
            </TableDetail>


            <TableDetail extraClass={(!element.editable ? ' bg-red-100 ' : '') + (isCheck ? ' bg-solitude-100 ' : '')}>
                {getCustomFormat(element.delivery_date, 'DD/MM/YYYY')}
            </TableDetail>



            <TableDetail extraClass={(!element.editable ? ' bg-red-100 relative ' : '') + (isCheck ? ' bg-solitude-100 relative ' : ' relative')}>


              

                <div className=" text-sm text-gray-900 capitalize ">
                    {
                        element.type === PRODUCT_SERVICE ? (
                            <div className="flex justify-center items-center">
                                <NumberFormat
                                    value={element.quantity}
                                    displayType={"text"}
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    suffix={''}
                                />
                            
                            </div>
                        ) : (

                                element.quantity ? (
                                    <div className="cursor-pointer" onClick={() => {
                                        if (extras.quotationDetail.status !== DRAFT && extras.isExporter && extras.isActiveProductQuantity) {
                                            extras.onOpenQuantity(true, element)
                                        }
                                    }}>
                                        <div className="flex  justify-center items-center relative">

                                            <div className={element.confirmed_quantity > element.quantity && "font-bold text-red-800"}>
                                                <div className="flex items-center ">
                                                    
                                                    {element.confirmed_quantity > element.quantity && (
                                                        <ExclamationCircleIcon className="h-5 w-5 text-red-800 mr-1" />
                                                    )}
                                                    <div>
                                                        {
                                                            extras.quotationDetail.status !== DRAFT && (
                                                                <>
                                                                    <NumberFormat
                                                                        value={element.confirmed_quantity ? element.confirmed_quantity : 0}
                                                                        displayType={"text"}
                                                                        thousandSeparator={"."}
                                                                        decimalSeparator={","}
                                                                        suffix={''}
                                                                    />/
                                                                </>
                                                            )
                                                        }

                                                        <NumberFormat
                                                            value={element.quantity}
                                                            displayType={"text"}
                                                            thousandSeparator={"."}
                                                            decimalSeparator={","}
                                                            suffix={' ' + (element.unit_value ? element.unit_value.symbol : ((element.product && element.product.unit_value) ? element.product.unit_value.symbol : ''))}
                                                        />
                                                    </div>

                                                   

                                                </div>
                                               
                                            </div>


                                        </div>
                                        {
                                            extras.quotationDetail.status !== DRAFT && element.type !== PRODUCT_SERVICE && (
                                                <div className="w-full bg-gray-200 rounded mt-1">
                                                    <div className={classNames(
                                                        percentage >= 50 ? 'shim-green' : '',
                                                        percentage < 50 ? 'shim-red' : '',
                                                        "text-xs font-medium rounded text-white text-center p-0.5 leading-none"
                                                    )} style={{ width: percentage + '%' }}>
                                                    </div>
                                                </div>
                                            )
                                        }


                                    </div>
                                ) : <div className="flex  justify-center items-center relative">-</div>
                                
                            
                        )
                    }
                </div>
                { /*  <div className="text-xs text-gray-500 capitalize">
                    element.type !== PRODUCT_SERVICE && (
                        <>{(element.unit_value ? element.unit_value.name : ((element.product && element.product.unit_value) ?  element.product.unit_value.name: '')) }</>
                    )
                </div>*/}


                {
                    (validateQuantity(element.quantity) && extras.isExporter) && (
                        <div className="text-red-500 capitalize flex  justify-center items-center" style={{ fontSize: '0.60rem' }}>
                            {t("app.container.packaging_error")}
                        </div>
                    )
                }

                {
                    (!(validateQuantity(element.quantity) && extras.isExporter) && (validateQuantity(element.confirmed_quantity) && extras.isExporter)) && (
                        <div className="text-red-500 capitalize flex  justify-center items-center" style={{ fontSize: '0.60rem' }}>
                            {validateQuantity2(element.confirmed_quantity)}
                        </div>
                    )
                }
                
            </TableDetail>

            <TableDetail extraClass={(!element.editable ? ' bg-red-100 ' : '') + (isCheck ? ' bg-solitude-100 ' : '')}>
                <div className="text-sm text-gray-900 flex  justify-center items-center">
                    {
                        (element.discount_price !== element.base_price) ? (
                            <>
                                <s className="line-through">
                                    <NumberFormat
                                        value={element.base_price}
                                        displayType={"text"}
                                        thousandSeparator={"."}
                                        decimalScale={4}
                                        decimalSeparator={","}
                                        suffix={" "+ (extras?.quotationDetail?.currency?.currency_symbol ?? '')}
                                    />
                                </s>{' '}
                                <NumberFormat
                                    value={element.discount_price}
                                    displayType={"text"}
                                    thousandSeparator={"."}
                                    decimalScale={4}
                                    decimalSeparator={","}
                                    suffix={" "+ (extras?.quotationDetail?.currency?.currency_symbol ?? '')}
                                />
                            </>
                        ) : (
                            <NumberFormat
                                value={element.base_price}
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalScale={4}
                                decimalSeparator={","}
                                suffix={" "+ (extras?.quotationDetail?.currency?.currency_symbol ?? '')}
                            />
                        )
                    }
                </div>
                <div className="text-xs text-gray-500">

                    {
                        discount !== false && (
                            <>{t("app.quotations.discount")} {discount}</>
                        )
                    }

                </div>
            </TableDetail>


            <TableDetail extraClass={(!element.editable ? ' bg-red-100 ' : '') + (isCheck ? ' bg-solitude-100 ' : '')}>
                

                <div className="flex flex-col justify-end  ">

                    <div className="flex justify-end">
                        <div className={"flex justify-end text-right"}>
                            <div>
                                <div className="text-sm text-gray-900 capitalize">
                                    <NumberFormat
                                        value={element.quantity * element.discount_price}
                                        displayType={"text"}
                                        thousandSeparator={"."}
                                        decimalScale={2}
                                        decimalSeparator={","}
                                        suffix={" " + (extras?.quotationDetail?.currency?.currency_symbol ?? '')}
                                    />
                                </div>
                                <div className="text-xs text-gray-500 capitalize">

                                    {
                                        extras.quotationDetail.status !== DRAFT && (
                                            <div style={{ fontSize: '0.65rem' }}>
                                                <NumberFormat
                                                    value={element.confirmed_quantity * element.discount_price}
                                                    displayType={"text"}
                                                    thousandSeparator={"."}
                                                    decimalScale={2}
                                                    decimalSeparator={","}
                                                    suffix={" " + (extras?.quotationDetail?.currency?.currency_symbol ?? '')}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                            </div>

                        </div>

                        {
                            extras.isHideSensitiveData && (
                                <PriceRange
                                    symbol={(extras?.quotationDetail?.currency?.currency_symbol ?? '')}
                                    purchasePrice={element.product.purchase_price ? element.product.purchase_price : false} price={element.discount_price} isLeft={true} showMargin={true} />

                            )
                        }
                    </div>

                    {
                        extras.quotationDetail.status !== DRAFT && element.type !== PRODUCT_SERVICE && (
                            <div className="w-full bg-gray-200  rounded mt-1">
                                <div className={classNames(
                                    percentage >= 50 ? 'shim-green' : '',
                                    percentage < 50 ? 'shim-red' : '',
                                    "text-xs font-medium rounded text-white text-center p-0.5 leading-none"
                                )} style={{ width: percentage + '%' }}>
                                </div>
                            </div>
                        )
                    }

                </div>
                
                
                
                
                
                
                
               
            </TableDetail>


                {
                     extras.canEdit && extras.isExporter && (
                        <TableDetail extraClass={(!element.editable ? ' bg-red-100 ' : '') + (isCheck ? ' bg-solitude-100 ' : '')}>
                            <SubscriptionWrapper requiredPermission={sp.QUOTATION_QUOTATION_PACKAGE} renderBaseOnFail={false}>
                                <ActionsMenu>
                                    <button className={'text-left'}
                                            onClick={(e) => {
                                                extras.setProductToEdit(element);
                                            }}
                                    >
                                        {t("app.common.edit")}
                                    </button>

                                    {
                                        extras.isActiveHideProduct && (
                                            <button
                                                className={'text-left'}
                                                onClick={() => {
                                                    extras.onShowHide(true, showHideDetail);
                                                }}
                                            >
                                                {element.editable ?  t("app.common.hide") : t("app.common.restore")}
                                            </button>
                                        )
                                    }

                                    <button className={'text-left'}
                                            onClick={() => {
                                                extras.onRemove(true, removeDetail);
                                            }}
                                    >
                                        {t("app.common.remove")}
                                    </button>
                                </ActionsMenu>
                            </SubscriptionWrapper>
                        </TableDetail>

                    )
                }


        </RowContainer>
    );
};


export default QuotationProducts;

import React, {useEffect, useState} from "react";
import BaseList from "../../../components/partials/common/base-list";
import {useTranslation} from "react-i18next";
import {getPathParam, getSelectParam, serialize} from "../../../utils/converter";
import {COMPANY, COMPANY_ATTRIBUTES, COMPANY_ATTRIBUTE, COMPANY_ATTRIBUTE_CATEGORIES} from "../../../api/endpoints";
import {useDispatch, useSelector} from "react-redux";
import ActionsMenu from "../../../components/common/table/actions-menu";
import CardDetail from "../../../components/common/list/card-detail";
import TableDetail from "../../../components/common/list/table-detail";
import { Controller, useForm } from "react-hook-form";
import InputText from "../../../components/common/form/input-text";
import {Warning} from "../../../components/common/alert/banner";
import InputSubmit from "../../../components/common/form/input-submit";
import { fetchCountries } from "../../../reducers/configReducer";
import BasePermissionWrapper, {hasPermissionFor} from "../../../components/partials/restricted/base-permission-wrapper";
import {getPermission, permissionGroup, permissionType} from "../../../constants/permissions";
import { HashtagIcon } from "@heroicons/react/outline";
import CatalogSlideLeft from "../../../components/partials/panel/catalog-slide-left";
import SubAttributes from "./sub-attributes";
import { useParams } from "react-router-dom";
import DynamicSearch from "../../../components/partials/common/dynamic-search";

const Attributes = () => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);
    let { category_id } = useParams();

    const { permissions } = useSelector((s) => s.userCompany);

    const [isOpenEditSubAttribute, setOpenEditSubAttribute] = useState(false);
    const [attributeDetails, setAttributeDetails] = useState(false);
    const [needRefresh, setNeedRefresh] = useState(false);

    
    const canCreate = hasPermissionFor(permissions, permissionGroup.PRODUCT_SETTING_ATTRIBUTE, permissionType.CREATE);
    const canEdit = hasPermissionFor(permissions, permissionGroup.PRODUCT_SETTING_ATTRIBUTE, permissionType.EDIT);
    const canView = hasPermissionFor(permissions, permissionGroup.PRODUCT_SETTING_ATTRIBUTE, permissionType.VIEW);

    return (
        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.PRODUCT_SETTING_ATTRIBUTE, permissionType.LIST)]} >
            {
                loggedCompany && (
                    <BaseList
                        columns={[
                            t("app.catalog.name"),
                            t("app.catalog.sub_attributes"),
                            "",
                        ]}
                        endpoint={getPathParam([COMPANY, loggedCompany.id, COMPANY_ATTRIBUTES])}
                        extraParams={{ category: category_id }}
                        storeEndpoint={getPathParam([COMPANY, loggedCompany.id, COMPANY_ATTRIBUTE])}
                        updateEndpoint={getPathParam([COMPANY, loggedCompany.id, COMPANY_ATTRIBUTE, ':id'])}
                        baseResult={{ total_rows: 0, attributes: []}}
                        resultLabel={'attributes'}
                        title={t("app.catalog.attributes")}
                        showHeader={true}
                        mobRow={MobRow}
                        deskRow={DeskRow}
                        addForm={AddForm}
                        icon={HashtagIcon}
                        addFormLabel={t("app.common.add_new")}
                        btnAddLabel={canCreate ? t("app.catalog.add_attribute") : false}
                        addPermission={[getPermission(permissionGroup.PRODUCT_SETTING_ATTRIBUTE, permissionType.CREATE)]}
                        editForm={EditForm}
                        needRefresh={needRefresh}
                        editTitle={t("app.common.edit")}
                        isActiveEdit={canView || canEdit}
                        onAddData={(r) => {
                            setAttributeDetails(r);
                            setOpenEditSubAttribute(true);
                        }}
                    />
                )
            }

            <CatalogSlideLeft
                title={t("app.catalog.sub_attributes")}
                isOpen={isOpenEditSubAttribute}
                setIsOpen={setOpenEditSubAttribute}
                setClean={() => {

                }}
            >
                <div className=" mx-auto ">
                    <SubAttributes attributeDetails={attributeDetails}  onRefresh={() => {
                        setNeedRefresh(!needRefresh)
                    }}/>
                </div>
            </CatalogSlideLeft>



        </BasePermissionWrapper>
    );
};


const MobRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);
    let { category_id } = useParams();

    const removeDetail = {
        title: t("app.common.remove_generic_title"),
        message: t("app.common.remove_generic_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, COMPANY_ATTRIBUTE, element.id]) + serialize({ category: category_id })
    };


    const { permissions } = useSelector((s) => s.userCompany);

    const canEdit = hasPermissionFor(permissions, permissionGroup.PRODUCT_SETTING_ATTRIBUTE, permissionType.EDIT);
    const canDelete = hasPermissionFor(permissions, permissionGroup.PRODUCT_SETTING_ATTRIBUTE, permissionType.DELETE);
    const canView = hasPermissionFor(permissions, permissionGroup.PRODUCT_SETTING_ATTRIBUTE, permissionType.VIEW);


    return (
        <li key={element.id} className="flex items-center gap-4 p-4">
            <div className="flex-grow">
                <div className="pb-4 items-center flex justify-between">
                    {element.name}
                    {
                        (canView || canEdit || canDelete) && (
                            <ActionsMenu>
                                {
                                    (canView || canEdit) && (
                                        <button className={'text-left'} onClick={() => {
                                            extras.onAdd(element);
                                        }}
                                        >
                                            {t("app.catalog.add_attribute")}
                                        </button>
                                    )
                                }

                                {
                                    (canView || canEdit) && (
                                        <button className={'text-left'} onClick={() => {
                                            extras.onEdit(true, element);
                                        }}
                                        >
                                            {t("app.common.edit")}
                                        </button>
                                    )
                                }
                                {
                                    canDelete && (
                                        <button className={'text-left'} onClick={() => {
                                            extras.onRemove(true, removeDetail);
                                        }}
                                        >
                                            {t("app.common.remove")}
                                        </button>
                                    )
                                }
                            </ActionsMenu>
                        )
                    }
                </div>
                <div className="flex flex-col">
                    <CardDetail title={t("app.catalog.add_attribute")}>
                        {element.company_sub_attribute_count}
                    </CardDetail>

                </div>
            </div>
        </li>
    );
};

const DeskRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);
    let { category_id } = useParams();

    const removeDetail = {
        title: t("app.common.remove_generic_title"),
        message: t("app.common.remove_generic_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, COMPANY_ATTRIBUTE, element.id]) + serialize({ category: category_id })
    };

    const { permissions } = useSelector((s) => s.userCompany);

    const canEdit = hasPermissionFor(permissions, permissionGroup.PRODUCT_SETTING_ATTRIBUTE, permissionType.EDIT);
    const canDelete = hasPermissionFor(permissions, permissionGroup.PRODUCT_SETTING_ATTRIBUTE, permissionType.DELETE);
    const canView = hasPermissionFor(permissions, permissionGroup.PRODUCT_SETTING_ATTRIBUTE, permissionType.VIEW);


    return (
        <tr key={element.id}>
            <TableDetail extraClass="max-w-sm">
                <div onClick={() => {
                    if ((canView || canEdit)){
                        extras.onAdd(element);
                    }
                    }} className="flex items-center space-x-3 lg:pl-2 cursor-pointer">
                    <div className="truncate hover:text-gray-600">
                        {element.name}
                    </div>
                </div>
            </TableDetail>

            <TableDetail>
                {element.company_sub_attribute_count}
            </TableDetail>

            <TableDetail>
                {
                    (canView || canEdit || canDelete) && (
                        <ActionsMenu>
                            {
                                (canView || canEdit) && (
                                    <button className={'text-left'} onClick={() => {
                                        extras.onAdd(element);
                                    }}
                                    >
                                        {t("app.catalog.add_attribute")}
                                    </button>
                                )
                            }

                            {
                                (canView || canEdit) && (
                                    <button className={'text-left'} onClick={() => {
                                        extras.onEdit(true, element);
                                    }}
                                    >
                                        {t("app.common.edit")}
                                    </button>
                                )
                            }
                            {
                                canDelete && (
                                    <button className={'text-left'} onClick={() => {
                                        extras.onRemove(true, removeDetail);
                                    }}
                                    >
                                        {t("app.common.remove")}
                                    </button>
                                )
                            }
                        </ActionsMenu>
                    )
                }
            </TableDetail>
        </tr>
    );
};

const AddForm = ({ exception, isLoading, onSubmitData }) =>  {

    const {
        register,
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm();
    let { category_id } = useParams();
    const { loggedCompany } = useSelector((state) => state.user);


    const dispatch = useDispatch();

    const { t } = useTranslation();

    const onSubmit = async (data) => {
        data['tmp_category'] = category_id;
        onSubmitData(data);
    };

    useEffect(() => {
        dispatch(fetchCountries());
    }, [dispatch]);




    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-4">
                <div className="grid md:grid-cols-1 lg:grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                    <InputText
                        id={"name"}
                        type={"text"}
                        errors={errors.name}
                        input={{ ...register("name", { required: true }) }}
                        label={t("app.catalog.name")}
                    />

                    <Controller
                        name="company_attribute_category"
                        rules={{ required: false }}
                        control={control}
                        render={({
                            field: { onChange, value, name },
                            fieldState: { error },
                        }) => (
                            <DynamicSearch
                                selectLabel={'Tag'}
                                endpoint={getPathParam([COMPANY, loggedCompany.id, COMPANY_ATTRIBUTE_CATEGORIES])}
                                responseLabel={'categories'}
                                label={'name'}
                                errors={error}
                                value={value}
                                name={name}
                                onChange={onChange}
                                onResponse={(r) => {
                                    if (r.find(r => r.id === category_id)) {
                                        setValue('company_attribute_category', r.find(r => r.id === category_id))
                                    }
                                }}
                            />
                        )}
                    />

                </div>
            </div>

            {/* Warning */}
            {exception && (
                <Warning message={exception} />
            )}

            <div className="flex before:flex-1 items-center justify-between mt-6">
                <InputSubmit
                    isLoading={isLoading}
                    label={t("app.catalog.add_attribute")}
                />
            </div>
        </form>
    );
}

const EditForm = ({ data, exception, onSubmitData, isEditLoading }) =>  {

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control
    } = useForm();
    let { category_id } = useParams();
    const { loggedCompany } = useSelector((state) => state.user);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { permissions } = useSelector((s) => s.userCompany);

    const canEdit = hasPermissionFor(permissions, permissionGroup.PRODUCT_SETTING_ATTRIBUTE, permissionType.EDIT);


    useEffect(() => {
        if(data !== null){
            for (const k in data) {
                if (typeof data[k] === "object") {
                    setValue(k, getSelectParam(data[k], "name"));
                }else{
                    setValue(k, data[k]);
                }
            }
        }
    }, [setValue, data]);

    const onSubmit = async (data) => {
        data['tmp_category'] = category_id;
        onSubmitData(data);
    };


    useEffect(() => {
        dispatch(fetchCountries());
    }, [dispatch]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <div className="space-y-4 pt-4 pb-5">

                        <InputText
                            id={"name"}
                            type={"text"}
                            errors={errors.name}
                            input={{ ...register("name", { required: true }) }}
                            label={t("app.catalog.name")}
                            isDisabled={!canEdit}
                        />

                        <Controller
                            name="company_attribute_category"
                            rules={{ required: false }}
                            control={control}
                            render={({
                                field: { onChange, value, name },
                                fieldState: { error },
                            }) => (
                                <DynamicSearch
                                    selectLabel={'Tag'}
                                    endpoint={getPathParam([COMPANY, loggedCompany.id, COMPANY_ATTRIBUTE_CATEGORIES])}
                                    responseLabel={'categories'}
                                    label={'name'}
                                    errors={error}
                                    value={value}
                                    name={name}
                                    onChange={onChange}
                                    onResponse={(r) => {
                                        if (r.find(r => r.id === category_id)) {
                                            setValue('company_attribute_category', r.find(r => r.id === category_id))
                                        }
                                    }}
                                />
                            )}
                        />

                    </div>
                </div>

                {/* Warning */}
                {exception && (
                    <Warning message={exception} />
                )}

                {
                    canEdit && (
                        <div className="mt-3">
                            <InputSubmit
                                isLoading={isEditLoading}
                                isFullWith={true}
                                label={t("app.form.save")}
                            />
                        </div>
                    )
                }

            </form>

        </>
    );
}

export default Attributes;

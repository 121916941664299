import React, { useEffect, useState } from "react";
import InputSubmit from "../../../components/common/form/input-submit";
import { Controller, useForm } from "react-hook-form";
import FormPopup from "../../../components/common/popup/form-popup";
import { fetchCities, fetchTermTypes } from "../../../reducers/configReducer";
import { useDispatch, useSelector } from "react-redux";
import InputSelect from "../../../components/common/form/input-select";
import { Warning } from "../../../components/common/alert/banner";
import { BOOKING, COMPANY, CONNECTION } from "../../endpoints";
import { getSelectParams, getPathParam } from "../../../utils/converter";
import { getPermission, permissionGroup, permissionType, sp } from "../../../constants/permissions";
import {useTranslation} from "react-i18next";
import SubscriptionWrapper from "../../../components/partials/restricted/subscription-wrapper";
import InputPortSelect from "../../../components/common/form/input-port-select";
import { fetchCustomers, fetchSuppliers } from "../../../reducers/connectionReducer";
import BasePermissionWrapper from "../../../components/partials/restricted/base-permission-wrapper";
import { RadioGroup } from '@headlessui/react';
import PickupIcon from "../../../resources/icons/PickupIcon";
import DeliveryIcon from "../../../resources/icons/DeliveryIcon";
import { DELIVERY, PICKUP, SEA } from "../../../constants/config";
import CustomerIcon from "../../../resources/icons/CustomerIcon";
import WarehouseIcon from "../../../resources/icons/WarehouseIcon";
import ShippingIcon from "../../../resources/icons/ShippingIcon";
import { ADDRESSES } from "../../../api/endpoints";
import DynamicSearch from "../../../components/partials/common/dynamic-search";
import InputDate from "../../../components/common/form/input-date";
import InputText from "../../../components/common/form/input-text";
import { postElement } from "../../../api/config";


export default function AddBooking({ isOpen, setIsOpen, onCreate }) {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        formState: { errors },
        register,
        handleSubmit,
        control,
        watch,
        setValue
    } = useForm();

    
    const { loggedCompany, isExporter } = useSelector((state) => state.user);
    const { termTypesList,isTermTypesListLoading} = useSelector((state) => state.config);
    const { customers, isLoadingCustomers, suppliers, isLoadingSuppliers } = useSelector((state) => state.connection);

    const fulfillmentTypes = [
        { type: PICKUP, name: t("app.fulfillment.pickup"), icon: PickupIcon },
        { type: DELIVERY, name: t("app.fulfillment.delivery"), icon: DeliveryIcon },
    ]
    const [fulfillmentType, setFulfillmentType] = useState(fulfillmentTypes[0])


    const shippingTypes = [
      //  { type: 'land', name: '', icon: TruckIcon },
        { type: 'sea', name: '', icon: ShippingIcon },
      //  { type: 'air', name: '', icon: AirIcon },
    ];
    const [shippingType, setShippingType] = useState(shippingTypes[0])


    const registryTypes = [
        { type: 'customer', name: '', icon: CustomerIcon },
        { type: 'supplier', name: '', icon: WarehouseIcon },
    ]
    const [registryType, setRegistryType] = useState(registryTypes[0])


    useEffect(() => {
        setValue('connection_address', null);
        setValue('connection', null)
    }, [registryType, setValue]);


    useEffect(() => {
        dispatch(fetchCities());
        dispatch(fetchTermTypes());
        dispatch(fetchCustomers({ company_id: loggedCompany.id }));
        dispatch(fetchSuppliers({ company_id: loggedCompany.id }));
    }, [dispatch, loggedCompany, isExporter]);



    // shipping
    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);

 


    const storeData = async (data) => {
        if (loggedCompany) {
            setLoading(true);
            setException(false);
            postElement(getPathParam([COMPANY, loggedCompany.id, BOOKING]), data)
                .then(response => {
                    onCreate(response);
                    setIsOpen(false);
                }).catch(e => {
                    setException(e.message);
                    setLoading(false);
                });
        }
    };

    const onSubmit = async (data) => {
        data['type'] = fulfillmentType.type;
        data['method'] = shippingType.type;
        await storeData(data);
    };

    const validateParams = () => {
        let flag = false;
        if (!shippingType) {
            flag = true;
        }

        if (!registryType) {
            flag = true;
        }

        if (!fulfillmentType) {
            flag = true;
        }

        if (!watch('connection')) {
            flag = true;
        }


        return flag
    };

    return (
        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.BOOKING, permissionType.CREATE)]}>
            <FormPopup
                showHeader={true}
                isActiveFormPm={true}
                title={t("app.booking.add_new")}
                isOpen={isOpen}
                setIsOpen={(r) => {
                    setIsOpen(r);
                }}
            >
                <SubscriptionWrapper requiredPermission={sp.BOOKING_INTERNAL_PACKAGE} renderBaseOnFail={true} checkUsage={true}>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="space-y-4">
                            <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-5 gap-x-5 sm:grid-cols-1">

                                <fieldset>
                                    <label
                                        className="block text-sm font-medium mb-1 text-gray-700"
                                    >
                                        {t("app.fulfillment.fulfillment_type")}<span className="text-red-500">*</span>
                                    </label>

                                    <RadioGroup value={fulfillmentType} onChange={setFulfillmentType} className="flex items-center  rounded-md ring-1 ring-gray-200">
                                        {fulfillmentTypes.map((option) => (
                                            <RadioGroup.Option
                                                key={option.name}
                                                value={option}
                                                className={({ active, checked }) =>
                                                    `cursor-pointer flex items-center justify-center   p-2 text-sm  rounded-md font-semibold     sm:flex-1
                                                    ${(fulfillmentType.type === option.type) ? 'bg-blue-1000 text-white' : 'bg-white text-blue-1000 hover:bg-gray-50'}`
                                                }
                                            >
                                                <option.icon className="h-5 w-5" />
                                                <span className={"ml-2"}>{option.name}</span>
                                            </RadioGroup.Option>
                                        ))}
                                    </RadioGroup>
                                </fieldset>


                                <fieldset>
                                    <label
                                        className="block text-sm font-medium mb-1 text-gray-700"
                                    >
                                        {t("app.fulfillment.shipping_type")}<span className="text-red-500">*</span>
                                    </label>

                                    <RadioGroup value={shippingType} onChange={setShippingType} className="flex items-center  rounded-md ring-1 ring-gray-200">
                                        {shippingTypes.map((option) => (
                                            <RadioGroup.Option
                                                key={option.name}
                                                value={option}
                                                className={({ active, checked }) =>
                                                    `cursor-pointer flex items-center justify-center   p-2 text-sm  rounded-md font-semibold     sm:flex-1
                                                    ${(shippingType.type === option.type) ? 'bg-blue-1000 text-white' : 'bg-white text-blue-1000 hover:bg-gray-50'}`
                                                }
                                            >
                                                <option.icon className="h-5 w-5" />
                                            </RadioGroup.Option>
                                        ))}
                                    </RadioGroup>
                                </fieldset>


                                <div className="col-span-2 grid grid-cols-5 flex items-center gap-1">
                                    <label
                                        className="block col-span-5 text-sm font-medium mb-1 text-gray-700"
                                    >
                                        {t("app.shippings.customer")}/{t("app.supplier.supplier")}<span className="text-red-500">*</span>
                                    </label>


                                    <RadioGroup value={registryType} onChange={setRegistryType} className="flex items-center  rounded-md ring-1 ring-gray-200">
                                        {registryTypes.map((option) => (
                                            <RadioGroup.Option
                                                key={option.name}
                                                value={option}
                                                className={({ active, checked }) =>
                                                    `cursor-pointer flex items-center justify-center   p-2 text-sm  rounded-md font-semibold     sm:flex-1
                                                    ${(registryType.type === option.type) ? 'bg-blue-1000 text-white' : 'bg-white text-blue-1000 hover:bg-gray-50'}`
                                                }
                                            >
                                                <option.icon className="h-5 w-5" />
                                            </RadioGroup.Option>
                                        ))}
                                    </RadioGroup>
                                    <div className="col-span-4">
                                        <Controller
                                            name="connection"
                                            rules={{ required: true }}
                                            control={control}
                                            render={({
                                                field: { onChange, value, name },
                                                fieldState: { error },
                                            }) => (
                                                <InputSelect
                                                    isShowLabel={false}
                                                    label={registryType.type === "customer" ? t("app.shippings.customer") : t("app.supplier.supplier")}
                                                    name={name}
                                                    options={registryType.type === "customer" ? getSelectParams(customers, "business_name") : getSelectParams(suppliers, "business_name")}
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                    }}
                                                    isLoading={registryType.type === "customer" ? isLoadingCustomers : isLoadingSuppliers}
                                                    isDisabled={registryType.type === "customer" ? isLoadingCustomers : isLoadingSuppliers}
                                                    errors={error}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>

                                





                                {
                                    shippingType.type !== SEA ? (
                                        <div className="col-span-2">
                                            <Controller
                                                name="connection_address"
                                                rules={{ required: false }}
                                                control={control}
                                                render={({
                                                    field: { onChange, value, name },
                                                    fieldState: { error },
                                                }) => (
                                                    <DynamicSearch
                                                        isDisabled={!watch('connection')}
                                                        selectLabel={t("app.fulfillment.destination_address", { type: fulfillmentType.name })}
                                                        endpoint={getPathParam([COMPANY, loggedCompany.id, CONNECTION, watch('connection')?.id ?? '', ADDRESSES])}
                                                        responseLabel={'addresses'}
                                                        label={'full_address'}
                                                        errors={error}
                                                        value={value}
                                                        name={name}
                                                        onResponse={(r) => {

                                                        }}
                                                        onChange={async (r) => {
                                                            onChange(r);
                                                        }}
                                                    />
                                                )}
                                            />

                                        </div>
                                    ) : (
                                        <>
                                            <InputPortSelect
                                                label={t("app.fulfillment.pickup_port")}
                                                placeholder={t("app.fulfillment.pickup_port")}
                                                selectedItem={watch('departure_port')}
                                                onSelect={(e) => {
                                                    setValue('departure_port', e)
                                                }}
                                            />

                                                <InputPortSelect
                                                    label={t("app.fulfillment.delivery_port")}
                                                    placeholder={t("app.fulfillment.delivery_port")}
                                                    selectedItem={watch('arrival_port')}
                                                    onSelect={(e) => {
                                                        setValue('arrival_port', e)
                                                    }}
                                                />
                                        </>
                                    )
                                }


                                <Controller
                                    name="terms_type"
                                    rules={{ required: false }}
                                    control={control}
                                    render={({
                                        field: { onChange, value, name },
                                        fieldState: { error },
                                    }) => (
                                        <InputSelect
                                            label={t("app.shippings.terms_type")}
                                            name={name}
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            options={termTypesList}
                                            value={value}
                                            isLoading={isTermTypesListLoading}
                                            isDisabled={isTermTypesListLoading}
                                            errors={error}
                                        />
                                    )}
                                />

                                <Controller
                                    name="expected_date"
                                    rules={{ required: false }}
                                    control={control}
                                    render={({
                                        field: { onChange, value, name },
                                        fieldState: { error },
                                    }) => (
                                        <InputDate
                                            label={t("app.fulfillment.expected_date")}
                                            format={"YYYY-MM-D HH:mm:ss"}
                                            placeholder={"Es. 01/12/2021"}
                                            activeMinDate={false}
                                            startDate={value}
                                            disabled={false}
                                            isShowTime={false}
                                            errors={error}
                                            name={name}
                                            onChange={(e) => {
                                                setValue('arrival_date', e);
                                                onChange(e);
                                            }}
                                        />
                                    )}
                                />

                                    <InputText
                                        id={'internal_reference'}
                                        type={'text'}
                                        errors={errors.internal_reference}
                                        input={{ ...register("internal_reference", { required: false }) }}
                                        label={t("app.fulfillment.reference")}
                                    />

                                <InputText
                                    id={'internal_note'}
                                    type={'text'}
                                    errors={errors.internal_note}
                                    input={{ ...register("internal_note", { required: false }) }}
                                    label={t("app.booking.internal_note")}
                                />
                                

                            </div>
                        </div>

                        {/* Warning*/}
                        {exception && (
                            <Warning message={exception} />
                        )}

                        <div className="flex  items-center justify-end mt-6">
                            <InputSubmit
                                disabled={validateParams()}
                                isLoading={isLoading}
                                label={t("app.booking.add_booking")}
                            />
                        </div>




                    </form>
                </SubscriptionWrapper>

            </FormPopup>
        </BasePermissionWrapper>
    );
}

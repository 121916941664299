import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ButtonSubmit from "../../../components/common/form/button-submit";
import { getElement, postElement } from "../../../api/config";
import { getPathParam } from "../../../utils/converter";
import {  CONTAINER, PRODUCT, QUOTATION, QUOTATION_CONFIRM, QUOTATION_CONFIRMS } from "../../endpoints";
import { Warning } from "../../../components/common/alert/banner";
import BoardPopup from "../../../components/board/board-popup";
import { useSelector } from "react-redux";
import InputQuantityNumber from "../../../components/common/form/input-quantity-number";
import InputDate from "../../../components/common/form/input-date";
import { TrashIcon } from "@heroicons/react/outline";
import UserInitial from "../../../components/common/table/user-initial";
import LoaderWrapper from "../../../components/common/loader/loader-wrapper";
import { getFormattedDate } from "../../../utils/timeUtils";
import NumberFormat from "react-number-format";
import RemovePopup from "../../../components/common/popup/remove-popup";
import { REVIEW } from "../../../constants/config";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const ManageQuantity = ({ isOpen, setIsOpen, productData, quotationId, containerId, quotationDetail,  onUpdateData }) => {

    const { loggedCompany } = useSelector((state) => state.user);
    const [isLoading, setLoading] = useState(false)
    const [exception, setException] = useState(false)
    const { t } = useTranslation();

    const [isLoadingData, setLoadingData] = useState(true)


    const [quotatiesData, setQuotatiesData] = useState([]);


    //REMOVE
    const [isOpenRemove, setOpenRemove] = useState(false);
    const [removeDetail, setRemoveDetail] = useState({});

    const {
        handleSubmit,
        control,
        watch
    } = useForm();


    const onCreate = async (data) => {
        setLoading(true);
        setException(false);
        postElement(getPathParam([QUOTATION, quotationId, CONTAINER, containerId, PRODUCT, productData.id, QUOTATION_CONFIRM]), data)
        .then(response => {
            setQuotatiesData(response.confirmations);
            setLoading(false);
            onUpdateData();
        }).catch(e => {
            setException(e.message);
            setLoading(false);
        });
    };


    useEffect(() => {
        async function fetchData() {
            setLoadingData(true);
            if (loggedCompany) {
                try {
                    const res = await getElement(getPathParam([QUOTATION, quotationId, CONTAINER, containerId, PRODUCT, productData.id, QUOTATION_CONFIRMS]));
                    setQuotatiesData(res.confirmations);
                } catch (e) {
                    setQuotatiesData([]);
                } finally {
                    setLoadingData(false);
                }
            }
        }


        fetchData();
        // eslint-disable-next-line
    }, [loggedCompany]);


    const validateQuantity2 = (quantity = null) => {

        let closedBoxes = productData?.product?.qty_per_packaging ?? 0;
        if (closedBoxes > 0) {

            let desiredQuantity = quantity;

            if (closedBoxes === desiredQuantity) {
                return ''
            }

            if (isNaN(desiredQuantity)) {
                return ''
            } else {
                if (desiredQuantity === 0) {
                    return t("app.container.product_predict") + (closedBoxes);
                } else {
                    if (closedBoxes > desiredQuantity) {
                        return t("app.container.product_predict") + (closedBoxes);

                    } else {
                        let suggestedQuantityPerBox = Math.ceil(desiredQuantity / closedBoxes);
                        if ((closedBoxes * suggestedQuantityPerBox) === desiredQuantity) {
                            return ''
                        } else {
                            return t("app.container.product_predict") + (closedBoxes * suggestedQuantityPerBox);
                        }
                    }
                }
            }



        } else {
            return ''
        }
    }




    return (

        <>

            <BoardPopup
                isOpen={isOpen}
                setIsOpen={(r) => {
                    setIsOpen(r)
                }}
                canClose={true}
                setClean={() => {
                
                }}
            >
                <div className={classNames(
                    " -mt-4 mb-3 bg-white"
                    )}>

                    <div className="px-4 sm:px-6">
                        
                        <>
                            {/* TITLE */}
                            <div className="flex items-center justify-between mt-2 mb-6">
                                <div className="flex items-start space-x-5">
                                    <div className="pt-1.5">
                                        <h1 className="text-xl font-bold text-blue-1000">{t("app.quotations.quantity_title")}</h1>
                                    </div>
                                </div>
                            </div>
                            {/* CONTENT */}

                            {
                                quotationDetail.status === REVIEW && (
                                    <form onSubmit={handleSubmit(onCreate)} className={' border-none pb-5 mt-3'}>
                                        <div className="mt-3 grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1">



                                            <div className="col-span-2 md:col-span-1">
                                                <Controller
                                                    name="confirmed_quantity"
                                                    rules={{ required: true }}
                                                    control={control}
                                                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                                        <InputQuantityNumber
                                                            label={t("app.quotations.quantity")}
                                                            name={name}
                                                            value={value}
                                                            decimalScale={4}
                                                            onChange={(e) => {
                                                                let price = e;
                                                                if (price === 0) {
                                                                    price = 1;
                                                                }
                                                                onChange(price);
                                                            }}
                                                            onChangeValues={(e) => {

                                                            }}
                                                            prefix={""}
                                                            suffix={' ' + (productData.unit_value ? productData.unit_value.symbol : ((productData.product && productData.product.unit_value) ? productData.product.unit_value.symbol : ''))}
                                                            errors={error}
                                                        />
                                                    )}
                                                />
                                                {
                                                    watch('confirmed_quantity') > 0 && validateQuantity2(watch('confirmed_quantity')) && (
                                                        <div className="mt-1 text-sm text-red-600">
                                                            {validateQuantity2(watch('confirmed_quantity'))}
                                                        </div>
                                                    )
                                                }


                                            </div>

                                            <div className="col-span-2 md:col-span-1">
                                                <Controller
                                                    name="confirmed_date"
                                                    rules={{ required: true }}
                                                    control={control}
                                                    render={({
                                                        field: { onChange, value, name },
                                                        fieldState: { error },
                                                    }) => (
                                                        <InputDate
                                                            label={t("app.quotations.date")}
                                                            format={"YYYY-MM-D HH:mm:ss"}
                                                            placeholder={"Es. 01/12/2021"}
                                                            activeMinDate={false}
                                                            startDate={value}
                                                            disabled={false}
                                                            isShowTime={false}
                                                            errors={error}
                                                            name={name}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            {/* Warning */}
                                            {exception && (
                                                <div className="col-span-4 md:col-span-2">
                                                    <Warning message={exception} />
                                                </div>
                                            )}


                                            <div className={classNames(
                                                "col-span-4 md:col-span-2",
                                            )}>

                                                <div className="flex flex-col items-center">
                                                    <ButtonSubmit
                                                        btnColor={'bg-blue-1000 hover:bg-blue-900'}
                                                        isLoading={isLoading}
                                                        isFullWith={true}
                                                        label={t("app.common.add")}

                                                    />
                                                </div>
                                            </div>


                                        </div>
                                    </form>
                                )
                               
                            }
                            
                           
                            
                            

                            <LoaderWrapper isLoading={isLoadingData}>
                                {
                                    quotatiesData.length > 0 && (
                                        <ul className="divide-y divide-gray-200 rounded-md border border-gray-200 mt-2 h-64 overflow-y-scroll">
                                            {
                                                quotatiesData.map((user, key) => (
                                                    <li className="lg:flex lg:items-center lg:justify-between  pl-3 pr-4 py-2.5 text-sm">
                                                        <div className="flex  flex-1 items-center mt-3 lg:mt-0">
                                                            <div className="flex items-center">
                                                                <div className="flex-shrink-0">
                                                                    <UserInitial user={user.user} />
                                                                </div>
                                                                <div className="flex-1 ml-3">
                                                                    <p className="text-sm font-medium text-gray-900">{user.user.fullname}</p>
                                                                    <p className="text-xs font-medium text-gray-700">{getFormattedDate(user.confirmed_date)}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className=" flex-shrink-0 flex  items-center ">

                                                            <div className="text-md text-gray-900 capitalize font-semibold mr-3">
                                                                <NumberFormat
                                                                    value={user.confirmed_quantity}
                                                                    displayType={"text"}
                                                                    thousandSeparator={"."}
                                                                    decimalSeparator={","}
                                                                    suffix={' ' + (productData.unit_value ? productData.unit_value.symbol : ((productData.product && productData.product.unit_value) ? productData.product.unit_value.symbol : ''))}
                                                                />
                                                            </div>
                                                           
                                                            {
                                                                quotationDetail.status === REVIEW && (
                                                                    <div
                                                                        onClick={() => {
                                                                            const removeDetail = {
                                                                                type: 'product',
                                                                                title: t("app.common.remove_generic_title"),
                                                                                message: t("app.common.remove_generic_description"),
                                                                                endpoint: getPathParam([QUOTATION, quotationId, CONTAINER, containerId, PRODUCT, productData.id, QUOTATION_CONFIRM, user.id]),
                                                                            };
                                                                            setRemoveDetail(removeDetail);
                                                                            setOpenRemove(true);
                                                                        }}
                                                                        className="text-red-500 hover:text-red-300 cursor-pointer   flex justify-center text-sm font-medium"
                                                                    >
                                                                        <TrashIcon
                                                                            className="h-4 w-4"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </div>
                                                                )
                                                            }
                                                           

                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    )
                                }
                            </LoaderWrapper>



                        </>
                    </div>

                </div>
                {isOpenRemove && (
                    <RemovePopup
                        isOpen={isOpenRemove}
                        setIsOpen={setOpenRemove}
                        detail={removeDetail}
                        setDetail={(r) => {
                            setQuotatiesData(r.confirmations);
                            onUpdateData();
                        }}
                    />
                )}

            </BoardPopup>


           
        </>


    )
};

export default ManageQuantity;
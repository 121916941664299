import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const InputDate = ({ label, isRequired, name, startDate, cleanSearch = null, cleanData = null, disabled, selectsEnd = false, activeMinDate, minDate = null, onChange, isShowTime, errors, warning, warningRegex, format, extraClass = '', isShowLabel= true }) => {


 /*   let date = startDate ? new Date(moment(startDate).format(isShowTime ? "MM/DD/YYYY HH:mm" : 'MM/DD/YYYY')) : '';
    const [selected, setSelected] = useState(date !== '' && date !== 'Invalid Date' ? date : null);*/

    const [selected, setSelected] = useState(null);

    useEffect(() => {
        if(startDate){
            let date = startDate ? new Date(moment(startDate).format(isShowTime ? "MM/DD/YYYY HH:mm" : 'MM/DD/YYYY')) : '';
            if(date !== '' && date !== 'Invalid Date'){
                setSelected(date);
            }
        }


        // eslint-disable-next-line
    }, [startDate]);


    useEffect(() => {
        if (cleanSearch !== null) {
            setSelected(null);
        }
    }, [cleanSearch]);


    useEffect(() => {
        if (cleanData ) {
            setSelected(null);
        }
    }, [cleanData]);

    const { t } = useTranslation();
    return (
        <div className={extraClass}>
            {
                isShowLabel && (
                    <label htmlFor={name} className="block text-sm font-medium mb-1 text-gray-700">
                        {label} {isRequired && <span className="text-red-500">*</span>}
                    </label>
                )
            }
            <DatePicker
                selected={selected}
                disabled={disabled}
                minDate={activeMinDate ? (minDate ? minDate : new Date()) : false}
                onChange={(date) => {
                    setSelected(date);
                    onChange(date ? moment(date, 'date/utc format').format('YYYY-MM-DD HH:mm:ss') : '');
                }}
                showTimeInput={isShowTime}
                selectsEnd={selectsEnd}
                timeInputLabel="Time:"
                timeCaption={'Orario'}
                placeholderText={t("app.common.placeholder", {field: label.toLowerCase()})}
                dateFormat={isShowTime ? "dd/MM/yyyy HH:mm" : 'dd/MM/yyyy'}
                popperPlacement={'top-end' }
                className={`form-input w-full ${errors ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md' : ''}`}
            />
            {errors && errors.type === 'required' && (  <p className="mt-2 text-sm text-red-600">{warning ? warning : t("app.warning.description", {field: label})}</p>)}
            {errors && errors.type === 'validate' && (  <p className="mt-2 text-sm text-red-600">{warning ? warning : t("app.warning.description", {field: label})}</p>)}
            {errors && errors.type === 'pattern'  && (  <p className="mt-2 text-sm text-red-600">{warningRegex ? warningRegex : t("app.warning.description_regex", {field: label})}</p>)}

        </div>

    )
};

export default InputDate;

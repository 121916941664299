import React, { useState, useEffect } from "react";
import SearchIcon from "../../../resources/icons/SearchIcon";
import {concatenateQueryStrings, removeParam, serialize} from "../../../utils/converter";
import {useLocation, useNavigate} from "react-router-dom";

const InputSearch = ({ defaultValue = "", cleanSearch = null, onSearch, autocomplete = true, hasMargin = true, length = 3, isDynamicSearch = false, extraClass = '' }) => {

    const checkSearch = () => {
        const querySearch = new URLSearchParams(window.location.search).get(
            "querySearch"
        );
        if(querySearch){
            return querySearch;
        }
        return "";
    }

  const [second, setSecond] = useState(0);
  const [search, setSearch] = useState(checkSearch());
  const [startCounter, setStartCounter] = useState(false);


  const location = useLocation();
  let navigate = useNavigate();


  useEffect(() => {
        if(isDynamicSearch){
            if(search.length === 0){
                navigate(removeParam('querySearch', location.pathname + location.search));
            }else{
                navigate(location.pathname + concatenateQueryStrings(location.search, serialize({querySearch: search})))
            }
        }
        // eslint-disable-next-line
  }, [isDynamicSearch, search]);

  useEffect(() => {
    if (cleanSearch !== null) {
      setSearch('');
      document.getElementById("search").value = "";
    }
  }, [cleanSearch]);

    useEffect(() => {
    const interval = setInterval(() => {
      if (startCounter) {
        setSecond(second + 1);
        if ((search.length >= length || search.length === 0) && second === 1) {
          setStartCounter(false);
          setSecond(0);
          onSearch(search);
        }
      }
    }, 200);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <div className={hasMargin ? "mt-1 relative rounded-md w-full sm:w-auto" : "relative rounded-md  w-full sm:w-auto "}>
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <SearchIcon className="h-5 w-5 text-blue-1000 " aria-hidden="true" />
      </div>
      <input
        type="search"
        name="search"
        id="search"
        autoComplete={autocomplete ? "on" : "off"}
        className={"form-search block w-full pl-10 "+extraClass}
        placeholder="Search..."
        onChange={(e) => {
          setSearch(e.target.value);
          setSecond(0);
          setStartCounter(true);
        }}
        defaultValue={search}
      />
    </div>
  );
};

export default InputSearch;

import React from "react";
import {  useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import InputSubmit from "../../common/form/input-submit";
import { useTranslation } from "react-i18next";

const EditFooter = ({ backLink, backLabel, btnLabel, isLoading , isDisabled = false }) => {

    const navigate = useNavigate();

    const { t } = useTranslation();

    const backLinkF = () => {
        navigate(-1); // Naviga indietro di una pagina
    };


  return (
    <div className={"px-4 py-3a bg-solitude-100 text-right sm:px-6 flex items-center justify-between" + (backLink ? '' : ' before:flex-1') }>
        {
            backLink && (
                <div
                      onClick={() => { backLinkF() }}
                        className="text-sm underline inline-flex items-center cursor-pointer p-4"
                >
                        <ArrowLeftIcon className="h-4 w-4 mr-2" aria-hidden="true" />{" "}
                        {t("app.common.back")}
                  </div>
            )
        }

        {
            isDisabled ? (
                <div />
            ) : (
                <InputSubmit isLoading={isLoading} label={btnLabel} />
            )
        }

    </div>
  );
};

export default EditFooter;

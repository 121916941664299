import React from "react";
import { CheckIcon, DocumentSearchIcon, PencilAltIcon, XIcon, BadgeCheckIcon, ClockIcon } from "@heroicons/react/outline";
import {useTranslation} from "react-i18next";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const StatusBarIcon = ({ status, isText = false}) => {


    const { t } = useTranslation();

    let statusStyles = {
        draft: {
            style: 'bg-gray-100 text-gray-800',
            styleText: 'text-red-800',
            title: t("app.quotations.status_draft"),
            icon: PencilAltIcon
        },
        review: {
            style: 'bg-yellow-100 text-yellow-800',
            styleText: 'text-yellow-800',
            title: t("app.quotations.status_review"),
            icon: DocumentSearchIcon
        },

        pending: {
            style: 'bg-yellow-100 text-yellow-800',
            styleText: 'text-yellow-800',
            title: t("app.catalog.status_pending"),
            icon: DocumentSearchIcon
        },
        negotiation: {
            style: 'bg-yellow-100 text-yellow-800',
            styleText: 'text-yellow-800',
            title: t("app.catalog.status_pending"),
            icon: DocumentSearchIcon
        },
        confirmed: {
            style: 'bg-green-100 text-green-800',
            styleText: 'text-green-800',
            title: t("app.quotations.status_confirmed"),
            icon: CheckIcon
        },
        in_progress: {
            style: 'bg-yellow-100 text-yellow-800',
            styleText: 'text-yellow-800',
            title: t("app.shippings.status_in_progress"),
            icon: DocumentSearchIcon
        },
        completed: {
            style: 'bg-green-100 text-green-800',
            styleText: 'text-green-800',
            title: t("app.shippings.status_completed"),
            icon: CheckIcon
        },
        canceled: {
            style: 'bg-red-100 text-red-800',
            styleText: 'text-red-800',
            title: t("app.quotations.status_canceled"),
            icon: XIcon
        },
        to_approve: {
            style: 'bg-yellow-100 text-yellow-800',
            styleText: 'text-yellow-800',
            title: t("app.catalog.status_to_approve"),
            icon: DocumentSearchIcon
        },
        approved: {
            style: 'bg-green-100 text-green-800',
            styleText: 'text-green-800',
            title: t("app.catalog.status_approved"),
            icon: BadgeCheckIcon
        },
        waiting: {
            style: 'bg-blue-100 text-blue-800',
            styleText: 'text-blue-800',
            title: t("app.booking.waiting"),
            icon: ClockIcon
        }

        
    };

    const IconList = ({status, className}) => {

        let IconType = statusStyles[status].icon;
        return (
            <IconType
                className={className}
                aria-hidden="true"
            />
        )
    }

    return (
        <>
        
        {
            isText ? (
                <span
                    className={classNames(
                        "inline-flex items-center  font-bold text-xs ",
                        statusStyles[status].styleText,

                    )}
                >
                    {statusStyles[status].title}
                </span>
            ) : (
                <span
                    className={classNames(
                        "inline-flex items-center rounded-full text-xs font-medium px-2.5 py-1",
                        statusStyles[status].style,
                    )}
                >
                    <IconList
                        status={status}
                        className={classNames(
                            "w-4 h-4 mr-1 flex-shrink-0"
                        )}
                    />

                    {statusStyles[status].title}
                </span>
            )
        }

        
        </>
        
       
    )
};

import React, {useEffect} from "react";
import BaseList from "../../../../components/partials/common/base-list";
import {useTranslation} from "react-i18next";
import {getPathParam, serialize} from "../../../../utils/converter";
import {
    COMPANY,
    COMPANY_MEDIAS,
    COMPANY_MEDIA,
    COMPANY_MEDIA_CATEGORIES
} from "../../../../api/endpoints";
import {useDispatch, useSelector} from "react-redux";
import ActionsMenu from "../../../../components/common/table/actions-menu";
import CardDetail from "../../../../components/common/list/card-detail";
import TableDetail from "../../../../components/common/list/table-detail";
import {Controller, useForm} from "react-hook-form";
import InputText from "../../../../components/common/form/input-text";
import {Warning} from "../../../../components/common/alert/banner";
import InputSubmit from "../../../../components/common/form/input-submit";
import {fetchCountries} from "../../../../reducers/configReducer";
import {useParams} from "react-router-dom";
import {PaperClipIcon} from "@heroicons/react/outline";
import UploadFile from "../../../../components/partials/upload/upload-file";
import FileCell from "../../../../components/common/table/file-cell";
import DynamicSearch from "../../../../components/partials/common/dynamic-search";

const CompanyMedia = () => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);
    let { category_id } = useParams();


    return (
        <>
            {
                loggedCompany && (
                    <>
                        <BaseList
                            columns={[
                                t("app.catalog.attachment"),
                                t("app.catalog.note"),
                                "",
                            ]}
                            endpoint={getPathParam([COMPANY, loggedCompany.id, COMPANY_MEDIAS])}
                            extraParams={{ category: category_id }}
                            storeEndpoint={getPathParam([COMPANY, loggedCompany.id, COMPANY_MEDIA])}
                            updateEndpoint={getPathParam([COMPANY, loggedCompany.id, COMPANY_MEDIA, ':id'])}
                            baseResult={{ total_rows: 0, company_media: [] }}
                            resultLabel={'company_media'}
                            title={t("app.catalog.attachments")}
                            showHeader={true}
                            mobRow={MobRow}
                            deskRow={DeskRow}
                            addForm={AddForm}
                            icon={PaperClipIcon}
                            addFormLabel={t("app.catalog.add", { type: t("app.catalog.attachment") })}
                            btnAddLabel={t("app.catalog.add", { type: t("app.catalog.attachment") })}
                            editForm={EditForm}
                            editTitle={t("app.catalog.attachment")}
                            isActiveEdit={true}
                            isActiveFileViewer={true}
                        />
                    </>

                )
            }
        </>
    );
};


const MobRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);
    let { category_id } = useParams();

    const removeDetail = {
        title: t("app.common.remove_generic_title"),
        message: t("app.common.remove_generic_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, COMPANY_MEDIA, element.id]) + serialize({ category: category_id })
    };

    return (
        <li key={element.id} className="flex items-center gap-4 p-4">
            <div className="flex-grow">
                <div className="pb-4 items-center flex justify-between">
                    <FileCell file={element} canView={true} onOpenFile={() => {
                        extras.onOpenFile(element);
                    }} />
                    <ActionsMenu>
                        <button className={'text-left'} onClick={() => {
                            extras.onEdit(true, element);
                        }}
                        >
                            {t("app.common.edit")}
                        </button>
                        <button className={'text-left'} onClick={() => {
                            extras.onRemove(true, removeDetail);
                        }}
                        >
                            {t("app.common.remove")}
                        </button>
                    </ActionsMenu>

                </div>
                <div className="flex flex-col">
                    <CardDetail title={t("app.catalog.note")}>
                        {element.file_note}
                    </CardDetail>
                </div>
            </div>
        </li>
    );
};

const DeskRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);
    let { category_id } = useParams();

    const removeDetail = {
        title: t("app.common.remove_generic_title"),
        message: t("app.common.remove_generic_description"),
        endpoint: getPathParam([COMPANY, loggedCompany.id, COMPANY_MEDIA, element.id]) + serialize({ category: category_id })
    };


    return (
        <tr key={element.id}>
            <TableDetail extraClass="max-w-sm">
                <div className="flex items-center space-x-3 lg:pl-2">
                    <div className="truncate hover:text-gray-600">
                        <FileCell file={element} canView={true} onOpenFile={() => {
                            extras.onOpenFile(element);
                        }} />
                    </div>
                </div>
            </TableDetail>

            <TableDetail>
                {element.file_note}
            </TableDetail>

            <TableDetail>
                <ActionsMenu>
                    <button className={'text-left'} onClick={() => {
                        extras.onEdit(true, element);
                    }}
                    >
                        {t("app.common.edit")}
                    </button>
                    <button className={'text-left'} onClick={() => {
                        extras.onRemove(true, removeDetail);
                    }}
                    >
                        {t("app.common.remove")}
                    </button>
                </ActionsMenu>
            </TableDetail>
        </tr>
    );
};

const AddForm = ({ exception, isLoading, onSubmitData }) =>  {
    const { loggedCompany } = useSelector((state) => state.user);
    let { category_id } = useParams();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        watch
    } = useForm();

    const { t } = useTranslation();

    const onSubmit = async (data) => {
        data['tmp_category'] = category_id;
        onSubmitData(data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-4">
                <div className="grid md:grid-cols-1 lg:grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-1">
                    <UploadFile
                        onUpload={(data) => {
                            setValue('file', {media_url: data.media_url});
                            setValue('file_name', data.detail.name);
                        }}
                        onCancel={(r) => {
                            if(r){
                                setValue('file', undefined);
                                setValue('file_name', undefined);
                            }
                        }}
                        onException={(e) => {}}
                        isLoading={false}
                        label={t("app.catalog.upload_attachment")}
                    />

                    <InputText
                        id={'file_note'}
                        type={'text'}
                        errors={errors.file_name}
                        input={{ ...register("file_name", { required: false }) }}
                        label={t("app.catalog.name")}
                    />

                    <Controller
                        name="category"
                        rules={{ required: false }}
                        control={control}
                        render={({
                            field: { onChange, value, name },
                            fieldState: { error },
                        }) => (
                            <DynamicSearch
                                selectLabel={t("app.configuration.folder")}
                                endpoint={getPathParam([COMPANY, loggedCompany.id, COMPANY_MEDIA_CATEGORIES])}
                                responseLabel={'categories'}
                                label={'name'}
                                errors={error}
                                value={value}
                                name={name}
                                onChange={onChange}
                                onResponse={(r) => {
                                    if (r.find(r => r.id === category_id)) {
                                        setValue('category', r.find(r => r.id === category_id))
                                    }
                                }}
                            />
                        )}
                    />


                  


                    <InputText
                        id={'file_note'}
                        type={'text'}
                        errors={errors.file_note}
                        input={{ ...register("file_note", { required: false })}}
                        label={t("app.catalog.note")}
                    />

                    



                </div>
            </div>

            {/* Warning */}
            {exception && (
                <Warning message={exception} />
            )}


            {
                watch('file') && (
                    <div className="flex before:flex-1 items-center justify-between mt-6">
                        <InputSubmit
                            isLoading={isLoading}
                            label={t("app.catalog.add")}
                        />
                    </div>
                )
            }


        </form>
    );
}

const EditForm = ({ data, exception, onSubmitData, isEditLoading }) =>  {

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control
    } = useForm();

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { loggedCompany } = useSelector((state) => state.user);
    let { category_id } = useParams();


    useEffect(() => {
        if(data !== null){
            for (const k in data) {
                if (typeof data[k] !== "object") {
                    setValue(k, data[k]);
                }
            }
        }
    }, [setValue, data]);

    const onSubmit = async (data) => {
        data['tmp_category'] = category_id;
        onSubmitData(data);
    };





    useEffect(() => {
        dispatch(fetchCountries());
    }, [dispatch]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <div className="space-y-4 pt-4 pb-5">

                        <UploadFile
                            onUpload={(data) => {
                                setValue('file', { media_url: data.media_url });
                                setValue('file_name', data.detail.name);
                            }}
                            onCancel={(r) => {
                                if (r) {
                                    setValue('file', undefined);
                                    setValue('file_name', undefined);
                                }
                            }}
                            onException={(e) => { }}
                            isLoading={false}
                            label={t("app.catalog.upload_attachment")}
                        />

                        <InputText
                            id={'file_name'}
                            type={'text'}
                            errors={errors.file_name}
                            input={{ ...register("file_name", { required: false }) }}
                            label={t("app.catalog.name")}
                        />

                        <Controller
                            name="category"
                            rules={{ required: false }}
                            control={control}
                            render={({
                                field: { onChange, value, name },
                                fieldState: { error },
                            }) => (
                                <DynamicSearch
                                    selectLabel={t("app.configuration.folder")}
                                    endpoint={getPathParam([COMPANY, loggedCompany.id, COMPANY_MEDIA_CATEGORIES])}
                                    responseLabel={'categories'}
                                    label={'name'}
                                    errors={error}
                                    value={value}
                                    name={name}
                                    onChange={onChange}
                                    onResponse={(r) => {
                                        if (r.find(r => r.id === category_id)) {
                                            setValue('category', r.find(r => r.id === category_id))
                                        }
                                    }}
                                />
                            )}
                        />


                        <InputText
                            id={'file_note'}
                            type={'text'}
                            errors={errors.file_note}
                            input={{ ...register("file_note", { required: false })}}
                            label={t("app.catalog.note")}
                        />

                    </div>
                </div>

                {/* Warning */}
                {exception && (
                    <Warning message={exception} />
                )}

                <div className="mt-3">
                    <InputSubmit
                        isLoading={isEditLoading}
                        isFullWith={true}
                        label={t("app.form.save")}
                    />
                </div>

            </form>

        </>
    );
}



export default CompanyMedia;

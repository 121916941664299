import React, {useEffect, useState} from "react";
import InputText from "../../../components/common/form/input-text";
import InputSubmit from "../../../components/common/form/input-submit";
import { Controller, useForm } from "react-hook-form";
import FormPopup from "../../../components/common/popup/form-popup";
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
import { Warning } from "../../../components/common/alert/banner";
import {CATALOG, PRODUCT, PRODUCT_DETAIL} from "../../endpoints";
import { getWebPathParam} from "../../../utils/converter";
import { useNavigate } from "react-router-dom";
import { putProductClone } from "../../../api/catalog";
import {hasPermissionFor} from "../../../components/partials/restricted/base-permission-wrapper";
import {permissionGroup, permissionSpecific, sp} from "../../../constants/permissions";
import {fetchCountries, fetchUnits} from "../../../reducers/configReducer";
import SubscriptionWrapper from "../../../components/partials/restricted/subscription-wrapper";
import {subscriptionUsageLimits} from "../../../reducers/subscriptionReducer";
import { refreshFilters } from "../../../reducers/userCompanyReducer";
import GenerateEncoding from "./edit/detail/generate-encoding";
import { Encoding } from "./edit/detail/product-details";
import InputToggle from "../../../components/common/form/input-toggle";
import ProductCell from "../../../components/common/table/product-cell";

export default function CloneProduct({ isOpen, setIsOpen, productDetail }) {
  const { loggedCompany } = useSelector((state) => state.user);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
      setValue
  } = useForm();
  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [isCreateProductLoading, setCreateProductLoading] = useState(false);
  const [createProductException, setCreateProductException] = useState(false);
  const { permissions } = useSelector((s) => s.userCompany);

  const canEncode = hasPermissionFor(permissions, permissionGroup.PRODUCT, permissionSpecific.PRODUCT_GENERATE_ENCODE);
  const [isOpenEncoding, setOpenEncoding] = useState(false);
  const [isOpenManage, setOpenManage] = useState(false);
  const [encodingData, setEncodingData] = useState({});

   
  const cloneProduct = async (data) => {
    if (loggedCompany) {

      setCreateProductLoading(true);

        putProductClone({ company_id: loggedCompany.id, product_id: productDetail.id, data })
        .then((response) => {
          // console.log(response);
          setCreateProductLoading(false);
            dispatch(subscriptionUsageLimits(loggedCompany.id));
         

            dispatch(refreshFilters({ company_id: loggedCompany.id }));

            navigate(getWebPathParam([CATALOG, PRODUCT, response.id, PRODUCT_DETAIL]));
          setIsOpen(false)
        })
        .catch((e) => {
          setCreateProductException(e.message);
          setCreateProductLoading(false);
        });
    }
  };

  useEffect(() => {
      dispatch(fetchCountries());
      dispatch(fetchUnits());
  }, [loggedCompany, navigate, dispatch, setValue]);


  const onSubmit = async (data) => {
      if (data['product_reference'] !== data['product_reference_tmp']) {
          delete data['generated_string'];
          delete data['encode_formatted'];
      }
      await cloneProduct(data);
  };

  return (
      <FormPopup
          title={t("app.catalog.clone_product")}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
      >
          <SubscriptionWrapper requiredPermission={sp.CATALOG_INTERNAL_PACKAGE} renderBaseOnFail={true} checkUsage={true}>
              <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="grid grid-cols-1 md:grid-cols-1 gap-3">

                      <h2 className="mb-2 border p-2 rounded-md">
                          <ProductCell product={productDetail} isActiveManufacture={true} />   
                      </h2>

                      <div>
                          <InputText
                              isRequired={true}
                              id={"product_reference"}
                              type={"text"}
                              errors={errors.product_reference}
                              input={{ ...register("product_reference", { required: true }) }}
                              label={t("app.products.product_code")}
                          />
                          {
                              (canEncode) && (
                                  <div className={"flex justify-end"}>
                                      <div className="mt-1 flex items-center text-xs text-indigo-600 underline cursor-pointer"
                                          onClick={() => {
                                              setOpenEncoding(true)
                                          }}>
                                          {t("app.catalog.encode_product")}
                                      </div>
                                  </div>
                              )
                          }
                      </div>


                      <h2 className="text-sm text-gray-900 pb-1 border-b font-semibold">
                          {t("app.catalog.clone_product_items")}
                      </h2>

                      <Controller
                          name={"product_rate"}
                          rules={{ required: false }}
                          control={control}
                          render={({
                              field: { onChange, value, name },
                              fieldState: { error },
                          }) => (
                              <InputToggle
                                  label={t("app.products.pricing_title")}
                                  description={''}
                                  onChange={(e) => {
                                      onChange(e)
                                  }}
                                  defaultChecked={value}
                                  errors={error}
                              />
                          )}
                      />

                      <Controller
                          name={"product_package"}
                          rules={{ required: false }}
                          control={control}
                          render={({
                              field: { onChange, value, name },
                              fieldState: { error },
                          }) => (
                              <InputToggle
                                  label={t("app.products.package_title")}
                                  description={''}
                                  onChange={(e) => {
                                      onChange(e)
                                  }}
                                  defaultChecked={value}
                                  errors={error}
                              />
                          )}
                      />

                      <Controller
                          name={"alternative_product"}
                          rules={{ required: false }}
                          control={control}
                          render={({
                              field: { onChange, value, name },
                              fieldState: { error },
                          }) => (
                              <InputToggle
                                  label={t("app.catalog.alternative")}
                                  description={''}
                                  onChange={(e) => {
                                      onChange(e)
                                  }}
                                  defaultChecked={value}
                                  errors={error}
                              />
                          )}
                      />

                      <Controller
                          name={"translation"}
                          rules={{ required: false }}
                          control={control}
                          render={({
                              field: { onChange, value, name },
                              fieldState: { error },
                          }) => (
                              <InputToggle
                                  label={t("app.products.translation")}
                                  description={''}
                                  onChange={(e) => {
                                      onChange(e)
                                  }}
                                  defaultChecked={value}
                                  errors={error}
                              />
                          )}
                      />


                  </div>

                  {/* Warning */}
                  {createProductException && (
                      <Warning message={createProductException} />
                  )}

                  <div className="flex before:flex-1 items-center justify-between mt-6">
                      <InputSubmit
                          isLoading={isCreateProductLoading}
                          label={t("app.catalog.clone_product")}
                      />
                  </div>
              </form>


              {
                  canEncode && isOpenEncoding && (
                      <Encoding
                          isOpen={isOpenEncoding}
                          setIsOpen={setOpenEncoding}
                          onGenerate={(r) => {
                              setEncodingData(r);
                              setOpenManage(true);
                          }}
                      />
                  )
              }

              {
                  (canEncode) && (
                      <GenerateEncoding
                          isOpenPanel={isOpenManage}
                          setOpenPanel={setOpenManage}
                          encodingData={encodingData}
                          onInsertCode={(string, code, formatted) => {
                              setValue('product_reference', code);
                              setValue('product_reference_tmp', code);
                              setValue('generated_string', string);
                              setValue('encode_formatted', formatted);
                              setOpenManage(false);
                          }}
                      />
                  )
              }
          </SubscriptionWrapper>
      </FormPopup>
  );
}

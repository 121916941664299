import { Popover, Transition } from '@headlessui/react'
import React, {Fragment} from 'react'
import {InformationCircleIcon} from "@heroicons/react/outline";
import NumberFormat from "react-number-format";
import {getFormattedDate} from "../../../utils/timeUtils";
import {useTranslation} from "react-i18next";

export default function PriceHistory({prices}) {
    const { t } = useTranslation();


    return (

        <Popover>
            {({ open }) => (
                <>
                    <Popover.Button>
                        <InformationCircleIcon className={"cursor-pointer text-blue-400 h-5 w-5"} />
                    </Popover.Button>




                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel className="absolute z-10 mt-3 w-screen max-w-xs transform px-4 sm:px-0 z-50">
                            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                <div className="relative  bg-white">

                                    <div className=" bg-white  rounded-sm border border-slate-200">
                                        <nav className="overflow-y-auto max-h-80" aria-label="Directory">
                                            <PricingList prices={prices.orders} label={t("app.quotations.history_orders")} />
                                            <PricingList prices={prices.quotations} label={t("app.quotations.history_quotation")} />
                                            <PricingList prices={prices.container_products} label={t("app.quotations.history_container_products")} />
                                        </nav>
                                    </div>

                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    )
}


const PricingList = ({prices, label}) => {

    return (
            prices.length > 0 ? (
                <div className="relative">
                    <div className="z-10 sticky top-0 border-b border-gray-200 bg-gray-50 p-2 text-sm font-medium text-gray-900">
                        <h3>{label}</h3>
                    </div>
                    <ul className="relative z-0 divide-y divide-gray-200">
                        {prices.map((price, index) => (
                            <li key={index} className=" px-2 bg-white">
                                <div className="py-2 flex items-center justify-between space-x-3">
                                    <div className="min-w-0 flex-1 flex items-center space-x-3">
                                        <div className="min-w-0 flex-1">
                                            <p className="text-sm font-medium text-gray-600 truncate">
                                                {getFormattedDate(price.date)}
                                            </p>
                                            <p className="text-xs text-gray-500">
                                                {price?.reference ?? ''}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-4 space-x-3 flex md:mt-0 md:ml-4">
                                        <NumberFormat
                                            value={price.price}
                                            displayType={"text"}
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            decimalScale={4}
                                            suffix={' €'}
                                        />
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : (
                <></>
            )

    )
}


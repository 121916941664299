import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {  useDispatch, useSelector } from "react-redux";
import { BOOKING, COMPANY, CONFRIM, FORWAREDER_TRAFFIC_LINES, INVALIDATE, TRAFFIC_LINE, TRAFFIC_LINES } from "../../../endpoints";
import { getPathParam, getVesselParam } from "../../../../utils/converter";
import { useTranslation } from "react-i18next";
import InputFormattedNumber from "../../../../components/common/form/input-formatted-number";
import { getElement, putElement } from "../../../../api/config";
import Initials from "../../../../components/common/avatar/initials";
import toast from "react-hot-toast";
import { PlusIcon, TrashIcon, PencilIcon, CollectionIcon, ViewListIcon } from "@heroicons/react/outline";
import { TrashButton, WhiteButton } from "../../../../components/layout/page-header";
import ListSection from "../../../../components/layout/list-section";
import TableDetail from "../../../../components/common/list/table-detail";
import { getFormattedDate } from "../../../../utils/timeUtils";
import { debounce } from 'lodash';
import ConfirmPopup from "../../../../components/common/popup/confirm-popup";
import ManageForwarder from "./manage-forwarder";
import UserInitial from "../../../../components/common/table/user-initial";
import UsersToolbar from "../../../../components/common/card/users-toolbar";
import InputDate from "../../../../components/common/form/input-date";
import InputSubmit from "../../../../components/common/form/input-submit";
import { RadioGroup, Transition } from "@headlessui/react";
import { Warning } from "postcss";
import { fetchContainerTypes, fetchShippingLines } from "../../../../reducers/configReducer";
import InputShippingLineSelect from "../../../../components/common/form/input-shipping-line-select";
import TooltipItem from "../../../../components/common/button/tooltip";
import ConfirmTextPopup from "../../../../components/common/popup/confirm-text-popup";
import NumberFormat from "react-number-format";
import CardDetail from "../../../../components/common/list/card-detail";
import BasePermissionWrapper, { hasPermissionFor } from "../../../../components/partials/restricted/base-permission-wrapper";
import { getPermission, permissionGroup, permissionType } from "../../../../constants/permissions";
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


function generateContainerDescription(lista, inputString) {
    // Conta le occorrenze dei codici nell'input
    const counts = inputString.split(',').reduce((acc, code) => {
        acc[code] = (acc[code] || 0) + 1;
        return acc;
    }, {});

    // Mappa per ottenere i nomi dei container
    const containerMap = lista.reduce((acc, item) => {
        if (item.internalCode) {
            acc[item.internalCode] = item.name;
        }
        return acc;
    }, {});

    // Crea la stringa finale
    const result = Object.keys(counts).map(code => {
        const count = counts[code];
        const containerName = containerMap[code];
        if (containerName) {
            // Estrai il nome leggibile dal `name`
            const [size, type] = containerName.split(' ');
            return `${count} Container ${size} ${type}`;
        }
        return '';
    }).filter(Boolean); // Rimuovi eventuali stringhe vuote

    // Unisci il tutto con le virgole
    return result.join(', ');
}

function groupByForwarder(list) {
    // Utilizzare una mappa per mantenere le informazioni di ogni spedizioniere
    const forwarderMap = new Map();

    list.forEach((item) => {
        const forwarderName = item.forwarder.id + item?.shipping_line?.id ?? '-';
        // Se il forwarder non esiste nella mappa, aggiungilo con le sue informazioni e la lista dei rates
        if (!forwarderMap.has(forwarderName)) {
            forwarderMap.set(forwarderName, {
                forwarder: item.forwarder,
                main_rate: null,
                rates: [],
            });
        }

        // Aggiungi il rate alla lista dei rates
        const forwarderData = forwarderMap.get(forwarderName);
        forwarderData.rates.push(item);

        // Aggiorna il main_rate con il primo elemento valido (invalidate === false)
        if (item.invalidate === false && forwarderData.main_rate === null) {
            forwarderData.main_rate = item;
        }  
    });


    forwarderMap.forEach((value) => {
        if (value.main_rate === null && value.rates.length > 0) {
            value.main_rate = value.rates[0];
        }
    });

    // Costruisci l'oggetto di output con main_rate e rates
    let obj = [];
    forwarderMap.forEach((value) => {
        if (value.main_rate) {
            value.rates = value.rates.filter(rate => rate.id !== value.main_rate.id);
            let data = { ...value.main_rate, rates: value.rates };
            obj.push(data);
        }
    });

    // Restituisci un array contenente le informazioni del forwarder, il rate principale e la lista degli altri rates
    return obj;
}


// FORWARDER DETAILS
export const NegotiationCard = ({ combinations, bookingDetail, onUpdateData }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { loggedCompany } = useSelector((state) => state.user);
    const { shippingLinesList, isShippingLinesListLoading, containerTypesList } = useSelector((state) => state.config);
    const { permissions } = useSelector((s) => s.userCompany);


    // DETAILS
    const [isLoading, setLoading] = useState(false);
    const [query, setQuery] = useState("");
    const [negotiations, setNegotiations] = useState([]);
    const [needRefresh, setNeedRefresh] = useState(false);

    //Manage Forwarder
    const [isOpenManageForwarder, setOpenManageForwarder] = useState(false);



    // REMOVE
    const [isOpenConfirm, setOpenConfirm] = useState(false);
    const [confirmDetail, setConfirmDetail] = useState({});





    const canEdit = hasPermissionFor(permissions, permissionGroup.FORWAREDER_TRAFFIC_LINE, permissionType.EDIT);
    const canCreate = hasPermissionFor(permissions, permissionGroup.FORWAREDER_TRAFFIC_LINE, permissionType.CREATE);
    const catRemove = hasPermissionFor(permissions, permissionGroup.FORWAREDER_TRAFFIC_LINE, permissionType.DELETE);


    useEffect(() => {
        dispatch(fetchShippingLines());
        dispatch(fetchContainerTypes());
    }, [dispatch]);


    let tlType = combinations.length > 0 ? combinations[0].configuration_type : '';



    const listTypes = [
        { type: 'list', name: '', icon: ViewListIcon },
        { type: 'group', name: '', icon: CollectionIcon },
    ];
    const [listType, setListType] = useState(localStorage.getItem('listType') ? listTypes.find(r => r.type === localStorage.getItem('listType')) : listTypes[0])




    // TABLE
    const [sortBy, setSortBy] = useState({ accessor: null, type: null, id: null, position: 0 });
    const [selectAll, setSelectAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);


    const handleSelectAll = () => {
        if (negotiations.length > 0) {
            setSelectAll(!selectAll);
            setIsCheck((listType.type === 'group' ? groupByForwarder(negotiations) : negotiations).filter(r=> r.invalidate === false));
            if (selectAll) {
                setIsCheck([]);
            }
        }
    };

    useEffect(() => {
        setSelectAll(false);
        setIsCheck([])
    }, [listType]);

    
    const handleClick = e => {
        const { id, checked } = e.target;
        setSelectAll(false);
        let list = (listType.type === 'group' ? groupByForwarder(negotiations) : negotiations).find(r => r.id === id);
        setIsCheck([...isCheck, list]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item.id !== id));
        }
    };

    const onUpdate = async (data, id) => {
        data['id'] = id;
        if (bookingDetail) {
            putElement(getPathParam([BOOKING, bookingDetail.id, TRAFFIC_LINE, id]), data)
                .then(response => {
                    setNegotiations(response);
                    toast.success(t("app.tasks.update_success"));
                }).catch(e => {
                    toast.error(e.message);
                });
        }
    };



    const columns = [
        {
            header: t("app.fulfillment.forwarder"),
            id: 'business_name',
            width: canEdit ? '23%' :'18%',
            extraClass: 'p-2 text-xxs',
            accessor: r => r.forwarder.name
        },
        {
            header: tlType === 'container' ? 'Container' : t("app.booking.cbm_cost"),
            id: 'tau',
            width: '10%',
            position: 'center',
            extraClass: 'p-2 text-xxs',
            accessor: r => r.tl_type === 'container' ? r.total_tao_config.split(',').length : ((r?.total_cost ?? 0) / r.total_volume_config)
        }, 
        {
            header: t("app.booking.total_cost_small"),
            id: 'tau',
            width: '12%',
            position: 'center',
            extraClass: 'p-2 text-xxs',
            accessor: r => r.total_cost
        },
        {
            header: t("app.booking.transport_cost_small"),
            id: 'transport_cost',
            width: '12%',
            position: 'center',
            extraClass: 'p-2 text-xxs',
            accessor: r => r.transport_cost
        },
        {
            header: t("app.booking.transit_time"),
            id: 'transit_time',
            width: '12%',
            position: 'center',
            extraClass: 'p-2 text-xxs',
            accessor: r => r.transit_time
        },
        {
            header: t("app.booking.free_time"),
            id: 'free_time',
            width: '12%',
            position: 'center',
            extraClass: 'p-2 text-xxs',
            accessor: r => r.free_time
        },
        {
            header: t("app.booking.expire_date"),
            id: 'expire_date',
            width: '10%',
            position: 'center',
            extraClass: 'p-2 text-xxs',
            accessor: r => new Date(r.expire_date).getTime()
        },
        {
            header: t("app.quotations.creator"),
            id: 'expire_date',
            width: '5%',
            position: 'center',
            extraClass: 'p-2 text-xxs',
            accessor: r => r.user.fullname
        },
        {
            header: "",
            extraClass: 'p-2',
            width: '4%',
            accessor: null
        }
    ].filter(Boolean);


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchData() {
            setLoading(true);
            try {
                const res = await getElement(getPathParam([BOOKING, bookingDetail.id, TRAFFIC_LINES]), signal, { query: query, tl_type: tlType });
                setNegotiations(res);
            } catch (e) {
                setLoading(false);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [bookingDetail, needRefresh, query]);
  
    return (
        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.FORWAREDER_TRAFFIC_LINE, permissionType.LIST)]} renderBaseOnFail={true}>

            <ListSection
                pageLimit={1000}
                isAbsolute={true}
                checkoxWidth={'5%'}
                title={t("app.booking.page_title")}
                columns={columns}
                totalRows={negotiations.length}
                list={listType.type === 'group' ? groupByForwarder(negotiations) : negotiations}
                onSearch={setQuery}
                isLoading={isLoading}
                mobileRow={MobRow}
                hasCheckbox={canEdit}
                selectAll={selectAll}
                handleSelectAll={handleSelectAll}
                desktopRow={DeskRow}
                isTableSort={true}
                sortBy={sortBy}
                onSortBy={(n) => {
                    setSortBy(n);
                }}
                activeDynamicFilter={false}
                extras={{
                    canEdit,
                    isCheck,
                    handleClick,
                    bookingDetail,
                    shippingLinesList,
                    isShippingLinesListLoading,
                    containerTypesList,
                    listType,
                    onRefreshData: (r) => {
                        setNegotiations(r);
                    },
                    onUpdate: async (fild, value, id) => {
                        await onUpdate({ [fild]: value }, id);
                    }
                }}
            >

                <div className="inline-flex items-center justify-center">
                    {
                        ((isCheck.length > 0) && catRemove) && (
                            <>
                                <div className={"mr-3"}>
                                    <TrashButton
                                        onClick={() => {
                                            const details = {
                                                type: 'general',
                                                title: t("app.common.remove_generic_title"),
                                                message: t("app.common.remove_generic_description"),
                                                endpointData: { forwarder_traffic_lines: isCheck.map(item => item.id).join(",") },
                                                endpoint: getPathParam([COMPANY, loggedCompany.id, FORWAREDER_TRAFFIC_LINES]),
                                            };
                                            setOpenConfirm(true);
                                            setConfirmDetail(details);
                                        }}
                                        icon={
                                            <TrashIcon
                                                className="w-4 h-4"
                                                aria-hidden="true"
                                            />
                                        }
                                        text={''}
                                    />
                                </div>
                               
                            </>

                        )
                    }

                    {
                        (isCheck.length === 1) && (
                            <>
                                <div className={"mr-3"}>
                                    <WhiteButton
                                        onClick={() => {
                                            const details = {
                                                type: 'confirm',
                                                title: t("app.booking.confirm_title"),
                                                message: t("app.booking.confirm_description"),
                                                endpoint: getPathParam([BOOKING, bookingDetail.id, TRAFFIC_LINE, isCheck[0].id, CONFRIM]),
                                            };
                                            setOpenConfirm(true);
                                            setConfirmDetail(details);
                                        }}
                                        text={t("app.booking.confirm_forworder")}
                                    />
                                </div>

                            </>

                        )
                    }

                    <fieldset className="min-w-36">
                        <RadioGroup value={listType} onChange={setListType} className="flex items-center  rounded-md ring-1 ring-gray-200">
                            {listTypes.map((option) => (
                                <RadioGroup.Option
                                    key={option.name}
                                    value={option}
                                    className={({ active, checked }) =>
                                        `cursor-pointer flex items-center justify-center   p-2 text-sm  rounded-md font-semibold     sm:flex-1
                                        ${(listType.type === option.type) ? 'bg-blue-1000 text-white' : 'bg-white text-blue-1000 hover:bg-gray-50'}`
                                    }
                                >
                                    <option.icon className="h-5 w-5" />
                                </RadioGroup.Option>
                            ))}
                        </RadioGroup>
                    </fieldset>

                    

                    {
                        canCreate && (
                            <div className={"ml-3"}>
                                <WhiteButton
                                    onClick={() => {
                                        setOpenManageForwarder(true);
                                    }}
                                    icon={
                                        <PlusIcon
                                            className="w-4 h-4"
                                            aria-hidden="true"
                                        />
                                    }
                                    text={t('app.booking.add_forwarder')}
                                />
                            </div>
                        )
                    }

               

                </div>


            </ListSection>

            {isOpenConfirm && (
                <ConfirmPopup
                    isOpen={isOpenConfirm}
                    setIsOpen={() => {
                        setOpenConfirm(false);
                    }}
                    detail={confirmDetail}
                    setDetail={(r) => {
                        if (confirmDetail.type === 'confirm'){
                            onUpdateData(r)
                        }else{
                            setIsCheck([])
                            setNeedRefresh(!needRefresh)
                        }
                    }}
                />
            )}
            
            {
                (bookingDetail !== null) && (
                    <ManageForwarder
                        isOpen={isOpenManageForwarder}
                        setIsOpen={setOpenManageForwarder}
                        bookingDetail={bookingDetail}
                        negotiationList={negotiations}
                        onRefresh={(r) => {
                            setNeedRefresh(!needRefresh)
                        }}
                    />
                )
            }

           

        </BasePermissionWrapper>
    )
}

const DeskRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const { company } = useSelector((state) => state.userCompany);
    const isCheck = extras.isCheck.filter(r => r.id === element.id).length > 0;
    
    
    let trafficLineDetail = element;

    const { watch, control, setValue } = useForm({ mode: "onBlur" });
    // get Shipping
    const [isLoading, setLoading] = useState(false);
    const [exception, setException] = useState(false);
    const [isShowing, setIsShowing] = useState(false)
    const [isShowingGroup, setIsShowingGroup] = useState(false)

    //Confirm
    const [isOpenConfirmValidation, setOpenConfirmValidation] = useState(false);
    const [formData, setFormData] = useState(false);


    // eslint-disable-next-line
    const debouncedPrice = useCallback(debounce((reference) => {
        extras.onUpdate('transport_cost', reference, element.id);
    }, 1000), []);

    // eslint-disable-next-line
    const debouncedFreeTime = useCallback(debounce((reference) => {
        extras.onUpdate('free_time', reference, element.id);
    }, 1000), []);

    // eslint-disable-next-line
    const debouncedTransitTime = useCallback(debounce((reference) => {
        extras.onUpdate('transit_time', reference, element.id);
    }, 1000), []);
    

    useEffect(() => {
        if (watch('transport_cost_table') !== trafficLineDetail.transport_cost && watch('transport_cost_table')) {
            debouncedPrice(watch('transport_cost_table'));
        }
        // eslint-disable-next-line
    }, [watch('transport_cost_table')]);

    useEffect(() => {
        if (watch('free_time_table') !== trafficLineDetail.free_time && watch('free_time_table')) {
            debouncedFreeTime(watch('free_time_table'));
        }
        // eslint-disable-next-line
    }, [watch('free_time_table')]);


    useEffect(() => {
        if (watch('transit_time_table') !== trafficLineDetail.transit_time && watch('transit_time_table')) {
            debouncedTransitTime(watch('transit_time_table'));
        }
        // eslint-disable-next-line
    }, [watch('transit_time_table')]);


    useEffect(() => {
        if (trafficLineDetail.transit_time) {
            setValue('transit_time_table', trafficLineDetail.transit_time);
        }

        if (trafficLineDetail.transport_cost) {
            setValue('transport_cost_table', trafficLineDetail.transport_cost);
        }

        if (trafficLineDetail.free_time) {
            setValue('free_time_table', trafficLineDetail.free_time);
        }
    }, [trafficLineDetail, setValue]);

    
    const onUpdate = async (data) => {
        setOpenConfirmValidation(true);
        setFormData(data);
    };


    const onInvalidateData = async (data) => {
        setLoading(true);
        setException(false);
        data['shipping_line'] = { id: data.shipping_line.id}
        putElement(getPathParam([BOOKING, extras.bookingDetail.id, TRAFFIC_LINE, element.id, INVALIDATE]), data)
            .then(response => {
               extras.onRefreshData(response);
                setLoading(false);
            }).catch(e => {
                setException(e.message);
                setLoading(false);
            });
    };

    const onUpdateData = async (data) => {
        data['id'] = element.id;
        data['shipping_line'] = { id: data.shipping_line.id }
        setLoading(true);
        setException(false);
        putElement(getPathParam([BOOKING, extras.bookingDetail.id, TRAFFIC_LINE, element.id]), data)
            .then(response => {
                extras.onRefreshData(response);
                setLoading(false);
            }).catch(e => {
                setException(e.message);
                setLoading(false);
            });
    };


    return (
        <>
            <tr key={element.id} className={classNames(
                isCheck ? 'bg-solitude-100 ' : '',
                element.invalidate ? 'bg-red-100 ' : '',
            )}>

                {
                    extras.canEdit && (
                        <TableDetail padding={'p-2'} extraClass=" relative">

                            {
                                !element.invalidate && (
                                    <>
                                        {
                                            isCheck && (
                                                <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                                            )
                                        }
                                        <input type="checkbox"
                                            id={element.id}
                                            onChange={extras.handleClick}
                                            checked={isCheck}
                                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                                        />
                                    </>
                                )
                            }

                        </TableDetail>
                    )
                }

              
                <TableDetail padding={'p-2'} extraClass={classNames(
                    "max-w-sm"
                )}>

                        <span className="flex items-center">
                            <Initials
                                color={element?.forwarder?.color ?? ''}
                                name={element?.forwarder?.name ?? ''}
                                isVerified={false}
                            />
                            <div className="text-left">
                                <p className="truncate text-xs text-gray-500">{element?.reference ?? ''}</p>
                                <p className="flex-wrap  whitespace-normal text-sm font-medium text-gray-900">{element?.forwarder?.name ?? ''}</p>
                                <p className="truncate text-xs text-gray-500">{element?.shipping_line?.name ?? ''}</p>
                            </div>
                        </span>      
                </TableDetail>

                <TableDetail padding={'p-2'} extraClass={"text-center"}>

                    {
                        trafficLineDetail.tl_type === 'container' ? (
                            <TooltipItem position={"top"} tooltipsText={generateContainerDescription(extras.containerTypesList, trafficLineDetail.total_tao_config)}>
                                {trafficLineDetail.total_tao_config.split(',').length}
                            </TooltipItem>
                        ) : (
                                <NumberFormat
                                    value={(element?.total_cost ?? 0) / element.total_volume_config}
                                    displayType={"text"}
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    decimalScale={2}
                                    suffix={" " + (company?.currency?.currency_symbol ?? '')}
                                />
                        )
                    }
                  
                </TableDetail>


                <TableDetail padding={'p-2'} extraClass="  text-center">
                    <NumberFormat
                        value={element?.total_cost ?? 0}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        suffix={" " + (company?.currency?.currency_symbol ?? '')}
                    />
                </TableDetail>

                <TableDetail padding={'p-2'} extraClass=" text-center">

                    {
                        element.invalidate ? (
                            <NumberFormat
                                value={element?.transport_cost ?? 0}
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                decimalScale={2}
                                suffix={" " + (company?.currency?.currency_symbol ?? '')}
                            />
                        ) : (
                            <Controller
                                name="transport_cost_table"
                                rules={{ required: true }}
                                control={control}
                                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                    <InputFormattedNumber
                                        isShowPlaceholder={false}
                                        isShowLabel={false}
                                        name={name}
                                        isDisabled={!extras.canEdit}
                                        customClass={"form-input text-center"}
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        prefix={''}
                                        suffix={" " + (company.currency?.currency_symbol ?? '')}
                                        errors={error}
                                    />
                                )}
                            />
                        )
                    }

                    
                </TableDetail>


                <TableDetail padding={'p-2'} extraClass=" text-center">

                    {
                        element.invalidate ? (
                            <NumberFormat
                                value={element?.transit_time ?? 0}
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                decimalScale={2}
                                suffix={" " + (element.transit_time ? (element.transit_time === 1 ? t("app.booking.day") : t("app.booking.days")) : "")}
                            />
                        ) : (
                                <Controller
                                    name="transit_time_table"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                        <InputFormattedNumber
                                            isShowPlaceholder={false}
                                            isShowLabel={false}
                                            name={name}
                                            isDisabled={!extras.canEdit}
                                            customClass={"form-input text-center"}
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            prefix={''}
                                            suffix={" " + (watch('transit_time_table') ? (watch('transit_time_table') === 1 ? t("app.booking.day") : t("app.booking.days")) : "")}
                                            errors={error}
                                        />
                                    )}
                                />
                        )
                    }
                    
                </TableDetail>

                <TableDetail padding={'p-2'} extraClass=" text-center">

                    {
                        element.invalidate ? (
                            <NumberFormat
                                value={element?.free_time ?? 0}
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                decimalScale={2}
                                suffix={" " + (element.free_time ? (element.free_time === 1 ? t("app.booking.day") : t("app.booking.days")) : "")}
                            />
                        ) : (
                                <Controller
                                    name="free_time_table"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                        <InputFormattedNumber
                                            isShowPlaceholder={false}
                                            isShowLabel={false}
                                            name={name}
                                            isDisabled={!extras.canEdit}
                                            customClass={"form-input text-center"}
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            prefix={''}
                                            suffix={" " + (watch('free_time_table') ? (watch('free_time_table') === 1 ? t("app.booking.day") : t("app.booking.days")) : "")}
                                            errors={error}
                                        />
                                    )}
                                />
                        )
                    }
                    
                </TableDetail>

                <TableDetail padding={'p-2'} extraClass="  text-center">
                    {getFormattedDate(trafficLineDetail.end_date)}
                </TableDetail>

                <TableDetail padding={'p-2'} extraClass=" text-center">
                   <UsersToolbar users={[element.user]} isActive={true}  className="relative">
                        <div className="flex items-center space-x-2">
                            <div className={"flex flex-shrink-0 -space-x-1"}>
                                <UserInitial user={element.user} ringClass={" ring-yellow-400 "} />
                            </div>
                        </div>
                    </UsersToolbar> 
                </TableDetail>

                <TableDetail padding={'p-2'} extraClass="text-right">

                    <span className="flex items-center">

                    {
                        extras.listType.type === 'group' && (element?.rates ?? []).length > 0 && (
                                <div className="cursor-pointer text-right float-right mr-3" onClick={() => { setIsShowingGroup(!isShowingGroup) }}>
                                <CollectionIcon
                                    className="w-4 h-4 text-gray-900"
                                    aria-hidden="true"
                                />
                            </div>
                        )
                    }


                    <div className="cursor-pointer text-right float-right mr-3" onClick={() => { setIsShowing(!isShowing) }}>

                        <PencilIcon
                            className="w-4 h-4 text-gray-900"
                            aria-hidden="true"
                        />
                   </div>
                   </span>
                </TableDetail>

            </tr>


            <TableForm 
                trafficLineDetail={element}
                isShowing={isShowing}
                onUpdate={onUpdate}
                extras={extras}
                canEdit={extras.canEdit}
                exception={exception}
                isLoading={isLoading}
            />

            {
                isShowingGroup && (element?.rates ?? []).map((rate) => (
                    <tr className={classNames( rate.invalidate ? 'bg-red-100 ' : 'bg-gray-50')}>
                        <TableDetail padding={'p-2'} extraClass={classNames("max-w-sm text-center")} colSpan={2}>
                            <p className="truncate text-sm text-gray-900">{rate?.reference ?? ''}</p>
                        </TableDetail>

                        <TableDetail padding={'p-2'} extraClass={"text-center"}>
                            {
                                trafficLineDetail.tl_type === 'container' ? (
                                    <TooltipItem position={"top"} tooltipsText={generateContainerDescription(extras.containerTypesList, rate.total_tao_config)}>
                                        {rate.total_tao_config.split(',').length}
                                    </TooltipItem>
                                ) : (
                                    <NumberFormat
                                        value={(rate?.total_cost ?? 0) / rate.total_volume_config}
                                        displayType={"text"}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        decimalScale={2}
                                        suffix={" " + (company?.currency?.currency_symbol ?? '')}
                                    />
                                )
                            }

                        </TableDetail>

                        <TableDetail padding={'p-2'} extraClass="  text-center">
                            <NumberFormat
                                value={rate?.total_cost ?? 0}
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                decimalScale={2}
                                suffix={" " + (company?.currency?.currency_symbol ?? '')}
                            />
                        </TableDetail>

                        <TableDetail padding={'p-2'} extraClass=" text-center">
                            <NumberFormat
                                value={rate?.transport_cost ?? 0}
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                decimalScale={2}
                                suffix={" " + (rate?.currency?.currency_symbol ?? '')}
                            />
                        </TableDetail>

                        <TableDetail padding={'p-2'} extraClass=" text-center">

                            <NumberFormat
                                value={rate?.transit_time ?? 0}
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                decimalScale={2}
                                suffix={" " + (rate.transit_time ? (rate.transit_time === 1 ? t("app.booking.day") : t("app.booking.days")) : "")}
                            />

                        </TableDetail>

                        <TableDetail padding={'p-2'} extraClass=" text-center">
                            <NumberFormat
                                value={rate?.free_time ?? 0}
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                decimalScale={2}
                                suffix={" " + (rate.free_time ? (rate.free_time === 1 ? t("app.booking.day") : t("app.booking.days")) : "")}
                            />
                        </TableDetail>

                        <TableDetail padding={'p-2'} extraClass="text-center">
                            {getFormattedDate(rate.end_date)}
                        </TableDetail>

                        <TableDetail padding={'p-2'} extraClass=" text-center" colSpan={2}>
                            <UsersToolbar users={[rate.user]} isActive={true} className="relative">
                                <div className="flex items-center space-x-2">
                                    <div className={"flex flex-shrink-0 -space-x-1"}>
                                        <UserInitial user={rate.user} ringClass={" ring-yellow-400 "} />
                                    </div>
                                </div>
                            </UsersToolbar>
                        </TableDetail>
                    </tr>
                ))
            }

            {(isOpenConfirmValidation) && (
                <ConfirmTextPopup
                    isOpen={isOpenConfirmValidation}
                    setIsOpen={setOpenConfirmValidation}
                    cancelTitle={t("app.common.no")}
                    detail={{ title: t("app.booking.invalidate_title"), message: t("app.booking.invalidate_description") }}
                    onConfirmed={(r) => {
                        onInvalidateData(formData)
                        setOpenConfirmValidation(false);
                    }}
                    onCancel={() => {
                        onUpdateData(formData)
                        setOpenConfirmValidation(false);
                    }}
                />
            )}
        </>
    );
};

const TableForm = ({ trafficLineDetail, isShowing, onUpdate, extras, exception, isLoading, canEdit }) => {
    
    
    const { t } = useTranslation();
    const { company } = useSelector((state) => state.userCompany);
    const { watch, control, handleSubmit, setValue } = useForm({ mode: "onBlur" });


    useEffect(() => {
        const fieldMapping = {
            transport_cost: 'transport_cost',
            free_time: 'free_time',
            transit_time: 'transit_time',
            shipping_line: 'shipping_line',
            start_date: 'start_date',
            end_date: 'end_date',
            extra_stops: 'extra_stops',
            extra_stops_cost: 'extra_stops_cost',
            service_accessory_cost: 'service_accessory_cost',
            administrative_cost: 'administrative_cost',
            delivery_cost: 'delivery_cost',
            insurance_cost: 'insurance_cost',
            departure_date: 'departure_date'
        };

        Object.keys(fieldMapping).forEach(key => {
            if (trafficLineDetail[key]) {
                const value = key === 'shipping_line' ? getVesselParam(trafficLineDetail[key]) : trafficLineDetail[key];
                setValue(fieldMapping[key], value);
            }
        });

        if (trafficLineDetail.transit_time) {
            setValue('transit_time_table', trafficLineDetail.transit_time);
        }

        if (trafficLineDetail.transport_cost) {
            setValue('transport_cost_table', trafficLineDetail.transport_cost);
        }

        if (trafficLineDetail.free_time) {
            setValue('free_time_table', trafficLineDetail.free_time);
        }

    }, [trafficLineDetail, setValue]);

    return (
        <Transition
            as={'tr'}
            className={'bg-gray-50/40'}
            show={isShowing}
            enter="transition-opacity duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >

            <TableDetail padding={'p-2'} colSpan={10}>
                <form onSubmit={handleSubmit(onUpdate)} className="px-4 pt-4 pb-2 text-sm text-gray-500 ">
                    <div className="grid grid-cols-3 gap-6">


                        <InputShippingLineSelect
                            label={t("app.shippings.shipping_line")}
                            placeholder={t("app.shippings.shipping_line")}
                            disabled={!canEdit}
                            isLoadingData={extras.isShippingLinesListLoading}
                            data={(watch('shipping_line') && !extras.shippingLinesList.some(vessel => vessel.id === watch('shipping_line').id)) ? [...extras.shippingLinesList, watch('shipping_line')] : extras.shippingLinesList}
                            selectedItem={watch('shipping_line')}
                            onSelect={(e) => {
                                setValue('shipping_line', e)
                            }}
                        />

                        <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1">
                            <Controller
                                name="transit_time"
                                rules={{ required: true }}
                                control={control}
                                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                    <InputFormattedNumber
                                        label={t("app.booking.transit_time")}
                                        isShowLabel={true}
                                        isRequired={true}
                                        isDisabled={!canEdit}
                                        name={name}
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        prefix={''}
                                        suffix={' ' + (watch("transit_time") > 1 ? t("app.plan.days") : t("app.plan.day"))}
                                        errors={error}
                                    />
                                )}
                            />

                            <Controller
                                name="free_time"
                                rules={{ required: true }}
                                control={control}
                                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                    <InputFormattedNumber
                                        label={t("app.booking.free_time")}
                                        isShowLabel={true}
                                        isDisabled={!canEdit}
                                        name={name}
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        isRequired={true}
                                        prefix={''}
                                        suffix={' ' + (watch("free_time") > 1 ? t("app.plan.days") : t("app.plan.day"))}
                                        errors={error}
                                    />
                                )}
                            />
                        </div>



                        <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1">

                            <Controller
                                name="start_date"
                                rules={{ required: false }}
                                control={control}
                                render={({
                                    field: { onChange, value, name },
                                    fieldState: { error },
                                }) => (
                                    <InputDate
                                        label={t("app.booking.start_date")}
                                        format={"YYYY-MM-D HH:mm:ss"}
                                        placeholder={"Es. 01/12/2023"}
                                        activeMinDate={false}
                                        startDate={value}
                                        disabled={!canEdit}
                                        isShowTime={false}
                                        isRequired={false}
                                        errors={error}
                                        name={name}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                    />
                                )}
                            />

                            <Controller
                                name="end_date"
                                rules={{ required: true }}
                                control={control}
                                render={({
                                    field: { onChange, value, name },
                                    fieldState: { error },
                                }) => (
                                    <InputDate
                                        label={t("app.booking.end_date")}
                                        format={"YYYY-MM-D HH:mm:ss"}
                                        placeholder={"Es. 01/12/2023"}
                                        activeMinDate={false}
                                        startDate={value}
                                        disabled={!canEdit}
                                        isShowTime={false}
                                        isRequired={true}
                                        errors={error}
                                        name={name}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                    />
                                )}
                            />
                        </div>



                        <Controller
                            name="departure_date"
                            rules={{ required: true }}
                            control={control}
                            render={({
                                field: { onChange, value, name },
                                fieldState: { error },
                            }) => (
                                <InputDate
                                    label={t("app.shippings.departure_date")}
                                    format={"YYYY-MM-D HH:mm:ss"}
                                    placeholder={"Es. 01/12/2023"}
                                    activeMinDate={false}
                                    startDate={value}
                                    disabled={!canEdit}
                                    isShowTime={false}
                                    isRequired={true}
                                    errors={error}
                                    name={name}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                />
                            )}
                        />


                        <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1">

                            <Controller
                                name="transport_cost"
                                rules={{ required: true }}
                                control={control}
                                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                    <InputFormattedNumber
                                        label={t("app.booking.transport_cost")}
                                        isShowLabel={true}
                                        isRequired={true}
                                        name={name}
                                        isDisabled={!canEdit}
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        prefix={''}
                                        suffix={" " + (company.currency?.currency_symbol ?? '')}
                                        errors={error}
                                    />
                                )}
                            />

                            <Controller
                                name="service_accessory_cost"
                                rules={{ required: false }}
                                control={control}
                                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                    <InputFormattedNumber
                                        label={t("app.booking.service_accessory_cost")}
                                        isShowLabel={true}
                                        isRequired={false}
                                        isDisabled={!canEdit}
                                        name={name}
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        prefix={''}
                                        suffix={" " + (company.currency?.currency_symbol ?? '')}
                                        errors={error}
                                    />
                                )}
                            />
                        </div>


                        <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-1">

                            <Controller
                                name="extra_stops"
                                rules={{ required: false }}
                                control={control}
                                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                    <InputFormattedNumber
                                        label={t("app.booking.extra_stops")}
                                        isShowLabel={true}
                                        isRequired={false}
                                        isDisabled={!canEdit}
                                        name={name}
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        prefix={''}
                                        suffix={""}
                                        errors={error}
                                    />
                                )}
                            />

                            <Controller
                                name="extra_stops_cost"
                                rules={{ required: false }}
                                control={control}
                                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                    <InputFormattedNumber
                                        label={t("app.booking.extra_stops_cost")}
                                        isShowLabel={true}
                                        isRequired={false}
                                        isDisabled={!canEdit}
                                        name={name}
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        prefix={''}
                                        suffix={" " + (company.currency?.currency_symbol ?? '')}
                                        errors={error}
                                    />
                                )}
                            />
                        </div>


                        


                        


                        <Controller
                            name="administrative_cost"
                            rules={{ required: false }}
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                <InputFormattedNumber
                                    label={t("app.booking.administrative_cost")}
                                    isShowLabel={true}
                                    isRequired={false}
                                    isDisabled={!canEdit}
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    prefix={''}
                                    suffix={" " + (company.currency?.currency_symbol ?? '')}
                                    errors={error}
                                />
                            )}
                        />

                        <Controller
                            name="delivery_cost"
                            rules={{ required: false }}
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                <InputFormattedNumber
                                    label={t("app.booking.delivery_cost")}
                                    isShowLabel={true}
                                    isRequired={false}
                                    isDisabled={!canEdit}
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    prefix={''}
                                    suffix={" " + (company.currency?.currency_symbol ?? '')}
                                    errors={error}
                                />
                            )}
                        />


                        <Controller
                            name="insurance_cost"
                            rules={{ required: false }}
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                <InputFormattedNumber
                                    label={t("app.booking.insurance_cost")}
                                    isShowLabel={true}
                                    isRequired={false}
                                    isDisabled={!canEdit}
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    prefix={''}
                                    suffix={" " + (company.currency?.currency_symbol ?? '')}
                                    errors={error}
                                />
                            )}
                        />
                    </div>

                    {/* Warning*/}
                    {exception && (
                        <Warning message={exception} />
                    )}


                    {
                        canEdit && (

                            <div className={"mt-5  flex items-center justify-end "}>
                                <InputSubmit isLoading={isLoading} label={t("app.common.update")} />
                            </div>

                        )
                    }

                </form>
            </TableDetail>
        </Transition>
    );
};


const MobRow = ({ element, extras }) => {
    const { t } = useTranslation();
    const isCheck = extras.isCheck.filter(r => r.id === element.id).length > 0;
    const { company } = useSelector((state) => state.userCompany);


    return (
        <li key={element.id} className={(isCheck ? 'flex items-center gap-4 p-4 bg-solitude-100' : 'flex items-center gap-4 p-4')}>
            <div className="flex-grow">
                <div className="pb-4 items-center flex justify-between">
                    <div className={"flex justify-between items-center"}>
                        <input type="checkbox"
                            id={element.id}
                            onChange={extras.handleClick} checked={isCheck}
                            className="h-4 w-4 mr-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />

                        <span className="flex items-center">
                            <Initials
                                color={element?.forwarder?.color ?? ''}
                                name={element?.forwarder?.name ?? ''}
                                isVerified={false}
                            />
                            <div className="text-left">
                                <p className="truncate text-xs text-gray-500">{element?.reference ?? ''}</p>
                                <p className="flex-wrap  whitespace-normal text-sm font-medium text-gray-900">{element?.forwarder?.name ?? ''}</p>
                                <p className="truncate text-xs text-gray-500">{element?.shipping_line?.name ?? ''}</p>
                            </div>
                        </span>  
                    </div>
                </div>

                <div className="flex flex-col">
                    <CardDetail title={"Container"}>
                        {
                            element.total_tao_config ? (
                                <TooltipItem position={"top"} tooltipsText={generateContainerDescription(extras.containerTypesList, element.total_tao_config)}>
                                    {element.total_tao_config.split(',').length}
                                </TooltipItem>
                            ) : (<>-</>)
                        }
                    </CardDetail>

                    
                    
                    
                    
                    
                    



                    <CardDetail title={t("app.booking.total_cost")}>
                        <NumberFormat
                            value={element?.total_cost ?? 0}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={2}
                            suffix={" " + (company?.currency?.currency_symbol ?? '')}
                        />
                    </CardDetail>



                    <CardDetail title={t("app.quotations.transport_cost")}>
                        <NumberFormat
                            value={element?.transport_cost ?? 0}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={2}
                            suffix={" " + (company?.currency?.currency_symbol ?? '')}
                        />
                    </CardDetail>

                    <CardDetail title={t("app.quotations.transit_time")}>
                        <NumberFormat
                            value={element?.transit_time ?? 0}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={2}
                            suffix={" " + (element.transit_time ? (element.transit_time === 1 ? t("app.booking.day") : t("app.booking.days")) : "")}
                        />
                    </CardDetail>

                    
                    <CardDetail title={t("app.quotations.free_time")}>
                        <NumberFormat
                            value={element?.free_time ?? 0}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={2}
                            suffix={" " + (element.free_time ? (element.free_time === 1 ? t("app.booking.day") : t("app.booking.days")) : "")}
                        />
                    </CardDetail>

                    <CardDetail title={t("app.quotations.expire_date")}>
                        {getFormattedDate(element.end_date)}
                    </CardDetail>


                    <CardDetail title={t("app.quotations.creator")}>
                        <UsersToolbar users={[element.user]} isActive={true} className="relative">
                            <div className="flex items-center space-x-2">
                                <div className={"flex flex-shrink-0 -space-x-1"}>
                                    <UserInitial user={element.user} ringClass={" ring-yellow-400 "} />
                                </div>
                            </div>
                        </UsersToolbar> 
                    </CardDetail>

                </div>

            </div>
        </li>
    );
};




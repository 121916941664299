import { useTranslation } from "react-i18next";
import { CONNECTION_OVERVIEW, PRODUCT, CATALOG,  CONTAINER, CONTAINER_GENERAL_INFORMATION, CUSTOMER, LEAD, LEAD_OVERVIEW, PRODUCT_DETAIL, QUOTATION, QUOTATION_OVERVIEW, SALES, SHIPPING, SHIPPING_DETAIL, REGISTRY, LOGISTICS, BOOKINGS } from "../routes/endpoints";
import { useSelector } from "react-redux";
import { permissionGroup, permissionType } from "./permissions";
import { hasPermission, hasPermissionFor } from "../components/partials/restricted/base-permission-wrapper";
import { SUPPLIER } from "../api/endpoints";

export const COMPANY_IMPORTER = 'importer';
export const COMPANY_EXPORTER = 'exporter';
export const USER_EMPLOYEE = 'employee';
export const USER_AGENT = 'agent';
export const USER_COMPANY = 'company';
export const PICKUP = "pickup";
export const DELIVERY = "delivery";

export const STATUS_PENDING = 'pending';
export const STATUS_CONFIRMED = 'confirmed';
export const STATUS_DISCONNECTED = 'disconnected';
export const STATUS_ARCHIVED = 'archived';


export const CONTAINER_REPORT = 'container';
export const SHIPPING_REPORT = 'shipping';

export const DURATION_1_Y = '1Y';
export const DURATION_1_M = '1M';

export const SEA = 'sea';
export const LAND = 'land';
export const AIR = 'air';


export const ATTACHMENT = 'attachment';

export const DRAFT = 'draft';
export const REVIEW = 'review';
export const CONFIRMED = 'confirmed';
export const CANCELED = 'canceled';
export const WAITING = 'waiting';


export const PENDDING = 'pending';
export const TO_APPROVE = 'to_approve';
export const APPROVED = 'approved';


export const IN_PROGRESS = 'in_progress';
export const COMPLETED = 'completed';


export const NEW_LEAD = 'new_lead';
export const QUALIFICATION = 'qualification';
export const PROPOSAL = 'proposal';
export const NEGOTIATION = 'negotiation';
export const CLOSED = 'closed';
export const LOST = 'lost';
export const BAD_TIMING = 'bad_timing';
export const ATTEMPTED_TO_CONTACT = 'attempted_to_contact';

// TEMPLATE CATEGORY
export const getTemplateEntities = (t) =>[
    {
        id: 1,
        value: 'fulfillment',
        label: t('app.menu.fulfillment'),
    },
    {
        id: 2,
        value: 'booking',
        label: t('app.menu.booking'),
    },
    {
        id: 20,
        value: 'generic',
        label: t('app.common.generic')
    }
];


// TEMPLATE CATEGORY
export const getTemplateFields = (t) => [
    {
        id: 1,
        type: 'fulfillment',
        fields: [
            {
                id: 1,
                label: t('app.settings.forwarderContactFirstname'),
                value: '{{forwarderContactFirstname}}',
            },
            {
                id: 2,
                label: t('app.settings.forwarderContactLastname'),
                value: '{{forwarderContactLastname}}',
            },
            {
                id: 3,
                label: t('app.settings.pickupConfiguration'),
                value: '{{pickupConfiguration}}',
            },
            {
                id: 4,
                label: t('app.settings.deliveryConfiguration'),
                value: '{{deliveryConfiguration}}',
            },
        ],
    },
    {
        id: 2,
        type: 'booking',
        fields: [
            {
                id: 1,
                label: t('app.settings.forwarderContactFirstname'),
                value: '{{forwarderContactFirstname}}',
            },
            {
                id: 2,
                label: t('app.settings.forwarderContactLastname'),
                value: '{{forwarderContactLastname}}',
            },
            {
                id: 3,
                label: t('app.settings.pickupConfiguration'),
                value: '{{pickupConfiguration}}',
            },
            {
                id: 4,
                label: t('app.settings.deliveryConfiguration'),
                value: '{{deliveryConfiguration}}',
            },
        ],
    }
];


// REPROT TYPE
export const CONTAINERS_REPORT = {
    type: 'container_report',
    config: {
      isConfirmable: true,
      isMultiGenerate: false
    },
    fields: [
        {
            type: 'string',
            shipType: false,
            name: 'document_number',
            translation: 'app.report.document_number',
            required: true
        },
        {
            type: 'date',
            shipType: false,
            name: 'document_date',
            translation: 'app.report.document_date',
            required: true
        },
        {
            type: 'currency',
            shipType: false,
            name: 'currency',
            translation: 'app.quotations.currency',
            required: true
        },
        {
            type: 'change_rate',
            shipType: false,
            name: 'currency_change_rate',
            translation: '',
            required: false
        }
    ]
};

export const PACKING_LIST = {
    type: 'packing_list',
    config: {
        isConfirmable: true,
        isMultiGenerate: false
    },
    fields: [
        {
            type: 'string',
            shipType: false,
            name: 'document_number',
            translation: 'app.report.document_number',
            required: true
        },
        {
            type: 'date',
            shipType: false,
            name: 'document_date',
            translation: 'app.report.document_date',
            required: true
        },
        {
            type: 'string',
            hideIfValidate: true,
            shipType: SEA,
            name: 'container_number',
            translation: 'app.container.container_number',
            required: true
        },
        {
            type: 'string',
            hideIfValidate: true,
            shipType: SEA,
            name: 'seal_number',
            translation: 'app.container.seal_number',
            required: true
        },
        {
            type: 'string',
            hideIfValidate: true,
            shipType: LAND,
            name: 'license_plate',
            translation: 'app.container.license_plate',
            required: true
        },
        {
            type: 'string',
            hideIfValidate: true,
            shipType: LAND,
            name: 'trailer_number',
            translation: 'app.container.trailer_number',
            required: true
        }
    ]
};

export const PACKING_LIST_SIMULATION = {
    type: 'packing_list_simulation',
    config: {
        isConfirmable: false,
        isMultiGenerate: false
    },
    fields: [
        {
            type: 'string',
            shipType: false,
            name: 'document_number',
            translation: 'app.report.document_number',
            required: true
        },
        {
            type: 'date',
            shipType: false,
            name: 'document_date',
            translation: 'app.report.document_date',
            required: true
        }
    ]
};

export const VERIFIED_GROSS_MASS_DECLARATION = {
    type: 'verified_gross_mass_declaration',
    config: {
        isConfirmable: true,
        isMultiGenerate: false
    },
    fields: [
        {
            type: 'radio-btn',
            shipType: false,
            group: 'method',
            name: 'method1',
            translation: 'app.report.method1',
            description: 'app.report.method1_description',
            required: true
        },
        {
            type: 'radio-btn',
            shipType: false,
            group: 'method',
            name: 'method2',
            translation: 'app.report.method2',
            description: 'app.report.method2_description',
            required: true
        },
        {
            type: 'string',
            shipType: false,
            name: 'authorized_person',
            translation: 'app.report.authorized_person',
            required: true
        },
        {
            type: 'string',
            shipType: false,
            hideIfValidate: true,
            name: 'container_number',
            translation: 'app.container.container_number',
            required: true
        },
        {
            type: 'string',
            shipType: false,
            name: 'calibrated',
            translation: 'app.report.calibrated',
            required: true
        },
        {
            type: 'number',
            shipType: false,
            name: 'container_tare',
            config:{
                suffix: ' Kg',
            },
            translation: 'app.report.container_tare',
            required: true
        },
        {
            type: 'number',
            shipType: false,
            name: 'verified_gross_weight',
            config:{
                suffix: ' Kg',
            },
            translation: 'app.report.verified_gross_weight',
            required: true
        },
        {
            type: 'string',
            shipType: false,
            name: 'printed_name',
            translation: 'app.report.printed_name',
            required: true
        },
        {
            type: 'date',
            shipType: false,
            config:{
                isActiveTime: true,
            },
            name: 'date',
            translation: 'app.report.document_date',
            required: true
        },
        {
            type: 'signature',
            shipType: false,
            name: 'signature',
            translation: 'app.common.signature',
            required: true
        },
    ]
};

export const DECLARATIION_FREE_EXPORT = {
    type: 'declaration_free_export',
    config: {
        isConfirmable: true,
        isMultiGenerate: false
    },
    fields: [
        {
            type: 'string',
            shipType: false,
            name: 'invoice_number',
            translation: 'app.report.document_number',
            required: true
        },
        {
            type: 'string',
            shipType: false,
            name: 'printed_name',
            translation: 'app.report.printed_name',
            required: true
        },
        {
            type: 'date',
            shipType: false,
            name: 'date',
            translation: 'app.report.document_date',
            required: true
        }
    ]
};

export const QUOTATION_REPORT = {
    type: 'quotation',
    entity: 'company_quotation',
    code: 'quotation',
    config: {
        isConfirmable: false,
        isMultiGenerate: true
    },
    configXlsParams: [
        {
            type: 'checkbox',
            name: 'config_show_hidden_fields',
            label: 'app.quotations.hidden_fields_title',
            description: 'app.quotations.hidden_fields_description',
            required: false
        }
    ],
    configPdfParams: [
        {
            type: 'checkbox',
            name: 'is_active_confirmed',
            label: 'app.quotations.active_confirmed_title',
            description: 'app.quotations.active_confirmed_description',
            required: false
        },
        {
            type: 'checkbox',
            name: 'is_active_residual',
            label: 'app.quotations.active_residual_title',
            description: 'app.quotations.active_residual_description',
            required: false
        }
    ],
    fields: [
        {
            type: 'string',
            shipType: false,
            name: 'consignee',
            translation: 'app.quotations.consignee',
            required: true
        },
        {
            type: 'string',
            shipType: false,
            name: 'consignee_address',
            translation: 'app.quotations.consignee_address',
            required: true
        },
        {
            type: 'string',
            shipType: false,
            name: 'consignee_zip_code',
            translation: 'app.quotations.consignee_zip_code',
            required: false
        },
        {
            type: 'string',
            shipType: false,
            name: 'consignee_city',
            translation: 'app.quotations.consignee_city',
            required: true
        },
        {
            type: 'string',
            shipType: false,
            name: 'consignee_country',
            translation: 'app.quotations.consignee_country',
            required: true
        },
        {
            type: 'editor',
            shipType: false,
            name: 'payment_terms',
            translation: 'app.quotations.payment_terms',
            required: true
        },
        {
            type: 'editor',
            shipType: false,
            name: 'note',
            translation: 'app.quotations.note',
            required: false
        }
    ]
};


export const TEMPLATE_FONTS = [
    { value: "Thin", label: "Thin"},
    { value: "ThinItalic", label: "Thin Italic"},
    { value: "ExtraLight", label: "Extra Light"},
    { value: "ExtraLightItalic", label: "Extra Light Italic"},
    { value: "Light", label: "Light"},
    { value: "LightItalic", label: "Light Italic"},
    { value: "Regular", label: "Regular"},
    { value: "RegularItalic", label: "Regular Italic"},
    { value: "Medium", label: "Medium"},
    { value: "MediumItalic", label: "Medium Italic"},
    { value: "SemiBold", label: "SemiBold"},
    { value: "SemiBoldItalic", label: "Semi Bold Italic"},
    { value: "Bold", label: "Bold"},
    { value: "BoldItalic", label: "Bold Italic"},
    { value: "ExtraBold", label: "Extra Bold"},
    { value: "ExtraBoldItalic", label: "Extra Bold Italic"},
    { value: "Black", label: "Black"},
    { value: "BlackItalic", label: "Black Italic"}
 ];

export function getReportType(args) {
    switch (args) {
        case CONTAINERS_REPORT.type:
            return CONTAINERS_REPORT
        case DECLARATIION_FREE_EXPORT.type:
            return DECLARATIION_FREE_EXPORT
        case PACKING_LIST.type:
            return PACKING_LIST
        case PACKING_LIST_SIMULATION.type:
            return PACKING_LIST_SIMULATION
        case VERIFIED_GROSS_MASS_DECLARATION.type:
            return VERIFIED_GROSS_MASS_DECLARATION
        default:
            return false
    }
}

export function getSelectSubParams(data, label, label2) {
    return (data ?? []).map((e) => ({
        id: e.id,
        value: e.id,
        code: e?.code ?? null,
        params: e?.params ?? null,
        file_link: e?.file?.reference ?? null,
        label: e[label][label2],
    }) );
}

export function getPeriodType(args) {
    switch (args) {
        case DURATION_1_Y:
            return 'year'
        case DURATION_1_M:
            return 'month'
        default:
            return false
    }
}


export default function BoardReletedEntities(){

    const { t } = useTranslation();
    const { companyType } = useSelector((state) => state.user);
    const { permissions } = useSelector((s) => s.userCompany);

    const canViewLead = hasPermission(permissions, permissionGroup.LEAD, permissionType.VIEW, companyType, true);
    const canViewSupplier = hasPermissionFor(permissions, permissionGroup.PRODUCT_SETTING_SUPPLIER, permissionType.VIEW, companyType, true);
    const canViewCustomer = hasPermissionFor(permissions, permissionGroup.CUSTOMER, permissionType.VIEW, companyType, true);
    const canViewQuotation = hasPermissionFor(permissions, permissionGroup.QUOTATION, permissionType.VIEW, companyType, true);
    const canViewBooking = hasPermissionFor(permissions, permissionGroup.BOOKING, permissionType.VIEW, companyType, true);
    const canViewShipping = hasPermissionFor(permissions, permissionGroup.SHIPPING, permissionType.VIEW, companyType, true);
    const canViewShippingContainer = hasPermissionFor(permissions, permissionGroup.CONTAINER, permissionType.VIEW, companyType, true);
    const canViewShippingDocument = hasPermissionFor(permissions, permissionGroup.DOCUMENT, permissionType.VIEW, companyType, true);
    const canViewShippingCatalog = hasPermissionFor(permissions, permissionGroup.CATALOG, permissionType.VIEW, companyType, true);


    return [
        canViewLead && { conSelect: true, accosciateEntity: true, context: 'lead', value: 'lead', label: t("app.board.entity_lead"), baseEndpoint: SALES + LEAD + '/:id' + LEAD_OVERVIEW },
        canViewSupplier && { conSelect: true, accosciateEntity: true, context: 'company', value: 'supplier', label: t("app.board.entity_company_supplier"), baseEndpoint: REGISTRY + '/' + SUPPLIER + '/:id' },
        canViewCustomer && { conSelect: true, accosciateEntity: true, context: 'customer', value: 'customer', label: t("app.board.entity_customer"), baseEndpoint: REGISTRY +  CUSTOMER + '/:id' + CONNECTION_OVERVIEW },
        canViewQuotation && { conSelect: true, accosciateEntity: true, context: 'quotation', value: 'quotation', label: t("app.board.entity_quotation"), baseEndpoint: SALES + QUOTATION + '/:id' + QUOTATION_OVERVIEW },
        canViewBooking && { conSelect: true, accosciateEntity: true, context: 'booking', value: 'booking', label: t("app.board.entity_booking"), baseEndpoint: LOGISTICS + BOOKINGS + '/:id' },
        canViewShipping && { conSelect: true, accosciateEntity: true, context: 'shipping', value: 'shipping', label: t("app.board.entity_shipping"), baseEndpoint: LOGISTICS + SHIPPING + '/:id' + SHIPPING_DETAIL },
        canViewShippingContainer && { conSelect: true, accosciateEntity: true, context: 'shipping', value: 'shipping_container', label: t("app.board.entity_shipping_container"), baseEndpoint: LOGISTICS + SHIPPING + '/:id' + CONTAINER + '/:id_2' + CONTAINER_GENERAL_INFORMATION },
        canViewShippingDocument && { conSelect: false, accosciateEntity: false, context: 'shipping', value: 'shipping_document', label: t("app.board.entity_shipping_document"), baseEndpoint: null },
        canViewShippingCatalog && { conSelect: true, accosciateEntity: true, context: 'product', value: 'product', label: t("app.board.entity_product"), baseEndpoint: CATALOG + PRODUCT + '/:id' + PRODUCT_DETAIL }
    ].filter(Boolean)
};

import React, {useEffect, useState} from "react";
import {useParams, Outlet, useNavigate, useLocation} from "react-router-dom";
import { useDispatch,useSelector} from "react-redux";
import LoaderWrapper from "../../../../components/common/loader/loader-wrapper";
import {useTranslation} from "react-i18next";
 import {getProduct} from "../../../../api/catalog";
import {getPathParam, getSelectParams} from "../../../../utils/converter";
import {
    CATALOG,
    CONTAINER_GALLERY,
    DIMENSIONS,
    P404, PRICING, PRODUCT, PRODUCT_ALTERNATIVE, PRODUCT_DETAIL, PRODUCT_LOG, PRODUCT_MEDIA, PRODUCT_SUPPLIERS,
    TRANSLATION
} from "../../../endpoints";
import {getCategories} from "../../../../api/category";
import PageHeaderMenu from "../../../../components/layout/page-header-menu";
import PanelNavigation from "../../../../components/partials/panel/panel-navigation";
import {
    DocumentTextIcon, PaperClipIcon,
    PhotographIcon,
    PresentationChartLineIcon, QrcodeIcon, ShoppingBagIcon,
    TemplateIcon
} from "@heroicons/react/outline";
import IntegrationIcon from "../../../../resources/icons/IntegrationIcon";
import {permissionGroup, permissionSpecific, permissionType} from "../../../../constants/permissions";
import {hasPermissionFor} from "../../../../components/partials/restricted/base-permission-wrapper";
import { ProductStatusList } from "../../../../components/common/alert/product-status-list";
import ConfirmPopup from "../../../../components/common/popup/confirm-popup";
import { COMPANY, STATUS } from "../../../../api/endpoints";
import { refreshFilters } from "../../../../reducers/userCompanyReducer";
import RequiredPopup from "../../../../components/common/popup/required-popup";
import BoxIcon from "../../../../resources/icons/BoxIcon";
import ConfirmTextPopup from "../../../../components/common/popup/confirm-text-popup";

function isValidParam(string){
    if (string === '' || string === undefined || string === null){
        return false
    }
    return true

}


const ProductEdit = () => {

    let {id}  = useParams();
    const { t } = useTranslation();
    let navigate = useNavigate();
    const dispatch = useDispatch();


    const location = useLocation();
    const { pathname } = location;
    const { company } = useSelector((state) => state.userCompany);


    const { loggedCompany } = useSelector((state) => state.user);
    const { permissions } = useSelector((s) => s.userCompany);

    const canViewProduct = hasPermissionFor(permissions, permissionGroup.PRODUCT, permissionType.VIEW);
    const canViewProductRate = hasPermissionFor(permissions, permissionGroup.PRODUCT_RATE, permissionType.LIST);
    const canViewProductSupplier = hasPermissionFor(permissions, permissionGroup.PRODUCT_SUPPLIER, permissionType.LIST);
    const canViewProductPackages = hasPermissionFor(permissions, permissionGroup.PRODUCT_PACKAGE, permissionType.LIST);
    const canViewProductAttachments = hasPermissionFor(permissions, permissionGroup.PRODUCT_ATTACHMENT, permissionType.LIST);
    const canViewProductTranslations = hasPermissionFor(permissions, permissionGroup.PRODUCT_TRANSLATION, permissionType.LIST);
    const canViewProductLog = hasPermissionFor(permissions, permissionGroup.PRODUCT, permissionSpecific.PRODUCT_LOG);
    const canChangeStatus = hasPermissionFor(permissions, permissionGroup.PRODUCT, permissionSpecific.PRODUCT_MANAGE_STATUS);
    const canViewAlternative = hasPermissionFor(permissions, permissionGroup.PRODUCT, permissionSpecific.PRODUCT_ALTERNATIVE);


    


    const navigation = [
        canViewProduct && { name: t("app.products.info"), location: CATALOG + PRODUCT + '/' + id + PRODUCT_DETAIL, icon: ShoppingBagIcon, current: pathname.includes(CATALOG + PRODUCT + '/' + id + PRODUCT_DETAIL), statusEditor: true },
        canViewProduct && { name: t("app.common.gallery"), location: CATALOG + PRODUCT + '/' + id + CONTAINER_GALLERY, icon: PhotographIcon, current: pathname.includes(CATALOG + PRODUCT + '/' + id + CONTAINER_GALLERY), statusEditor: false },
        canViewProductRate && { name: t("app.products.pricing_title"), location: CATALOG + PRODUCT + '/' + id + PRICING, icon: PresentationChartLineIcon, current: pathname.includes(CATALOG + PRODUCT + '/' + id + PRICING), statusEditor: false },
        canViewProductSupplier && { name: t("app.catalog.supplier"), location: CATALOG + PRODUCT + '/' + id + PRODUCT_SUPPLIERS, icon: QrcodeIcon, current: pathname.includes(CATALOG + PRODUCT + '/' + id + PRODUCT_SUPPLIERS), statusEditor: false },
        canViewProductPackages && { name: t("app.products.package_title"), location: CATALOG + PRODUCT + '/' + id + DIMENSIONS, icon: BoxIcon, current: pathname.includes(CATALOG + PRODUCT + '/' + id + DIMENSIONS), statusEditor: false },
        canViewProductAttachments && { name: t("app.catalog.attachments"), location: CATALOG + PRODUCT + '/' + id + PRODUCT_MEDIA, icon: PaperClipIcon, current: pathname.includes(CATALOG + PRODUCT + '/' + id + PRODUCT_MEDIA), statusEditor: false },
        canViewAlternative && { name: t("app.catalog.alternative"), location: CATALOG + PRODUCT + '/' + id + PRODUCT_ALTERNATIVE, icon: TemplateIcon, current: pathname.includes(CATALOG + PRODUCT + '/' + id + PRODUCT_ALTERNATIVE), statusEditor: false },
        canViewProductTranslations && { name: t("app.products.translation"), location: CATALOG + PRODUCT + '/' + id + TRANSLATION, icon: IntegrationIcon, current: pathname.includes(CATALOG + PRODUCT + '/' + id + TRANSLATION), statusEditor: false },
        canViewProductLog && { name: t("app.products.log"), location: CATALOG + PRODUCT + '/' + id + PRODUCT_LOG, icon: DocumentTextIcon, current: pathname.includes(CATALOG + PRODUCT + '/' + id + PRODUCT_LOG), statusEditor: false }
    ].filter(Boolean);


    // get product
    const [isLoading, setLoading] = useState(true);
    const [productDetail, setProductDetail] = useState(true);

    const [isLoadingCategories, setLoadingCategories] = useState(true);
    const [categories, setCategories] = useState([]);

    // Change Status
    const [isOpenConfirm, setOpenConfirm] = useState(false);
    const [confirmDetail, setConfirmDetail] = useState({});



    // Required Fields
    const [isOpenRequird, setOpenRequird] = useState(false);
    const [requiredDetail, setRequiredDetail] = useState({});


    const [needRefresh, setNeedRefresh] = useState(false);

    const [isOpenConfirmPopup, setOpenConfirmPopup] = useState(false);
    const [isActiveSaveCheck, setActiveSaveCheck] = useState(false);
    const [isConfirmSave, onConfirmSave] = useState(false);


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        function fetchProduct() {
            if (loggedCompany) {
                setLoading(true);
                getProduct({ company_id: loggedCompany.id, product_id: id, signal })
                    .then((response) => {
                        setProductDetail(response);
                        setLoading(false);
                    })
                    .catch((e) => {
                        e.code === 404 && navigate(P404);
                        setLoading(false);
                    });
            }
        }

        async function fetchCategories() {
            if (loggedCompany) {
                setLoadingCategories(true);
                try {
                    const res = await getCategories({
                        company_id: loggedCompany.id,
                        signal,
                    });
                    setCategories(getSelectParams(res.categories, "name"));
                } catch (e) {
                    setCategories([]);
                } finally {
                    setLoadingCategories(false);
                }
            }
        }
        
        fetchProduct();
        fetchCategories();
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [id, loggedCompany]);



    useEffect(() => {
        function fetchProduct() {
            if (loggedCompany && needRefresh) {
                getProduct({ company_id: loggedCompany.id, product_id: id })
                    .then((response) => {
                        setProductDetail(response);
                        setNeedRefresh(false);
                    })
                    .catch((e) => {
                        e.code === 404 && navigate(P404);
                        setNeedRefresh(false);
                    });
            }
        }
        

        fetchProduct();
    
        // eslint-disable-next-line
    }, [needRefresh, id, loggedCompany]);


    const requiredFieldText = () => {
        if (company.product_manage_status) {
            if (
                isValidParam(productDetail.name) &&
                isValidParam(productDetail.unit_value) && 
                isValidParam(productDetail.country_origin) && 
                isValidParam(productDetail.hs_code) && 
                isValidParam(productDetail.company_manufacture) && 
                isValidParam(productDetail.manufacture_reference) && 
                isValidParam(productDetail.category) && 
                isValidParam(productDetail.company_brand) && 
                isValidParam(productDetail.product_reference) &&
                productDetail.has_supplier === true &&
                productDetail.has_rate === true
            ){
                return true;
            }
        }
        return false;
    };



    return (
        <LoaderWrapper isLoading={isLoading}>
            <div className="flex h-full">

                <div className="flex min-w-0 flex-1 flex-col overflow-hidden">

                    <div className="relative z-10 flex flex-1 overflow-hidden">
                        <main className="relative z-10 flex-1 overflow-y-auto focus:outline-none xl:order-last">
                            {/* Start main area*/}
                            <div className="absolute inset-0 py-6 px-4 sm:px-6 lg:px-8">
                                {
                                    navigation.length > 0 && (
                                        <div className="max-w-7xl mx-auto mb-3">
                                            <PageHeaderMenu
                                                isActiveSaveCheck={isActiveSaveCheck}
                                                onChangeNavigation={(url) => {
                                                    const detailData = {
                                                        title: t("app.catalog.edit_product_alert_title"),
                                                        message: t("app.catalog.edit_product_alert_desc"),
                                                        redirect: url,
                                                    };
                                                    setConfirmDetail(detailData);
                                                    setOpenConfirmPopup(true);
                                                }}
                                                taskContext={'product'}
                                                taskContextId={productDetail.id}
                                                title={navigation.find(r => r.current === true)?.name ?? ''}
                                                statusEditor={navigation.find(r => r.current === true)?.statusEditor ?? false}
                                                navigation={navigation}
                                                description={t("app.products.product_code")+": "+productDetail?.product_reference ?? ''}
                                            >


                                            {
                                                company.product_manage_status && (
                                                    <ProductStatusList
                                                        isLoading={isLoading}
                                                        status={productDetail?.status ?? 'draft'}
                                                        isEditable={canChangeStatus === true}
                                                        onChangeStatus={(r) => {
                                                            if (r === 'approved' && !requiredFieldText()){
                                                                const dataRequireds = [
                                                                    !isValidParam(productDetail.name) && {
                                                                        title: t("app.form.name")
                                                                    },
                                                                    !isValidParam(productDetail.product_reference) && {
                                                                        title: t("app.products.product_code")
                                                                    },
                                                                    !isValidParam(productDetail.unit_value) && {
                                                                        title: t("app.products.unit_value")
                                                                    },
                                                                    !isValidParam(productDetail.country_origin) && {
                                                                        title: t("app.products.country_origin")
                                                                    },
                                                                    !isValidParam(productDetail.hs_code) && {
                                                                        title: 'Hs Code'
                                                                    },
                                                                    !isValidParam(productDetail.company_manufacture) && {
                                                                        title: t("app.catalog.manufacture")
                                                                    },
                                                                    !isValidParam(productDetail.manufacture_reference) && {
                                                                        title: t("app.products.manufacture_code")
                                                                    },
                                                                    !isValidParam(productDetail.category) && {
                                                                        title: t("app.products.category")
                                                                    },
                                                                    productDetail.has_supplier === false && {
                                                                        title: t("app.catalog.supplier")
                                                                    },
                                                                    productDetail.has_rate === false && {
                                                                        title: t("app.products.pricing_title")
                                                                    },
                                                                    !isValidParam(productDetail.company_brand) && {
                                                                        title: t("app.catalog.brands")
                                                                    },
                                                                ].filter(Boolean);

                                                                setRequiredDetail(dataRequireds);
                                                                setOpenRequird(true);
                                                                
                                                                

                                                            }else{
                                                                const detailData = {
                                                                    title: t("app.catalog.status_confirm_title"),
                                                                    message: t("app.catalog.status_confirm_description", { status: t("app.catalog.status_" + r) }),
                                                                    endpoint: getPathParam([COMPANY, loggedCompany.id, PRODUCT, productDetail.id, STATUS, r]),
                                                                };
                                                                setConfirmDetail(detailData);
                                                                setOpenConfirm(true);
                                                            }
                                                        }}
                                                    />
                                                )
                                            }

                                               
                                            </PageHeaderMenu>
                                        </div>
                                    )
                                }

                                <div className="max-w-7xl mx-auto pb-4">
                                    <Outlet context={{
                                        productDetail, navigation, categories, isLoadingCategories, setProductDetail, setNeedRefresh, isActiveSaveCheck, setActiveSaveCheck, isConfirmSave,
                                        onConfirmUpdate: () => {
                                            setOpenConfirmPopup(false);
                                            setActiveSaveCheck(false);
                                            navigate(confirmDetail.redirect)
                                        }
                                    }} />
                                </div>
                            </div>
                            {/* End main area */}
                        </main>

                        {
                            navigation.length > 0 && (
                                <aside className="relative hidden w-80 flex-shrink-0 overflow-y-auto border-r bg-white border-gray-200 lg:order-first lg:flex lg:flex-col">
                                    {/* Start secondary column (hidden on smaller screens) */}
                                    <div className="absolute inset-0">
                                        <PanelNavigation 
                                            onChangeNavigation={(url) => {
                                                const detailData = {
                                                    title: t("app.catalog.edit_product_alert_title"),
                                                    message: t("app.catalog.edit_product_alert_desc"),
                                                    redirect: url,
                                                };
                                                setConfirmDetail(detailData);
                                                setOpenConfirmPopup(true);
                                            }}
                                            isActiveSaveCheck={isActiveSaveCheck} 
                                            navigation={navigation} 
                                            isCenterTitle={true} 
                                        />
                                    </div>
                                    {/* End secondary column */}
                                </aside>
                            )
                        }


                    </div>
                </div>
            </div>


            {isOpenConfirm && (
                <ConfirmPopup
                    isOpen={isOpenConfirm}
                    setIsOpen={() => {
                        setOpenConfirm(false);
                    }}
                    detail={confirmDetail}
                    setDetail={(r) => {
                        dispatch(refreshFilters({ company_id: loggedCompany.id }));
                        setProductDetail(r);
                    }}
                />
            )}


            {isOpenRequird && (
                <RequiredPopup
                    title={t("app.catalog.required_error_title")}
                    description= {t("app.catalog.required_error_description")}
                    isOpen={isOpenRequird}
                    setIsOpen={() => {
                        setOpenRequird(false);
                    }}
                    requireds={requiredDetail}
                />
            )}
            

            {isOpenConfirmPopup && (
                <ConfirmTextPopup
                    isOpen={isOpenConfirmPopup}
                    setIsOpen={() => {
                        setOpenConfirmPopup(false);
                        navigate(confirmDetail.redirect);
                        setActiveSaveCheck(false);

                    }}
                    detail={confirmDetail}
                    onConfirmed={(r) => {
                        onConfirmSave(!isConfirmSave);
                    }}
                />
            )}

            
            
        

        </LoaderWrapper>
    );
};

export default ProductEdit;

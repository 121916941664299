import React, {useState, useEffect} from 'react'
import {useSelector} from "react-redux";
import { getElement } from '../../../api/config';
import { getPathParam } from '../../../utils/converter';
import { COMPANY } from '../../../api/endpoints';
import { JOBS } from '../../../routes/endpoints';
import ProgressNotification from '../../common/notification/progress-notification';
const NotificationProgress = () => {



    const { messageBusNotification } = useSelector((state) => state.config);
    const { loggedCompany } = useSelector((state) => state.user);

    const [shownNotifications, setShownNotifications] = useState([]);

    const [notifications, setNotifications] = useState([]);


    useEffect(() => {
        async function fetchData() {
            if (loggedCompany) {
                try {
                    const res = await getElement(getPathParam([COMPANY, loggedCompany.id, JOBS]));
                    const newJobs = res.jobs.filter(job => !shownNotifications.some(notif => notif.id === job.id));

                    // Notifica solo i nuovi job
                    newJobs.forEach((job) => {
                        setNotifications((prev) => [
                            ...prev,
                            job,
                        ]);
                    });

                    // Aggiungi i nuovi job all'elenco delle notifiche già mostrate
                    setShownNotifications(prev => [...prev, ...newJobs]);


                } catch (e) {
                    console.log(e);
                }
            }
        }


        fetchData();
        // eslint-disable-next-line
    }, [loggedCompany, messageBusNotification]);


    return (

        <div >
            <ProgressNotification notifications={notifications} />
        </div>
        
    )
};

export default NotificationProgress

import { RadioGroup } from "@headlessui/react";
import InputSearch from "../form/input-search";

export default function TableHeader({ extraClass = "", onSearch, children, title = '', searchLength = 3, shippingTypes = [], setShippingType, shippingType, showShippingType = false, totalRows = null, activeDynamicFilter = false, isDynamicSearch = false, showSearch = true}) {
  
  
  return (
    <header>
      <div className={activeDynamicFilter ? ("px-4 sm:px-6 py-3 bg-white flex flex-wrap gap-4 " + extraClass) : ("px-4 sm:px-6 py-3 bg-white flex flex-wrap gap-4 justify-end sm:justify-between " + extraClass)}>

          <div className="flex items-center">
          {
            showSearch && (
              <InputSearch onSearch={onSearch} isDynamicSearch={isDynamicSearch} hasMargin={false} length={searchLength} />
            )
          }

          {
            showShippingType && (
              <fieldset className="min-w-36 ml-3">
                <RadioGroup value={shippingType} onChange={setShippingType} className="flex items-center  rounded-md ring-1 ring-gray-200">
                  {shippingTypes.map((option) => (
                    <RadioGroup.Option
                      key={option.name}
                      value={option}
                      className={({ active, checked }) =>
                        `cursor-pointer flex items-center justify-center   p-2 text-sm  rounded-md font-semibold     sm:flex-1
                            ${(shippingType.type === option.type) ? 'bg-blue-1000 text-white' : 'bg-white text-blue-1000 hover:bg-gray-50'}`
                      }
                    >
                      <option.icon className="h-5 w-5" />
                    </RadioGroup.Option>
                  ))}
                </RadioGroup>
              </fieldset>
            )
          }
          </div>


          {
              activeDynamicFilter ? (
                  <>{children}</>
              ) : (
                  <div className="flex gap-4">
                      {children}
                  </div>
              )
          }

      </div>
      {
        title && (
          <div className="sm:hidden px-4 py-3 bg-solitude-400">
            <h2 className="text-xs font-semibold text-blue-1000 uppercase tracking-wide">{title} {totalRows !== null && '(' + totalRows + ')'}</h2>
          </div>
        )
      }
    </header>
  );
}

import {useTranslation} from "react-i18next";
import React from "react";
import NumberFormat from "react-number-format";

export default function InputQuantityNumber ({
     isFullW = false,
     isDisabled = false,
     value,
     prefix,
     suffix,
     label,
     isShowLabel = true,
     customClass = '',
     isRequired,
     id,
     errors,
     warning,
     warningRegex,
     onChange
}){

    const { t } = useTranslation();

    return (

        <div className={isFullW  ? 'w-full' : ''}>
            {
                isShowLabel && (
                    <label htmlFor={id} className="block text-sm font-medium mb-1 text-gray-700">
                        {label} {isRequired && <span className="text-red-500">*</span>}
                    </label>
                )
            }
            <div className="flex flex-col">
                <div className="mx-auto my-auto w-full">
                    <div className="flex flex-row h-10 form-input p-0">
                        <NumberFormat
                            value={value}
                            className={`${customClass !== '' ? customClass : 'form-input'} flex input-discount  items-center border-0 w-full shadow-none disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed ${errors ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md' : ''}`}
                            inputMode="decimal"
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            onValueChange={(values) => {
                               onChange(values.floatValue);
                            }}
                            placeholder={t("app.common.placeholder", {field: label.toLowerCase()})}
                            disabled={isDisabled}
                            prefix={prefix}
                            suffix={suffix}
                        />
                    </div>
                
                    {errors && errors.type === 'required' && (  <p className="mt-2 text-sm text-red-600">{warning ? warning : t("app.warning.description", {field: label})}</p>)}
                    {errors && errors.type === 'validate' && (  <p className="mt-2 text-sm text-red-600">{warning ? warning : t("app.warning.description", {field: label})}</p>)}
                    {errors && errors.type === 'pattern'  && (  <p className="mt-2 text-sm text-red-600">{warningRegex ? warningRegex : t("app.warning.description_regex", {field: label})}</p>)}
                    {errors && errors.type === 'custom'   && (  <p className="mt-2 text-sm text-red-600">{errors.message}</p>)}

                </div>
            </div>

        </div>

    )
};

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getPathParam, getWebPathParam } from "../../../../utils/converter";
import {
    COMPANY,
    COMPANY_MEDIA_CATEGORIES,
    COMPANY_MEDIA_CATEGORY
} from "../../../../api/endpoints";
import { useSelector } from "react-redux";
import ActionsMenu from "../../../../components/common/table/actions-menu";
import { useForm } from "react-hook-form";
import InputText from "../../../../components/common/form/input-text";
import { Warning } from "../../../../components/common/alert/banner";
import InputSubmit from "../../../../components/common/form/input-submit";
import { FolderIcon } from "@heroicons/react/solid";
import { getElement, postElement, putElement } from "../../../../api/config";
import RemovePopup from "../../../../components/common/popup/remove-popup";
import LoaderWrapper from "../../../../components/common/loader/loader-wrapper";
import { DotsVerticalIcon, PlusIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import { COMPANY_MEDIA, CONFIGURATION } from "../../../endpoints";
import FormPopup from "../../../../components/common/popup/form-popup";
import InputSearch from "../../../../components/common/form/input-search";
import { ActionButton } from "../../../../components/layout/page-header";


const CompanyMediaGroup = () => {
    const { t } = useTranslation();
    const { loggedCompany } = useSelector((state) => state.user);
    let navigate = useNavigate();


    const [isLoading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [isOpenAdd, setOpenAdd] = useState(false);


    const [isOpenEdit, setOpenEdit] = useState(false);
    const [editDetail, setEditDetail] = useState(false);

    const [isOpenRemove, setOpenRemove] = useState(false);
    const [removeDetail, setRemoveDetail] = useState({});

    const [nonCategory, setNonCategory] = useState(0);
    const [query, setQuery] = useState("");


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchData() {
            setLoading(true);
            if (loggedCompany) {
                try {
                    const res = await getElement(getPathParam([COMPANY, loggedCompany.id, COMPANY_MEDIA_CATEGORIES]), signal, { query: query });
                    setCategories(res.categories);
                    setNonCategory(res.total_non_category_rows);
                } catch (e) {
                    setCategories([]);
                    setNonCategory(0);
                } finally {
                    setLoading(false);
                }
            }
        }
        fetchData();
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [loggedCompany, query]);





    return (
        <>
            <div className={("px-4 sm:px-6 py-3 bg-white flex flex-wrap gap-4 justify-end sm:justify-between rounded-xl shadow-sm mb-3")}>

                <div className="flex items-center">
                    <InputSearch onSearch={setQuery} isDynamicSearch={false} hasMargin={false} length={1} />
                </div>


                <div className="flex gap-4">
                    <ActionButton
                        icon={
                            <PlusIcon
                                className="w-4 h-4 "
                            />
                        }
                        onClick={() => {
                            setOpenAdd(true);
                        }}
                        text={t("app.configuration.add_folder")}
                    />
                </div>

            </div>
            <LoaderWrapper isLoading={isLoading}>

                <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">


                    {categories.map((category) => (
                        <li key={category.id} className="col-span-1 divide-y divide-gray-200">
                            <div className={"rounded-lg  min-h-[100px] border border-gray-200 bg-white/30    hover:bg-gray-50 leading-5 relative no-underline p-3"}>
                                <div className="flex flex-col h-20 justify-between relative">
                                    <div className="flex  justify-between items-center w-full">
                                        <FolderIcon className="h-6 w-6 text-yellow-500" />
                                        <ActionsMenu icon={DotsVerticalIcon}>
                                            <button className={'text-left'} onClick={() => {
                                                setEditDetail(category);
                                                setOpenEdit(true);
                                            }}
                                            >
                                                {t("app.common.edit")}
                                            </button>
                                            <button className={'text-left'} onClick={() => {
                                                const removeDetail = {
                                                    title: t("app.common.remove_generic_title"),
                                                    message: t("app.common.remove_generic_description"),
                                                    endpoint: getPathParam([COMPANY, loggedCompany.id, COMPANY_MEDIA_CATEGORY, category.id])
                                                };
                                                setRemoveDetail(removeDetail);
                                                setOpenRemove(true)
                                            }}
                                            >
                                                {t("app.common.remove")}
                                            </button>
                                        </ActionsMenu>
                                    </div>

                                    <div className="items-baseline flex flex-col justify-start cursor-pointer" onClick={() => {
                                        navigate(getWebPathParam([COMPANY, CONFIGURATION, COMPANY_MEDIA, category.id]));
                                    }}>

                                        <div className="text-sm text-gray-900 font-semibold capitalize">
                                            {category.name}
                                        </div>
                                        <div className="text-xs text-gray-500 capitalize">
                                            {category.media_count} file
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}


                    {
                        nonCategory > 0 && (
                            <li className="col-span-1 divide-y divide-gray-200">
                                <div className={"rounded-lg  min-h-[100px] border border-gray-200 bg-white/30 hover:bg-gray-50 leading-5 relative no-underline p-3"}>
                                    <div className="flex flex-col h-20 justify-between relative">
                                        <div className="flex  justify-between items-center w-full">
                                            <FolderIcon className="h-6 w-6 text-yellow-500" />
                                        </div>

                                        <div className="items-baseline flex flex-col justify-start cursor-pointer" onClick={() => {
                                            navigate(getWebPathParam([COMPANY, CONFIGURATION, COMPANY_MEDIA, 'NON']));
                                        }}>

                                            <div className="text-sm text-gray-900 font-semibold capitalize">
                                                {t("app.configuration.no_category")}
                                            </div>
                                            <div className="text-xs text-gray-500 capitalize">
                                                {nonCategory} file
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        )
                    }


                </ul>


            </LoaderWrapper>

            {isOpenAdd && (
                <FormPopup
                    title={t("app.configuration.add_folder")}
                    isOpen={isOpenAdd}
                    setIsOpen={(r) => {
                        setOpenAdd(r);
                    }}
                >

                    <AddForm
                        onSubmitData={(r) => {
                            setCategories(r.categories);
                            setOpenAdd(false);
                        }}
                    />

                </FormPopup>
            )}





            {isOpenEdit && (
                <FormPopup
                    title={t("app.configuration.edit_folder")}
                    isOpen={isOpenEdit}
                    setIsOpen={(r) => {
                        setOpenEdit(r);
                    }}
                >

                    <EditForm
                        data={editDetail}
                        onSubmitData={(r) => {
                            setCategories(r.categories);
                            setOpenEdit(false);
                        }}
                    />

                </FormPopup>
            )}

            {isOpenRemove && (
                <RemovePopup
                    isOpen={isOpenRemove}
                    setIsOpen={setOpenRemove}
                    detail={removeDetail}
                    setDetail={(res) => {
                        setCategories(res.categories);
                    }}
                />
            )}
        </>
    );
};




const AddForm = ({ onSubmitData }) => {

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const { t } = useTranslation();
    const [exception, setException] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const { loggedCompany } = useSelector((state) => state.user);

    const onSubmit = async (data) => {
        setLoading(true);
        setException(false);
        postElement(getPathParam([COMPANY, loggedCompany.id, COMPANY_MEDIA_CATEGORY]), data)
            .then(response => {
                onSubmitData(response)
                setLoading(false);
            }).catch(e => {
                setException(e.message);
                setLoading(false);
            });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-1">
                <div className="grid md:grid-cols-1 lg:grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-1">
                    <InputText
                        id={"name"}
                        type={"text"}
                        errors={errors.name}
                        input={{ ...register("name", { required: true }) }}
                        label={t("app.sub_categories.sub_category_name")}
                    />

                </div>
            </div>

            {/* Warning */}
            {exception && (
                <Warning message={exception} />
            )}


            <div className="flex before:flex-1 items-center justify-between mt-6">
                <InputSubmit
                    isLoading={isLoading}
                    label={t("app.form.save")}
                />
            </div>
        </form>
    );
}

const EditForm = ({ data, onSubmitData }) => {

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm();

    const { t } = useTranslation();
    const [exception, setException] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const { loggedCompany } = useSelector((state) => state.user);


    useEffect(() => {
        if (data !== null) {
            for (const k in data) {
                setValue(k, data[k]);
            }
        }
    }, [setValue, data]);


    const onSubmit = async (data) => {
        setLoading(true);
        setException(false);
        putElement(getPathParam([COMPANY, loggedCompany.id, COMPANY_MEDIA_CATEGORY, data.id]), data)
            .then(response => {
                onSubmitData(response)
                setLoading(false);
            }).catch(e => {
                setException(e.message);
                setLoading(false);
            });
    };



    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-1">
                <div className="grid md:grid-cols-1 lg:grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-1">
                    <InputText
                        id={"name"}
                        type={"text"}
                        errors={errors.name}
                        input={{ ...register("name", { required: true }) }}
                        label={t("app.sub_categories.sub_category_name")}
                    />

                </div>
            </div>

            {/* Warning */}
            {exception && (
                <Warning message={exception} />
            )}


            <div className="flex before:flex-1 items-center justify-between mt-6">
                <InputSubmit
                    isLoading={isLoading}
                    label={t("app.form.save")}
                />
            </div>
        </form>
    );
}

export default CompanyMediaGroup;

import { DocumentTextIcon, DotsVerticalIcon, DuplicateIcon,   LinkIcon,   PencilIcon,  PlusIcon, TrashIcon } from "@heroicons/react/outline";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputText from "../../components/common/form/input-text";
import BoxUploadMultifile from "../../components/partials/upload/box-upload-multifile";
import InputEmailEditor from "../../components/common/form/input-email-editor";
import BoardSlideLeft from "../../components/board/board-slide-left";
import { useTranslation } from "react-i18next";
import { deleteElement, getElement, postElement, putElement } from "../../api/config";
import { ACTIVITIES, ACTIVITY, BOARD, RELETED_ENTITIES, RELETED_ENTITY, RELETED_TASK, SEND_NOTIFICATION, STATUS, STATUSES, TASK } from "../endpoints";
import { getPathParam, getSelectParam, makeid } from "../../utils/converter";
import toast from "react-hot-toast";
import InputDate from "../../components/common/form/input-date";
import BoardActionsMenu from "../../components/board/styles/board-actions-menu";
import InputSelect from "../../components/common/form/input-select";
import ButtonSubmit from "../../components/common/form/button-submit";
import { getCustomFormat, getFormattedDate } from "../../utils/timeUtils";
import UsersToolbar from "../../components/common/card/users-toolbar";
import DynamicSearch from "../../components/partials/common/dynamic-search";
import { TASKS } from "../../api/endpoints";
import LoaderWrapper from "../../components/common/loader/loader-wrapper";
import UserInitial from "../../components/common/table/user-initial";
import BoardReletedEntities, { ATTACHMENT } from "../../constants/config";
import { getFileType } from "../../constants/file-extension";
import { useSelector } from "react-redux";
import Initials from "../../components/common/avatar/initials";
import RemovePopup from "../../components/common/popup/remove-popup";
import CustomUserSelect from "../../components/common/form/custom-user-select";
import ConfirmPopup from "../../components/common/popup/confirm-popup";
import RelationEntities, { BookingCard, CompanySupplierCard, ContainerCard, CustomerCard, LeadCard, ProductCard, QuotationCard, ShippingCard } from "./relation-entities";
import InputFormattedNumber from "../../components/common/form/input-formatted-number";
import NumberFormat from "react-number-format";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const slugify = text =>
    text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')


const TaskManage = ({ isOpen, canEditArchived = null, setIsOpen, setClean, boardData, statusDetail, taskDetail, onRefresh, onOpenReletadTask, canEdit = true, isArchived = false, onRefreshArchive = null, isAdmin = false, isMember = false }) => {


    const { t } = useTranslation();
    const { detail } = useSelector((state) => state.user);

    const [isLoadingTask, setLoadingTask] = useState(false);

    const [taskData, setTaskData] = useState(taskDetail);

    const [isNewTask, setNewTask] = useState(taskData === false);


    const [entitiesData, setEntitiesData] = useState([]);


    const [pictureAttachment, setPictureAttachment] = useState(false);

    // Remove Details
    const [isOpenRemove, setOpenRemove] = useState(false);
    const [removeDetail, setRemoveDetail] = useState({});

    // Confirm Modal
    const [isOpenConfirm, setOpenConfirm] = useState(false);
    const [confirmDetail, setConfirmDetail] = useState({});


    const reletedEntities = BoardReletedEntities();


    useEffect(() => {
        if (taskData){
            setNewTask(taskData === false)
        }
        // eslint-disable-next-line
    }, [taskData]);



    const findPirctureAttachment = (attachments) => {

        let flag = true;
        let isStop = false;
        // eslint-disable-next-line
        attachments.map((attachment) => {
            if (attachment.file && !isStop){
                const ext = attachment.file.reference.split('.').pop();
                const fileType = getFileType(ext);
                if (fileType && fileType.split('/').shift() === 'image'){
                    setPictureAttachment(attachment.file.reference);
                    flag = false;
                    isStop = true;
                }
            }
        });

        if (flag){
            setPictureAttachment(false);
        }
    }


    const fetchReleatedEntiites = async (signal = null) => {
        if (boardData && isOpen && !isNewTask) {
            let taskId = taskDetail ? taskDetail.id : (taskData?.id ?? false);
            if (taskId){
                try {
                    const res = await getElement(getPathParam([BOARD, boardData.id, TASK, taskId, RELETED_ENTITIES]), signal);
                    setEntitiesData(res.entities)
                } catch (e) {
                    setEntitiesData([])
                }
            }


        }
    }



    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchData() {
            if (boardData && isOpen && !isNewTask) {
                setLoadingTask(true);
                try {
                    const res = await getElement(getPathParam([BOARD, boardData.id, TASK, taskDetail.id]), signal);
                    setTaskData(res);
                    findPirctureAttachment(res?.task_attachments ?? []);
                } catch (e) {
                    setLoadingTask(false);
                } finally {
                    setLoadingTask(false);
                }
            }
        }

        fetchData();
        fetchReleatedEntiites(signal);
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [isOpen, taskDetail, isNewTask]);
    

    const onSubmit = async (data) => {
        postElement(getPathParam([BOARD, boardData.id, STATUS, statusDetail.id, TASK]), data)
            .then(response => {
                setTaskData(response);
                onRefresh(true);
                setNewTask(false);
                toast.success(t("app.tasks.update_success"))
            }).catch(e => {
                toast.error(e.message);
            });

    };


    const onUpdate = async (data) => {
        if (taskData && canEdit){
            data['id'] = taskData.id;
            putElement(getPathParam([BOARD, boardData.id, STATUS, statusDetail.id, TASK, taskData.id]), data)
            .then(response => {
                setTaskData(response);
                toast.success(t("app.tasks.update_success"));
                onRefresh(true);
            }).catch(e => {
                toast.error(e.message);
            });
        }
    };

    const isTaskMember = (taskDetails) => {
        let admin = false;


        if (isAdmin) {
            admin = true;
        }

        if (taskDetails.user && taskDetails.user.id === detail.id) {
            admin = true;
        }

        if (taskDetails) {

            if (taskDetails.assigned_users.length  === 0){
                admin = true;
            }


            if (taskDetails.assigned_users.length > 0 && taskDetails.assigned_users.filter(r => (r.id === detail.id)).length > 0) {
                admin = true;
            }
        }


        return admin;
    }


    let canEditData = canEditArchived !== null ? false : ((taskData && taskData.user && taskData.user.id === detail.id) ? true : (isTaskMember(taskData) && canEdit));

    return (

        <>

            <BoardSlideLeft
                title={isNewTask ? t("app.board.add_task") : t("app.board.edit_task")}
                isOpen={isOpen}
                isNewTask={isNewTask}
                setIsOpen={setIsOpen}
                canEdit={canEditData}
                isActiveNotification={(taskData?.assigned_user_notification ?? [])?.find(r => r.user_id === detail.id)?.send_notification ?? false}
                isArchived={isArchived}
                isAdmin={isAdmin || (taskData && taskData.user && taskData.user.id === detail.id)}
                setClean={() => {
                    setTaskData(false);
                    setClean();
                }}
                onDeleteTask={() => {
                    const removeDetail = {
                        title: t("app.board.remove_task_title"),
                        message: t("app.board.remove_task_description"),
                        endpoint: getPathParam([BOARD, boardData.id, TASK, taskData.id])
                    };
                    setRemoveDetail(removeDetail);
                    setOpenRemove(true);
                }}
                onArchiveTask={() => {
                    const confirmDetail = {
                        title: t("app.board.archive_task_title"),
                        message: t("app.board.archive_task_description"),
                        endpoint: getPathParam([BOARD, boardData.id, STATUS, statusDetail.id, TASK, taskData.id]),
                        endpointData: { id: taskData.id, is_archived: true }
                    };

                    setConfirmDetail(confirmDetail);
                    setOpenConfirm(true);
                }}
                onRemoveArchiveTask={() => {
                    const confirmDetail = {
                        title: t("app.board.remove_archive_task_title"),
                        message: t("app.board.remove_archive_task_description"),
                        endpoint: getPathParam([BOARD, boardData.id, STATUS, statusDetail.id, TASK, taskData.id]),
                        endpointData: { id: taskData.id, is_archived: false }
                    };

                    setConfirmDetail(confirmDetail);
                    setOpenConfirm(true);
                }}
                onChangeNotification={(r) => {
                    const confirmDetail = {
                        type: 'notification',
                        title: r ? t("app.board.active_notification_title") : t("app.board.disactive_notification_title"),
                        message: r ? t("app.board.active_notification_description") : t("app.board.disactive_notification_description"),
                        endpoint: getPathParam([BOARD, boardData.id, STATUS, statusDetail.id, TASK, taskData.id, SEND_NOTIFICATION]),
                        endpointData: { id: taskData.id, send_notification: r }
                    };

                    setConfirmDetail(confirmDetail);
                    setOpenConfirm(true);
                }}
            >

                <div className={classNames(" mb-6 bg-white ")}>

                    <LoaderWrapper isLoading={isLoadingTask && !isNewTask}>

                        {
                            pictureAttachment && (
                                <div className={'bg-gray-700'}>
                                    <img className="w-full  object-contain h-52" src={pictureAttachment} alt="" />
                                </div>
                            )
                        }

                        <div className="px-4 sm:px-6  ">
                            {/* TITLE */}
                            <div className=" mt-1 pt-1.5 ">
                                <TaskTitle
                                    canEdit={canEditData}
                                    isNewTask={isNewTask}
                                    title={isNewTask ? false : taskData?.title ?? ''}
                                    onChange={async (r) => {
                                        if (isNewTask) {
                                            await onSubmit({ title: r });
                                        } else {
                                            await onUpdate({ title: r });
                                        }
                                    }}

                                />
                                <p className="text-sm font-medium text-gray-500 pl-1.5">
                                    {t("app.board.on_status", { status_name: statusDetail?.title ?? '' })}
                                </p>
                            </div>

                            <div className={classNames(
                                "mt-3 p-2 relative ",
                                isNewTask && ' blur-sm  cursor-not-allowed'
                            )}>
                                {
                                    isNewTask && (
                                        <div className="absolute inset-0   z-[100000] " />
                                    )
                                }

                                {/* PROPERIES */}
                                <PropertiesCard
                                    taskData={taskData}
                                    canEdit={canEditData}
                                    boardData={boardData}
                                    entitiesData={entitiesData}
                                    reletedEntities={reletedEntities}
                                    onChange={async (property, value) => {
                                        await onUpdate({ [property]: value });
                                    }}
                                    onRefresh={(r) => {
                                        fetchReleatedEntiites();
                                    }}
                                />


                                {/* PROPERIES */}
                                <CheckListCard
                                    canEdit={canEditData}
                                    taskData={taskData}
                                    onChange={async (property, value) => {
                                        await onUpdate({ [property]: value });
                                    }}
                                />

                                {/* SUB-TAKS */}
                                <SubTaskCard
                                    taskData={taskData}
                                    canEdit={canEditData}
                                    statusDetail={statusDetail}
                                    boardData={boardData}
                                    onUpdate={(response) => {
                                        setTaskData(response);
                                        onRefresh(true);
                                    }}
                                    onOpenTask={(id, is_archived, board_status, virtual_board) => {
                                        onOpenReletadTask({ id: id, is_archived: is_archived, board_status: board_status, virtual_board: virtual_board});
                                    }}
                                />


                                {/* ATTACHMENTS */}
                                <AttachmentsCard
                                    canEdit={canEditData}
                                    taskData={taskData}
                                    boardData={boardData}
                                    onUpdateAttachments={(att) => {
                                        findPirctureAttachment(att);
                                    }}
                                    onRefresh={onRefresh}
                                />


                                <DescriptionCard
                                    canEdit={canEditData}
                                    taskData={taskData}
                                    onChange={async (property, value) => {
                                        await onUpdate({ [property]: value });
                                    }}
                                />

                                {/* ACTIVITY */}
                                <ActivityCard
                                    canEdit={canEditData}
                                    taskData={taskData}
                                    boardData={boardData}
                                    isNewTask={isNewTask}
                                    onRefresh={onRefresh}
                                />

                            </div>
                        </div>





                    </LoaderWrapper>
                    {
                        isOpenRemove && (
                            <RemovePopup
                                isOpen={isOpenRemove}
                                setIsOpen={setOpenRemove}
                                detail={removeDetail}
                                onRemoveConfirmed={() => {
                                    if (onRefreshArchive){
                                        onRefreshArchive(true);
                                        setIsOpen(false);
                                        setTaskData(false);
                                        setClean();
                                    }else{
                                        onRefresh(true);
                                        setIsOpen(false);
                                        setTaskData(false);
                                        setClean()
                                    }
                                   
                                }}
                            />
                        )
                    }

                    {isOpenConfirm && (
                        <ConfirmPopup
                            isOpen={isOpenConfirm}
                            setIsOpen={(r, c) => {
                                setOpenConfirm(false);
                            }}
                            detail={confirmDetail}
                            onConfirmed={() => {
                                if (onRefreshArchive) {
                                    onRefreshArchive(true);
                                }
                                if (confirmDetail.type && confirmDetail.type === 'notification'){
                                    onRefresh(true);
                                }else{
                                    onRefresh(true);
                                    setIsOpen(false);
                                    setTaskData(false);
                                    setClean();
                                }
                                

                            }}
                            setDetail={(res) => {
                                if (confirmDetail.type && confirmDetail.type === 'notification') {
                                    setTaskData(res);
                                }
                            }}
                        />
                    )}
                
                </div>  
            </BoardSlideLeft>

           
        </>


    )
};




const TaskTitle = ({ isNewTask, title, onChange, canEdit }) => {

    const [isOpenInput, setOpenInput] = useState(isNewTask);
    const wrapperRef = useRef(null);
    const { t } = useTranslation();

    const {
        register,
        formState: { errors },
        watch,
        setValue
    } = useForm();


    useEffect(() => {
        if ((watch('title') !== undefined && watch('title') !== '' && isNewTask) || !isNewTask){
            function handleClickOutside(event) {
                if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                    setOpenInput(false);
                    onChange(watch('title'));
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
        // eslint-disable-next-line
    }, [wrapperRef, watch('title')]);


    useEffect(() => {
        if (title && title !== '') {
            setValue('title', title);
        }
    }, [title, setValue]);




    return (
        <div className={classNames(
            "relative mt-1 p-1.5",
            !isOpenInput && "hover:bg-gray-200 hover:rounded"
        )}>
            {
                isOpenInput ? (
                    <dd className="relative w-full z-10" ref={wrapperRef}>
                        <InputText
                            id={'title'}
                            type={"textarea-2"}
                            errors={errors.title}
                            input={{ ...register('title', { required: false }) }}
                            label={t("app.board.task_title")}
                            isShowLabel={false}
                        />
                    </dd>
                ) : (
                    <>
                        <h1 className="text-2xl font-bold text-blue-1000 cursor-pointer" onClick={() => {
                                if (canEdit){
                                    setOpenInput(true);
                                }
                            }}>{watch('title')}</h1>
                    </>
                )
            }





        </div>
    )
}
// PROPERTIES
const PropertiesCard = ({ taskData, onChange, boardData, onRefresh, entitiesData, reletedEntities, canEdit }) => {


    const fixProperty = (dataProperties) => {
        let properties = [];
        // eslint-disable-next-line
        dataProperties.map(p => {
            properties.push({
                title: p.title,
                value: null,
                type: p.type,
                field: p.field
            });
        });
        return properties;
    }



    const [dynamicTaskProperties, setDynamicTaskProperties] = useState(taskData?.properties ?? ( fixProperty(boardData?.properties ?? [])));
    const [checkList, setCheckList] = useState(taskData?.check_list ?? []);
    const [isClose, setClose] = useState(false);


    useEffect(() => {
        let timer;
        if (isClose) {
            timer = setTimeout(() => {
                setClose(false);
            }, 1000); // 500 ms = 0.5 second
        }

        // Cleanup the timer if the component is unmounted or flag changes
        return () => clearTimeout(timer);
    }, [isClose])

    const {
        register,
        control,
        formState: { errors },
        setValue,
        watch
    } = useForm();
    const { t } = useTranslation();
    

    let internalTaskProperties = [
        {
            title: t('app.board.task_reference'),
            value: taskData?.task_reference ?? null,
            type: 'text',
            isCustomConfiguration: false,
            isDisabled: true,
            field: 'task_reference'
        },
        {
            title: t('app.board.status_select'),
            value: (taskData && taskData.board_status) ? getSelectParam(taskData.board_status, "title") : null,
            type: 'board_status',
            isCustomConfiguration: true,
            field: 'board_status'
        },
        {
            title: t('app.board.task_start_date'),
            value: taskData?.task_date ?? null,
            type: 'date',
            isCustomConfiguration: false,
            field: 'task_date'
        }, 
        {
            title: t('app.board.task_end_date'),
            value: taskData?.end_date ?? null,
            type: 'date',
            isCustomConfiguration: false,
            field: 'end_date'
        },
        {
            title: t('app.board.task_users'),
            value: taskData.assigned_users && taskData.assigned_users.length > 0 ? taskData.assigned_users  : null,
            type: 'users',
            isCustomConfiguration: true,
            field: 'assigned_users'
        }
    ];



    const onSubmit = async (property_title, property_type) => {
        dynamicTaskProperties.push({
            title: property_title,
            value: null,
            type: property_type.value,
            field: slugify(property_title) + makeid(20)
        });

        setValue('property_title', null);
        setValue('property_type', null);
        setDynamicTaskProperties(dynamicTaskProperties);
        onChange('properties', dynamicTaskProperties)
    };

    const onSubmitCheckList = async (data) => {
        checkList.push({
            title: data,
            field: slugify(data) + makeid(20),
            checklists: [],
        });
        setValue('checklist_title', null);
        setCheckList(checkList);
        onChange('check_list', checkList)
    };


    

    return (
        <>
            <dl className="">


                {
                    (taskData && taskData.user) && (
                        <div className="p-1 md:grid md:grid-cols-4 gap-4 px-0 md:flex md:items-center ">
                            <dt className="text-sm font-medium leading-6 text-gray-900  md:py-1 flex items-center group ">
                                <p>{t("app.quotations.creator")}</p>
                            </dt>

                            <dd className={classNames(
                                "relative mt-1 flex group items-center justify-between text-sm leading-6  text-gray-700 md:p-1 md:pl-2 md:col-span-3 mt-0",
                            )}>
                                <div className={'mr-10'}>
                                    <p>
                                        <div className="flex items-center gap-1 text-sm  border-b last:border-0">
                                            <UserInitial user={taskData.user} ringClass={" ring-yellow-400 "} />
                                            <div className="text-left ml-3">
                                                <div className="truncate text-sm text-gray-900">
                                                    {taskData?.user?.fullname ?? ''}
                                                </div>
                                                <div className="-mt-1 text-xs flex-wrap text-gray-500" style={{ fontSize: '11px' }}>
                                                    {t("app.quotations.created_on", { date: getCustomFormat(taskData.created, "DD/MM/YYYY") })}
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </dd>
                        </div>
                    )
                }

                {
                    internalTaskProperties.map((property, index) => (
                        <TaskItem 
                            key={index}
                            canEdit={canEdit}
                            field={property.field}
                            title={property.title} 
                            value={property.value}
                            boardData={boardData}
                            isDisabled={property?.isDisabled ?? false}
                            type={property.type}
                            canDelete={false}
                            zIndex={"3" + internalTaskProperties.length - index}
                            isCustomConfiguration={property.isCustomConfiguration}
                            onChange={(value) => {
                                onChange(property.field, value)
                            }}
                        />
                    ))
                }

                <EntityItem
                    entitiesData={entitiesData}
                    canEdit={canEdit}
                    reletedEntities={reletedEntities}
                    boardData={boardData}
                    taskData={taskData}
                    onUpdate={() => {
                        onRefresh(true);
                    }}
                />

                {
                    dynamicTaskProperties.map((property, index) => (
                        <TaskItem
                            key={index}
                            canEdit={canEdit}
                            field={property.field}
                            title={property.title}
                            value={property?.value ?? null}
                            type={property.type}
                            attach={property?.attach ?? false}
                            boardData={boardData}
                            canDelete={true}
                            zIndex={"2" + dynamicTaskProperties.length - index}
                            onDeleteProperty={(type, field) => {
                                let filtered = [...dynamicTaskProperties.filter(r => r.field !== field)];
                                setDynamicTaskProperties(filtered);
                                onChange('properties', filtered)
                            }}
                            onPinProperty={(field) => {
                                let filtered = [...dynamicTaskProperties];
                                let foundIndex = filtered.findIndex((x) => x.field === field);

                                if (filtered[foundIndex]['attach'] !== undefined) {
                                    filtered[foundIndex]['attach'] = !filtered[foundIndex]['attach'];
                                }else{
                                    filtered[foundIndex]['attach'] = true;
                                }

                                setDynamicTaskProperties(filtered);
                                onChange('properties', filtered)
                            }}
                            onChange={(value) => {
                                let filtered = [...dynamicTaskProperties];
                                let foundIndex = filtered.findIndex((x) => x.field === property.field);
                                filtered[foundIndex]['value'] = value;
                                setDynamicTaskProperties(filtered);
                                onChange('properties', filtered)
                            }}
                        />
                    ))
                }


               
            </dl>
            
            
            {
                canEdit && (
                    <div className="flex items-center mt-3">
                        <BoardActionsMenu
                            btnClass="flex w-full flex-1 items-center cursor-pointer group"
                            extraClass={''}
                            onClose={isClose}
                            classExtra={'w-[300px]'}
                            icon={
                                (<PlusIcon className="h-4 w-4 flex-shrink-0 text-gray-400 group-hover:text-gray-600" aria-hidden="true" />)
                            }
                            title={(
                                <div className="ml-1 flex min-w-0 flex-1 gap-2">
                                    <span className="text-sm font-medium leading-6 text-gray-400 group-hover:text-gray-600">{t("app.board.add_property")}</span>
                                </div>
                            )}
                        >

                            <div className="-m-3  space-y-4 flex flex-col   rounded-lg p-2 transition duration-150 ease-in-out ">

                                <InputText
                                    id={'property_title'}
                                    type={"text"}
                                    errors={errors.property_title}
                                    input={{ ...register('property_title', { required: true }) }}
                                    label={t('app.board.property_title')}
                                    isRequired={true}
                                />


                                <Controller
                                    name="property_type"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                        <InputSelect
                                            label={t("app.board.property_type")}
                                            name={name}
                                            options={[{ value: 'text', label: t("app.board.property_text") }, { value: 'date', label: t("app.board.property_date") }, { value: 'number', label: t("app.board.property_number") }, { value: 'currency', label: t("app.board.property_currency") }]}
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            isLoading={false}
                                            isDisabled={false}
                                            errors={error}
                                            isRequired={true}
                                        />
                                    )}
                                />


                                <ButtonSubmit
                                    btnColor={'bg-blue-1000 hover:bg-blue-900 '}
                                    isLoading={false}
                                    isFullWith={true}
                                    label={t("app.board.property_add")}
                                    disabled={!(watch('property_title') && watch('property_type'))}
                                    onClick={() => {
                                        setClose(true);
                                        onSubmit(watch('property_title'), watch('property_type'));
                                    }}
                                />

                            </div>
                        </BoardActionsMenu>

                        <BoardActionsMenu
                            btnClass="ml-3 flex w-full flex-1 items-center cursor-pointer group"
                            extraClass={''}
                            onClose={isClose}
                            classExtra={'w-[300px]'}
                            icon={
                                (<PlusIcon className="h-4 w-4 flex-shrink-0 text-gray-400 group-hover:text-gray-600" aria-hidden="true" />)
                            }
                            title={(
                                <div className="ml-1 flex min-w-0 flex-1 gap-2">
                                    <span className="text-sm font-medium leading-6 text-gray-400 group-hover:text-gray-600">{t("app.board.add_check_list")}</span>
                                </div>
                            )}
                        >

                            <div  className="-m-3  space-y-4 flex flex-col   rounded-lg p-2 transition duration-150 ease-in-out ">
                                <InputText
                                    id={'checklist_title'}
                                    type={"text"}
                                    errors={errors.checklist_title}
                                    input={{ ...register('checklist_title', { required: true }) }}
                                    label={t('app.board.check_list_title')}
                                    isRequired={true}
                                />
 
                                <ButtonSubmit
                                    btnColor={'bg-blue-1000 hover:bg-blue-900 '}
                                    isLoading={false}
                                    isFullWith={true}
                                    disabled={!watch('checklist_title')}
                                    label={t("app.board.add_check_list")}
                                    onClick={() => {
                                        setClose(true);
                                        onSubmitCheckList(watch('checklist_title'));
                                    }}
                                />
                            </div>
                        </BoardActionsMenu>


                        <RelationEntities
                            boardData={boardData}
                            taskData={taskData}
                            onRefresh={() => {
                                onRefresh(true)
                            }}
                        />
                    </div>
                )
            }

           

        </>
    )
}

const TaskItem = ({ title, value, type, field, attach = false, onChange, isCustomConfiguration = false, isDisabled = false, boardData, canDelete, onDeleteProperty, onPinProperty, canEdit }) => {


    const [isOpenInput, setOpenInput] = useState(value === null);
    const wrapperRef = useRef(null);
    const { company } = useSelector((state) => state.userCompany);


    const {
        register,
        formState: { errors },
        control,
        setValue,
        watch,
    } = useForm();

    useEffect(() => {
        if ((watch(field) !== undefined && watch(field) !== '') || (isCustomConfiguration && watch(field))) {
            function handleClickOutside(event) {
                
                if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                    setOpenInput(false);
                    onChange(watch(field));
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
        // eslint-disable-next-line
    }, [wrapperRef, field, watch(field)]);


    useEffect(() => {
        if (value && value !== '') {
            setValue(field, value);
        }

    }, [field, value, setValue]);


    useEffect(() => {
        if (value === null && !isDisabled) {
            setOpenInput(true);
        }
        if (isDisabled) {
            setOpenInput(false);
        }

    }, [value, isDisabled]);



    return (
        <div className="p-1 md:grid md:grid-cols-4 gap-4 px-0 md:flex md:items-center ">
            <dt className="text-sm font-medium leading-6 text-gray-900  md:py-1 flex items-center group ">
                {
                    (canDelete && canEdit)  && (
                        <div className="cursor-pointer" onClick={() => {
                            onDeleteProperty('dProperty', field);
                        }}>
                            <TrashIcon className="h-4 w-4 text-red-600 mr-2" />
                        </div>
                       
                    )
                }
                <p>{title}</p>

                {
                    (canDelete && value !== null) && (
                        <div className={attach ? "cursor-pointer " : "cursor-pointer hidden group-hover:block"} onClick={() => {
                            onPinProperty(field);
                        }}>
                            <LinkIcon className="h-4 w-4 text-gray-900 ml-2" />
                        </div>

                    )
                }
            </dt>

            <dd className={classNames(
                "relative mt-1 flex group items-center justify-between text-sm leading-6  text-gray-700 md:p-1 md:pl-2 md:col-span-3 mt-0",
                !isOpenInput && "hover:bg-gray-200 hover:rounded"
            )}>
                {
                    (isOpenInput && canEdit) ? (
                        <dd  className={`relative  w-full`} ref={wrapperRef}>

                            {
                                type === 'date' && (
                                    <Controller
                                        name={field}
                                        rules={{ required: false }}
                                        control={control}
                                        render={({ field: { onChange, value, name }, fieldState: { error }, }) => (
                                            <InputDate
                                                label={title}
                                                isShowLabel={false}
                                                format={"YYYY-MM-DD HH:mm:ss"}
                                                placeholder={"Es. 01/12/2021"}
                                                activeMinDate={false}
                                                startDate={value}
                                                isShowTime={false}
                                                errors={error}
                                                name={name}
                                                onChange={(e) => {
                                                    if (e === ''){
                                                        onChange(null);
                                                    }else{
                                                        onChange(e);
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                )
                            }

                            {
                                type === 'board_status' && (
                                    <Controller
                                        name={field}
                                        rules={{ required: false }}
                                        control={control}
                                        render={({
                                            field: { onChange, value, name },
                                            fieldState: { error },
                                        }) => (
                                            <DynamicSearch
                                                isShowLabel={false}
                                                selectLabel={title}
                                                endpoint={getPathParam([BOARD, boardData.id, STATUSES])}
                                                responseLabel={'statuses'}
                                                label={'title'}
                                                errors={error}
                                                value={value}
                                                name={name}
                                                onChange={onChange}
                                            />
                                        )}
                                    />

                                )
                            }

                            {
                                type === 'text' && (
                                    <InputText
                                        id={field}
                                        type={"textarea-2"}
                                        errors={errors[field]}
                                        input={{ ...register(field, { required: false }) }}
                                        label={''}
                                        isShowLabel={false}
                                    />
                                )
                            }


                            {
                                type === 'number' && (
                                    <Controller
                                        name={field}
                                        rules={{ required: false }}
                                        control={control}
                                        render={({
                                            field: { onChange, value, name },
                                            fieldState: { error },
                                        }) => (
                                            <InputFormattedNumber
                                                isShowLabel={false}
                                                label={title}
                                                name={name}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                }}
                                                prefix={""}
                                                suffix={""}
                                                errors={error}
                                            />
                                        )}
                                    />
                                )
                            }

                            {
                                type === 'currency' && (
                                    <Controller
                                        name={field}
                                        rules={{ required: false }}
                                        control={control}
                                        render={({
                                            field: { onChange, value, name },
                                            fieldState: { error },
                                        }) => (
                                            <InputFormattedNumber
                                                isShowLabel={false}
                                                label={title}
                                                name={name}
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                }}
                                                prefix={""}
                                                suffix={" " + (company.currency?.currency_symbol ?? '')}
                                                errors={error}
                                            />
                                        )}
                                    />
                                )
                            }


                            {
                                type === 'users' && (
                                    <UserList 
                                        activeUsers={value}
                                        boardData={boardData}
                                        onChange={(r) => {
                                            setValue(field, r);
                                        }}
                                    />
                                )
                            }
                        </dd>
                    ) : (
                        <>
                            <div className={isDisabled ? 'mr-10' : 'cursor-pointer mr-10'} onClick={() => {
                                if (!isDisabled && canEdit){
                                    setOpenInput(true);
                                }
                            }}>

                                        {
                                            type === 'date' && (
                                                <p> {getFormattedDate(watch(field))}</p>
                                            )
                                        }

                                        {
                                            type === 'text' && (
                                                <p> {watch(field)}</p>
                                            )
                                        }

                                        {
                                            type === 'number' && (
                                                <p>
                                                    <NumberFormat
                                                        value={watch(field)}
                                                        displayType={"text"}
                                                        decimalScale={2}
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        prefix={""}
                                                    />
                                                </p>
                                            )
                                        }


                                        {
                                            type === 'currency' && (
                                                <p>
                                                    <NumberFormat
                                                        value={watch(field)}
                                                        displayType={"text"}
                                                        decimalScale={2}
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        suffix={" " + (company.currency?.currency_symbol ?? '')}
                                                    />
                                                </p>
                                            )
                                        }

                                        {
                                            type === 'board_status' && (
                                                <p>
                                                    {watch(field)?.label ?? ''}
                                                </p>
                                            )
                                        }



                                    

                                        {
                                            (type === 'users' && value) && (
                                                <div className="mt-1">
                                                    <UsersToolbar users={(value ?? [])} isActive={(value ?? []).length > 1} className={'relative'}>
                                                        <div className="flex items-center space-x-2 mr-3">
                                                            <div
                                                                className={(value ?? []).length === 1 ? "flex items-center gap-1 text-sm" : "flex flex-shrink-0 -space-x-1"}>

                                                                {((value ?? []).length >= 4 ? value.slice(0, 4) : value).map((user, index) => (
                                                                    <UserInitial user={user} />
                                                                ))}
                                                            </div>
                                                            {value.length > (value.length >= 4 ? value.slice(0, 4) : value).length ? (
                                                                <span className="flex-shrink-0 text-xs font-medium leading-5">
                                                                    +{value.length - (value.length >= 4 ? value.slice(0, 4) : value).length}
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    </UsersToolbar>


                                                </div>
                                            )
                                        }
                            </div>


                            {
                                !isCustomConfiguration && (
                                    <button
                                        type="button"
                                        className="hidden group-hover:block text-gray-700 hover:text-gray-900  mr-2"
                                        onClick={() => {
                                            let copyText = '';

                                            if (type === 'date'){
                                                copyText = getFormattedDate(watch(field));
                                            }
                                           
                                            if (type === 'text' || type === 'number' || type === 'currency' ) {
                                                copyText = watch(field);
                                            }

                                            navigator.clipboard.writeText(copyText)
                                        }}
                                    >
                                        <span className="sr-only">Close panel</span>
                                        <DuplicateIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                )
                            }

                            {
                                    (type === 'users' || type === 'board_status') && canEdit && (
                                    <div className="hidden group-hover:block text-gray-700 hover:text-gray-900  mr-2 cursor-pointer" onClick={() => {
                                            if (!isDisabled && canEdit) {
                                            setOpenInput(true);
                                        }
                                    }}>
                                        <PencilIcon className="h-5 w-5 text-gray-900" />
                                    </div>
                                )
                            }
                       
                        </>
                    )
                }





            </dd>
        </div>
    )
}

const EntityItem = ({ entitiesData, reletedEntities, boardData, taskData, onUpdate, canEdit }) => {

    const { t } = useTranslation();
    // Remove Details
    const [isOpenRemove, setOpenRemove] = useState(false);
    const [removeDetail, setRemoveDetail] = useState({});



    const getFiltered = () => {
        if (entitiesData.length > 0) {
            const customOrder = reletedEntities.map(function (obj) {
                return obj.value;
            });


            let list = entitiesData.sort((a, b) => customOrder.indexOf(a.entity_name) - customOrder.indexOf(b.entity_name));

            let result = list.reduce((r, a) => {
                let flag = a.entity_name;
                r[flag] = r[flag] || [];
                r[flag].push(a);
                return r;
            }, Object.create(null));

            return result;
        }
        return [];
    }


    const navigatePage = (entity, id_1, id_2 = '') => {
        let url = reletedEntities.find(r => r.value === entity)?.baseEndpoint ?? false;
        if (url){
            url = url.replace(":id", id_1).replace(":id_2", id_2);
            const newTab = window.open('', '_blank');
            newTab.location.href = url;
        }
    }
    
    return (
        <>
            {
                Object.keys(getFiltered()).length > 0 && (
                        Object.keys(getFiltered()).map((group) => (

                            <div key={group} className="p-1.5 gap-4 px-0 flex place-content-start grid grid-cols-1 md:grid-cols-4">
                               <div>
                                    <div className="text-sm font-medium leading-6 text-gray-900  py-1 flex items-center">
                                        <p>{reletedEntities?.find(r => r.value === group)?.label ?? ''}</p>
                                
                                    </div>
                               </div>

                                <div className={classNames(
                                    "relative md:col-span-3 mt-0",
                                )}>

                                    {getFiltered()[group].map((entity) => (
                                        <div key={entity.id}>

                                                {
                                                (entity.entity_name === 'shipping' && entity.object) && (
                                                        <ShippingCard
                                                            canEdit={canEdit}
                                                            data={entity.object} 
                                                            onSelect={() => {
                                                                navigatePage(entity.entity_name, entity.entity_id);
                                                            }} 
                                                            onDelete={() => {
                                                                const removeDetail = {
                                                                    title: t("app.board.remove_releted_entity"),
                                                                    message: t("app.board.remove_releted_entity_description"),
                                                                    endpoint: getPathParam([BOARD, boardData.id, TASK, taskData.id, RELETED_ENTITY, entity.id])
                                                                };
                                                            setRemoveDetail(removeDetail);
                                                            setOpenRemove(true);
                                                            }} 
                                                        />
                                                    )
                                                }

                                            {
                                                (entity.entity_name === 'booking' && entity.object) && (
                                                    <BookingCard
                                                        canEdit={canEdit}
                                                        data={entity.object}
                                                        onSelect={() => {
                                                            navigatePage(entity.entity_name, entity.entity_id);
                                                        }}
                                                        onDelete={() => {
                                                            const removeDetail = {
                                                                title: t("app.board.remove_releted_entity"),
                                                                message: t("app.board.remove_releted_entity_description"),
                                                                endpoint: getPathParam([BOARD, boardData.id, TASK, taskData.id, RELETED_ENTITY, entity.id])
                                                            };
                                                            setRemoveDetail(removeDetail);
                                                            setOpenRemove(true);
                                                        }}
                                                    />
                                                )
                                            }

                                                {
                                                (entity.entity_name === 'supplier' && entity.object) && (
                                                    <CompanySupplierCard canEdit={canEdit} data={entity.object} 
                                                        onSelect={() => {
                                                            navigatePage(entity.entity_name, entity.entity_id);
                                                        }} 
                                                    onDelete={() => {
                                                            const removeDetail = {
                                                                title: t("app.board.remove_releted_entity"),
                                                                message: t("app.board.remove_releted_entity_description"),
                                                                endpoint: getPathParam([BOARD, boardData.id, TASK, taskData.id, RELETED_ENTITY, entity.id])
                                                            };
                                                            setRemoveDetail(removeDetail);
                                                            setOpenRemove(true);
                                                        }} />
                                                    )
                                                }

                                                {
                                                (entity.entity_name === 'customer' && entity.object) && (
                                                    <CustomerCard canEdit={canEdit} data={entity.object} onSelect={() => {
                                                        navigatePage(entity.entity_name, entity.entity_id);
                                                        }} onDelete={() => {
                                                            const removeDetail = {
                                                                title: t("app.board.remove_releted_entity"),
                                                                message: t("app.board.remove_releted_entity_description"),
                                                                endpoint: getPathParam([BOARD, boardData.id, TASK, taskData.id, RELETED_ENTITY, entity.id])
                                                            };
                                                            setRemoveDetail(removeDetail);
                                                            setOpenRemove(true);
                                                        }} />
                                                    )
                                                }


                                                {
                                                (entity.entity_name === 'product' && entity.object) && (
                                                    <ProductCard canEdit={canEdit} data={entity.object} onSelect={() => {
                                                            navigatePage(entity.entity_name, entity.entity_id);
                                                        }} onDelete={() => {
                                                            const removeDetail = {
                                                                title: t("app.board.remove_releted_entity"),
                                                                message: t("app.board.remove_releted_entity_description"),
                                                                endpoint: getPathParam([BOARD, boardData.id, TASK, taskData.id, RELETED_ENTITY, entity.id])
                                                            };
                                                            setRemoveDetail(removeDetail);
                                                            setOpenRemove(true);
                                                        }} />
                                                    )
                                                }


                                                {
                                                (entity.entity_name === 'lead' && entity.object) && (
                                                    <LeadCard canEdit={canEdit} data={entity.object} onSelect={() => {
                                                        navigatePage(entity.entity_name, entity.entity_id);
                                                        }} onDelete={() => {
                                                            const removeDetail = {
                                                                title: t("app.board.remove_releted_entity"),
                                                                message: t("app.board.remove_releted_entity_description"),
                                                                endpoint: getPathParam([BOARD, boardData.id, TASK, taskData.id, RELETED_ENTITY, entity.id])
                                                            };
                                                            setRemoveDetail(removeDetail);
                                                            setOpenRemove(true);
                                                        }} />
                                                    )
                                                }

                                                {
                                                (entity.entity_name === 'quotation' && entity.object) && (
                                                    <QuotationCard canEdit={canEdit} data={entity.object} onSelect={() => {
                                                        navigatePage(entity.entity_name, entity.entity_id);
                                                    }} onDelete={() => {
                                                        const removeDetail = {
                                                            title: t("app.board.remove_releted_entity"),
                                                            message: t("app.board.remove_releted_entity_description"),
                                                            endpoint: getPathParam([BOARD, boardData.id, TASK, taskData.id, RELETED_ENTITY, entity.id])
                                                        };
                                                        setRemoveDetail(removeDetail);
                                                        setOpenRemove(true);
                                                    }} />
                                                    )
                                                }

                                                {
                                                (entity.entity_name === 'shipping_container' && entity.object) && (
                                                    <ContainerCard canEdit={canEdit} data={entity.object} onSelect={() => {
                                                            navigatePage(entity.entity_name, entity?.object?.shipping_id ?? '', entity.entity_id);
                                                        }} onDelete={() => {
                                                            const removeDetail = {
                                                                title: t("app.board.remove_releted_entity"),
                                                                message: t("app.board.remove_releted_entity_description"),
                                                                endpoint: getPathParam([BOARD, boardData.id, TASK, taskData.id, RELETED_ENTITY, entity.id])
                                                            };
                                                            setRemoveDetail(removeDetail);
                                                            setOpenRemove(true);
                                                        }} />
                                                )
                                            }


                                            


                                        </div>
                                    ))}
                                </div>
                            </div>

                        ))
                )
            }
        
            {
                isOpenRemove && (
                    <RemovePopup
                        isOpen={isOpenRemove}
                        setIsOpen={setOpenRemove}
                        detail={removeDetail}
                        setDetail={(res) => {
                            onUpdate()
                        }}
                    />
                )
            }

           
        
        </>
       
    )
}

const UserList = ({ activeUsers, onChange, boardData }) => {
    const [selectedUsers, setSelectedUsers] = useState(activeUsers === null ? [] : activeUsers);



    return (
        <CustomUserSelect
            label={''}
            users={boardData?.assigned_users ?? []}
            isShowLabel={false}
            placeholder={''}
            multiple={true}
            includeAll={true}
            excludeId={''}
            selectedUsers={selectedUsers}
            onSelect={(r) => {
                setSelectedUsers(r);

                let toRemove = activeUsers ?? [];

                let toAddUsers = [];
                let toRemoveUsers = [];

                let data = { to_add: [], to_remove: [] };


                if (r.length > 0) {
                    // eslint-disable-next-line
                    r.map((user) => {
                        toAddUsers.push({ user: { id: user.id } })
                    })

                    data['to_add'] = toAddUsers;
                    toRemove = (activeUsers ?? []).filter(obj1 => !r.some(obj2 => obj1.id === obj2.id));

                }

                if (toRemove.length > 0) {
                    // eslint-disable-next-line
                    toRemove.map((user) => {
                        toRemoveUsers.push({ user: { id: user.id } })
                    })
                    data['to_remove'] = toRemoveUsers;
                }
                onChange(data);
            }}
        />
    )
}


// CHECK-List
const CheckListCard = ({ taskData, onChange, canEdit }) => {

    const [checklists, setChecklists] = useState(taskData?.check_list ?? []);
    const [addChecklist, setAddChecklist] = useState(false);
    const [editChecklist, setEditChecklist] = useState(false);



    useEffect(() => {
        setChecklists(taskData?.check_list ?? [])
    }, [taskData])
    
    const {
        register,
        formState: { errors },
        setValue,
        watch
    } = useForm();
    const { t } = useTranslation();
    



    const getPercentage = (checklist) => {
        let percentage = 0;
        let confirmed = 0;
        let len = (checklist ?? []).length;
        // eslint-disable-next-line
        (checklist ?? []).map((item) => {
            if (item.status){
                confirmed = confirmed + 1;
            }
        })


        if (len > 0){
            percentage = (100 / len) * confirmed;
        }


        return percentage;
    }
    

    return (
        <>
            {
                checklists.map(((checklist, index) => (
                    <div className="mt-6" key={checklist.field}>

                        <div className="flex items-center mt-6 mb-2 ">
                            {
                                canEdit && (
                                    <div onClick={() => {
                                        let data = checklists.filter(r => r.field !== checklist.field);
                                        setChecklists(data);
                                        onChange('check_list', data)
                                    }}
                                        className="cursor-pointer mr-2"
                                    >
                                        <TrashIcon
                                            className="h-4 w-4 text-red-500 hover:text-red-300"
                                            aria-hidden="true"
                                        />
                                    </div>
                                )
                            }
                         
                            <h1 className="text-xl font-bold text-blue-1000 ">{checklist.title}</h1>
                        </div>

                        <div className="flex items-center mt-2 mb-4">
                            <div className="mr-2 text-blue-1000 text-sm font-medium">
                                {getPercentage(checklist?.checklists ?? []).toFixed(0)}%
                            </div>
                            <div class="w-full  bg-gray-200 rounded-full h-2.5">
                                <div class="bg-gray-900 h-2.5 rounded-full" style={{ width: getPercentage(checklist?.checklists ?? []).toFixed(0) +"%" }}></div>
                            </div>
                        </div>

                        {
                            (checklist?.checklists?? []).map((item, indexInternal) => (
                                <div className=" w-full mt-3" key={item.id}>
                                    {
                                        editChecklist !== item.id && (
                                            <div className="flex">
                                                <input
                                                    id={item.id}
                                                    aria-describedby={item.id + "-description"}
                                                    name={item.id}
                                                    defaultChecked={item.status}
                                                    disabled={!canEdit}
                                                    type="checkbox"
                                                    onChange={(r) => {
                                                        checklists[index]['checklists'][indexInternal]['status'] = r.target.checked;
                                                        setChecklists(checklists);
                                                        onChange('check_list', checklists)
                                                    }}
                                                    className="focus:ring-gray-900 h-4 w-4 text-gray-900 border-gray-900 rounded"
                                                />



                                                <label className="font-medium text-gray-700 cursor-pointer text-sm ml-3" onClick={() => {
                                                    if (canEdit) {
                                                        setEditChecklist(item.id);
                                                        setValue(item.id, item.description);
                                                    }
                                                }}>
                                                    {item.description}
                                                </label>

                                            </div>
                                        )
                                    }


                                    {
                                        editChecklist === item.id && (
                                            <div className="flex flex-col w-full">
                                                <InputText
                                                    id={item.id}
                                                    type={"textarea-2"}
                                                    errors={errors[item.id]}
                                                    input={{ ...register(item.id, { required: false }) }}
                                                    label={''}
                                                    isShowLabel={false}
                                                />

                                                <div className="flex items-center  mt-2">
                                                    <ButtonSubmit
                                                        btnColor={'bg-blue-1000 hover:bg-blue-900 '}
                                                        isLoading={false}
                                                        isFullWith={false}
                                                        disabled={!watch(item.id)}
                                                        label={t("app.board.update_check_list_element")}
                                                        onClick={() => {
                                                            checklists[index]['checklists'][indexInternal]['description'] = watch(item.id);
                                                            setChecklists(checklists);
                                                            onChange('check_list', checklists)
                                                            setEditChecklist(false);
                                                        }}
                                                    />

                                                    
                                                    <div onClick={() => {
                                                        let internal_checklists = checklists[index]['checklists'].filter(r => r.id !== item.id);
                                                        checklists[index]['checklists'] = internal_checklists;
                                                        setChecklists(checklists);
                                                        onChange('check_list', checklists)
                                                        setEditChecklist(false);
                                                    }}
                                                        className="bg-red-500 ml-3 hover:bg-red-300 cursor-pointer mr-2 disabled:opacity-70 flex justify-center py-2.5 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white"
                                                    >
                                                        <TrashIcon
                                                            className="h-4 w-4"
                                                            aria-hidden="true"
                                                        />
                                                    </div>

                                                    <div
                                                        onClick={() => {
                                                            setValue(item.id, checklist.description);
                                                            setEditChecklist(false);
                                                        }}
                                                        className="text-sm ml-3 underline inline-flex  items-center cursor-pointer"
                                                    >
                                                        {t("app.board.cancel")}
                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    }

                                    

                                </div>
                            ))
                        }

                        {
                            canEdit && (
                                <>
                                    {
                                        addChecklist === checklist.field ? (
                                            <div className="flex flex-col w-full mt-6">
                                                <InputText
                                                    id={'task_description_' + checklist.field}
                                                    type={"textarea-2"}
                                                    errors={errors['task_description_' + checklist.field]}
                                                    input={{ ...register('task_description_' + checklist.field, { required: false }) }}
                                                    label={''}
                                                    isShowLabel={false}
                                                />

                                                <div className="flex items-center  mt-2">
                                                    <ButtonSubmit
                                                        btnColor={'bg-blue-1000 hover:bg-blue-900 '}
                                                        isLoading={false}
                                                        isFullWith={false}
                                                        disabled={!watch('task_description_' + checklist.field)}
                                                        label={t("app.board.add_check_list")}
                                                        onClick={() => {
                                                            let filtered = checklist.checklists;
                                                            filtered = [...checklist.checklists, {
                                                                description: watch('task_description_' + checklist.field),
                                                                status: false,
                                                                id: makeid(30)
                                                            }];

                                                            checklists[index]['checklists'] = filtered;
                                                            setChecklists(checklists);
                                                            onChange('check_list', checklists)
                                                            setValue('task_description_' + checklist.field, null);
                                                            setAddChecklist(false);
                                                        }}
                                                    />

                                                    <div
                                                        onClick={() => {
                                                            setValue('task_description_' + checklist.field, null);
                                                            setAddChecklist(false);
                                                        }}
                                                        className="text-sm ml-3 underline inline-flex  items-center cursor-pointer"
                                                    >
                                                        {t("app.board.cancel")}
                                                    </div>

                                                </div>
                                            </div>
                                        ) : (
                                            <div className="flex w-full flex-1 items-center cursor-pointer group mt-3" onClick={() => {
                                                setAddChecklist(checklist.field);
                                            }}>
                                                <PlusIcon className="h-4 w-4 flex-shrink-0 text-gray-400 group-hover:text-gray-600" aria-hidden="true" />
                                                <div className="ml-1 flex min-w-0 flex-1 gap-2">
                                                    <span className="text-sm font-medium leading-6 text-gray-400 group-hover:text-gray-600">{t("app.board.add_check_list_element")}</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                
                                </>
                            )
                        }


                        

                        
                        
                    </div>
                )))
            }
            
            


    

        </>
    )
}

// SUB-TASKS
const SubTaskCard = ({ boardData, taskData, onUpdate, onOpenTask, canEdit }) => {

    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
        setValue,
    } = useForm();
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(false);
    const [isLoadingAssociaiton, setLoadingAssociaiton] = useState(false);


    // Remove Details
    const [isOpenRemove, setOpenRemove] = useState(false);
    const [removeDetail, setRemoveDetail] = useState({});



    
    const onCreateAssociation = async (data) => {
        postElement(getPathParam([BOARD, boardData.id, TASK, taskData.id, RELETED_TASK]), data)
        .then(response => {
            onUpdate(response);
            setValue('associated_task', null);
            setValue('task_title', null);
            setValue('associated_status', null);
            setLoading(false);
            setLoadingAssociaiton(false);
            toast.success(t("app.tasks.update_success"))
        }).catch(e => {
            toast.error(e.message);
            setLoading(false);
            setLoadingAssociaiton(false);
        });
    };



    const onSubmit = async (data) => {
        delete data.associated_task;
        setValue('associated_task', null);
        setLoading(true);
        await onCreateAssociation(data);
    };


    return (
        <>
            <div className="mt-6 ">
                <h1 className="text-xl font-bold text-blue-1000 mt-6 mb-2">{t("app.board.sub_task_title")}</h1>

                <ul className="divide-y divide-gray-300 rounded-md border border-gray-300">
                   
                   
                   {
                        (taskData?.related_tasks ?? []).map((related_task) => (
                            <li key={related_task.id} className="flex items-center justify-between p-3 pr-5 text-sm leading-6">

                                <div className="flex w-0 flex-1 items-center group cursor-pointer" onClick={() => {
                                    onOpenTask(related_task.id, related_task.is_archived, related_task.board_status, related_task.virtual_board)
                                }}>
                                    <DocumentTextIcon className="h-5 w-5 flex-shrink-0 text-gray-900 group-hover:text-gray-700" aria-hidden="true" />
                                    <div className="ml-2 flex min-w-0 flex-1 gap-2">
                                        <span className="text-sm font-medium leading-6 text-gray-900 group-hover:text-gray-700">{related_task.title}</span>
                                    </div>
                                </div>

                                 {
                                    canEdit && (
                                        <div className="ml-4 flex-shrink-0 flex text-sm ">
                                            <div

                                                onClick={() => {
                                                    const removeDetail = {
                                                        title: t("app.common.remove_generic_title"),
                                                        message: t("app.common.remove_generic_description"),
                                                        endpoint: getPathParam([BOARD, boardData.id, TASK, taskData.id, RELETED_TASK, related_task.id])
                                                    };
                                                    setRemoveDetail(removeDetail);
                                                    setOpenRemove(true);
                                                }}
                                                className="font-medium text-red-500 group hover:text-red-300 cursor-pointer flex text-sm"
                                            >
                                                <TrashIcon
                                                    className="h-5 w-5 text-red-500 group-hover:text-red-300"
                                                    aria-hidden="true"
                                                />
                                            </div>

                                        </div>
                                    )
                                 }
                               
                            </li>
                        ))
                   }
                   
                   
                    {
                        canEdit && (
                            <li className="flex items-center justify-between p-3 pr-5 text-sm leading-6">
                                <BoardActionsMenu
                                    btnClass="flex w-0 flex-1 items-center group"
                                    extraClass={''}
                                    classExtra={'w-[350px]'}
                                    icon={
                                        (<PlusIcon className="h-4 w-4 flex-shrink-0 text-gray-400 group-hover:text-gray-600" aria-hidden="true" />)
                                    }
                                    title={(
                                        <div className="ml-4 flex-shrink-0">
                                            <span className="text-sm font-medium leading-6 text-gray-400 group-hover:text-gray-600">{t("app.board.add_sub_task")}</span>
                                        </div>
                                    )}
                                >

                                    <form onSubmit={handleSubmit(onSubmit)} className="-m-3  space-y-4 flex flex-col   rounded-lg p-2 transition duration-150 ease-in-out ">



                                        <Controller
                                            name="associated_task"
                                            rules={{ required: false }}
                                            control={control}
                                            render={({
                                                field: { onChange, value, name },
                                                fieldState: { error },
                                            }) => (
                                                <DynamicSearch
                                                    selectLabel={t("app.board.task_title")}
                                                    endpoint={getPathParam([BOARD, boardData.id, TASKS])}
                                                    responseLabel={'tasks'}
                                                    escludeId={taskData.id}
                                                    esludeIds={taskData.related_tasks}
                                                    label={'title'}
                                                    errors={error}
                                                    isInternalLoading={isLoadingAssociaiton}
                                                    value={value}
                                                    name={name}
                                                    onChange={async (r) => {
                                                        setValue('task_title', null);
                                                        setValue('associated_status', null);
                                                        setLoadingAssociaiton(true);
                                                        await onCreateAssociation({ id: r.id });
                                                    }}
                                                />
                                            )}
                                        />


                                        <div className="relative">
                                            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                <div className="w-full border-t border-gray-200" />
                                            </div>
                                            <div className="relative flex justify-center text-sm font-medium leading-6">
                                                <span className="bg-white px-6 text-gray-900">{t("app.board.or_create")}</span>
                                            </div>
                                        </div>


                                        <InputText
                                            id={'task_title'}
                                            type={"text"}
                                            errors={errors.task_title}
                                            input={{ ...register('task_title', { required: false }) }}
                                            label={t('app.board.task_title')}
                                            isRequired={false}
                                        />


                                        <Controller
                                            name="associated_status"
                                            rules={{ required: false }}
                                            control={control}
                                            render={({
                                                field: { onChange, value, name },
                                                fieldState: { error },
                                            }) => (
                                                <DynamicSearch
                                                    selectLabel={t("app.board.status_select")}
                                                    endpoint={getPathParam([BOARD, boardData.id, STATUSES])}
                                                    responseLabel={'statuses'}
                                                    label={'title'}
                                                    errors={error}
                                                    value={value}
                                                    name={name}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />


                                        <ButtonSubmit
                                            btnColor={'bg-blue-1000 hover:bg-blue-900 '}
                                            isLoading={isLoading}
                                            isFullWith={true}
                                            label={t("app.board.task_add")}
                                        />

                                    </form>
                                </BoardActionsMenu>


                            </li>
                        )
                    }
                    
                </ul>

            </div>


            {isOpenRemove && (
                <RemovePopup
                    isOpen={isOpenRemove}
                    setIsOpen={setOpenRemove}
                    detail={removeDetail}
                    setDetail={(res) => {
                        onUpdate(res);
                    }}
                />
            )}

        </>
    )
}



// ATTACHMENTS
const AttachmentsCard = ({ boardData, taskData, onUpdateAttachments, onRefresh, canEdit }) => {

    const [selectedAttachments, setSelectedAttachments] = useState(taskData?.task_attachments ?? []);
    const [isLoading, setLoading] = useState(false);


    const { t } = useTranslation();


    const onSubmit = async (data) => {
        postElement(getPathParam([BOARD, boardData.id, TASK, taskData.id, ATTACHMENT]), data)
            .then(response => {
                setSelectedAttachments(response.attachments);
                toast.success(t("app.tasks.update_success"));
                onRefresh(true);
            }).catch(e => {
                //toast.error(e.message);
                setLoading(false);
            });

    };

    const onRemove = async (id) => {
        deleteElement(getPathParam([BOARD, boardData.id, TASK, taskData.id, ATTACHMENT, id]))
            .then(response => {
                setSelectedAttachments(response.attachments);
                toast.success(t("app.tasks.update_success"))
                onRefresh(true);
            }).catch(e => {
                toast.error(e.message);
            });

    };
    const onUpload = async (data) => {
        setLoading(true);
        data.map(async r => {
            await setTimeout(async function () {
                await onSubmit({
                    file_name: r.name,
                    file: {
                        media_url: r.reference
                    }
                })
            }, 500);
        })
        setLoading(false);
    };

    useEffect(() => {
        onUpdateAttachments(selectedAttachments);
        // eslint-disable-next-line
    }, [selectedAttachments])


    return (
        <>
            <div className="mt-6 ">
                <h1 className="text-xl font-bold text-blue-1000 mt-6 mb-2">{t("app.board.attachments")}</h1>


                <BoxUploadMultifile
                    onUpload={async (data) => {
                        await onUpload(data);
                    }}
                    onException={(e) => { }}
                    isLoading={isLoading}
                    canEdit={canEdit}
                    isDepend={true}
                    genericDocuments={selectedAttachments}
                    onRemoveGeneric={(id) => {
                        setSelectedAttachments(selectedAttachments.filter(function (obj) {
                            return obj.id !== id;
                        }));
                        onRemove(id);
                    }}
                />
            </div>
        </>
    )
}


// DESCRIPTION
const DescriptionCard = ({ taskData, onChange, canEdit }) => {


    const [second, setSecond] = useState(0);
    const [startCounter, setStartCounter] = useState(false);
    const { t } = useTranslation();

    const {
        control,
        setValue,
        watch
    } = useForm();

    useEffect(() => {
        const interval = setInterval(() => {
            if (startCounter && canEdit) {
                setSecond(second + 1);
                if ((watch('description') !== undefined && watch('description') !== '' && ((taskData?.description ?? '') !== watch('description'))) && second === 2) {
                    setStartCounter(false);
                    setSecond(0);
                    onChange('description', watch('description'));
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    });



    useEffect(() => {
        setValue('description', taskData?.description ?? '');
    }, [setValue, taskData])


    return (
        <>
            <div className="mt-6">
                <h1 className="text-xl font-bold text-blue-1000 mt-6 mb-2">{t("app.board.description")}</h1>

                <div>
                    <Controller
                        name="description"
                        rules={{ required: false }}
                        control={control}
                        render={({ field: { onChange, value, name },
                            fieldState: { error },
                        }) => (
                            <InputEmailEditor
                                className={'activity_quill'}
                                errors={error}
                                imageEdit={false}
                                defaultValue={value}
                                isDisabled={!canEdit}
                                name={name}
                                onChange={(e) => {
                                    onChange(e);
                                    setSecond(0);
                                    setStartCounter(true);

                                }}
                            />
                        )}
                    />
                </div>
               
            </div>
        </>
    )
}

// ACTIIVITY
const ActivityCard = ({ boardData, taskData, isNewTask, onRefresh, canEdit }) => {




    const { t } = useTranslation();

   
    const { detail } = useSelector((state) => state.user);

    const [isLoadingActivity, setLoadingActivity] = useState(isNewTask === false);
    const [activities, setActivities] = useState([]);


    const [editActivity, setEditActivity] = useState(false);

    

    // Remove Details
    const [isOpenRemove, setOpenRemove] = useState(false);
    const [removeDetail, setRemoveDetail] = useState({});

    
    //ATIVITY

     useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchData() {
            if (boardData && taskData && !isNewTask) {
                setLoadingActivity(true);
                try {
                    const res = await getElement(getPathParam([BOARD, boardData.id, TASK, taskData.id, ACTIVITIES ]), signal);
                    setActivities(res.activities);
                
                } catch (e) {
                    setLoadingActivity(false);
                } finally {
                    setLoadingActivity(false);
                }
            }
        }

        fetchData();
        return () => {
            controller.abort();
        };
         // eslint-disable-next-line
     }, []);



    const colorNamesWithAt = (text) => {
        const pattern = /@[a-zA-Z0-9._]+/g;
        const parts = text.split(pattern);
        const matches = text.match(pattern);

        if (!matches) {
            return <span>{text}</span>;
        }

        return parts.reduce((acc, part, index) => {
            acc.push(<span key={`part-${index}`}>{part}</span>);
            if (matches[index]) {
                acc.push(<span key={`match-${index}`} className="p-1 border border-gray-200 bg-gray-100  text-gray-900 rounded-md ">{matches[index]}</span>);
            }
            return acc;
        }, []);
    };


    return (
        <>
            <h1 className="text-xl font-bold text-blue-1000 mt-6 mb-3">{t("app.board.activity_title")}</h1>
                <LoaderWrapper isLoading={isLoadingActivity}>
                    <div className="flow-root ">
                        <ul className="-mb-8">
                            {activities.map((activity, activityItemIdx) => (
                                <li key={activity.id}>
                                    <div className="relative pb-8">
                                        {activityItemIdx !== activities.length - 1 ? (<span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />) : null}
                                        <div className="relative flex items-start space-x-3">
                                            <div className="relative">

                                                {
                                                    activity.user.picture ? (
                                                        <img className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 object-contain" src={activity.user.picture.reference} alt="" />
                                                    ) : (
                                                        <Initials
                                                            color={activity.user?.color ?? ''}
                                                            name={activity.user?.fullname ?? ''}
                                                            iconClass={' h-10 w-10 justify-center'}
                                                        />
                                                    )
                                                }
                                            </div>



                                            <div className="min-w-0 flex-1">
                                                <div className="flex justify-between items-center">
                                                    <div>
                                                        <div className="text-sm">
                                                            <h1 className="font-medium text-gray-900">
                                                                {activity.user.fullname}
                                                            </h1>
                                                        </div>
                                                        {
                                                            activity.created === activity.updated ? (
                                                                <p className="mt-0.5 text-xs text-gray-500">
                                                                    {t("app.board.activity_created_on", { date: getCustomFormat(activity.created, "DD/MM/YYYY"), time: getCustomFormat(activity.created, "HH:mm") })}
                                                                </p>
                                                            ) : (
                                                                <p className="mt-0.5 text-xs text-gray-500">
                                                                    {t("app.board.activity_edited_on", { date: getCustomFormat(activity.updated, "DD/MM/YYYY"), time: getCustomFormat(activity.updated, "HH:mm") })}
                                                                </p>
                                                            )
                                                        }
                                                    </div>


                                                    {
                                                        (activity.user.id === detail.id && canEdit) && (
                                                            <BoardActionsMenu
                                                                btnClass="ml-4"
                                                                icon={(<DotsVerticalIcon className="h-4 w-4 text-gray-400" />)}
                                                            >

                                                                <div
                                                                    className="cursor-pointer -m-3 mb-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                    onClick={() => {
                                                                        setEditActivity(activity)
                                                                    }}
                                                                >
                                                                    <div className="flex h-5 w-5  shrink-0 items-center justify-center text-gray-900">
                                                                        <PencilIcon aria-hidden="true" />
                                                                    </div>
                                                                    <div className="ml-4">
                                                                        <p className="text-sm font-medium text-gray-900">
                                                                            {t("app.board.activity_edit")}
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className="cursor-pointer  -m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                                                                    onClick={() => {
                                                                        const removeDetail = {
                                                                            title: t("app.board.remove_activity_title"),
                                                                            message: t("app.board.remove_activity_description"),
                                                                            endpoint: getPathParam([BOARD, boardData.id, TASK, taskData.id, ACTIVITY, activity.id])
                                                                        };
                                                                        setRemoveDetail(removeDetail);
                                                                        setOpenRemove(true);
                                                                    }}
                                                                >
                                                                    <div className="flex h-5 w-5  shrink-0 items-center justify-center text-red-900">
                                                                        <TrashIcon aria-hidden="true" />
                                                                    </div>
                                                                    <div className="ml-4">
                                                                        <p className="text-sm font-medium text-red-900">
                                                                            {t("app.board.remove_activity")}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </BoardActionsMenu>
                                                        )
                                                    }

                                                </div>
                                                <div className="mt-2 text-sm text-gray-700 whitespace-pre-line">
                                                    <p>{colorNamesWithAt(activity.activity)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
            </LoaderWrapper>

                    {
                    canEdit && (
                        <div className={classNames(
                            "flex items-start space-x-4 ",
                            activities.length > 0 ? "mt-6" : "mt-2"
                        )}>
                            <div className="flex-shrink-0">
                                {
                                    detail.picture ? (
                                        <img className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 object-contain" src={detail.picture.reference} alt="" />
                                    ) : (
                                        <Initials
                                            color={detail?.color ?? ''}
                                            name={detail?.firstname ?? ''}
                                            iconClass={' h-10 w-10 inline-block'}
                                        />
                                    )
                                }
                            </div>
                            <div className="min-w-0 flex-1">


                                <TextareaMention
                                    boardData={boardData}
                                    taskData={taskData}
                                    onRefresh={onRefresh}
                                    editActivity={editActivity}
                                    onSetActivities={(e) => {
                                        setActivities(e);
                                        onRefresh(true);
                                    }}
                                    onEditActivity={(e) => {
                                        setEditActivity(e);
                                    }}
                                />

                                

                            </div>
                        </div>
                    )
                    }
                    
                    
               


            {isOpenRemove && (
                <RemovePopup
                    isOpen={isOpenRemove}
                    setIsOpen={setOpenRemove}
                    detail={removeDetail}
                    setDetail={(res) => {
                        setActivities(res.activities);
                        onRefresh(true);
                    }}
                />
            )}


           

        </>
    )
}



const TextareaMention = ({ boardData, taskData, editActivity, onEditActivity, onSetActivities }) => {

    const {
        formState: { errors },
        handleSubmit,
        setValue,
        watch
    } = useForm();
    const { t } = useTranslation();

    const keys = ['@'];

    const [items, setItems] = useState([]);
    const [displayedItems, setDisplayedItems] = useState([]);
    const [caretPosition, setCaretPosition] = useState({ top: 0, left: 0 });
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [showPopover, setShowPopover] = useState(false);
    const [key, setKey] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const textareaRef = useRef(null);

    //POST
    const [isLoadingCreate, setLoadingCreate] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (textareaRef.current && !textareaRef.current.contains(event.target)) {
                closeMenu();
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const filteredItems = () => {
        if (!searchText) {
            return items;
        }
        const lowerSearchText = searchText.toLowerCase();
        return items.filter(item => {
            const text = item.identifier.toLowerCase();
            return text.includes(lowerSearchText);
        });
    };

    const updateDisplayedItems = () => {
        setSelectedIndex(0);
        setDisplayedItems(filteredItems().slice(0, 4));
    };

    const getCaretCoordinates = (element, position) => {
        const properties = [
            'boxSizing', 'width', 'height', 'overflowX', 'overflowY', 'borderTopWidth', 'borderRightWidth', 'borderBottomWidth', 'borderLeftWidth',
            'paddingTop', 'paddingRight', 'paddingBottom', 'paddingLeft', 'fontStyle', 'fontVariant', 'fontWeight', 'fontStretch', 'fontSize', 'lineHeight',
            'fontFamily', 'textAlign', 'textTransform', 'textIndent', 'textDecoration', 'letterSpacing', 'wordSpacing'
        ];

        const div = document.createElement('div');
        const style = div.style;
        const computed = getComputedStyle(element);

        style.whiteSpace = 'pre-wrap';
        style.position = 'absolute';
        style.visibility = 'hidden';
        style.top = element.offsetTop + parseInt(computed.borderTopWidth) + 'px';
        style.left = "400px";

        properties.forEach(prop => {
            style[prop] = computed[prop];
        });

        div.textContent = element.value.substring(0, position);
        if (element.nodeName === 'INPUT') {
            div.textContent = div.textContent.replace(/\s/g, "\u00a0");
        }

        const span = document.createElement('span');
        span.textContent = element.value.substring(position) || '.';
        div.appendChild(span);

        document.body.appendChild(div);
        const coordinates = { top: span.offsetTop + parseInt(computed['borderTopWidth']) - element.scrollTop, left: span.offsetLeft + parseInt(computed['borderLeftWidth']) };
        document.body.removeChild(div);
        return coordinates;
    };

    const checkKey = () => {
        const index = textareaRef.current.selectionStart;
        if (index >= 0) {
            const lastKeyData = keys.map(key => ({
                key,
                keyIndex: textareaRef.current.value.lastIndexOf(key, index - 1),
            })).sort((a, b) => b.keyIndex - a.keyIndex)[0];
            if (lastKeyData.keyIndex !== -1) {
                const searchText = textareaRef.current.value.substring(lastKeyData.keyIndex + 1, index);
                if (!/\s/.test(searchText)) {
                    setKey(lastKeyData.key);
                    setSearchText(searchText);
                    updateDisplayedItems();
                    setShowPopover(true);
                    setItems(lastKeyData.key === '@' ? boardData.assigned_users : []);
                    setCaretPosition(getCaretCoordinates(textareaRef.current, lastKeyData.keyIndex));
                    return;
                }
            }
        }
        closeMenu();
    };

    const closeMenu = () => {
        setShowPopover(false);
        setKey(null);
    };

    const applyMention = (itemIndex) => {
        // users data
        const item = displayedItems[itemIndex];

        // Posizione dove scrivere
        const index = textareaRef.current.selectionStart;
        const text = textareaRef.current.value;
        const keyIndex = text.lastIndexOf(key, index - 1);
        const value = (key || '') + item.identifier;
        const newText = text.slice(0, keyIndex) + value + text.slice(index);
        textareaRef.current.value = newText;
        setValue('activity', newText);
        closeMenu();
    };

    const handleInput = (e) => {
        checkKey();
    };


    useEffect(() => {
        if (editActivity){
            setValue('id', editActivity.id);
            setValue('activity', editActivity.activity);
            textareaRef.current.value = editActivity.activity;
            document.getElementById("activity").focus();
         }
     }, [editActivity, setValue])



    const handleKeyDown = (e) => {
        if (key) {
            if (keys.includes(e.key)) {
                e.preventDefault();
                e.stopPropagation();
            }
            if (e.key === 'ArrowDown' || e.keyCode === 40) {
                setSelectedIndex((selectedIndex + 1) % displayedItems.length);
                e.preventDefault();
                e.stopPropagation();
            }
            if (e.key === 'ArrowUp' || e.keyCode === 38) {
                setSelectedIndex((selectedIndex - 1 + displayedItems.length) % displayedItems.length);
                e.preventDefault();
                e.stopPropagation();
            }
            if ((e.key === 'Enter' || e.key === 'Tab' || e.keyCode === 13 || e.keyCode === 9) &&
                displayedItems.length > 0) {
                applyMention(selectedIndex);
                e.preventDefault();
                e.stopPropagation();
            }
            if (e.key === 'Escape' || e.keyCode === 27) {
                closeMenu();
                e.preventDefault();
                e.stopPropagation();
            }
        }
    };

    const handleKeyUp = (e) => {
        if (key && e.key === 'Escape') {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const handleScroll = () => {
        setCaretPosition(getCaretCoordinates(textareaRef.current, textareaRef.current.selectionStart));
    };


    const onSubmit = async (data) => {
        setLoadingCreate(true);

        const regex = /@\w+(\.\w+)*\b/g;
        const matches = data.activity.match(regex);
        let usersFund = matches || [];


        if (usersFund.length > 0){
            data['mentioned_user'] = [];
            // eslint-disable-next-line
            if (editActivity){
                const matchesEdited = editActivity.activity.match(regex);
                let usersFundEditd = usersFund.filter(obj1 => !(matchesEdited || []).some(obj2 => obj1 === obj2));

                // eslint-disable-next-line
                boardData.assigned_users.map(r => {
                    if (usersFundEditd.filter(n => n.substring(1) === r.identifier).length > 0) {
                        data['mentioned_user'].push({ user: { id: r.id } });
                    }
                });
            }else{
                // eslint-disable-next-line
                boardData.assigned_users.map(r => {
                    if (usersFund.filter(n => n.substring(1) === r.identifier).length > 0) {
                        data['mentioned_user'].push({ user: { id: r.id } });
                    }
                });
            }
        }

      
        if (data.id) {
            putElement(getPathParam([BOARD, boardData.id, TASK, taskData.id, ACTIVITY, data.id]), data)
                .then(response => {
                    onSetActivities(response.activities);
                    toast.success(t("app.tasks.update_success"))
                    setLoadingCreate(false);
                    setValue('id', null);
                    setValue('activity', null);
                    textareaRef.current.value = null;
                }).catch(e => {
                    toast.error(e.message);
                    setLoadingCreate(false);
                });
        } else {
            postElement(getPathParam([BOARD, boardData.id, TASK, taskData.id, ACTIVITY]), data)
                .then(response => {
                    onSetActivities(response.activities);
                    toast.success(t("app.tasks.update_success"))
                    setLoadingCreate(false);
                    setValue('activity', null);
                    textareaRef.current.value = null;
                    onEditActivity(false);
                }).catch(e => {
                    toast.error(e.message);
                    setLoadingCreate(false);
                });
        }
        
    };



    return (
        <>

            <form onSubmit={handleSubmit(onSubmit)} className="relative">
                <div className="overflow-hidden rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-blue-900">
                    <textarea
                        ref={textareaRef}
                        className="w-full  break-words overflow-y-auto resize-none m-0 px-3 py-2  border-[none] block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        rows="3"
                        placeholder={t("app.board.add_comment")}
                        onInput={handleInput}
                        onKeyDown={handleKeyDown}
                        onKeyUp={handleKeyUp}
                        onScroll={handleScroll}
                        id={'activity'}
                        onChange={(r) => {
                            setValue('activity', r.target.value)
                        }}
                        errors={errors.activity}

                    ></textarea>
                    {showPopover && (
                        <div className="absolute z-30" style={{ top: `${caretPosition.top - 5}px`, left: `${caretPosition.left - 10}px` }}>
                            <span className="absolute top-0 left-0 w-2 h-2 bg-white transform rotate-45 -mt-1 ml-3 border-gray-300 border-r border-b z-20"></span>
                            <div className="bg-white overflow-auto rounded-lg shadow-md z-10  border border-gray-300 text-gray-800 text-xs absolute bottom-full">
                                <ul className="list-reset">
                                    {!displayedItems.length ? (
                                        <li>
                                            <span className="block px-4 py-1 text-gray-500 text-nowrap whitespace-nowrap">Scrivi un nome</span>
                                        </li>
                                    ) : (
                                        displayedItems.map((item, index) => (
                                            <li
                                                key={item.id}
                                                onClick={() => applyMention(index)}
                                                className={`px-4 py-1 flex no-underline hover:no-underline transition-colors duration-100 text-nowrap whitespace-nowrap cursor-pointer ${selectedIndex === index ? 'bg-gray-900 text-white' : 'hover:bg-gray-100'}`}
                                            >
                                                <span className="font-bold">{item.fullname}</span>
                                                <span className="ml-2">[{key || ''}{item.identifier}]</span>
                                            </li>
                                        ))
                                    )}
                                </ul>
                            </div>
                        </div>
                    )}

                    <div className="py-2" aria-hidden="true">
                        <div className="py-px">
                            <div className="h-9" />
                        </div>
                    </div>
                </div>


                <div className="absolute before:flex-1 inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                    <div className="flex-shrink-0">
                        <ButtonSubmit
                            btnColor={'bg-blue-1000 hover:bg-blue-900 '}
                            isLoading={isLoadingCreate}
                            isFullWith={true}
                            disabled={!watch('activity')}
                            label={t("app.board.confirm_coment")}
                        />
                    </div>
                </div>
            </form>


            
        
        </>
    );
}




export default TaskManage;